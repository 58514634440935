import React, { useEffect, useState } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import FilterComp from "../../../../components/Admin/FilterComp";
import FilterCompRadio from "../../../../components/Admin/FilterCompRadio";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GET_ALL_AMCS, GET_TIMESTAMPS } from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import { asset_Sub_Category } from "../../../../components/Data/assetSubCatogory";
import { fabClasses } from "@mui/material";

const fundType = [
  { name: "Assisted services", value: "Assisted services" },
  { name: "DIY Funds", value: "DIY Funds" },
];

const amcType = [
  { name: "Major", value: "Major" },
  { name: "Minor", value: "Minor" },
];

const Filters = ({ set_filter, reset_filter }) => {
  const useStyles = makeStyles({
    reset: {
      fontSize: "0.9rem",
      color: "#0C97F0",
      textAlign: "end",
      cursor: "pointer",
      marginTop: "2rem",
    },
    root: {
      width: "100%",
      "& .MuiAccordion-root:before": {
        height: "0px",
        top: -4,
      },
      "& .MuiAccordion-rounded:first-child ": {
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
      },
      "& .MuiAccordion-rounded:last-child ": {
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
      },
      "& .MuiFormControlLabel-root": {
        padding: "5px 0px",
      },
      " & .MuiAccordion-root.Mui-expanded": {
        margin: "0px",
      },
      "& .MuiAccordionSummary-root.Mui-expanded": {
        minHeight: "15px",
      },
      //  " & .MuiAccordionSummary-content.Mui-expanded":{
      //   marginBottom:"0px"
      //  }
    },

    checkBox: {
      fontSize: "0.8rem",
      "& .MuiTypography-body1": {
        fontSize: "0.85rem",
      },
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: "#326CF9",
      },
    },
    accordion: {
      borderRadius: "10px",
    },

    heading: {
      fontSize: "0.85rem",
      fontWeight: 500,
      "& .MuiIconButton-root": {
        padding: "0px",
      },
    },
  });

  const classes = useStyles();

  const [assisted, setAssisted] = useState(true);
  const [allAmcs, setAllAmcs] = useState([]);
  const [reset, setReset] = useState(false);
  const [timestamps, setTimestamps] = useState([]);

  const get_timestamps = async () => {
    const httpConfig = {
      method: "GET",
      //tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_TIMESTAMPS}`, httpConfig);
      setTimestamps(response.data?.data);
    } catch {}
  };

  const get_all_amc = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      params: { name: "" },
    };
    try {
      const response = await httpInstance(`${GET_ALL_AMCS}`, httpConfig);
      let arry = response.data?.data?.map((ele) => {
        return {
          name: ele.amcName,
          value: ele.amcCode,
        };
      });
      setAllAmcs(arry);
    } catch {}
  };

  useEffect(() => {
    get_all_amc();
    get_timestamps();
  }, []);

  //--------------------------filter fundtype---------------------------------------//
  const filter_fundType = (e) => {
    if (e.target.value == "assisted") {
      setAssisted(true);
      set_filter({ assisted: true });
    } else {
      setAssisted(false);
      set_filter({ assisted: false });
    }
  };

  //----------------------------filter amctype----------------------------------------//
  const filter_amcType = (arry) => {
    set_filter({
      major: arry.includes("Major"),
      minor: arry.includes("Minor"),
    });
  };
  //---------------------------filter amc--------------------------------------------//
  const filter_amc = (arry) => {
    let amcArry = arry.map((ele) => {
      return {
        amc: ele,
      };
    });
    set_filter({ fundHouse: amcArry });
  };
  //-----------------------filter catogory-------------------------------------------------------//
  const filter_category = (arry) => {
    set_filter({ assetSubCategory: arry.toString() });
  };
  //---------------------------------reset filter-------------------------------------------//
  const handle_reset = () => {
    setReset(!reset);
    reset_filter();
  };

  const filter_timestamp = (arry) => {
    set_filter({ assistedServicesTimestampId: arry.toString() });
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.reset} onClick={handle_reset}>
        Reset
      </Typography>

      <Accordion variant="outlined" className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          //aria-controls="panel1a-content"
          //id="panel1a-header"
          className={classes.heading}
        >
          Type of Fund
        </AccordionSummary>
        <AccordionDetails className={classes.checkBox}>
          <FormGroup>
            <FormControlLabel
              //key={i}
              control={
                <Checkbox
                  color="primary"
                  value="assisted"
                  checked={assisted}
                  onChange={filter_fundType}
                />
              }
              label={"Assisted services"}
            />

            <FormControlLabel
              //key={i}
              control={
                <Checkbox
                  color="primary"
                  value="browse"
                  checked={!assisted}
                  onChange={filter_fundType}
                />
              }
              label={"DIY Funds"}
            />
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      <FilterComp
        title={"Major/Minor Fund Houses"}
        data={amcType}
        handle_filter={filter_amcType}
        reset={reset}
      />
      <FilterComp
        title={"Fund Houses"}
        data={allAmcs}
        handle_filter={filter_amc}
        reset={reset}
      />
      {/* <FilterComp title={"Active/ Inactive"} data={amcType}  reset={reset}/> */}
      <FilterComp
        title={"Category"}
        data={asset_Sub_Category}
        handle_filter={filter_category}
        reset={reset}
      />
      <FilterCompRadio
        title={"Timestamps"}
        data={timestamps}
        handle_filter={filter_timestamp}
        reset={reset}
      />
    </div>
  );
};

export default Filters;
