import React from 'react'
import { Box,Typography } from '@material-ui/core'
import Questionare from '../../../components/Admin/ViewChanges/Questionare'

const QuestionaryChanges = ({data,type}) => {
  return (
    <div>
         {type.justification=="Update" && 
         <Box>
            <Typography>Existing Data</Typography>
            <Questionare data={data?.existingList?.questionaire} />
        </Box> 
        }
        <Box mt={type.justification=="Update" ? 5 : 0}>
            <Typography>{type.justification=="Update" ? "Proposed Data" : type.justification}</Typography>
            <Questionare data={data?.proposedList?.questionaire}/>
        </Box>
    </div>
  )
}

export default QuestionaryChanges