import { Box, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useStyles } from "../styles";
import Lottie from "react-lottie";
import animationData from "../../../lotties/succes.json";
import { GET_PERSONAL_DETAILS } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { Link } from "react-router-dom";
import { namespace } from "../../../environment";

function Step14() {
  const classes = useStyles();
  const [userName, setUserName] = useState("");

  const get_user = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await httpInstance(
        `${GET_PERSONAL_DETAILS}`,
        httpConfig
      );
      setUserName(response.data?.data?.fullName);
    } catch {}
  };

  useEffect(() => {
    get_user();
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      <Box display={"flex"} justifyContent="center">
        <Box display={"flex"} alignItems="flex-start" flexDirection={"column"}>
          <Box fontSize={"1.8rem"} fontWeight={400} mb={2}>
            {`Congratulations ${userName}`}
          </Box>
          <Box>
            <Lottie options={defaultOptions} height={300} width={300} />
          </Box>
          <Box fontSize={"1.2rem"} fontWeight={400} my={3}>
            Your Investment Account Created
          </Box>
          <Box>
            <Link to={`${namespace}/dashboard/mutualfund/diy`}>
              {" "}
              <button className={classes.primaryButton}>Get Started</button>
            </Link>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Step14;
