import React, { useState,useEffect } from "react";
import {
  Box,
 
  Container,
  
  makeStyles,
  
} from "@material-ui/core";


function ThirdSection({handleShowModal}) {
  let [plan, setPlan] = useState("");
  const APIKEY = "goldapi-1ig8tkzg3zu8e-io";
  const [result, setResult] = useState([]);
  const [active, setActive] = useState("");
  const [goldRate,setGoldRate]=useState('')
  const [silverRate,setSilverRate]=useState('')
  const [tabData,setTabData]=useState("buy")

  const [grams,setGrams]=useState('')
  const [rate,setRate]=useState('')

  const [metal, setMetal] = useState("XAU");

  useEffect(()=>{
    getData()
  },[])

  const getData = async () => {

var requestOptions = {
  method: 'GET',
  
};
    try{
      const agm=await fetch(
        `https://www.augmont.com/api/digital-gold/rates`,requestOptions
      )

      
     const allRates=await agm.json()
    
    setGoldRate(allRates?.rate?.rates?.gBuy)
    setSilverRate(allRates?.rate?.rates?.sBuy)
    }
    catch(error){
      
    }
    
  };


  const handleGramChange=(e)=>{
    e.target.value = e.target.value.replace(/[^0-9.]/g, '').toLowerCase()
    setGrams(e.target.value)
    
    if(metal=="XAU"){
      setRate((goldRate * e.target.value).toFixed(2) || '')
    }else{
      setRate((silverRate* e.target.value).toFixed(2) || '')
    }
   
  }

  const handleRateChange=(e)=>{
    e.target.value = e.target.value.replace(/[^0-9.]/g, '').toLowerCase()
    setRate(e.target.value)
    if(metal=="XAU"){
      setGrams(e.target.value/(goldRate)|| " ")
    }else{
      setGrams(e.target.value/(silverRate)|| " ")
    }
  }

  const change_fixed_rate=(rate)=>{
    setActive(rate)
    setRate(rate)
    if(metal=="XAU"){
      setGrams(rate/(goldRate)|| " ")
    }else{
      setGrams(rate/(silverRate)|| " ")
    }
  }

  const handle_metal=(metal)=>{
    setMetal(metal);
    getData(metal);
    if(metal=="XAU"){
      setRate((goldRate * grams).toFixed(2) || '')
    }else{
      setRate((silverRate* grams).toFixed(2) || '')
    }
    
  }

  const handle_tabData=(data)=>{
    setTabData(data)
  }


  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundImage: "url('./images/Component2.svg')",
      background: "#fff",
      padding: "0rem 0rem 8rem 0rem",
      backgroundPosition: "left",
      backdropFilter: "blur(10px)"
    },
    card: {
      boxShadow: "16px 16px 24px 4px rgba(103,103,103,0.08)",
      borderRadius: "24px"
    },
    heading: {
      fontSize: "2.1em",
      fontWeight: 500
    },
    tab: {
      borderBottom: "1px solid #D0D0D0"
    },
    activeBorderBottom: {
      borderBottom: "2px solid #326CF9"
    },
    tabCardLabel: {
      fontSize: "1.2rem",
      fontWeight: 500,
      color: "#272727"
    },
    checkBox: {
      height: "12px",
      width: "12px"
    },
    activeMoney: {
      border: "1px solid #326cf9",
      background: "#326CF9",
      borderRadius: "12px",
      color: "#fff",
      padding: "0.6rem 2rem",
      margin: "0 1rem 0 0",
      fontFamily:"inherit"
    },
    inActiveMoney: {
      border: "1px solid #676767",
      background: "none",
      borderRadius: "12px",
      color: "#676767",
      padding: "0.6rem 1rem",
      margin: "0 1rem 0 0",
      fontFamily:"inherit"
    },
    button: {
      borderRadius: "40px",
      color: "#fff",
      background: "#326cf9",
      fontWeight: "medium",
      fontSize: "1rem",
      padding: "1rem 4rem",
      border: "none",
      margin: "0px 2rem 0px 0px ",
      cursor:"pointer"
    },
    buttonBordered: {
      borderRadius: "40px",
      color: "#326cf9",
      background: "#fff",
      fontWeight: "medium",
      fontSize: "1rem",
      padding: "1rem 4rem",
      border: "2px solid #326cf9",
      cursor:"pointer"
    },
    inputField: {
      height: "",
      width: "",
      color: "#8E8EA9",
      padding: "10px 20px",
      borderRadius: "4px",
      border: "1px solid #DCDCE4"
    },
    inputLabel: {
      fontSize: "12px",
      color: "#474747"
    },

    cursorPointer:{
      cursor:"pointer"
    },
    rupees:{
      fontFamily:"Open Sans "
    },
   
    "@media (max-width: 900px)": {
      containerBox: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
      },
      sectionOne: {
        width: "100%",
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent:"center"
      },
      sectionInput: {},
      inputField: {
        width: "73%"
      },
      flexColumn: {
        display: "flex",
        flexDirection: "column"
      },
      arrowImage: {
        display: "none"
      },
      heading: {
        fontSize: "1.6rem",
        textAlign: "center"
      },
      tabCardLabel: {
        fontSize: "0.8rem",
        margin: "0px 0.4rem"
      },
      
      
    },

    "@media (max-width: 280px)": {
      card: {
        width:"100%"
      },
      button:{
        padding:"10px 30px"
      },
      buttonBordered:{
        padding:"10px 30px"
      },
      inputField: {
        width: "56%"
      },
      
    },

    currencyCode:{
      position:"absolute",
      marginLeft:"10px",
      marginTop:"11px",
      paddingRight:"2px",
      opacity:"0.6",
      fontFamily:"Open Sans",
      fontSize:"0.8rem"
    },
    
    
  }));
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        py={1}
        width="100%"
        className={classes.containerBox}
      >
        <Box
          display="flex"
          alignItems="start"
          justifyContent="center"
          flexDirection="column"
          width="50%"
          className={classes.sectionOne}
        >
          <Box className={classes.heading} mb={4}>
            Its that simple <br />
            just give a go!
          </Box>
          <Box >
            <img src={"/images/Arrow.png"} className={classes.arrowImage} />
          </Box>
        </Box>
        <Box px={16} width="50%" className={classes.sectionOne}>
          <Box className={classes.card}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={classes.tab}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={3}
                width="50%"
                className={classes.activeBorderBottom}
                fontWeight={500}
                onClick={(handleShowModal)}
              >
                BUY
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={3}
                width="50%"
                fontWeight={500}
                onClick={handleShowModal}
                className={classes.cursorPointer}
              >
                SELL
              </Box>
            </Box>
            <Box p={{md:3}}>
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
                width="100%"
              >
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  flexDirection="row"
                  width="50%"
                >
                  <input
                    type="radio"
                    className={classes.checkBox}
                    name="metal"
                    onChange={() =>handle_metal("XAU")}
                    checked={metal == "XAU" ? true : false}
                    style={{ height: "18px", width: "18px" }}
                  />
                  <Box ml={3} className={classes.tabCardLabel}>
                    Gold 24K 99.9
                  </Box>
                </Box>
                <Box mt={2} mx={4}></Box>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  flexDirection="row"
                  width="50%"
                >
                  <input
                    type="radio"
                    className={classes.checkBox}
                    name="metal"
                    onChange={()=>handle_metal("XAG")}
                    checked={metal == "XAG" ? true : false}
                    style={{ height: "18px", width: "18px" }}
                  />

                  <Box ml={3} className={classes.tabCardLabel}>
                    Silver 24K 99.9
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="row"
                mt={2}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="flex-start"
                  flexDirection="column"
                >
                  <Box className={classes.inputLabel}>Grams</Box>
                  <input
                    
                    type="text"
                    className={classes.inputField}
                    onChange={handleGramChange}
                    value={grams}
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  width="25%"
                >
                  <Box mt={2} mx={1}>
                    <img src="/images/convert.svg" height={20} width={20} />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="start"
                  flexDirection="column"
                >
                  <Box className={classes.inputLabel}>Rupees</Box>
                  <div className={classes.currencyWrap}>
                    <span className={classes.currencyCode}>{"₹"+" "}</span>
                  <input
                    type="text"
                    className={classes.inputField}
                    value={ rate}
                    placeholder=""
                    onChange={handleRateChange}
                  />
                  </div>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                py={3}
              >
                <Box
                  value={plan}
                  onChange={() => setPlan("500")}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-around"
                >
                  <Box
                    value="₹ 500"
                    label="₹ 500"
                    onClick={()=>change_fixed_rate("500")}
                  >
                    <button
                      className={
                        active == "500"
                          ? classes.activeMoney
                          : classes.inActiveMoney
                      }
                    >
                      
                      <span className={classes.rupees}>₹</span>{"500"}
                    </button>
                  </Box>
                  <Box
                    value="₹ 1000"
                    label="₹ 1000"
                    onClick={()=>change_fixed_rate("1000")}
                  >
                    <button
                      className={
                        active === "1000"
                          ? classes.activeMoney
                          : classes.inActiveMoney
                      }
                    >
                      <span className={classes.rupees}>₹</span>1000
                    </button>
                  </Box>
                  <Box
                    value="₹ 5000"
                    label="₹ 500"
                    onClick={()=>change_fixed_rate("5000")}
                  >
                    <button
                      className={
                        active === "5000"
                          ? classes.activeMoney
                          : classes.inActiveMoney
                      }
                    >
                      <span className={classes.rupees}>₹</span>5000
                    </button>
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="row"
              >
                <button className={classes.button} onClick={handleShowModal}>Buy</button>
                <button className={classes.buttonBordered} onClick={handleShowModal}>SIP</button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default ThirdSection;
