import React, { useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { fontSize } from "@mui/system";
import { ADD_MANDATE } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import ErrorMessage from "../../../components/Validation/ErrorMessage";

const SetupMandate = ({ show, handleClose, bankDetails }) => {
  const useStyles = makeStyles({
    root: {
      "& .MuiDialogContent-root": {
        fontSize: "0.8rem",
        lineHeight: "25px",
        padding: "20px 34px",
      },
      "& .MuiDialog-paperWidthSm": {
        minWidth: "35%",
        maxWidth: "1000px",
        maxHeight: "100%",
      },
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "center",
      alignItems: " center",
      textAlign: "center",
      padding: "5px",
      marginTop: "-10px",
      "& .MuiTypography-body1": {
        fontSize: "1.1rem",
        fontWeight: 600,
      },
    },
    closeIcon: {
      padding: "5px",
      justifyContent: "flex-end",
    },
    title1: {
      fontSize: "0.8rem",
      fontWeight: 500,
    },
    imageBack: {
      padding: "4px 5px 0px",
      background: "#ADDFFF",
      borderRadius: "50%",
      marginRight: "5px",
    },
    borderBox: {
      border: "1px solid #C0C0C0",
      borderRadius: "5px",
    },
    textgrey: {
      fontSize: "0.75rem",
      color: "#676767",
      marginTop: "10px",
    },
    text: {
      fontSize: "0.75rem",
      marginTop: "10px",
    },
    primaryButton: {
      padding: "0.2rem 2rem",
      background: "#326CF9",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 500,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      fontFamily: "inherit",
    },
    borderButton: {
      padding: "0.2rem 2rem",
      background: "#fff",
      color: "#326CF9",
      fontSize: "0.8rem",
      fontWeight: 500,
      borderRadius: "45px",
      border: "2px solid #326cf9",
      cursor: "pointer",
      fontFamily: "inherit",
    },
    primaryButton2: {
      padding: "0.8rem 5rem",
      background: "#326CF9",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      fontFamily: "inherit",
      width: "100%",
    },
    inputBox: {
      background: "#E6E6E6",
      padding: "0.7rem 0.95rem",
      borderRadius: "10px",
      border: "none",
      width: "50%",
      fontFamily: "inherit",
    },
    rupees: {
      fontFamily: "Open Sans",
    },
  });

  const classes = useStyles();

  const [custFrq, setCustFrq] = useState(false);
  const [frequency, setFrequency] = useState(100000);
  const [value, setValue] = useState(100000);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const handle_button = (value) => {
    setCustFrq(false);
    setFrequency(value);
  };

  const handle_custom = (value) => {
    setCustFrq(true);
    setFrequency(value);
    setValue("");
  };

  const handle_value = (e) => {
    console.log(e.target.value);
    if (e.target.value < 25000) {
      setError("Minimum amount should be 25000");
    } else {
      setError("");
    }
    setValue(e.target.value);
  };

  //-----------------------------submit mandate--------------------------------------------------------//
  const handle_mandate = async () => {
    if (frequency == "custom" && !value) {
      setError("Please Enter Frequency of payments");
    } else {
      setError("");
      setLoading(true);
      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          limit: frequency == "custom" ? value : frequency,
          bankId: bankDetails?.id,
          isWeb: true,
        },
      };

      try {
        const response = await httpInstance(`${ADD_MANDATE}`, httpConfig);
        // window.location.replace(response.data?.data?.tokenUrl);
        window.location.reload();
      } catch {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Dialog open={show} className={classes.root} onClose={handleClose}>
        <Box display="flex" className={classes.closeIcon}>
          {" "}
          <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
        </Box>
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Typography className={classes.title}>Set up Automandate</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.title1}>
            Select your Bank Account
          </Typography>

          <Box
            className={classes.borderBox}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={1}
            p={1}
          >
            <Box display="flex" alignItems="center">
              <img
                src={bankDetails.imageUrl}
                height="20px"
                width="20px"
                style={{ marginRight: "5px" }}
              />
              <Typography className={classes.title1}>
                {bankDetails?.bankName}
              </Typography>
            </Box>
            <Typography
              className={classes.title1}
            >{`XXXXXXX${bankDetails?.accountNumber.slice(-4)}`}</Typography>
            <input type="radio" checked={true} />
          </Box>

          <Typography className={classes.title1}>
            Specify your limit for the Automandate
          </Typography>
          <Typography className={classes.textgrey}>
            This is the Maximum amount you can invest in one go using Auto
            Mandate
          </Typography>

          <Typography className={classes.textgrey}>Popular Limits</Typography>
          <Box display="flex" justifyContent="space-between" p={2}>
            <button
              className={
                frequency == 100000
                  ? classes.primaryButton
                  : classes.borderButton
              }
              onClick={() => handle_button(100000)}
            >
              <span className={classes.rupees}>₹</span>100000
            </button>
            <button
              className={
                frequency == 50000
                  ? classes.primaryButton
                  : classes.borderButton
              }
              onClick={() => handle_button(50000)}
            >
              <span className={classes.rupees}>₹</span>50000
            </button>
            <button
              className={
                frequency == "custom"
                  ? classes.primaryButton
                  : classes.borderButton
              }
              onClick={() => handle_custom("custom")}
            >
              Custom
            </button>
          </Box>

          {custFrq && (
            <>
              <Typography className={classes.text}>Enter the Amount</Typography>
              <input className={classes.inputBox} onChange={handle_value} />
              {error && <ErrorMessage message={error} />}
            </>
          )}

          <Box width="57%">
            <Typography className={classes.textgrey}>
              Frequency of Payments
            </Typography>
            <Box
              style={{ border: "1px solid #E6E6E6", borderRadius: "10px" }}
              p={1}
            >
              <Typography>As and when presented</Typography>
            </Box>
          </Box>

          <Box width="57%">
            <Typography className={classes.textgrey}>
              Automandate Validity
            </Typography>
            <Box
              style={{ border: "1px solid #E6E6E6", borderRadius: "10px" }}
              p={1}
            >
              <Typography>Until Cancelled by me</Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            mt={5}
            flexDirection="column"
            alignItems="center"
          >
            <Typography className={classes.textgrey}>
              By confirming you agree to Neosurge’s T&C’s
            </Typography>
            <button
              className={classes.primaryButton2}
              onClick={handle_mandate}
              disabled={error ? true : false}
            >
              {loading ? (
                <CircularProgress size="1rem" color="inherit" />
              ) : (
                "Continue"
              )}
            </button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SetupMandate;
