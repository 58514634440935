import React from 'react'
import {Box,Typography,Dialog,DialogTitle,DialogContent,makeStyles,CircularProgress,IconButton,Grid} from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close';


const ConfirmPrimary = ({show, handleClose,changePrimary}) => {
    const useStyles = makeStyles({
        root:{
            "& .MuiDialogContent-root":{
                fontSize:"0.8rem",
                lineHeight:"25px",
                padding:"20px 25px"
            },
            "& .MuiDialog-paperWidthSm":{
                minWidth:"25%",
                maxWidth:"300px",
                maxHeight:"100%"
                
            },
        
        },
        dialogTitle: {
            //display: "flex",
            justifyContent: "center",
            alignItems: " center",
            textAlign: "center",
            padding: "10px 10px 0px 25px",
            marginTop:"-10px",
            "& .MuiTypography-body1":{
                fontSize:"1.1rem",
                fontWeight:600,
            }
          },
        closeIcon: {
            padding: "5px",
            justifyContent: "flex-end",
  
          },
          text:{
            fontSize:"0.8rem"
          },
          textCancel:{
            fontSize:"0.8rem",
            cursor:"pointer"
          },
          textConform:{
            fontSize:"0.8rem",
            color:"#326CF9",
            cursor:"pointer"
          }
        })

        const classes=useStyles()
  return (
    <div>

        <Dialog className={classes.root} open={show} onClose={handleClose}>
        <Box display="flex" className={classes.closeIcon}>
          {" "}
          
        </Box>
        <DialogTitle
          classes={{ root: classes.dialogTitle }}
        
        >
            <Box  display="flex" justifyContent="space-between" >
            <Typography className={classes.title}>Are you sure ?</Typography>
          
                <CloseIcon onClick={handleClose}/>
            
            </Box>
         
          
        </DialogTitle>
        <DialogContent>

            <Typography className={classes.text}>Your existing SIP installments will continue being debited from older mandate.</Typography>
            <Box mt={2}>
            <Typography className={classes.text}>Your new SIP installments will be debited from your new primary mandate.</Typography>
            </Box>

            <Box display="flex" justifyContent="space-between" px={5} py={1} mt={3} style={{borderTop:"1px solid #cccc"}}>
                <Typography className={classes.textCancel} onClick={handleClose}>Cancel</Typography>
                <Typography className={classes.textConform} onClick={changePrimary}>Confirm</Typography>
            </Box>
        </DialogContent>
        </Dialog>

    </div>
  )
}

export default ConfirmPrimary