import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const CustomToast = (toastMessage, type) => {
    
  if (type === "SUCCESS") {
    return toast.success(toastMessage, {
      //position: "top-center",     
      autoClose:1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } 

 else if (type === "FAILED") {
    return toast.error(toastMessage, {
      //position: "top-center",     
      autoClose:1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } 
}
export default CustomToast;