import React from 'react'
import NomineeForm from '../../../components/signup/nominees/NomineeForm'
import { Box, makeStyles,Typography } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";


const Index = () => {
  const useStyles = makeStyles((theme) => ({
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
    },
  }))

  const classes=useStyles()
  return (
    <div>
       <Box
        display="flex"
        alignItems="start"
        px={{ xs: 1, md: 17 }}
        pt={2}
        style={{ borderBottom: "1px solid #E6E3E3",}}
      >
      
          <Typography className={classes.subTabtext}>
            My Profile
          </Typography>
       
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography
            className={classes.textUnderline}
          >
            Nominee Details
          </Typography>
        </Box>
      </Box>
        <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        mt={3}
      >
        <Typography style={{fontSize:"1.2rem", fontWeight:500}}>Nominee Details</Typography>
        <NomineeForm type="profile"/>
        </Box>
    </div>
  )
}

export default Index