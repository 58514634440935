import React, { useEffect, useState } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import FundCard from "../../Admin/FundManagement/AssistedService/FundCard";
import localeString from "../../../hooks/LocaleString";
import SuggestedPieChart from "../../../components/MutualFund/SuggestedPieChart";
import InvsestDialogue from "./InvsestDialogue";
import {
  INVEST_ASSISTED_FUND,
  INITIATE_PAYMENT,
  GET_SUGGESTED_FUND,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";

const SuggestedFunds = () => {
  const useStyles = makeStyles({
    header: {
      fontWeight: 600,
      fontSize: "1.1rem",
      marginBottom: "1rem",
    },
    card: {
      borderRadius: "10px",
    },
    subText: {
      fontSize: "0.9rem",
    },
    text: {
      fontSize: "0.8rem",
    },
    gridItem: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      fontWeight: 600,
    },
    rupees: {
      fontFamily: "Open Sans",
    },
    primaryButton: {
      color: "white",
      backgroundColor: "#326CF9",
      border: "none",
      borderRadius: "39px",
      fontSize: "15px",
      fontWeight: "500",
      padding: "10px 5rem",
      fontFamily: "inherit",
      cursor: "pointer",
      margin: "0rem 1rem",
    },
    secondaryButton: {
      color: "#326CF9",
      backgroundColor: "#fff",
      border: "2px solid #326CF9",
      borderRadius: "39px",
      fontSize: "15px",
      fontWeight: "500",
      padding: "8px 2.5rem",
      fontFamily: "inherit",
      cursor: "pointer",
      margin: "0rem 1rem",
    },
    "@media (max-width: 600px)": {
      primaryButton: {
        padding: "8px 2rem",
      },
      secondaryButton: {
        padding: "8px 1rem",
      },
    },
  });
  const classes = useStyles();

  const [chartData, setChartData] = useState([]);
  const [showInvest, setShowInvest] = useState(false);
  const [fundData, setFundData] = useState([]);
  const [paymentId, setpaymentId] = useState("");
  const [count, setCount] = useState(2);
  const [loading, setLoading] = useState(false);
  const [loadingPrev, setLoadingPrev] = useState(false);
  const [isDisabled, setIsDisabled] = useState(0);
  const [isDisabledPrev, setIsDisabledPrev] = useState(0);

  const color = [
    "242,126,69",
    "12,151,240",
    "242,190,72",
    "147,88,244",
    "235,116,52",
    "52,171,235",
    "78,171,75",
    "171,75,104",
    "173,141,50",
    "50,130,173",
    "173,50,50",
  ];
  const pieColor = [
    "#F27E45",
    "#0C97F0",
    "#F2BE48",
    "#9358F4",
    "#eb7434",
    "#34abeb",
    "#4eab4b",
    "#ab4b68",
    "#ad8d32",
    "#3282ad",
    "#ad3232",
  ];

  const { state } = useLocation();
  const { fundsData, totalAmount, tenure } = state;
  const get_pieChartData = () => {
    let arry = fundData?.map((ele) => {
      return {
        name: ele.name,
        value: (ele.amount / totalAmount) * 100,
      };
    });
    setChartData(arry);
  };

  useEffect(() => {
    setFundData(fundsData);
  }, []);

  useEffect(() => {
    get_pieChartData();
  }, [fundData]);

  //---------------------------------------------------------------------------//

  const get_assisted = async (type) => {
    setIsDisabled(1);

    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        amount: totalAmount,
        tenure: tenure,
        userId: localStorage.getItem("user_id"),
        suggestCount: count,
      },
    };

    if (type === "previous") {
      setLoadingPrev(true);
      setFundData(fundsData);
      setIsDisabled(0);
      setCount(2);
      setIsDisabledPrev(1);
      setLoadingPrev(false);
    } else {
      try {
        setLoading(true);
        const response = await httpInstance(
          `${GET_SUGGESTED_FUND}`,
          httpConfig
        );
        setFundData([]);
        setFundData(response.data?.data);
        setLoadingPrev(false);
        setLoading(false);
        setIsDisabledPrev(0);
      } catch {
        setLoading(false);
        setLoadingPrev(false);
      }
    }
  };

  //----------------------------------invest assisted service-----------------------------------//
  const pay_assisted_service = async () => {
    //     const formData = {
    //       userId: localStorage.getItem("user_id"),
    //       suggestionList: fundData?.map((ele) => {
    //         return {
    //           id: ele.id,
    //           fundId: ele.fundId,
    //           name: ele.name,
    //           type: ele.type,
    //           amount: ele.amount,
    //           isThematic: ele.isThematic,
    //         };
    //       }),
    //     };
    //     const httpConfig = {
    //       method: "POST",
    //       tokenType: "user",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       data: formData,
    //     };
    //     try {
    //       const response = await httpInstance(
    //         `${INVEST_ASSISTED_FUND}`,
    //         httpConfig
    //       );
    //       console.log(response.data?.data);
    //       setpaymentId(response.data?.data);
    setShowInvest(true);
    //     } catch {}
  };

  return (
    <div>
      <Box px={{ xs: 2, md: 14 }}>
        <Typography className={classes.header}>Fund Allocations</Typography>
        {fundData?.map((fund, i) => {
          return (
            <Box
              key={fund.id}
              py={3}
              pl={1}
              pr={3}
              mt={4}
              className={classes.card}
              style={{ background: `rgba(${color[i % 12]},0.2)` }}
            >
              <Grid container>
                <Grid item xs={3} md={1}>
                  <Box pl={2}>
                    <img src={fund.img_url} height="50px" width="50px" />
                  </Box>
                </Grid>
                <Grid item xs={9} md={7}>
                  <Box>
                    <Typography>{fund.name}</Typography>
                    <Grid container>
                      <Grid item xs={6} md={4} className={classes.subText}>
                        {fund.isThematic ? "Thematic" : "Non Thematic"}
                      </Grid>
                      <Grid item xs={6} md={4} className={classes.subText}>
                        <span className={classes.rupees}>₹ </span>
                        {fund.nav}
                      </Grid>
                    </Grid>

                    <Box mt={2}>
                      <Grid container>
                        <Grid item xs={6} md={4}>
                          <Typography
                            style={{
                              color: `rgba(${color[i % 12]})`,
                              fontWeight: 600,
                            }}
                          >
                            {" "}
                            {fund.performance?.oneY}%
                          </Typography>
                          <Typography className={classes.text}>
                            Returns( 1yr )
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <Typography
                            style={{
                              color: `rgba(${color[i % 12]})`,
                              fontWeight: 600,
                            }}
                          >
                            {" "}
                            {fund.performance?.threeY}%
                          </Typography>
                          <Typography className={classes.text}>
                            Returns( 3yr )
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <Typography
                            style={{
                              color: `rgba(${color[i % 12]})`,
                              fontWeight: 600,
                            }}
                          >
                            {" "}
                            {fund.performance?.fiveY}%
                          </Typography>
                          <Typography className={classes.text}>
                            Returns( 5yr )
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={2}
                  className={classes.gridItem}
                  style={{ color: `rgba(${color[i % 12]})` }}
                >
                  Fund Allocated
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={2}
                  className={classes.gridItem}
                  style={{ color: `rgba(${color[i % 12]})` }}
                >
                  <span className={classes.rupees}>₹ </span>{" "}
                  {localeString(fund.amount)}
                </Grid>
              </Grid>
            </Box>
          );
        })}

        {/* Pie chart */}
        <Box mt={5}>
          <Box py={5}>
            <Typography className={classes.header}>Fund Allocations</Typography>
            <SuggestedPieChart
              data={chartData}
              colors={pieColor}
              fundSize={totalAmount}
              suggested={true}
            />
          </Box>
          <Box display="flex" justifyContent="center" mt={2}>
            <button
              disabled={isDisabledPrev === 1 ? true : false}
              className={classes.secondaryButton}
              onClick={() => {
                get_assisted("previous");
              }}
            >
              {" "}
              {loadingPrev ? (
                <CircularProgress
                  size="1rem"
                  color="inherit"
                  style={{ margin: "0px 25px" }}
                />
              ) : (
                " Previous Suggestions"
              )}
            </button>
            <button
              className={classes.secondaryButton}
              onClick={() => {
                get_assisted("next");
              }}
              disabled={isDisabled === 1 ? true : false}
            >
              {" "}
              {loading ? (
                <CircularProgress
                  size="1rem"
                  color="inherit"
                  style={{ margin: "0px 25px" }}
                />
              ) : (
                " Other Suggestions"
              )}
            </button>
            <button
              className={classes.primaryButton}
              onClick={pay_assisted_service}
            >
              Invest
            </button>
          </Box>
        </Box>
      </Box>

      {/* <InvsestDialogue
        show={showInvest}
        handleClose={() => setShowInvest(false)}
        paymentId={paymentId}
        totalAmount={totalAmount}
      /> */}
      <InvsestDialogue
        show={showInvest}
        handleClose={() => setShowInvest(false)}
        fundData={fundData}
        totalAmount={totalAmount}
      />
    </div>
  );
};

export default SuggestedFunds;
