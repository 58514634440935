import React from 'react'
import OrderStatus from '../../containers/Portfolio/DigitalGold/OrderStatus'

const Order = () => {
  return (
    <div>
        <OrderStatus/>
    </div>
  )
}

export default Order