import React, { useState } from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import SetupMandate from "./SetupMandate";
import moment from "moment";
import { ACTIVATE_MANDATE, DELETE_MANDATE } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import Confirmation from "../../../components/MutualFund/InfoMsg/Confirmation";
import EditIfsc from "./EditIfsc";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useSelector } from "react-redux";
import { namespace } from "../../../environment";
import { useNavigate } from "react-router-dom";

const AutoMandate = ({ bankMandate, bankDetails, isPrimary, get_all_bank }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      border: "1px solid #C0C0C0",
      borderRadius: "5px",
    },

    title: {
      fontSize: "1.1rem",
      padding: "0px 10px",
      fontWeight: 500,
    },

    header: {
      fontSize: "1rem",
      padding: "0px 10px",
      fontWeight: 400,
    },
    text: {
      fontSize: "0.8rem",
    },
    primaryButton: {
      padding: "0.8rem 2rem",
      background: "#326CF9",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      marginTop: "3rem",
      fontFamily: "inherit",
    },
    dangerButton: {
      padding: "0.8rem 2rem",
      background: "#dc3545",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      marginTop: "3rem",
      fontFamily: "inherit",
    },
    imageBack: {
      padding: "4px 5px 0px",
      background: "#ADDFFF",
      borderRadius: "50%",
      width: "fit-content",
    },
    textblue: {
      fontSize: "0.75rem",
      fontWeight: 500,
      color: "#0C97F0",
      padding: "0px 10px",
    },
    text2: {
      fontSize: "0.8rem",
      padding: "8px 0px",
      color: "#676767",
    },
    text3: {
      fontSize: "0.8rem",
      padding: "8px 0px",
      fontWeight: 600,
    },

    editIcon: {
      padding: "5px",
      color: "#326CF9",
      cursor: "pointer",
    },
  }));
  const classes = useStyles();

  const [showMandate, setShowMandate] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const { userStore } = useSelector((state) => state);
  const navigate = useNavigate();
  const handle_Close = () => setShowMandate(false);

  const handle_mandate = async () => {
    if (bankMandate?.status == "CREATED") {
      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        params: { mandateId: bankMandate.mandateId, isWeb: true },
      };

      try {
        const response = await httpInstance(`${ACTIVATE_MANDATE}`, httpConfig);
        if (response.data?.data?.tokenUrl == null) {
          navigate(`${namespace}/my-profile/bank-and-autoMandate-progress`, {
            state: { mandateId: bankMandate.mandateId },
          });
        } else {
          window.location.replace(response.data?.data?.tokenUrl);
        }
      } catch {}
    } else {
      setShowMandate(true);
    }
  };

  const handle_confirm = () => {
    setShowInfo(false);
    setShowEdit(true);
  };

  const deleteMandate = async (id) => {
    const httpConfig = {
      method: "DELETE",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { mandateId: id },
    };

    try {
      const response = await httpInstance(`${DELETE_MANDATE}`, httpConfig);
      window.location.reload();
    } catch {}
  };
  return (
    <div>
      <Box className={classes.root} mt={2} p={3}>
        <Box display="flex" justifyContent="center">
          <Typography className={classes.title}>
            Bank Account Details
          </Typography>
        </Box>
        <Box mt={2}>
          <Grid container>
            <Grid item xs={1}>
              <img
                src={bankDetails.imageUrl}
                height="20px"
                width="20px"
                style={{ marginTop: "5px" }}
              />
            </Grid>

            <Grid>
              <Typography className={classes.header}>
                {bankDetails?.bankName}
              </Typography>
              {bankDetails?.id == isPrimary && (
                <Typography className={classes.textblue}>Primary</Typography>
              )}
            </Grid>
          </Grid>
          <Box mt={2}>
            <Grid container>
              <Grid item xs={6}>
                <Typography className={classes.text2}>Status</Typography>
                <Typography className={classes.text2}>
                  Account Number
                </Typography>
                <Typography className={classes.text2}>IFSC Code</Typography>
                <Typography className={classes.text2}>Branch Name</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.text3}>
                  {bankDetails?.isBankVerified ? "Verified" : "Not Verified"}
                </Typography>
                <Typography className={classes.text3}>
                  {bankDetails?.accountNumber}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography className={classes.text3}>
                    {bankDetails?.ifscCode}{" "}
                  </Typography>
                  <ModeEditIcon
                    className={classes.editIcon}
                    onClick={() => setShowInfo(true)}
                    style={{ fontSize: "1rem" }}
                  />
                </Box>

                <Typography className={classes.text3}>
                  {bankDetails?.branchName}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {bankDetails?.mandateId && (
            <Box mt={2} style={{ borderTop: "1px solid #C0C0C0" }} pt={1}>
              <Typography>Automandates</Typography>
              <Grid container style={{ marginTop: "5px" }}>
                <Grid item xs={6}>
                  <Typography className={classes.text2}>Autopay ID</Typography>
                  <Typography className={classes.text2}>Status</Typography>
                  <Typography className={classes.text2}>Created on</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.text3}>
                    {bankMandate?.mandateId}
                  </Typography>
                  <Typography className={classes.text3}>
                    {bankMandate?.status}
                  </Typography>
                  <Typography className={classes.text3}>
                    {moment(bankMandate?.createdAt).format("DD MMM YYYY")}
                  </Typography>
                </Grid>
              </Grid>
              <button
                className={classes.dangerButton}
                onClick={() => deleteMandate(bankMandate?.mandateId)}
              >
                Delete
              </button>
            </Box>
          )}
          {userStore?.user?.isOnBoardingCompleted &&
            bankMandate?.status != "SUBMITTED" &&
            bankMandate?.status != "APPROVED" && (
              <Box display="flex" justifyContent="center">
                <button
                  className={classes.primaryButton}
                  onClick={handle_mandate}
                >
                  {bankMandate?.status == "CREATED"
                    ? "Activate Mandate"
                    : "Setup Automandate"}
                </button>
              </Box>
            )}
        </Box>
      </Box>
      <SetupMandate
        show={showMandate}
        handleClose={handle_Close}
        bankDetails={bankDetails}
      />
      <Confirmation
        show={showInfo}
        handleClose={() => setShowInfo(false)}
        handle_yes={handle_confirm}
        title=" Are you sure want to update your IFSC?"
        text="Your account will be re-verified according to the new IFSC Code"
      />
      <EditIfsc
        show={showEdit}
        handleClose={() => setShowEdit(false)}
        bankDetails={bankDetails}
        get_all_bank={get_all_bank}
      />
    </div>
  );
};

export default AutoMandate;
