import React, { useState } from "react";
import { useStyles } from "../styles";
import { Box } from "@material-ui/core";
import TitleLayoult from "../TitleLayoult";
import httpInstance from "../../../utils/instance";
import {
  VERIFY_MOBILE_OTP,
  ADD_MOBILE_NUMBER,
  VERIFY_FORGOT_MPIN,
} from "../../../utils/endpoints";
import ErrorMessage from "../../Validation/ErrorMessage";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import OtpInput from "react-otp-input";
import { namespace } from "../../../environment";

function PhoneOtp({ step, descriptionData }) {
  const classes = useStyles();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const resetMpin = searchParams.get("reset");

  const [otpError, setOtpError] = useState(false);
  const [otp, setOpt] = useState("");
  const [resendmsg, setResendMsg] = useState("");

  const handleChange = (code) => setOpt(code);

  //-------------------------------------SUBMIT OTP----------------------------------------------------------------------//
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    if (resetMpin) {
      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: { otp: otp },
      };
      try {
        await httpInstance(`${VERIFY_FORGOT_MPIN}`, httpConfig);
        navigate({
          pathname: `${namespace}/signIn`,
          search: `?${createSearchParams({
            stepcount: "mpin_generate",
            reset: "mpin",
          })}`,
        });
      } catch {}
    } else {
      const formData = {
        mobileNumber: descriptionData.mobile,
        otp: otp,
      };

      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      try {
        const response = await httpInstance(`${VERIFY_MOBILE_OTP}`, httpConfig);
        navigate({
          pathname: `${namespace}/signIn`,
          search: `?${createSearchParams({ stepcount: "mpin_generate" })}`,
        });
      } catch (error) {
        setOtpError(error.response.data?.message);
      }
    }
  };
  //-------------------------------resend OTP------------------------------------------------------------------------//
  const handleResentOtp = async (data) => {
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: { mobileNumber: descriptionData.mobile },
    };

    try {
      const response = await httpInstance(`${ADD_MOBILE_NUMBER}`, httpConfig);
      setResendMsg(response.data?.message);
    } catch (error) {}
  };

  return (
    <div>
      <Box display={"flex"} justifyContent="center">
        <Box
          display={"flex"}
          alignItems="flex-start"
          flexDirection={"column"}
          p={3}
        >
          <Box>
            <TitleLayoult
              title="Verify your Phone number"
              description={`Please Enter the OTP sent to ${
                descriptionData.mobile
                  ? descriptionData.mobile.substring(0, 2)
                  : ""
              }********to verify your phone number`}
            />
          </Box>
          <form onSubmit={handleOtpSubmit}>
            <OtpInput
              value={otp}
              onChange={handleChange}
              numInputs={4}
              separator={<span style={{ width: "8px" }}></span>}
              isInputNum={true}
              shouldAutoFocus={true}
              inputStyle={{
                border: "none",
                borderRadius: "8px",
                width: "40px",
                height: "40px",
                fontSize: "13px",
                color: "#000",
                fontWeight: "400",
                caretColor: "blue",
                background: "#E6E6E6",
              }}
              focusStyle={{
                border: "1px solid #CFD3DB",
                outline: "none",
              }}
            />
            <p className={classes.resendOtpText} onClick={handleResentOtp}>
              Resend OTP
            </p>
            <p className={classes.verifiedKycText}>{resendmsg}</p>
            {otpError && <ErrorMessage message={otpError} />}
            <Box mt={5} display="flex" justifyContent="center">
              <button className={classes.primaryButton} type="submit">
                Verify
              </button>
            </Box>
          </form>
        </Box>
      </Box>
    </div>
  );
}

export default PhoneOtp;
