import {
  GET_ADMIN_USER_DATA,
  VERIFY_LOADING,
  UPLOAD_PIC,
  VERIFY_ERROR,

} from "./userActionTypes";
import httpInstance from '../../../utils/instance'
import {GET_USER} from '../../../utils/endpoints'


export const getAdminUser = () => {
 
  const httpConfig = {
    method: "GET",
    tokenType: "admin",
    headers:{
      "Content-Type": "application/json",
    },
  };
  return async (dispatch) => {
    dispatch({
      type: VERIFY_LOADING,
    });
    try {
      const data = await httpInstance(`${GET_USER}`, httpConfig);
   
      dispatch({
        type: GET_ADMIN_USER_DATA,
        payload: data?.data?.data
      });
    } catch (error) {
      
    }
  };
};

export const uploadProfilePic = (img) => {
  
  return(dispatch)=>{
    dispatch({
      type: VERIFY_LOADING,
    });

    dispatch({
      type: UPLOAD_PIC,
      payload: img
    });
  
  }
}
 

