import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Divider,
  Grid,
} from "@material-ui/core";
import {
  GET_ALL_ALLOCATION,
  UPDATE_ALLOCATION,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import ErrorMessage from "../../../components/Validation/ErrorMessage";

const obj_to_string = (arry) => {
  const str = arry
    .map((item) => {
      return item.percentage;
    })
    .join(",");
  return str;
};

const AllocationTable = () => {
  const useStyles = makeStyles({
    root: {
      background: "#fff",
      borderRadius: "10px",
    },
    primaryButton: {
      background: "#326CF9",
      color: "#fff",
      padding: "5px 1.5rem",
      border: "none",
      borderRadius: "20px",
      fontFamily: "inherit",
      boxShadow: "0px 2px 26px rgba(0, 0, 0, 0.15)",
      cursor: "pointer",
    },
    table: {
      borderTop: "1px solid #DDDDDD",
    },
    tableRow: {
      "&:last-child td": {
        borderBottom: 0,
      },
    },
    cellBorder: {
      "&.MuiTableCell-root": {
        borderRight: "1px solid #DDDDDD",
        minWidth: "10rem",
      },
    },

    folioCell: {
      "&.MuiTableCell-root": {
        borderRight: "1px solid #DDDDDD",
        maxWidth: "10rem",
      },
    },

    gridBorder: {
      borderRight: "1px solid #DDDDDD",
      paddingBottom: "5px",
    },
    cell: {
      "&.MuiTableCell-root": {
        padding: "0px",
        paddingTop: "5px",
        borderRight: "1px solid #DDDDDD",
        maxWidth: "19rem",
      },
    },

    cell2: {
      "&.MuiTableCell-root": {
        padding: "0px",
        paddingTop: "5px",
        borderRight: "none",
        maxWidth: "19rem",
      },
    },
    subCell: {
      "&.MuiTableCell-root": {
        padding: "0px",
        borderRight: "1px solid #DDDDDD",
      },
    },
    subCell2: {
      "&.MuiTableCell-root": {
        padding: "0px",
        borderRight: "none",
      },
    },
    gridItem: {
      padding: "16px",
      borderRight: "1px solid #DDDDDD",
    },
    gridItem1: {
      padding: "16px",
    },

    inputBox: {
      width: "100%",
      border: "1px solid #DDDDDD",
      borderRadius: "5px",
      //margin:"0px 10px",
      padding: "5px",
      fontFamily: "inherit",
    },

    inputBox1: {
      width: "100%",
      border: "1px solid #DDDDDD",
      borderRadius: "5px",
      margin: "0px 10px",
      padding: "5px",
      fontFamily: "inherit",
    },
  });
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [allocations, setAllocations] = useState([]);
  const [editObj, setEditObj] = useState([]);
  const [error, setError] = useState(false);

  const get_allocation = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_ALL_ALLOCATION}`, httpConfig);
      setAllocations(response.data?.data);
      let data = response.data?.data;
      const array = data?.map((ele) => {
        return {
          id: ele.id,
          lowAmount: ele.lowAmount,
          highAmount: ele.highAmount,
          noOfFolios: ele.noOfFolios,
          thematicMajorFundPercentage: obj_to_string(
            ele.thematicMajorFundPercentage
          ),
          thematicMinorFundPercentage: obj_to_string(
            ele.thematicMinorFundPercentage
          ),
          capMajorFundPercentage: obj_to_string(ele.capMajorFundPercentage),
          capMinorFundPercentage: obj_to_string(ele.capMinorFundPercentage),
        };
      });
      setEditObj(array);
    } catch {}
  };

  useEffect(() => {
    get_allocation();
  }, []);

  const handle_edit = () => {
    if (edit) {
      update_allocation();
    } else {
      setEdit(true);
    }
  };
  //----------------percentage display------------------------------------------//
  const percentages = (arry) => {
    let string = arry
      .map((item) => {
        return " " + item.percentage + "%";
      })
      .join(",");
    return string;
  };
  //---------------------number format-----------------------------------//
  function numFormatter(num) {
    if (num > 999 && num < 100000) {
      return num / 1000 + "K"; // convert to K for number from > 1000 < 1 L
    } else if (num > 99999 && num < 10000000) {
      return num / 100000 + "L"; // convert to L
    } else if (num > 9999999) {
      return num / 10000000 + "Cr"; //
    } else {
      return num;
    }
  }

  //---------------------get percentage array--------------------------------//
  const get_array = (arry) => {
    const percentages = arry.map((ele) => {
      return {
        percentage: Number(ele),
      };
    });
    return percentages;
  };

  //------------------------handle change-------------------------------------------//

  const handle_change = (e, id) => {
    e.target.value = e.target.value.replace(/[^0-9,.]/g, "").toLowerCase();
    const editData = editObj?.map((ele) => {
      if (ele.id == id) {
        return { ...ele, [e.target.name]: e.target.value };
      } else {
        return ele;
      }
    });

    setEditObj(editData);
  };

  //-------------------------------update allocation---------------------------------//

  const check_count = () => {
    return editObj?.every(
      (ele) =>
        ele.noOfFolios ==
        ele.thematicMajorFundPercentage.split(",").length +
          ele.thematicMinorFundPercentage.split(",").length +
          ele.capMajorFundPercentage.split(",").length +
          ele.capMinorFundPercentage.split(",").length
    );
  };

  //-----------------------check total percentage-------------------------------//

  const check_thematic_percentage = () => {
    return editObj?.every(
      (ele) =>
        Math.round(
          Number(
            ele.thematicMajorFundPercentage
              .split(",")
              .reduce((a, b) => parseFloat(a) + parseFloat(b))
          )
        ) +
          Math.round(
            Number(
              ele.thematicMinorFundPercentage
                .split(",")
                .reduce((a, b) => parseFloat(a) + parseFloat(b))
            )
          ) ==
        100
    );
  };

  const check_cap_percentage = () => {
    return editObj?.every(
      (ele) =>
        Math.round(
          Number(
            ele.capMajorFundPercentage
              .split(",")
              .reduce((a, b) => parseFloat(a) + parseFloat(b))
          )
        ) +
          Math.round(
            Number(
              ele.capMinorFundPercentage
                .split(",")
                .reduce((a, b) => parseFloat(a) + parseFloat(b))
            )
          ) ==
        100
    );
  };

  const update_allocation = async () => {
    if (!check_count()) {
      setError(
        "Total count of Thematic and Cap percentages must be equal to number of folios"
      );
    } else if (!check_thematic_percentage()) {
      setError("Total Thematic percentage must be equal to 100%");
    } else if (!check_cap_percentage()) {
      setError("Total Cap percentage must be equal to 100%");
    } else {
      setError(false);
      const formData = editObj.map((ele) => {
        return {
          id: ele.id,
          lowAmount: ele.lowAmount,
          highAmount: ele.highAmount,
          noOfFolios: ele.noOfFolios,
          thematicMajorFundPercentage: get_array(
            ele.thematicMajorFundPercentage.split(",")
          ),
          thematicMinorFundPercentage: get_array(
            ele.thematicMinorFundPercentage.split(",")
          ),
          capMajorFundPercentage: get_array(
            ele.capMajorFundPercentage.split(",")
          ),
          capMinorFundPercentage: get_array(
            ele.capMinorFundPercentage.split(",")
          ),
        };
      });

      const httpConfig = {
        method: "POST",
        tokenType: "admin",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };
      try {
        await httpInstance(`${UPDATE_ALLOCATION}`, httpConfig);
        get_allocation();
        setEdit(false);
      } catch (error) {
        setError(error.response.message);
      }
    }
  };

  return (
    <div>
      <Box pr={15} pl={3} mt={5}>
        <Box className={classes.root}>
          <Box display="flex" justifyContent="end" px={5} py={2}>
            <button className={classes.primaryButton} onClick={handle_edit}>
              {edit ? "Save" : "Edit"}
            </button>
          </Box>

          <Box display="flex" justifyContent="end" px={5}>
            {error && <ErrorMessage message={error} />}
          </Box>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.cellBorder}>
                  Amount Invested
                </TableCell>
                <TableCell align="center" className={classes.folioCell}>
                  No.of Folios
                </TableCell>
                <TableCell align="center" className={classes.cell}>
                  Thematic % wise Folio bifurcation
                  <Divider />
                  <Grid container>
                    <Grid item xs={6} className={classes.gridBorder}>
                      Major
                    </Grid>
                    <Grid item xs={6}>
                      Minor
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="center" className={classes.cell2}>
                  Cap % wise folio bifurcation
                  <Divider />
                  <Grid container>
                    <Grid item xs={6} className={classes.gridBorder}>
                      Major
                    </Grid>
                    <Grid item xs={6}>
                      Minor
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allocations?.map((ele, i) => {
                return (
                  <TableRow key={ele.id} className={classes.tableRow}>
                    <TableCell align="center" className={classes.cellBorder}>
                      {edit ? (
                        <Box display="flex" alignItems="center">
                          <input
                            className={classes.inputBox1}
                            name="lowAmount"
                            value={editObj[i].lowAmount}
                            onChange={(e) => handle_change(e, ele.id)}
                            required
                          />
                          -
                          <input
                            className={classes.inputBox1}
                            name="highAmount"
                            value={editObj[i].highAmount}
                            onChange={(e) => handle_change(e, ele.id)}
                            required
                          />
                        </Box>
                      ) : (
                        `${numFormatter(ele.lowAmount)} - ${numFormatter(
                          ele.highAmount
                        )}`
                      )}
                    </TableCell>
                    <TableCell align="center" className={classes.folioCell}>
                      {edit ? (
                        <input
                          className={classes.inputBox}
                          name="noOfFolios"
                          value={editObj[i].noOfFolios}
                          onChange={(e) => handle_change(e, ele.id)}
                          required
                        />
                      ) : (
                        `${ele.noOfFolios}`
                      )}
                    </TableCell>
                    <TableCell align="center" className={classes.subCell}>
                      <Grid container>
                        <Grid item xs={6} className={classes.gridItem}>
                          {edit ? (
                            <input
                              className={classes.inputBox}
                              name="thematicMajorFundPercentage"
                              value={editObj[i].thematicMajorFundPercentage}
                              onChange={(e) => handle_change(e, ele.id)}
                              required
                            />
                          ) : (
                            percentages(ele.thematicMajorFundPercentage)
                          )}
                        </Grid>
                        <Grid item xs={6} className={classes.gridItem1}>
                          {edit ? (
                            <input
                              className={classes.inputBox}
                              value={editObj[i].thematicMinorFundPercentage}
                              name="thematicMinorFundPercentage"
                              onChange={(e) => handle_change(e, ele.id)}
                            />
                          ) : (
                            percentages(ele.thematicMinorFundPercentage)
                          )}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell align="center" className={classes.subCell2}>
                      <Grid container>
                        <Grid item xs={6} className={classes.gridItem}>
                          {edit ? (
                            <input
                              className={classes.inputBox}
                              name="capMajorFundPercentage"
                              value={editObj[i].capMajorFundPercentage}
                              onChange={(e) => handle_change(e, ele.id)}
                              required
                            />
                          ) : (
                            percentages(ele.capMajorFundPercentage)
                          )}
                        </Grid>
                        <Grid item xs={6} className={classes.gridItem1}>
                          {edit ? (
                            <input
                              className={classes.inputBox}
                              value={editObj[i].capMinorFundPercentage}
                              name="capMinorFundPercentage"
                              onChange={(e) => handle_change(e, ele.id)}
                            />
                          ) : (
                            percentages(ele.capMinorFundPercentage)
                          )}
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}

              <TableRow className={classes.tableRow}>
                <TableCell align="center" className={classes.cellBorder}>
                  {" "}
                  {">1Cr"}
                </TableCell>
                <TableCell align="center"> </TableCell>
                <TableCell align="center"> Personal Assistance</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </div>
  );
};

export default AllocationTable;
