import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  makeStyles,
  AppBar,
  Toolbar,
  CssBaseline,
  Menu,
  MenuItem,
} from "@material-ui/core";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getAdminUser } from "../../redux/Admin/AdminUser/userActions";
import { getNotificationCount } from "../../redux/Admin/AdminNotification/AdminNotifiActions";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "react-avatar";
import { BASE_URL, namespace } from "../../environment";
import { useNavigate } from "react-router-dom";
import AdminNotification from "./AdminNotification";

const Topbar = () => {
  const drawerWidth = 240;
  const useStyles = makeStyles({
    root: {
      "& .MuiAppBar-colorPrimary": {
        background: "#ffff",
        boxShadow: "none",
      },
    },
    title: {
      marginLeft: 240,
    },
    username: {
      color: "black",
      fontSize: "0.8rem",
      marginLeft: "10px",
      cursor: "pointer",
    },
    bellIconBorder: {
      borderRight: "1px solid #DDDDDD",
      borderLeft: "1px solid #DDDDDD",
      marginRight: "10px",
    },
    menu: {
      "& .MuiList-padding": {
        padding: "15px 0px 15px 0px",
      },
    },

    menuEmail: {
      fontSize: "0.8rem",
      fontWeight: 400,
    },

    menuName: {
      fontSize: "0.9rem",
      fontWeight: 500,
    },
  });
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { adminStore } = useSelector((state) => state);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAdminUser());
    dispatch(getNotificationCount());
  }, []);

  //---------------handle logout------------------//
  const handle_logout = () => {
    localStorage.removeItem("admin_userId");
    localStorage.removeItem("admin_token");
    navigate(`${namespace}/admin/login`);
  };
  //--------------------------------handle profile----------------------------//
  const handle_profile = () => {
    navigate(`${namespace}/admin/admin-profile`);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"

        // sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Box display="flex" justifyContent="end" alignItems="center" p={2}>
          <Box px={2} className={classes.bellIconBorder}>
            <AdminNotification />
          </Box>
          {adminStore.Adminuser?.profile_image ? (
            <Avatar
              src={`${BASE_URL}${adminStore.Adminuser?.profile_image}`}
              size="35"
              round={true}
            />
          ) : (
            <Avatar
              name={adminStore.Adminuser?.userName?.charAt(0)}
              size="35"
              round={true}
              textSizeRatio={2}
            />
          )}
          <Typography className={classes.username} onClick={handleClick}>
            {adminStore?.Adminuser?.userName}
          </Typography>
          <ArrowDropDownIcon
            className={classes.username}
            onClick={handleClick}
          />

          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            onClose={handleClose}
            open={Boolean(anchorEl)}
            className={classes.menu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            getContentAnchorEl={null}
          >
            <Box
              px={2}
              pb={2}
              style={{ borderBottom: "1px solid #cccc" }}
              display="flex"
              alignItems="center"
              onClick={handleClose}
            >
              {adminStore.Adminuser?.profile_image ? (
                <Avatar
                  src={`${BASE_URL}${adminStore.Adminuser?.profile_image}`}
                  size="35"
                  round={true}
                />
              ) : (
                <Avatar
                  name={adminStore.Adminuser?.userName?.charAt(0)}
                  size="35"
                  round={true}
                  textSizeRatio={1}
                />
              )}
              <Box px={2}>
                <Typography className={classes.menuName}>
                  {adminStore.Adminuser?.userName}
                </Typography>
                <Typography className={classes.menuEmail}>
                  {adminStore.Adminuser?.emailId}
                </Typography>
              </Box>
            </Box>

            <MenuItem onClick={handle_profile} style={{ fontSize: "0.9rem" }}>
              View Profile
            </MenuItem>
            <MenuItem onClick={handle_logout} style={{ fontSize: "0.9rem" }}>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </AppBar>
    </div>
  );
};

export default Topbar;
