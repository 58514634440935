import React, { useState, useEffect } from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import httpInstance from "../../../utils/instance";
import {
  SELL_GOLD_SILVER,
  GET_GOLD_SILVER_RATE,
  CREATE_ORDER,
  POST_PAYMENT,
} from "../../../utils/endpoints";
import { useNavigate, createSearchParam, Link } from "react-router-dom";
import ReactLoading from "react-loading";
import { namespace } from "../../../environment";

const DeliveryOrder = ({ sku, addressId, metal, checkoutRate }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      fontFamily: "inherit",
    },
    card: {
      boxShadow: "0 0 8px -3px #444444",
      borderRadius: "10px",
    },
    borderRight: {
      borderRight: "3px solid #0C97F0",
    },
    rateText: {
      display: "flex",
      justifyContent: "center",
      fontSize: "1rem",
      padding: "4px",
      fontFamily: "inherit",
    },
    counterText: {
      fontSize: "1rem",
      fontFamily: "inherit",
      color: "#48D4A5",
    },

    totalAmount: {
      width: "35.5%",
      boxShadow: "0 0 8px -3px #444444",
      borderRadius: "8px",
      padding: "0px 10px",
    },

    gridWidth: {
      // width:"45%",
    },

    table: {
      width: "34%",
    },
    text: {
      fontSize: "0.9rem",
      padding: "12px 0px",
      fontFamily: "inherit",
    },

    text2: {
      fontSize: "0.9rem",
      padding: "12px 0px",
      textAlign: "end",
      fontFamily: "inherit",
    },
    primaryButton: {
      padding: "0.8rem 5rem",
      background: "#326CF9",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
    },

    blueBorder: {
      border: "1px solid #0C97F0",
      borderRadius: "10px",
      fontSize: "0.8rem",
      fontWeight: 400,
      cursor: "pointer",
    },
    name: {
      fontSize: "0.8rem",
      fontWeight: 600,
    },
    boxWidth: {
      width: "35%",
    },

    addressTitle: {
      fontSize: "0.9rem",
      margin: "5px",
      fontWeight: 500,
      paddingLeft: "15px",
    },
    rupees: {
      fontFamily: "Open Sans",
    },

    "@media (max-width:480px)": {
      boxWidth: {
        width: "75%",
      },

      table: {
        width: "70%",
      },

      totalAmount: {
        width: "73.5%",
      },
    },
  }));

  const classes = useStyles();

  const [goldRate, setGoldRate] = useState("");
  const [silverRate, setSilverRate] = useState("");
  const [orderData, setOrderData] = useState();
  const [spinner, setSpinner] = useState(false);
  const [time, setTime] = React.useState({
    minutes: 5,
    seconds: 59,
  });
  const [expired, setExpired] = useState(false);

  const navigate = useNavigate();

  const countDown = () => {
    if (time.minutes == 0 && time.seconds == 0) {
      setExpired(true);
    } else if (time.seconds == 0) {
      setTime({
        minutes: time.minutes - 1,
        seconds: 59,
      });
    } else {
      setTime({
        minutes: time.minutes,
        seconds: time.seconds - 1,
      });
    }
  };

  useEffect(() => {
    let timerID = setInterval(() => countDown(), 1000);
    return () => clearInterval(timerID);
  });

  useEffect(() => {
    getData();
    create_order();
  }, []);

  const getData = async () => {
    var requestOptions = {
      method: "GET",
    };

    const httpConfig1 = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const allRates = await httpInstance(`${GET_GOLD_SILVER_RATE}`, httpConfig1);
    try {
      setGoldRate(allRates?.data?.data?.goldSellRate);
      setSilverRate(allRates?.data?.data?.silverSellRate);
    } catch (error) {}
  };

  const create_order = async () => {
    const formData = {
      metalType: metal.type,
      type: "delivery",
      shippingUserAddressId: addressId.shippingUserAddressId,
      billingUserAddressId: addressId.billingUserAddressId,
      isShippingSameAsBilling: true,
      product: [
        {
          sku: sku,
          quantity: metal.quantity,
        },
      ],
    };

    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      const response = await httpInstance(`${CREATE_ORDER}`, httpConfig);
      setOrderData(response.data?.data);
    } catch {}
  };

  const postPayment = async (response) => {
    const formData = {
      orderId: response.razorpay_order_id,
      paymentId: response.razorpay_payment_id,
      status: "success",
      shippingUserAddressId: addressId.shippingUserAddressId,
      billingUserAddressId: addressId.billingUserAddressId,
      isShippingSameAsBilling: addressId.isShippingSameAsBilling,
      product: [
        {
          sku: sku,
          quantity: metal.quantity,
        },
      ],
    };

    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      await httpInstance(`${POST_PAYMENT}`, httpConfig);
      navigate(`${namespace}/myportfolio/delivery-success`);
    } catch {}
  };

  // const postPaymentfailed = async (response) => {
  //   const formData = {
  //     orderId:response.error.metadata.order_id,
  //     paymentId: response.error.metadata.payment_id,
  //     status: "failed",
  //     shippingUserAddressId: addressId.shippingUserAddressId,
  //     billingUserAddressId: addressId.billingUserAddressId,
  //     isShippingSameAsBilling: addressId.isShippingSameAsBilling,
  //     product: [
  //       {
  //         sku: sku,
  //         quantity: metal.quantity,
  //       },
  //     ],
  //   };

  //   const httpConfig = {
  //     method: "POST",
  //     tokenType: "user",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: formData,
  //   };

  //   try {
  //     await httpInstance(`${POST_PAYMENT}`, httpConfig);
  //     navigate("/myportfolio/delivery-success");
  //   } catch {}
  // };
  //--------------------------post payment failed----------------------------------//
  const postPaymentFailed = async () => {
    const formData = {
      orderId: orderData.orderId,
      status: "failed",
    };

    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      await httpInstance(`${POST_PAYMENT}`, httpConfig);
      navigate(`${namespace}/myportfolio/delivery-failed`);
    } catch {}
  };

  const handlePayment = () => {
    var options = {
      key: orderData?.key,
      amount: orderData.amount,
      currency: "INR",
      name: orderData.name,
      description: orderData.description,
      //image: "https://example.com/your_logo",
      order_id: orderData?.orderId,
      prefill: {
        name: orderData.name,
        email: orderData?.email,
        contact: orderData?.contact,
      },
      config: {
        display: {
          hide: [
            {
              method: "wallet",
            },
          ],
          preferences: {
            show_default_blocks: true,
          },
        },
      },
      handler: function (response) {
        setSpinner(true);
        postPayment(response);
      },

      timeout: time.minutes * 60 + time.seconds - 2,
      modal: {
        ondismiss: function () {
          postPaymentFailed();
        },
      },
    };

    var rzp1 = new window.Razorpay(options);

    // rzp1.on('payment.failed', function (response){
    //   //postPaymentfailed(response)
    //   navigate('/myportfolio/delivery-failed')
    // });

    rzp1.open();
  };
  return (
    <div>
      {spinner ? (
        <Box
          display="flex"
          justifyContent="center"
          style={{ height: "300px" }}
          mt={5}
        >
          {" "}
          <ReactLoading
            type="spokes"
            color="	#0C97F0"
            height={"5%"}
            width={"5%"}
          />{" "}
        </Box>
      ) : (
        <>
          <Box px={{ xs: 2, md: 10 }} pt={2}>
            {" "}
            <Link to={`${namespace}/myportfolio/delivery?tab=goldcoin`}>
              <img src="/images/backIcon.svg" height="20px" width="20px" />
            </Link>
          </Box>
          <Box className={classes.root}>
            <p className={classes.addressTitle}>Order Summary</p>

            <Box justifyContent="center" display="flex" mt={2}>
              <Box
                className={classes.card}
                display="flex"
                justifyContent="center"
                p={2}
              >
                <Box className={classes.borderRight} px={3}>
                  <Typography className={classes.rateText}>
                    GOLD Rate / 1 gm
                  </Typography>
                  <Typography className={classes.rateText}>
                    {goldRate}
                  </Typography>
                </Box>
                <Box px={3}>
                  <Typography className={classes.rateText}>
                    SILVER Rate / 1 gm
                  </Typography>
                  <Typography className={classes.rateText}>
                    {silverRate}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* timer */}
            <Box mt={3}>
              <Typography className={classes.rateText}>
                {!expired ? (
                  <Typography className={classes.rateText}>
                    {" "}
                    {`This price will be valid for -`}
                    <span className={classes.counterText}>{`${time.minutes
                      .toString()
                      .padStart(2, "0")}:${time.seconds
                      .toString()
                      .padStart(2, "0")}`}</span>
                  </Typography>
                ) : (
                  "Price Expired ! Please try again"
                )}
              </Typography>
            </Box>
            {/* delivery address */}
            <Box className={classes.boxWidth} mt={4}>
              <p className={classes.addressTitle}>Delivery Address</p>
              <Box className={classes.blueBorder} px={3}>
                <p className={classes.name}>
                  {checkoutRate?.shippingUserAddressDetail?.fullName}
                </p>
                <p>
                  {checkoutRate?.shippingUserAddressDetail?.address},{" "}
                  {checkoutRate?.shippingUserAddressDetail?.city?.name}
                </p>
                <p>
                  {checkoutRate?.shippingUserAddressDetail?.state?.name} -
                  {checkoutRate?.shippingUserAddressDetail?.pincode}
                </p>
                <p>
                  Phone :{checkoutRate?.shippingUserAddressDetail?.mobileNumber}
                </p>
              </Box>
            </Box>
            {/* billing address */}
            <Box className={classes.boxWidth} my={4}>
              <p className={classes.addressTitle}>Billing Address</p>
              <Box className={classes.blueBorder} px={3}>
                <p className={classes.name}>
                  {checkoutRate?.billingUserAddressDetail?.fullName}
                </p>
                <p>
                  {checkoutRate?.billingUserAddressDetail?.address},{" "}
                  {checkoutRate?.billingUserAddressDetail?.city?.name}
                </p>
                <p>
                  {checkoutRate?.billingUserAddressDetail?.state?.name} -
                  {checkoutRate?.billingUserAddressDetail?.pincode}
                </p>
                <p>
                  Phone :{checkoutRate?.billingUserAddressDetail?.mobileNumber}
                </p>
              </Box>
            </Box>

            <Grid container mt={4} className={classes.table} px={3}>
              <Grid item xs={8} md={10}>
                <Typography className={classes.text} pl={3}>
                  Quantity(gms)
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography className={classes.text2}>
                  {checkoutRate?.quantity?.toFixed(4)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container mt={4} className={classes.table} px={3}>
              <Grid item xs={8} md={10}>
                <Typography className={classes.text} pl={3}>
                  Amount(<span className={classes.rupees}>₹</span>)
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography className={classes.text2}>
                  {checkoutRate?.amount.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={4} className={classes.table} px={3}>
              <Grid item xs={8} md={10}>
                <Typography className={classes.text} pl={3}>
                  Tax(<span className={classes.rupees}>₹</span>)(GST+3%)
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography className={classes.text2}>
                  {checkoutRate?.tax.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={4} className={classes.table} px={3}>
              <Grid item xs={8} md={10}>
                <Typography className={classes.text} pl={3}>
                  Total Amount(<span className={classes.rupees}>₹</span>)
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography className={classes.text2}>
                  {checkoutRate?.totalAmount.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>

            {/* <Grid container mt={4} className={classes.table} px={3}>
          <Grid item xs={8} md={10}>
            <Typography className={classes.textBlue}>
              Paid from wallet(₹)
            </Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <Typography className={classes.textBlue}>00</Typography>
          </Grid>
        </Grid> */}

            <Grid container mt={4} className={classes.totalAmount} px={3}>
              <Grid item xs={8} md={10}>
                <Typography className={classes.text} pl={3}>
                  Amount Payable(<span className={classes.rupees}>₹</span>)
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography className={classes.text2}>
                  {checkoutRate?.amountPayable.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={4}
          >
            <Typography style={{ fontSize: "0.8rem", padding: "0px 10px" }}>
              Powered By
            </Typography>
            <img src="/images/Augmont Logo 1.svg" height="35px" width="80px" />
          </Box>
          <Box display="flex" justifyContent="center" mt={5}>
            <button className={classes.primaryButton} onClick={handlePayment}>
              Proceed to Pay
            </button>
          </Box>
        </>
      )}
    </div>
  );
};

export default DeliveryOrder;
