import React,{useState,useEffect} from 'react'
import { Box, Typography, makeStyles, Grid,Table,TableBody,TableCell,TableHead,TableRow } from "@material-ui/core";
import {GET_HOLDINGS} from '../../utils/endpoints'
import httpInstance from '../../utils/instance'
import { fontSize } from '@mui/system';


const Holdings = ({schemeCode}) => {
    const useStyles = makeStyles((theme) => ({
        root:{

        },
        title:{
            fontWeight:600,
            padding:"0px 7rem",
            fontSize:"1.2rem"
        },
        tableHead:{
            fontWeight:600 
        },
        "@media (min-width: 992px)": {
        tablefirstCell:{
            fontWeight:600 ,
            width:"45%",
            paddingLeft:"7rem",
            
        },
        tableBodyfirstCell:{
            paddingLeft:"7rem",
            width:"45%",
           
        },
        tablelastCell:{
            fontWeight:600 ,
            paddingRight:"5rem",
            
        },
        tableBodylastCell:{
            paddingRight:"5rem",           
        },
        },
        viewAll:{
            fontSize:"0.9rem",
            fontWeight:600 ,
            color:'#0C97F0',
            textDecoration:"underline",
            cursor:"pointer"
        }
        

    }))
    const classes = useStyles();

    const [holdingData,setHoldingData]=useState([])
    const [viewAll,setViewAll]=useState(false)

    const get_holding=async()=>{
        const formData={
            scheme_code:schemeCode,
            as_on:"",
            portfolio_type:''
        }
        const httpConfig = {
            method: "POST",
            tokenType: "user",
            headers:{
              "Content-Type": "application/json",
            },
            data:formData,
          };
          try{
            const response= await httpInstance(`${GET_HOLDINGS}`,httpConfig)
            setHoldingData(response.data?.data)
          }
          catch{

          }
    }

    useEffect(()=>{
        get_holding()
    },[])

    const handle_viewAll=()=>{
        setViewAll(!viewAll)
    }

  return (
    <div>
        <Box mt={5}>
        <Typography className={classes.title}>Holdings</Typography>
        <div style={{width: 'auto', overflowX: 'auto'}}>
        <Table style={{marginTop:"2rem"}}>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tablefirstCell} >Name</TableCell>
                    <TableCell className={classes.tableHead} >Sector</TableCell>
                    <TableCell className={classes.tablelastCell}>Assets</TableCell>

                </TableRow>
            </TableHead>
            <TableBody>
                {(!viewAll ? holdingData?.slice(0,10) : holdingData)?. map((ele,i)=>{
                    return(
                    <TableRow key={i}>
                            <TableCell className={classes.tableBodyfirstCell}>{ele.security}</TableCell>
                            <TableCell>{ele.sector}</TableCell>
                            <TableCell className={classes.tableBodylastCell}>{ele.holding_perc}%</TableCell>
                            
                    </TableRow>
                    )
                })}
            </TableBody>
        </Table>
        </div>
           <Box display="flex" justifyContent="center" mt={2}><Typography className={classes.viewAll} onClick={handle_viewAll}>{!viewAll ? "View All Holdings" :"View less"}</Typography></Box> 
        </Box>
    </div>
  )
}

export default Holdings