import React from 'react'
import { MutualData } from '../../utils/content'
import Accordian from '../../components/Accordian'

const Mf_Faq = () => {
  return (
    <div>
       { MutualData.map(({ title, content }, key) => (
                <Accordian key={key} title={title} content={content} />
              ))}
    </div>
  )
}

export default Mf_Faq