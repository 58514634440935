import React from 'react'
import FundDetail from '../../containers/MutualFund/FundDetail'

const fund_details = () => {
  return (
    <div>
        <FundDetail/>
    </div>
  )
}

export default fund_details