
import React,{useState,useEffect} from 'react'
import { Box,makeStyles,Typography,Grid,Table,TableBody,TableCell,TableContainer,TableRow } from '@material-ui/core'
import {GET_TRANS_HISTORY} from '../../../../utils/endpoints'
import httpInstance from '../../../../utils/instance'
import moment from 'moment'
import localeString from '../../../../hooks/LocaleString'


const TransHistory = ({transData}) => {
    const useStyles = makeStyles({
        title:{
            color:"#C0C0C0",
          fontWeight:600,
    
        },
        textGrey:{
            color:"#C0C0C0"
        },
        rupees:{
            fontFamily:"Open Sans"
          }
    })
    const classes=useStyles()

    

    
  return (
    <div>
        <Box mt={4}>
            <Typography className={classes.title}>TRANSCATION HISTORY</Typography>
            <TableContainer>
                <Table>
                <TableBody>
                    {transData?.transactionHistory?.map((ele,i)=>{
                        return(
                            <TableRow key={i}>
                            <TableCell>
                                <p className={classes.textGrey}>
                                {ele?.type=="purchase" && "Invest"}
                                {ele?.type=="redemption" && "Redeem"}
                                </p>
                                <p>{moment(ele.tradedOn).format("DD MMM YYYY")}</p>
                            </TableCell>
                            <TableCell >
                                <Grid container>
                                    <Grid item xs={6}>
                                        <p className={classes.textGrey}>Units</p>
                                        <p className={classes.textGrey}>NAV</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <p>{ele.units}</p>
                                    <p>{ele.tradedAt}</p>
                                    </Grid>
                                </Grid>
                            </TableCell>
                            <TableCell align="right">
                                <p><span className={classes.rupees}>₹</span>{localeString(ele.amount)}</p>
                                <p className={classes.textGrey}>Completed</p>
                            </TableCell>
                            </TableRow>
                        )
                    })}
                    
                </TableBody>
                </Table>
            </TableContainer>
        </Box>
    </div>
  )
}

export default TransHistory