import React from 'react'
import DetailInvestment from '../../components/DashBoard/DigitalGold/DetailInvestment'
import InvestmentTable from '../../components/DashBoard/DigitalGold/InvestmentTable'

const InvestmentDetail = () => {
  return (
    <div>
        <DetailInvestment/>
        <InvestmentTable/>
    </div>
  )
}

export default InvestmentDetail