import React,{useState,useEffect} from 'react'
import { Box,makeStyles,Typography,Grid} from '@material-ui/core'
import {GET_ALL_RETURNS} from '../../../utils/endpoints'
import httpInstance from '../../../utils/instance'
import LocaleString from '../../../hooks/LocaleString'

const Total_Invest = ({holdings}) => {
    const useStyles = makeStyles({
        root:{
            border:"1px solid #D0D0D0",
            borderRadius:"5px"
        },
        header:{
            fontSize:"2rem",
            fontWeight:600
        },
        title:{
            fontSize:"1rem",
            fontWeight:500
        },
        text:{
            fontSize:"0.9rem", 
            color:"#F32424"
        },
        text1:{
            fontSize:"0.9rem", 
        },
        text2:{
            fontSize:"0.9rem", 
            color:"#48D4A5"
        },
        grid:{
            display:"flex",
            alignItems:"center",
            flexDirection:"column",
            "& p":{
                margin:"5px"
            }
        },
        rupees:{
            fontFamily:"Open Sans"
          }
    })
    const classes=useStyles()
    const [data,setData]=useState()



    // const get_all_investment=async()=>{
    //     const userId=localStorage.getItem("user_id")
    //     const httpConfig = {
    //         method: "GET",
    //         tokenType: "user",
    //         headers:{
    //           "Content-Type": "application/json",
    //         },
    //         params:{userId:userId}
    //       };
    //       try{
    //         const response=await httpInstance(`${GET_ALL_RETURNS}`,httpConfig)
    //         setData(response.data?.data)
    //       }
    //       catch{

    //       }
    // }

    // useEffect(()=>{
    //     get_all_investment()
    // },[])
  return (
    <div>
        {holdings && 
        <Box className={classes.root} p={{xs:0,md:4}} px={{xs:0,md:5}} display="flex" flexDirection="column" alignItems="center" mt={3}>
            <Typography className={classes.header}><span className={classes.rupees}> ₹</span> {LocaleString(holdings?.currentValue)}</Typography>
            <Grid container style={{marginTop:"10px"}}>
                {/* <Grid item xs={6} md={3} className={classes.grid}>
                   
                    <p className={classes.title}>1D Returns</p>
                    <p className={classes.text}>+₹31 (1.5%)</p>
                    
                </Grid> */}
                <Grid item xs={6}  className={classes.grid}>
                    <p className={classes.title}>Total Returns</p>
                    <p className={holdings?.totalReturns>0 ? classes.text2 :classes.text}>{holdings?.totalReturns>0 && "+"}<span className={classes.rupees}> ₹</span>{LocaleString(holdings?.totalReturns)} ({holdings?.returnsPercentage?.toFixed(2)}%)</p>
                </Grid>
                <Grid item xs={6}  className={classes.grid}>
                    <p className={classes.title}>Invested Amount</p>
                    <p className={classes.text1}><span className={classes.rupees}> ₹</span>{LocaleString(holdings?.investedValue)} </p>
                </Grid>
                {/* <Grid item xs={12} md={4} className={classes.grid}>
                    <p className={classes.title}>Abs return</p>
                    <p className={classes.text1}>{holdings?.returnsPercentage.toFixed(2)}%</p>
                </Grid> */}
                {/* <Grid item xs={12} md={4} className={classes.grid}>
                    <p className={classes.title}>Current Value</p>
                    <p className={classes.text1}>₹{LocaleString(data?.currentValue)}</p>
                </Grid> */}
            </Grid>
        </Box>
}
    </div>
  )
}

export default Total_Invest