import React, { useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ADD_ADMINS, CREATE_PIN } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import ErrorMessage from "../../../components/Validation/ErrorMessage";

const AddPins = ({ show, handleClose, get_all_admins }) => {
  const useStyles = makeStyles({
    root: {
      "& .MuiDialogContent-root": {
        fontSize: "0.8rem",
        lineHeight: "25px",
        padding: "20px 25px",
      },
      "& .MuiDialog-paperWidthSm": {
        minWidth: "45%",
        //maxWidth:"300px",
        maxHeight: "100%",
      },
    },
    dialogTitle: {
      //display: "flex",
      justifyContent: "center",
      alignItems: " center",
      //textAlign: "center",
      padding: "0px 10px 0px 25px",
      marginTop: "-10px",
      "& .MuiTypography-body1": {
        fontSize: "1.2rem",
        fontWeight: 500,
      },
    },
    closeIcon: {
      padding: "5px",
      justifyContent: "flex-end",
    },
    text: {
      fontSize: "0.8rem",
    },
    textCancel: {
      fontSize: "0.8rem",
      cursor: "pointer",
    },
    select: {
      fontSize: "0.85rem",
      color: "black",
      padding: "3px 0px",
      borderRadius: "10px",

      minWidth: "20rem",
      "& .MuiSelect-select": {
        paddingTop: "10px ",
        paddingBottom: "10px",
        paddingRight: "40px",
        paddingLeft: "10px",
        border: "1px solid #DDDDDD",
        borderRadius: "5px",
        "&:focus": {
          backgroundColor: "#fff",
        },
      },
      "& .MuiSvgIcon-root": {
        color: "black",
      },
      "& .MuiMenuItem-root": {
        fontSize: "0.85rem !important",
      },
    },
    menu: {
      boxShadow: "none",
      border: "1px solid #DDDDDD",
    },
    menuItem: {
      fontSize: "0.85rem !important",
    },
    inputBox: {
      padding: "9px",
      border: "1px solid #DDDDDD",
      borderRadius: "5px",
      fontSize: "0.85rem",
      fontFamily: "inherit",
      marginTop: "10px",
      "&::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "black",
        opacity: 1 /* Firefox */,
      },

      "&:-ms-input-placeholder": {
        /* Internet Explorer 10-11 */ color: "black",
      },

      "&::-ms-input-placeholder ": {
        /* Microsoft Edge */ color: "black",
      },
    },
    primaryButton: {
      background: "#326CF9",
      color: "#fff",
      padding: "10px 2rem",
      border: "none",
      borderRadius: "20px",
      fontFamily: "inherit",
    },
  });

  const classes = useStyles();

  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("");
  const [date, setDate] = useState("");
  const [error, setError] = useState(false);

  const handle_close = () => {
    setType("");
    setPin("");
    setDate("");
    handleClose();
  };

  const handleChange = (e) => {
    setType(e.target.value);
  };

  //----------------handle email--------------------------------//
  const handle_pin = (e) => {
    setPin(e.target.value);
  };

  const handle_date = (e) => {
    setDate(e.target.value);
  };

  const add_pin = async () => {
    setLoading(true);
    setError(false);
    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      data: { isDeactivated: type, pin: pin, expiresOn: date },
    };

    try {
      await httpInstance(`${CREATE_PIN}`, httpConfig);
      get_all_admins();
      handle_close();
    } catch (error) {
      setLoading(false);
      setError(error.response?.data?.message);
    }
  };

  return (
    <div>
      <Dialog className={classes.root} open={show} onClose={handle_close}>
        <Box
          display="flex"
          className={classes.closeIcon}
          onClick={handle_close}
        >
          <CloseIcon />
        </Box>

        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Box display="flex" justifyContent="center">
            <Typography className={classes.title}>Add Pins</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            mt={5}
          >
            <Box display="flex" flexDirection="column">
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <input
                  className={classes.inputBox}
                  placeholder="Enter Pin"
                  onChange={handle_pin}
                />
                <Select
                  value={type}
                  onChange={handleChange}
                  className={classes.select}
                  disableUnderline
                  IconComponent={KeyboardArrowDownIcon}
                  displayEmpty
                  //renderValue={(value) => (value !== '' ? value : 'Placeholder text')}
                  //   renderValue={
                  //     type.length> 0
                  //       ? type
                  //       : () => "Select User Type"
                  //   }
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    style: {
                      maxHeight: 350,
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.menu },
                  }}
                >
                  <MenuItem value="" className={classes.menuItem} disabled>
                    Select Is Deactivated?
                  </MenuItem>
                  <MenuItem value={true} className={classes.menuItem}>
                    True
                  </MenuItem>
                  <MenuItem value={false} className={classes.menuItem}>
                    False
                  </MenuItem>
                </Select>
                <label for="" style={{ marginTop: "10px" }}>
                  Expires On
                </label>
                <input
                  className={classes.inputBox}
                  placeholder="Enter Expires On"
                  onChange={handle_date}
                  type="date"
                  style={{ marginTop: 0 }}
                />
              </FormControl>
            </Box>
            <Box mt={15} mb={5}>
              <button className={classes.primaryButton} onClick={add_pin}>
                Send request
              </button>
            </Box>
            {error && <ErrorMessage message={error} />}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddPins;
