import React from "react";
import {
  Box,
  makeStyles,
} from "@material-ui/core";

function CuratedCard({ title, paragraph, image, handleShowModal }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundImage: "url('./images/Strings.svg')",
      padding: "0rem 0rem 5rem 0rem",
      backgroundPosition: "left",
    },
    backDrop: {
      background: "white",
      backdropFilter: "blur(4px)",
    },
    section1: {
      background: "#fff",
      borderRadius: "5px",
      width: "82%",
      boxShadow: "32px 32px 60px 0px #326CF914",
      padding: "2rem",
    },
    heading: {
      color: "#2c2c2c",
      textAlign: "left",
      fontSize: "1.6rem",
      fontWeight: 500,
    },
    description: {
      color: "#2c2c2c",
      textAlign: "left",
      fontWeight: 400,
      fontSize: "1rem",
      width: "62%",
      lineHeight: "24px",
    },
    subtitle: {
      fontSize: "1rem",
      fontWeight: 400,
      color: "#272727",
      cursor: "pointer",
    },
    curatedHolder: {
      display: "flex",
    },
    "@media (max-width: 900px)": {
      videoPlayer: {
        width: "300px",
      },
      curatedHolder: {
        display: "flex",
        flexDirection: "column",
      },
      description: {
        width: "100%",
      },
      section1: {
        background: "#fff",
        borderRadius: "5px",
        width: "82%",
        boxShadow: "32px 32px 60px 0px #326CF914",
        padding: "2rem",
        margin: "1rem 0",
      },
    },
  }));
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="center" flexDirection="row">
      <Box
        display="flex"
        flexDirection="column"
        pt={5}
        className={classes.section1}
      >
        <Box className={classes.heading}>{title}</Box>
        <Box className={classes.description} mt={4} height="60px">
          {paragraph}
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          flexDirection="row"
          className={classes.curatedHolder}
          mt={2}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            mt={4}
            onClick={handleShowModal}
            className={classes.subtitle}
          >
            <Box className={classes.subtitle} mr={2}>
              Learn more
            </Box>
            <img
              src="/images/Group 21264.svg"
              alt="BullSurge"
              height={25}
              width={25}
            />
          </Box>
          <Box display="flex" alignItems="flex-end" justifyContent="flex-start">
            <video
              autoPlay
              muted
              playsInline
              loop
              controls={false}
              width="300"
              height="240"
              className={classes.videoPlayer}
            >
              <source src={image} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CuratedCard;
