import React, { useState,useEffect } from "react";
import {
  Box,
  Container,
  makeStyles,
  Typography,
  
} from "@material-ui/core";
import { fontSize } from "@mui/system";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import {GET_GOLD_SILVER_RATE,GET_GOLD_USER,CREATE_CHECKOUT} from '../../../utils/endpoints'
import httpInstance from '../../../utils/instance'
import InsufficientBalanceModal from "../../DigitalGold/Payement/InsufficientBalanceModal";
import Kyc_status from "../Funds/KycStatusCheck/Kyc_status";


function RateCaluclation({createOrder,ChangeTabData}) {

    const useStyles = makeStyles((theme) => ({
        root: {
          backgroundImage: "url('./images/Component2.svg')",
          background: "#fff",
          padding: "0rem 0rem 8rem 0rem",
          backgroundPosition: "left",
          backdropFilter: "blur(10px)"
        },
        card: {
          boxShadow: "0 0 8px -3px #444444",
          borderRadius: "8px"
        },
        heading: {
          fontSize: "2.5em",
          fontWeight: 500
        },
        tab: {
          borderBottom: "1px solid #D0D0D0"
        },
        activeBorderBottom: {
          borderBottom: "2px solid #326CF9",
          fontSize: "1.5rem",
        },
        tabCardLabel: {
          fontSize: "1rem",
          fontWeight: 500,
          color: "#0C97F0"
        },
        checkBox: {
          height: "12px",
          width: "12px"
        },
        activeMoney: {
          border: "1px solid #326cf9",
          background: "#326CF9",
          borderRadius: "20px",
          color: "#fff",
          padding: "0.4rem 1.5rem",
          //margin: "0 1rem 0 0",
          fontFamily:"inherit"
        },
        inActiveMoney: {
          border: "1px solid #326CF9",
          background: "none",
          borderRadius: "20px",
          color: "#326CF9",
          padding: "0.4rem 1.5rem",
          //margin: "0 1rem 0 0",
          fontFamily:"inherit"
        },
        button: {
          borderRadius: "40px",
          color: "#fff",
          background: "#326cf9",
          fontWeight: 500,
          fontSize: "0.9rem",
          padding: "0.8rem 4rem",
          border: "none",
          fontFamily:"inherit",
         // margin: "0px 2rem 0px 0px ",
          cursor:"pointer"
        },
        buttonBordered: {
          borderRadius: "40px",
          color: "#326cf9",
          background: "#fff",
          fontWeight:500,
          fontSize: "0.9rem",
          padding: "0.7rem 4rem",
          border: "2px solid #326cf9",
          fontFamily:"inherit",
          //margin: "0px 2rem 0px 0px ",
          cursor:"pointer"
        },
        inputField: {
            background: "#E6E6E6",
            padding: "0.7rem 0.95rem",
            borderRadius: "10px",
            border: "none",
            
        },
        inputLabel: {
          fontSize: "11px",
          fontWeight: 500,
          //color: "#474747"
        },
    
        cursorPointer:{
          cursor:"pointer",
          fontSize:"1.5rem"
        },

        rateText:{
            display:"flex",
            justifyContent:"center",
            fontSize:'1.1rem',
            padding:"4px",
            fontFamily:"inherit"
        },


        borderRight:{
            borderRight:"3px solid #0C97F0",
           
        },
       
        "@media (max-width: 900px)": {
          containerBox: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
          },
          sectionOne: {
            //width: "100%",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent:"center",
           
          },
          sectionInput: {},
          inputField: {
            width: "73%"
          },
          flexColumn: {
            display: "flex",
            flexDirection: "column"
          },
          arrowImage: {
            display: "none"
          },
          heading: {
            fontSize: "1.6rem",
            textAlign: "center"
          },
          tabCardLabel: {
            fontSize: "0.8rem",
            margin: "0px 0.4rem"
          },

          activeMoney:{
            padding: "0.4rem 0.4rem",
          },
          inActiveMoney:{
            padding: "0.4rem 0.4rem",
          },

          button:{
            padding: "0.4rem 1rem",
          },

          buttonBordered:{
            padding: "0.4rem 1rem",
          }
          
        },
    
        "@media (max-width: 280px)": {
          card: {
            width:"100%"
          },
          button:{
            padding:"10px 30px"
          },
          buttonBordered:{
            padding:"10px 30px"
          },
          inputField: {
            width: "56%"
          },        
        },
    
        currencyCode:{
          position:"absolute",
          marginLeft:"0.7rem",
          marginTop:"10px",
          paddingRight:"2px",
          opacity:"0.6",
          fontSize:"0.9rem",
          fontFamily:"Open Sans"
        },
        rupees:{
          fontFamily:"Open Sans"
        }
        
        
      }));
        const classes = useStyles();
        const [plan, setPlan] = useState("");
        const [active, setActive] = useState("");
        const [goldRate,setGoldRate]=useState('')
        const [silverRate,setSilverRate]=useState('')
        const [goldSellRate,setGoldSellRate]=useState('')
        const [silverSell,setSilverSell]=useState('')
        const [grams,setGrams]=useState('')
        const [rate,setRate]=useState('')
        const [buttonStyle,setButtonStyle]=useState('')
        const [tabData,setTabData]=useState("buy")
        const [error,setError]=useState('')
        const [changeType,setChangeType]=useState({gram:false,rate:false})
        const [metal, setMetal] = useState("XAU");

        //user balance check

        const [balance,setBalance]=useState('')
        const [dialog,setDialog]=useState(false)

        const handleCloseModal=(data)=>{
          setDialog(false)
          data === "checkout" && setTabData("buy")
        }

       

//------------------------------------------------------------------------------------------------------------------//

    useEffect(()=>{
        getData()
        if(grams || rate){
          get_checkoutRate()
        }
        
        
      },[changeType,metal])

      const get_checkoutRate= async()=>{

        const formData={
          weight:changeType.gram ? grams : rate,
          type:tabData,
          metalType:metal==="XAU" ? "gold" :"silver",
          unit:changeType.gram ? "gms" : "inr"
        }
        const httpConfig = {
          method: "POST",
          headers:{
            "Content-Type": "application/json",
          },
          data:formData
        };

        try{
          const response= await httpInstance(`${CREATE_CHECKOUT}`,httpConfig)
          setError("")
           if(changeType.gram){
             setRate(response?.data?.data?.totalAmount)
           }else{
             setGrams(response?.data?.data?.metalWeight)
           }
        }

        catch(error){
          setError(error.response.data?.message? error.response.data?.message : "Please enter valid grams/currency")
        }
      }
    
     const getData = async () => {
    
      
   
    const httpConfig1 = {
      method: "POST",
      headers:{
        "Content-Type": "application/json",
      },
    };

    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers:{
        "Content-Type": "application/json",
      },
    };
        try{
          // const agm=await fetch(
          //   `https://www.augmont.com/api/digital-gold/rates`,requestOptions
          // )
    
          
         //const allRates=await agm.json()
         const allRates= await httpInstance(`${GET_GOLD_SILVER_RATE}`,httpConfig1)
        
        setGoldRate(allRates?.data?.data?.goldBuyRate)
        setGoldSellRate(allRates?.data?.data?.goldSellRate)
        setSilverRate(allRates?.data?.data?.silverBuyRate)
        setSilverSell(allRates?.data?.data?.silverSellRate)

        const response= await httpInstance(`${GET_GOLD_USER}`,httpConfig)
        setBalance(response.data?.data)
        }
        catch(error){
          
        }
        
      };
    
    
      const handleGramChange=(e)=>{
        if(e.target.value==""){
          setRate("")
        }
        setChangeType({gram:true,rate:false})
        e.target.value = e.target.value.replace(/[^0-9.]/g, '').toLowerCase()
        setGrams(e.target.value.replace(/^00+/, ''))
        
       
      }

    
    
      const handleRateChange=(e)=>{
        if(e.target.value==""){
          setGrams("")
        }
        setChangeType({gram:false,rate:true})
        e.target.value = e.target.value.replace(/[^0-9.]/g, '').toLowerCase()
        setRate(e.target.value.replace(/^00+/, ''))
        
      }

      
    
      const change_fixed_rate=(rate)=>{
        setChangeType({gram:false,rate:true})
        setActive(rate)
        setRate(rate)
        
      }
    
      const handle_metal=(metal)=>{
        setMetal(metal);       
      }


    const handle_checkout=()=>{
        if(!grams ){
          setError("Please enter valid grams/currency")
        }else if(!(isNaN(grams))){
          setError(false)
          const data={
            weight:changeType.gram ? grams : rate,
            type:tabData,
            metalType:metal==="XAU" ? "gold" :"silver",
            unit:changeType.gram ? "gms" : "inr"
          }
          if(tabData==="sell"){
            let metalType= metal==="XAU" ? "gold" :"silver"
            let userBalance= metalType==="gold" ? balance.transactionalGoldBalance : balance.transactionalSilverBalance

            if(userBalance<grams){
              setDialog(true)
            }else{
              createOrder(data)
            }
          }else{
            createOrder(data)
          }
          
            
          
        }
      
    }

    const handle_tabData=(data)=>{
      setTabData(data)
      ChangeTabData(data)
      setRate('')
      setGrams('')
    }

    
  return (
    <div>
        <Box px={16} py={5}  display="flex"  justifyContent="center"className={classes.sectionOne} >
          
          <Box className={classes.card}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={classes.tab}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={3}
                width="50%"
                className={tabData=="buy" ? classes.activeBorderBottom :classes.cursorPointer }
                fontWeight={500}
                onClick={()=>handle_tabData("buy")}
               
              >
                BUY
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={3}
                width="50%"
                fontWeight={500}
                onClick={()=>handle_tabData("sell")}
                className={tabData=="sell" ? classes.activeBorderBottom :classes.cursorPointer }
              >
                SELL
              </Box>
            </Box>
            <Box p={{md:4}}>
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
                width="100%"
              >
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  flexDirection="row"
                  width="50%"
                  
                >
                  <input
                    type="radio"
                    className={classes.checkBox}
                    name="metal"
                    onChange={() =>handle_metal("XAU")}
                    checked={metal == "XAU" ? true : false}
                    style={{ height: "18px", width: "18px", marginLeft:"15px" }}
                  />
                  <Box ml={3} className={classes.tabCardLabel}>
                    Gold 24K 99.9
                  </Box>
                </Box>
                <Box mt={2} mx={3}></Box>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  flexDirection="row"
                  width="50%"
                >
                  <input
                    type="radio"
                    className={classes.checkBox}
                    name="metal"
                    onChange={()=>handle_metal("XAG")}
                    checked={metal == "XAG" ? true : false}
                    style={{ height: "18px", width: "18px" }}
                  />

                  <Box ml={3} className={classes.tabCardLabel}>
                    Silver 24K 99.9
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="start"
                flexDirection="row"
                mt={2}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="flex-start"
                  flexDirection="column"
                  px={2}
                >
                  <Box className={classes.inputLabel}>Grams</Box>
                  <input
                    
                    type="text"
                    className={classes.inputField}
                    onChange={handleGramChange}
                    value={grams}
                  />
                 
                </Box>
                
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  width="25%"
                >
                  <Box mt={3} mx={1}>
                    <img src="/images/arrowConvert.png" height={20} width={20} />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="start"
                  flexDirection="column"
                  px={2}
                >
                  <Box className={classes.inputLabel}>INR</Box>
                  <div className={classes.currencyWrap}>
                    <span className={classes.currencyCode}>{"₹"+" "}</span>
                  <input
                    type="text"
                    className={classes.inputField}
                    style={{paddingLeft:"1.4rem"}}
                    value={ rate}
                    placeholder=""
                    onChange={handleRateChange}
                  />
                  </div>
                </Box>
              </Box>
              {error && <ErrorMessage message={error}/>}
              <Box
                
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                py={4}
              >
                <Box
                  value={plan}
                  onChange={() => setPlan("500")}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-around"
                >
                  <Box
                    value="₹ 500"
                    label="₹ 500"
                    onClick={()=>change_fixed_rate("500")}
                  >
                    <button
                      className={
                        active == "500"
                          ? classes.activeMoney
                          : classes.inActiveMoney
                      }
                    >
                      
                      <span className={classes.rupees}>₹</span> {"500"}
                    </button>
                  </Box>
                  <Box
                    value="₹ 1000"
                    label="₹ 1000"
                    onClick={()=>change_fixed_rate("1000")}
                  >
                    <button
                      className={
                        active === "1000"
                          ? classes.activeMoney
                          : classes.inActiveMoney
                      }
                    >
                      <span className={classes.rupees}>₹</span> 1000
                    </button>
                  </Box>
                  <Box
                    value="₹ 5000"
                    label="₹ 500"
                    onClick={()=>change_fixed_rate("5000")}
                  >
                    <button
                      className={
                        active === "5000"
                          ? classes.activeMoney
                          : classes.inActiveMoney
                      }
                    >
                      <span className={classes.rupees}>₹</span> 5000
                    </button>
                  </Box>
                  <Box
                    value="₹ 500"
                    label="₹ 500"
                    onClick={()=>change_fixed_rate("10000")}
                  >
                    <button
                      className={
                        active == "10000"
                          ? classes.activeMoney
                          : classes.inActiveMoney
                      }
                    >
                      
                      <span className={classes.rupees}>₹</span> {"10000"}
                    </button>
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                //justifyContent={tabData=="buy" ? "space-between" : "center"}
                alignItems="center"
                flexDirection="row"
                p={5}
              >
               {/* {tabData=="buy" && <button className={classes.buttonBordered} onClick={handleStartSip} >Start SIP</button>} */}
                <button className={ classes.button} onClick={handle_checkout}>Checkout</button>
              </Box>
            </Box>
          </Box>
          {/* second section */}
          
        </Box>
        <Box p={2} justifyContent="center" display="flex" >
            <Box className={classes.card} display="flex" justifyContent="center" p={4}>
            <Box className={classes.borderRight}  px={3} >
            <Typography className={classes.rateText}>GOLD Rate / 1 gm</Typography>
            <Typography className={classes.rateText}>{tabData==="buy" ? goldRate : goldSellRate}</Typography>
            </Box>
            <Box px={3} >
            <Typography className={classes.rateText}>SILVER Rate / 1 gm</Typography>
            <Typography className={classes.rateText}>{tabData=="buy" ? silverRate :silverSell}</Typography>
            </Box>
            </Box>
        </Box>

        <InsufficientBalanceModal dialog={dialog} closeDialog={handleCloseModal}/>
    </div>
  )
}

export default RateCaluclation