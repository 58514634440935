import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  ADD_FUND_TO_ASSISTED,
  GET_TIMESTAMPS,
  INITIATE_TRIGGER,
  CONFIRM_TRIGGER,
} from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import ErrorMessage from "../../../../components/Validation/ErrorMessage";
import { useSelector } from "react-redux";
import CustomToast from "../../../../components/UI/CustomToast";

const TimestampPopup = ({
  show,
  handleClose,
  fundData,
  timestamps,
  fundList,
}) => {
  const useStyles = makeStyles({
    root: {
      "& .MuiDialogContent-root": {
        fontSize: "0.8rem",
        lineHeight: "25px",
        padding: "20px 25px",
      },
      "& .MuiDialog-paperWidthSm": {
        minWidth: "45%",
        //maxWidth:"300px",
        maxHeight: "100%",
      },
    },
    dialogTitle: {
      //display: "flex",
      justifyContent: "center",
      alignItems: " center",
      //textAlign: "center",
      padding: "0px 10px 0px 25px",
      marginTop: "-10px",
      "& .MuiTypography-body1": {
        fontSize: "1.2rem",
        fontWeight: 500,
      },
    },
    closeIcon: {
      padding: "5px",
      justifyContent: "flex-end",
    },
    text: {
      fontSize: "0.8rem",
    },
    textCancel: {
      fontSize: "0.8rem",
      cursor: "pointer",
    },
    select: {
      fontSize: "0.85rem",
      color: "black",
      padding: "3px 0px",
      borderRadius: "10px",

      minWidth: "20rem",
      "& .MuiSelect-select": {
        paddingTop: "10px ",
        paddingBottom: "10px",
        paddingRight: "40px",
        paddingLeft: "10px",
        border: "1px solid #DDDDDD",
        borderRadius: "5px",
        "&:focus": {
          backgroundColor: "#fff",
        },
      },
      "& .MuiSvgIcon-root": {
        color: "black",
      },
      "& .MuiMenuItem-root": {
        fontSize: "0.85rem !important",
      },
    },
    menu: {
      boxShadow: "none",
      border: "1px solid #DDDDDD",
    },
    menuItem: {
      fontSize: "0.85rem !important",
    },
    inputBox: {
      padding: "9px",
      border: "1px solid #DDDDDD",
      borderRadius: "5px",
      fontSize: "0.85rem",
      fontFamily: "inherit",
      marginTop: "10px",
      "&::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "black",
        opacity: 1 /* Firefox */,
      },

      "&:-ms-input-placeholder": {
        /* Internet Explorer 10-11 */ color: "black",
      },

      "&::-ms-input-placeholder ": {
        /* Microsoft Edge */ color: "black",
      },
    },
    primaryButton: {
      background: "#326CF9",
      color: "#fff",
      padding: "10px 2rem",
      border: "none",
      borderRadius: "20px",
      fontFamily: "inherit",
    },
  });

  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);
  // const [timestamp, setTimestamp] = useState("");

  const { adminStore } = useSelector((state) => state);
  const timestamp = useRef();
  const handle_close = () => {
    handleClose();
  };

  const handleChange = (e) => {
    timestamp.current = e.target.value;
  };

  //----------------handle email--------------------------------//

  // const remove_from_list = (scheme_code) => {
  //   const arry = fundList?.filter((ele) => {
  //     return scheme_code != ele.scheme_code;
  //   });
  //   setFundList(arry);
  // };

  const handle_add_fund = async (data) => {
    setError(false);
    const formData = {
      schemeName: data?.scheme_name,
      assetSubCategories: [data?.asset_sub_category],
      isTriggerByBulk: false,
      planId: data?.scheme_code,
      assistedServicesTimestampId: timestamp.current,
    };

    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    const httpConfig1 = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      await httpInstance(`${INITIATE_TRIGGER}`, httpConfig);
      await httpInstance(`${CONFIRM_TRIGGER}`, httpConfig1);
      if (adminStore?.Adminuser?.role?.name == "Superadmin") {
        //remove_from_list(data?.scheme_code);
      }
      CustomToast(
        `${
          adminStore?.Adminuser?.role?.name == "Superadmin"
            ? `Exit trigger sent successfully`
            : `Exit trigger request for approval sent`
        }`,
        "SUCCESS"
      );
    } catch (error) {
      CustomToast(`${error.response.data?.message}`, "FAILED");
    }
  };

  return (
    <div>
      <Dialog className={classes.root} open={show} onClose={handle_close}>
        <Box
          display="flex"
          className={classes.closeIcon}
          onClick={handle_close}
        >
          <CloseIcon />
        </Box>

        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Box display="flex" justifyContent="center">
            <Typography className={classes.title}>Select Timestamp</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            mt={5}
          >
            <Box display="flex" flexDirection="column">
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <Select
                  //   value={isDeleted}
                  onChange={handleChange}
                  className={classes.select}
                  disableUnderline
                  IconComponent={KeyboardArrowDownIcon}
                  //   defaultValue={timestamp?.[0]?.isDeleted}
                  displayEmpty
                  //renderValue={(value) => (value !== '' ? value : 'Placeholder text')}
                  //   renderValue={
                  //     type.length> 0
                  //       ? type
                  //       : () => "Select User Type"
                  //   }
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    style: {
                      maxHeight: 350,
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.menu },
                  }}
                >
                  {/* <MenuItem
                    value=""
                    className={classes.menuItem}
                    disabled
                    selected
                  >
                    Select Timestamp
                  </MenuItem> */}
                  {timestamps?.map((timestamp) => {
                    return (
                      <MenuItem
                        value={timestamp?.id}
                        className={classes.menuItem}
                      >
                        {timestamp?.versionName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box mt={15} mb={5}>
              <button
                className={classes.primaryButton}
                onClick={() => handle_add_fund(fundData)}
              >
                Send request
              </button>
            </Box>
            {error && <ErrorMessage message={error} />}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TimestampPopup;
