import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";

function Accordian({ title, content }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      border: "none",
      boxShadow: "none",
      borderBottom: "0.5px solid #272727",
      padding: "0.6rem 0rem",
      '&:last-child': {
        borderBottomLeftRadius:"0px",
        borderBottomRightRadius:"0px"
      },
     
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: "bold",
      fontFamily: "Poppins",
      color: "#474747"
    },
    content:{
      fontSize:theme.typography.pxToRem(14)
    },
   
  }));
  const classes = useStyles();
  return (
    <React.Fragment>
      <Accordion className={classes.root}>
        <AccordionSummary
          expandIcon={
            <img
              src="/images/Vector 5.svg"
              height="20"
              width="20"
             
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.content}>{content}</Typography>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}

export default Accordian;
