import React from 'react'
import { Outlet } from 'react-router-dom';
import {makeStyles,Box } from "@material-ui/core";
import SideNav from './SideNav';
import Topbar from './Topbar';


const Layout = ({children}) => {

  const useStyles=makeStyles({
    root:{
      display:"flex",
      
    },
    page:{
      background:"#ebedf5",
      width:"100%",
      padding:20,
      marginTop:"4rem",
      minHeight:"100vh"
    },

  })
  const classes=useStyles()

 
  return (
    <div className={classes.root}>
      <Box display="flex">
        <Topbar/>
        <SideNav/>
      </Box>
      
    <div className={classes.page}>
      <Outlet/>
    </div>
    </div>
  )
}

export default Layout