import React,{useState,useEffect} from 'react'
import { Box, Typography, makeStyles, Grid,Slider,Tooltip } from "@material-ui/core";

  


const ReturnCal = ({returns}) => {
    const useStyles = makeStyles((theme) => ({
        root:{
            marginTop:"3rem",
            
        },

        borderBox:{
            border:"1px solid #E4E4E4",
            borderRadius:"5px",
            padding:"1.5rem",
            width:"75%",
            '& .MuiSlider-valueLabel': {
                '& > *': {
                  
                  backgroundColor: 'white',
                  color:"black"
                },
            }
        },
        ButtonInactive: {
            border: "none",
            background: "none",
            padding:"5px 20px",
            borderRadius:"20px",
            fontSize: "0.75rem",
            fontFamily:"inherit",
            marginRight:"2rem",
            cursor:"pointer",
            outline:"none",
            "&:hover":{
              background:"#6FDDB8",
              color:"white",
             
              },
              "&:active":{
                background:"#6FDDB8",
                color:"white"
                }
          },
      
          activeButton:{
            background:"#6FDDB8",
            color:"white",
            padding:"5px 20px",
            border: "none",
            borderRadius:"20px",
            fontSize: "0.75rem",
            fontFamily:"inherit",
            marginRight:"2rem",
            cursor:"pointer",
            outline:"none",
          },
          activeYearButton:{
            background:"#6FDDB8",
            color:"white",
            padding:"5px 20px",
            border: "none",
            borderRadius:"20px",
            fontSize: "0.7rem",
            fontWeight:700,
            fontFamily:"inherit",
            marginRight:"2rem",
            cursor:"pointer",
            outline:"none",

          },

          inactiveYearButton:{
            border: "1px solid #6FDDB8 ",
            background: "none",
            padding:"5px 20px",
            borderRadius:"20px",
            fontSize: "0.7rem",
            fontWeight:700,
            fontFamily:"inherit",
            marginRight:"2rem",
            color:"#6FDDB8",
            cursor:"pointer",
            outline:"none",
            "&:hover":{
              background:"#6FDDB8",
              color:"white",
             
              },
              "&:active":{
                background:"#6FDDB8",
                color:"white"
                }
          },

          text:{
            fontSize:"0.8rem"
          },

          title:{
            fontSize:"0.9rem",
            marginTop:"1rem"
          },

          thumb: {
            background: "#F3C04F",
            width:"15px",
            height:"15px",
            boxShadow:'none !important',
            ' &.Mui-focusVisible':{
                boxShadow:'none !important',
              },
              '&.Mui-active': {
                boxShadow:'none !important',
              },

          },
          mark: {
            background: "black"
          },
          rail: {
            background: "#FFE3A4",
            height:"5px"
          },
          track: {
            background: "#F3C04F",
            height:"5px"
          },
          rupees:{
            fontFamily:"Open Sans"
          },
          "@media (max-width: 600px)": {
            activeButton:{
              marginRight:"0rem",
            },
            ButtonInactive:{
              marginRight:"0rem",
            },
            activeYearButton:{
              marginRight:"0.5rem",
            },
            inactiveYearButton:{
              marginRight:"0.5rem",
            }
          } 
      
    }))

    const classes = useStyles();

    const [button,setButton]=useState('monthly')
    const [yearButton,setYearButton]=useState('1')
    const [totalReturn,setTotalReturn]=useState()
    const [invest,setInvest]=useState(5000)

    const handleButton=(type)=>{
        setButton(type)
    }

    const handleYearButton=(type)=>{
        setYearButton(type)
        
    }

    const get_return=()=>{
        if(yearButton=='1'){
            return returns?.oneY
        }else if(yearButton=="3"){
            return returns?.threeY
        }else{
            return returns?.fiveY
        }
    }

    useEffect(()=>{  
        return_calculator(5000)  
    },[returns])

    useEffect(()=>{  
        return_calculator(invest)  
    },[yearButton,button])

    const return_calculator=(value)=>{
      console.log("value",value)
        setInvest(value)
        if(button=="monthly"){
       let n= yearButton*12
       let i=(Number(get_return())/(12))/100
        //M = P × ({[1 + i]n – 1} / i) × (1 + i).
        const totalReturnValue =value * ((Math.pow(1 +i ,n)-1)/i) * (1 + i)
        setTotalReturn(totalReturnValue)
        }else{
            //A = P (1 + r/n) ^ nt
             let n=2
             let r=get_return()/100
             let t=yearButton

             const totalReturnValue= value * (Math.pow(1 + r / n, n * t))
             setTotalReturn(totalReturnValue)

            
             

        }
    }
   
  return (
    <div className={classes.root}>
        <Typography>Return Calculator</Typography>
        <Box className={classes.borderBox} mt={3}>
            <Box>
                <button className={button=='monthly' ? classes.activeButton :classes.ButtonInactive} onClick={()=>handleButton('monthly')}>Monthly SIP</button>
                <button className={button=='onetime' ? classes.activeButton :classes.ButtonInactive}onClick={()=>handleButton('onetime')}>One Time</button>
            </Box>

            <Box mt={8} px={3}>
            <Slider classes={{
                    thumb: classes.thumb,
                    rail: classes.rail,
                    track: classes.track,
                    valueLabel: classes.valueLabel,
                    mark: classes.mark  
                    }} 
                    defaultValue={5000} aria-label="Default" valueLabelDisplay="on"
                    size="medium"
                    valueLabelFormat={(x)=>{
                        return(
                            <p style={{color:"black"}}><span className={classes.rupees}>₹</span>{x.toLocaleString('en-IN')}</p>
                        )
                    }}  
                    min={500}  
                    max={100000} 
                    onChange={(e,value)=>return_calculator(value)}         
        />
            </Box>

            <Typography className={classes.title}>Over the past</Typography>
            <Box mt={3}>
            <button className={yearButton=="1" ? classes.activeYearButton:classes.inactiveYearButton} onClick={()=>handleYearButton("1")}>1 Year</button>
            <button className={yearButton=="3" ? classes.activeYearButton:classes.inactiveYearButton} onClick={()=>handleYearButton("3")}>3 Year</button>
            <button className={yearButton=="5" ? classes.activeYearButton:classes.inactiveYearButton} onClick={()=>handleYearButton("5")}>5 Year</button>
            </Box>

            <Box  mt={3}>
            <Typography className={classes.text}>Total Investment of <span className={classes.rupees}>₹</span>{button=="monthly" ? (invest*yearButton*12).toLocaleString('en-IN'): invest}</Typography>
            <Typography className={classes.text}>would have become <span className={classes.rupees}>₹</span>{(totalReturn)?.toLocaleString('en-IN',{maximumFractionDigits:0})} <span style={{color:"#6FDDB8"}}>({get_return()}%)</span></Typography>
            </Box>
        </Box>
    </div>
  )
}

export default ReturnCal