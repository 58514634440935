import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  CircularProgress,
  IconButton,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import CategoryList from "./FundList";
import { DELETE_CATEGORY, CONFIRM_TRIGGER } from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import CustomToast from "../../../../components/UI/CustomToast";
import { useSelector } from "react-redux";

const ConfirmTrigger = ({ show, handleClose, bulkCatogory, reset_list }) => {
  const useStyles = makeStyles({
    root: {
      "& .MuiDialogContent-root": {
        fontSize: "0.8rem",
        lineHeight: "25px",
        padding: "20px 25px",
      },
      "& .MuiDialog-paperWidthSm": {
        minWidth: "50%",
        // maxWidth:"300px",
        maxHeight: "95%",
      },
    },
    dialogTitle: {
      //display: "flex",
      justifyContent: "center",
      alignItems: " center",
      //textAlign: "center",
      padding: "5px 10px 0px 25px",
      marginTop: "-10px",
      "& .MuiTypography-body1": {
        fontSize: "1.2rem",
        fontWeight: 600,
      },
    },
    closeIcon: {
      padding: "5px",
      justifyContent: "flex-end",
      cursor: "pointer",
    },
    text: {
      fontSize: "0.85rem",
    },
    text2: {
      fontSize: "0.7rem",
      marginLeft: "2rem",
    },
    boxBorder: {
      border: "1px solid #DDDDDD",
      borderRadius: "10px",
    },
    input: {
      border: "1px solid #DDDDDD",
      borderRadius: "10px",
      width: "100%",
      padding: "10px",
      fontFamily: "inherit",
    },
    primaryButton: {
      background: "#326CF9",
      color: "#fff",
      padding: "1rem 5rem",
      border: "none",
      borderRadius: "30px",
      fontFamily: "inherit",
      boxShadow: "0px 2px 26px rgba(0, 0, 0, 0.15)",
      cursor: "pointer",
      fontSize: "0.9rem",
    },
  });
  const classes = useStyles();

  const [showCategory, setShowCategory] = useState(false);
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [funds, setFunds] = useState([]);

  const { adminStore } = useSelector((state) => state);

  useEffect(() => {
    setAllSubCategory(bulkCatogory);
  }, [bulkCatogory]);

  const handle_close = () => {
    handleClose();
  };

  const handle_close_show_category = () => {
    setShowCategory(false);
  };

  //-----------------------------search----------------------------------------//
  const handle_search = (e) => {
    const arry = bulkCatogory.filter((ele) => {
      return ele.assetSubCateogry
        ?.toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    setAllSubCategory(arry);
  };

  //------------------------------delete categaory--------------------------------------------//

  const delete_catogary = async (categaory) => {
    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      params: { AssetSubCategory: categaory },
    };
    try {
      await httpInstance(`${DELETE_CATEGORY}`, httpConfig);
      let arry = allSubCategory.filter((ele) => {
        return categaory != ele.assetSubCateogry;
      });
      setAllSubCategory(arry);
    } catch {}
  };

  //--------------------------------confirm trigger---------------------------------------------//
  const confirm_trigger = async () => {
    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      await httpInstance(`${CONFIRM_TRIGGER}`, httpConfig);
      reset_list();
      handle_close();
      CustomToast(
        `${
          adminStore?.Adminuser?.role?.name == "Superadmin"
            ? `Exit Trigger generated successfully`
            : `Approval Request sent`
        }`,
        "SUCCESS"
      );
    } catch {}
  };

  //---------------------------------handle fundlist---------------------------------------//
  const show_fundlist = (fundlist) => {
    setFunds(fundlist);
    setShowCategory(true);
  };

  //----------------------------------remove deleted fund-----------------------------------//
  const remove_fund = (planId) => {
    const arry = allSubCategory.map((ele) => {
      let fundData = ele.lstExitTriggerFundsDTO.filter((fund) => {
        return planId != fund.planId;
      });
      return { ...ele, lstExitTriggerFundsDTO: fundData };
    });

    setAllSubCategory(arry);
  };
  return (
    <div>
      <Dialog className={classes.root} open={show} onClose={handle_close}>
        <Box
          display="flex"
          className={classes.closeIcon}
          onClick={handle_close}
        >
          <CloseIcon />
        </Box>

        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Box display="flex" justifyContent="center">
            <Typography className={classes.title}>Confirm Trigger</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          {!showCategory ? (
            <>
              <Box px={10} mb={10}>
                <input
                  className={classes.input}
                  placeholder="Search for Categories"
                  onChange={handle_search}
                />
                {allSubCategory?.map((ele, i) => {
                  return (
                    <Box
                      key={i}
                      mt={5}
                      p={3}
                      className={classes.boxBorder}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          show_fundlist(ele.lstExitTriggerFundsDTO)
                        }
                      >
                        <Typography className={classes.text}>
                          {ele.assetSubCateogry}
                        </Typography>
                        <Typography className={classes.text2}>
                          {ele.lstExitTriggerFundsDTO.length} Funds
                        </Typography>
                      </Box>
                      <img
                        src="/images/admin/deleteblue.svg"
                        height="20px"
                        width="20px"
                        style={{ cursor: "pointer" }}
                        onClick={() => delete_catogary(ele.assetSubCateogry)}
                      />
                    </Box>
                  );
                })}
              </Box>
              <Box display="flex" justifyContent="center" my={5}>
                <button
                  className={classes.primaryButton}
                  onClick={confirm_trigger}
                >
                  Confirm
                </button>
              </Box>
            </>
          ) : (
            <CategoryList
              closeCategory={handle_close_show_category}
              funds={funds}
              remove_fund={remove_fund}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ConfirmTrigger;
