import React,{useEffect, useState}  from 'react'
import { Box,makeStyles,Table,TableRow,TableHead,TableBody,TableCell, Typography } from '@material-ui/core'
import {USER_STATUS} from '../../../utils/endpoints'
import httpInstance from '../../../utils/instance'


const Index = () => {
    const useStyles=makeStyles({
        root:{
            "& .MuiTypography-body1":{
              fontSize:"0.85rem"
            }
          },
          table:{
            margin:"1rem 0px",
            "& .MuiTableCell-root":{
                fontSize:"0.8rem"
            },
            "& .MuiTableHead-root":{
                background:"#F9FBFC",
                borderTopLeftRadius:" 4px",
                "& .MuiTableCell-root":{
                    color:"#C0C5D0",
    
                }
            },
            "& .MuiTableBody-root":{
                background:"#fff",
            }
        },
        approveText:{
          color:"#4FD000",
          fontWeight:500
        },
        rejectText:{
          color:"#D03200",
          fontWeight:500
        },
        inprogressText:{
          color:"#F2AF1A"
        }
    })

    const classes=useStyles()

    const [data,setData]=useState()

    const get_data=async()=>{
        const httpConfig = {
            method: "GET",
            tokenType: "admin",
            headers:{
              "Content-Type": "application/json",
            },
            params:{userId:localStorage.getItem("admin_userId")}
          };
          try{
           const response=await httpInstance(`${USER_STATUS}`,httpConfig)
           setData(response.data?.data)          
            
          }
          catch{

          }
    }

    useEffect(()=>{
        get_data()
    },[])

    //-----------------word format------------------------------//
    
  return (
    <div>
      {data?.length>0 ?
         <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell align="left">Change</TableCell>
                    <TableCell align="left">Justification</TableCell>
                    <TableCell align="left">Super Admin</TableCell>
                    <TableCell align="left">Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((ele)=>{
                return(
                  <TableRow key={ele.id}>
                        <TableCell>{ele.type}</TableCell>
                        <TableCell>{ele.justification}</TableCell>
                        <TableCell>{ele.approvedBy?.userName}</TableCell>
                        <TableCell className={ele.status=="APPROVED" ?classes.approveText : ele.status=="REJECTED" ? classes.rejectText :classes.inprogressText}> {ele.status[0]+(ele.status.slice(1)).toLowerCase()}</TableCell>
                        </TableRow>
                )
              })}
                        
                    
                        
                   
            
            </TableBody>
         </Table> : 
         <Typography style={{textAlign:"center"}}>No data</Typography>
            }
    </div>
  )
}

export default Index