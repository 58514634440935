import React,{useEffect,useState} from 'react'
import PaymentSuccess from '../../components/MutualFund/PaymentSuccess'
import {GET_LUMSUM_DETAIL} from '../../utils/endpoints'
import httpInstance from '../../utils/instance'
import {useDispatch } from "react-redux";
import { getNotificationCount } from '../../redux/Notification/NotifiActions';


const Payment_success = () => {

  const [data,setData]=useState('')

  const dispatch=useDispatch()

  const get_Data= async()=>{
    const id=sessionStorage.getItem('lumsumId')
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers:{
        "Content-Type": "application/json",
      },
      params:{id:id},
    };

    const response= await httpInstance(`${GET_LUMSUM_DETAIL}`,httpConfig)
    setData({
      name:response.data?.data?.planName,
      logo:response.data?.data?.amcLogo?.imgUrl
    })
  }


  useEffect(()=>{
    if(sessionStorage.getItem("sip")){
      let sipData=JSON.parse(sessionStorage.getItem("sip"))
      setData({name:sipData.name,logo:sipData?.img})
    }else{
      get_Data()
    }
    dispatch(getNotificationCount())
  },[])
  return (
    <div>
        <PaymentSuccess message={"You have successfully Purchased "} fundData={data}/>
    </div>
  )
}

export default Payment_success