import React from 'react'
import UserStatus from '../../containers/Admin/UserStatus'

const User_status = () => {
  return (
    <div>
        <UserStatus/>
    </div>
  )
}

export default User_status