import React from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/Footer'
import BuySuccess from '../../containers/DigitalGold/Payement/BuySuccess'

const PaymentSuccess = () => {
  return (
    <div>
        {/* <Header/> */}
            <BuySuccess/>
        {/* <Footer/> */}

    </div>
  )
}

export default PaymentSuccess