import React, { useState } from "react";
import { Box, makeStyles, Grid, Typography } from "@material-ui/core";
import Confirmation from "../../../../components/MutualFund/InfoMsg/Confirmation";
import TimestampPopup from "./timestampPopup";

const ExitFundCard = ({ fundData, initiate_trigger, timestamps, fundList }) => {
  const useStyles = makeStyles({
    root: {
      background: "#ffff",
      borderRadius: "10px",
    },

    primaryButton: {
      background: "#326CF9",
      color: "#fff",
      padding: "10px 2rem",
      border: "none",
      borderRadius: "20px",
      fontFamily: "inherit",
      //margin: "0px 15px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },

    subText: {
      fontSize: "0.85rem",
    },
  });
  const classes = useStyles();

  const [showDialog, setShowDailog] = useState(false);
  const [showTimestamp, setShowTimestamp] = useState(false);

  const handle_trigger = () => {
    initiate_trigger(fundData);
    setShowDailog(false);
  };
  return (
    <div>
      <Box mt={2}>
        <Box p={2} className={classes.root}>
          <Grid container alignItems="center">
            <Grid item xs={9}>
              <Box display="flex">
                <img
                  src={fundData?.img_url}
                  height="35px"
                  width="35px"
                  style={{ marginRight: "15px" }}
                />
                <Box>
                  <Typography>{fundData?.scheme_name} </Typography>
                </Box>
              </Box>
              <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={7}>
                  <Typography
                    style={{ marginLeft: "8px" }}
                    className={classes.subText}
                  >
                    {fundData?.asset_sub_category}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.subText}>
                    {fundData?.risk_profile}
                  </Typography>
                </Grid>
              </Grid>
              {/* <Box display="flex" >
                                <Typography style={{marginRight:"6rem"}} className={classes.subText}>{fundData?.asset_sub_category}</Typography>
                                <Typography className={classes.subText}>{fundData?.risk_profile}</Typography>

                            </Box> */}
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" justifyContent="end">
                <button
                  className={classes.primaryButton}
                  onClick={() => setShowDailog(true)}
                >
                  {fundData?.exitTriggerInitiated ? "Triggered" : "Trigger"}
                </button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* <TimestampPopup
        timestamps={timestamps}
        show={showTimestamp}
        handleClose={() => setShowTimestamp(false)}
        fundData={fundData}
        fundList={fundList}
      /> */}

      {/* confirm trigger */}
      <Confirmation
        show={showDialog}
        handleClose={() => setShowDailog(false)}
        handle_yes={handle_trigger}
        title="Trigger Confirmation"
        text="Are you sure you want to trigger this fund?"
      />
    </div>
  );
};

export default ExitFundCard;
