import React from 'react'
import {
    Box,
    Typography,
    Grid,
    makeStyles
  } from "@material-ui/core";
import {DELETE_FUND,CONFIRM_DELETE} from '../../../../utils/endpoints'
import httpInstance from '../../../../utils/instance'
import { useState } from 'react';
import { useEffect } from 'react';


const CategoryList = ({closeCategory,funds,remove_fund}) => {
    const useStyles = makeStyles({
        text: {
          fontSize: "0.85rem",
        },
        text2: {
            fontSize: "0.7rem",
            marginLeft:"2rem"
          },
        boxBorder: {
          border: "1px solid #DDDDDD",
          borderRadius: "10px",
        },
        input: {
          border: "1px solid #DDDDDD",
          borderRadius: "10px",
          width: "100%",
          padding: "10px",
          fontFamily: "inherit",
        },
        primaryButton: {
          background: "#326CF9",
          color: "#fff",
          padding: "1rem 5rem",
          border: "none",
          borderRadius: "30px",
          fontFamily: "inherit",
          boxShadow: "0px 2px 26px rgba(0, 0, 0, 0.15)",
          cursor: "pointer",
          fontSize:"0.9rem"
        },
    })
    const classes=useStyles()

    const [fundList,setFundList]=useState([])
    const [code,setCode]=useState('')

    useEffect(()=>{
      setFundList(funds)
    },[])

    //-----------------------------search----------------------------------------//
  const handle_search=(e)=>{
    const arry=funds.filter((ele)=>{
      return ele.schemeName?.toLowerCase().includes(e.target.value.toLowerCase())
    })
    setFundList(arry)
  }

  //----------------------------delete fund----------------------------------------------//
  const delete_fund=async(planId)=>{
    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      params:{planId:planId}
    };
    try{
      await httpInstance(`${DELETE_FUND}`,httpConfig)
      const arry=fundList?.filter((ele)=>{
        return ele.planId!=planId
      })
      setFundList(arry)
      setCode(planId)
    }
    catch{

    }
  }
  //---------------------------confirm delete-----------------------------------------//
  const confirm_delete=async()=>{
    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try{
      await httpInstance(`${CONFIRM_DELETE}`,httpConfig)
      remove_fund(code)
      closeCategory()
    }
    catch{

    }
  }
  return (
    <div>
        <Box px={10} mb={10}>
            <input className={classes.input} placeholder="Search for Funds" 
            onChange={handle_search} 
            />
            <Box mt={5}>
            {fundList?.map((ele,i)=>{
              return (
                <Box key={i} mt={2} p={2} className={classes.boxBorder} display ="flex" alignItems="center" justifyContent="space-between">
                <Box display ="flex" alignItems="center" justifyContent="spac-between" style={{cursor:"pointer"}}>
                {/* <img src={ele.} height="20px" width="20px" /> */}
                    <Typography className={classes.text}>{ele.schemeName}</Typography>
                    
                   
                </Box>
                <img src="/images/admin/deleteblue.svg" height="20px" width="20px" style={{cursor:"pointer"}} onClick={()=>delete_fund(ele.planId)}/>
            </Box>
              )
            })}
            </Box>
            </Box>
            <Box display ="flex" justifyContent="center" my={5}>
                <button className={classes.primaryButton} onClick={confirm_delete}>Confirm</button>
            </Box>
    </div>
  )
}

export default CategoryList