import React from 'react'
import { Box, Typography,makeStyles,Grid } from '@material-ui/core'
import OrderprogressCard from '../../../components/Portfolio/DigitalGold/OrderprogressCard'

const EmptyPortPolio = () => {
    const useStyles = makeStyles((theme) => ({
        title:{
            fontSize:"1.1rem",
            fontWeight:500
        }
    }))
    const classes = useStyles();
   
  return (
    <div>
        <Box>
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" my={4}>
                <img src="/images/undraw_book_reading_re_fu2c 1.svg" height="175px" width="175px"/>
                <Typography className={classes.title}>Your Portfolio is empty</Typography>
                
            </Box>
            <OrderprogressCard/>
        </Box>
    </div>
  )
}

export default EmptyPortPolio