import React from 'react'
import { Box, Typography } from '@material-ui/core'
import InvestorTable from '../../../components/Admin/ViewChanges/InvestorTable'

const InvestorChanges = ({data}) => {
  return (
    <div>
        <Box>
            <Typography>Existing Data</Typography>
            <InvestorTable data={data?.existingList?.ratio?.input}/>
        </Box>
        <Box mt={5}>
            <Typography>Proposed Data</Typography>
            <InvestorTable data={data?.proposedList?.ratio?.input}/>
        </Box>
    </div>
  )
}

export default InvestorChanges