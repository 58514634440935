import React, { useState, useEffect } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import { namespace } from "../../../environment";

const ProductCard = ({ data, handleSku, setProductName }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      boxShadow: "0px 0px 40.156px rgba(0, 0, 0, 0.12)",
      borderRadius: " 10px",
      textAlign: "center",
      cursor: "pointer",
    },
    gramText: {
      fontSize: "0.7rem",
      fontWeight: 600,
      padding: "7px",
      fontFamily: "inherit",
    },

    description: {
      fontSize: "0.7rem",
      padding: "7px",
      fontFamily: "inherit",
    },

    text: {
      fontSize: "0.6rem",
      color: "#898989",
      fontFamily: "inherit",
    },
    rupees: {
      fontFamily: "Open Sans",
    },
  }));
  const classes = useStyles();

  const navigate = useNavigate();

  const handleRoute = (data) => {
    handleSku(data.sku);
    setProductName(data.name);
    navigate({
      pathname: `${namespace}/myportfolio/delivery`,
      search: `?${createSearchParams({ tab: "delivery-address" })}`,
    });
  };
  return (
    <Box
      display="flex"
      justifyContent="center"
      className={classes.root}
      p={2}
      px={5}
      flexDirection="column"
      alignItems="center"
      onClick={() => handleRoute(data)}
    >
      <img src={data.defaultImageUrl} height="60px" width="60px" />
      <Typography
        className={classes.gramText}
      >{`${data.productWeight} gm`}</Typography>
      <Typography className={classes.description}>{data.name}</Typography>
      <Typography className={classes.gramText}>
        <span className={classes.rupees}>₹</span>
        {`${data.basePrice}`}
      </Typography>
      <Typography className={classes.text}>
        Making and Delivery charges
      </Typography>
    </Box>
  );
};

export default ProductCard;
