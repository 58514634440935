import React from "react";
import AssistedPins from "../../containers/Admin/AssistedPins";

const Assisted_pins = () => {
  return (
    <div>
      <AssistedPins />
    </div>
  );
};

export default Assisted_pins;
