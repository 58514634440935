
import {
  GET_NOTIFICATION,
  READ_NOTIFICATION,
  UNREAD_COUNT,
  VERIFY_LOADING,
  READ_ALL_NOTIFICATION
} from "./AdminNotifiActionTypes";
import httpInstance from '../../../utils/instance'
import {ADMIN_NOTIFICATION,GET_ADMIN_UNREAD_COUNT,READ_ADMIN_NOTIFICATIONS,READ_ALL_ADMIN_NOTIFICATIONS} from '../../../utils/endpoints'


// import CustomToast from "components/UI/CustomToast";

export const getNotificationCount = () => {
  
  const httpConfig = {
    method: "GET",
    tokenType: "admin",
    headers:{
      "Content-Type": "application/json",
    },
  };
  return async (dispatch) => {
    dispatch({
      type: VERIFY_LOADING,
    });
    try {
       const data = await httpInstance(`${GET_ADMIN_UNREAD_COUNT}`, httpConfig);
   
      dispatch({
        type: UNREAD_COUNT,
        payload: data?.data?.data?.notificationCount
      });
    } catch (error) {
      
    }
  };
};

export const getNotification = (page,handle_has_more,handle_page) => {
 
  const httpConfig = {
    method: "GET",
    tokenType: "admin",
    headers:{
      "Content-Type": "application/json",
    },
    params:{pageNumber:page}
  };
  return async (dispatch) => {
    dispatch({
      type: VERIFY_LOADING,
    });
    try {
       const data = await httpInstance(`${ADMIN_NOTIFICATION}`, httpConfig);
       if(data?.data?.responseCount==0){
        handle_has_more()
       }else{
        dispatch({
          type: GET_NOTIFICATION,
          payload: {notifi:data?.data?.data,page:page}
        });
        handle_page()
       }
   
      
    } catch (error) {
      
    }
  };
};

export const readNotification = (id) => {
 
  const httpConfig = {
    method: "POST",
    tokenType: "admin",
    headers:{
      "Content-Type": "application/json",
    },
    data:{id:id}
  };
  return async (dispatch) => {
    dispatch({
      type: VERIFY_LOADING,
    });
    dispatch({
      type: READ_NOTIFICATION,
      payload:id
    });
    try {
       await httpInstance(`${READ_ADMIN_NOTIFICATIONS}`, httpConfig);
   
    } catch (error) {
      
    }
  };
};

export const readAllNotification = (id) => {
 
  const httpConfig = {
    method: "POST",
    tokenType: "admin",
    headers:{
      "Content-Type": "application/json",
    },
  };
  return async (dispatch) => {
    dispatch({
      type: VERIFY_LOADING,
    });
    dispatch({
      type: READ_ALL_NOTIFICATION,
    });
    try {
       await httpInstance(`${READ_ALL_ADMIN_NOTIFICATIONS}`, httpConfig);
   
    } catch (error) {
      
    }
  };
};




