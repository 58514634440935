import React,{useState} from 'react'
import {Box,Typography,Dialog,DialogTitle,DialogContent,makeStyles,FormControlLabel,Checkbox} from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close';

const Desclaimer = () => {
    const useStyles = makeStyles({
        root:{
            "& .MuiDialogContent-root":{
                fontSize:"0.8rem",
                lineHeight:"25px",
                padding:"20px 34px"
            },
            "& .MuiDialog-paperWidthSm":{
                maxWidth:"1000px",
                
            }
        },
        title: {
            fontSize: "1.5rem",
            fontWeight: 500,
            textAlign: "center",
            fontFamily:"inherit"
          },
          dialogTitle: {
            display: "flex",
            justifyContent: "center",
            alignItems: " center",
            textAlign: "center",
            padding: "5px",
          },
          checkBox:{
            "& .MuiTypography-body1":{
                fontSize:"0.85rem"
            }
          },
          closeIcon: {
            padding: "5px",
            justifyContent: "flex-end",
  
          },
    })

    const classes=useStyles()

    const [show,setShow]=useState(localStorage.getItem('desclaimer') ? false :true)

    const handle_desclaimer=(e)=>{
        if(e.target.checked){
            setShow(false)
            localStorage.setItem('desclaimer',true)
        }
    }
    
  return (
    <div>
        <Dialog open={show} className={classes.root}>
        <Box display="flex" className={classes.closeIcon}>
          {" "}
          <CloseIcon onClick={()=>setShow(false)} style={{cursor:"pointer"}}/>
        </Box>
        <DialogTitle
          classes={{ root: classes.dialogTitle }}
        >
          <Typography className={classes.title}>DISCLAIMER </Typography>
        </DialogTitle>
        <DialogContent >
        Neosurge is deposit accepting Non-Banking Financial Institution and has a platform purely for the convenience of its Customers to display/communicate offers extended by Augmont to Neosurge's Customers. Neosurge is not selling/rendering any of these products/services. Neosurge does not act as express or implied agent of the Augmont/the owners of the products vis-a-vis the Customers.Neosurge is neither guaranteeing nor giving any warranty nor making any representation with respect to the offers made by the Augmont. The products/services are being offered solely by Augmont and Neosurge is not responsible for sale/quality/features of the products/services under the offer. Neosurge is neither endorsing Augmont or any product/service of the Augmont. Neosurge is not undertaking the business of on-line retail trading or shopping by hosting the offers on its platform. If the Customer proceeds from here, he/she will be redirected to the Augmont's Website. Any Information on the Augmont's Website (or the section thereof to which the Customer will be directed) whether about the Augmont or the products/services being offered have not been verified by Neosurge. Neosurge shall not be liable or responsible for any such information. Neosurge will not bear any obligation or liability if a Customer purchases/avails of a product/services under an offer. If the Customer chooses to avail of the purchase/avail of a product/services, Neosurge will neither be a party to nor in any manner concemed with such purchase/availment nor be liable or responsible for any act or omission of the Augmont or the Customer. All products/services related queries/complaints will be addressed by Augmont only.

       <Box className={classes.checkBox} mt={2}><FormControlLabel control={<Checkbox onChange={handle_desclaimer}  color="primary"/>} label="Don't show this agian" /></Box> 
        </DialogContent>
        </Dialog>
    </div>
  )
}

export default Desclaimer