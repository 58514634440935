import React,{useState,useEffect} from 'react'
import { Box,Typography,makeStyles,Tab,Tabs } from '@material-ui/core'
import { LineChart, Line, XAxis, YAxis,CartesianGrid,Tooltip,Legend,ResponsiveContainer } from "recharts";
import {GET_DAILY_USER,GET_MONTHLY_USER} from '../../../utils/endpoints'
import httpInstance from '../../../utils/instance'
import moment from 'moment';

const ActiveUsers = () => {
    const useStyles=makeStyles({
        root:{
            background:"#fff",
            borderRadius:"20px",
            "& .MuiTab-textColorPrimary.Mui-selected":{
                color:"#0C97F0",
                textTransform:"none"
            },
            " & .MuiTabs-flexContainer":{
                borderBottom:"1px solid #DDDDDD"
            }
        },
        header:{
            color:"#686868",
            fontSize:"1rem",
            fontWeight:600,
            padding:"0px 10px"
        },
        indicator:{
            backgroundColor: '#0C97F0'
          },
          tab:{
            textTransform:"none"
          },
          tab_style: {
            background:"white",
            color:"black",
            "& .MuiTab-root":{
              fontFamily:"inherit",
              fontSize:"0.85rem",
              color:"black",
              opacity:1,
              background:"none",
              minWidth:"auto",
              marginRight:"2rem",
              marginLeft:"1rem"
            },
            "& .MuiTab-textColorInheri":{
              opacity:0.9
            }
        },

        title:{
            color:"#0C97F0",
            fontWeight:500
        }
    })

    const classes=useStyles()

    const [value, setValue] = useState('1');
    const [data,setData]=useState([])
    
    const get_daily_user=async()=>{
      const httpConfig = {
        method: "GET",
        tokenType: "admin",
        headers:{
          "Content-Type": "application/json",
        },
        params:{from:moment().format('YYYY-MM-01 HH:MM:SS'), to :moment().format('YYYY-MM-DD HH:MM:SS')}
      };
      try{
        const response=await httpInstance(`${GET_DAILY_USER}`,httpConfig)
        setData(response.data?.data)
      }
      catch{

      }
    }
//---------get monthly user-----------------------------------//
    const get_monthly_user=async()=>{
      const httpConfig = {
        method: "GET",
        tokenType: "admin",
        headers:{
          "Content-Type": "application/json",
        },
        params:{from:moment().format('YYYY-01-01 HH:MM:SS'), to :moment().format('YYYY-MM-DD HH:MM:SS')}
      };
      try{
        const response=await httpInstance(`${GET_MONTHLY_USER}`,httpConfig)
        setData(response.data?.data)
      }
      catch{

      }
    }

    useEffect(()=>{
      if(value=="1"){
        get_monthly_user()
      }else{
        get_daily_user()
      }
      
    },[value])

    

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const month_format=(month)=>{
    let name=month.toLowerCase()
    return name.charAt(0).toUpperCase()+name.slice(1,3)
  }


  return (
    <div>
        <Box className={classes.root} >
            <Box display="flex" p={3}>
            <img src="/images/admin/user.svg" height="20px" width="15px"/>
           <Typography className={classes.header}>Active Users</Typography>
           </Box>

           <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
            classes={{ indicator: classes.indicator,}}
            className={classes.tab_style}
            >
                    <Tab className={classes.tab} label="Monthly Active users" value="1" />
                    <Tab className={classes.tab}label="Daily Active Users" value="2"/>
            </Tabs>

            <Box pt={4} display="flex">
            {data?.list?.length>0 && 
            <ResponsiveContainer width={"70%"} height={250}>
            <LineChart width={630} height={250} data={data?.list} connectNulls={true}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="" />
                <XAxis 
                dataKey={value=="1" ? "month":"date" }
                tick={{fontSize: 12}} 
                tickFormatter = {(unixTime) => value=="2" ? moment(unixTime).format('DD MMM') : month_format(unixTime)}
                interval="preserveStartEnd"
                domain={["dataMin", "dataMax + 1"]}
                />
                <YAxis tick={{fontSize: 12}}  allowDecimals={false}/>
                <Tooltip />
               
               
                <Line data={data?.list}  
                type="monotone" 
                dataKey="count" 
                stroke="#0C97F0" 
                fill="#0C97F0" 
                connectNulls={false}
                />
            </LineChart>
            </ResponsiveContainer>}
            <Typography className={classes.title}>Daily active user : {data?.numberOfUsers}</Typography>
            </Box>
 
        </Box>
    </div>
  )
}

export default ActiveUsers