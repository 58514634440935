import React,{useState,useEffect} from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import {VIEW_CHANGES,APPROVE_REQUEST,REJECT_REQUEST} from '../../../utils/endpoints'
import httpInstance from '../../../utils/instance'
import InvestorChanges from "./InvestorChanges";
import DoneIcon from '@mui/icons-material/Done';
import QuestionaryChanges from "./QuestionaryChanges";
import FundChanges from "./FundChanges";


const ChangesView = ({show,handleClose,type,get_tabel_data}) => {
  const useStyles = makeStyles({
    root: {
      "& .MuiDialogContent-root": {
        fontSize: "0.8rem",
        lineHeight: "25px",
        padding: "20px 25px",
      },
      "& .MuiDialog-paperWidthSm": {
        minWidth: "60%",
        maxWidth:"100%",
        maxHeight: "90%",
      },
    },
    dialogTitle: {
      //display: "flex",
      justifyContent: "center",
      alignItems: " center",
      //textAlign: "center",
      padding: "0px 10px 0px 25px",
      marginTop: "-10px",
      "& .MuiTypography-body1": {
        fontSize: "1.2rem",
        fontWeight: 500,
      },
    },
    closeIcon: {
      padding: "5px",
      justifyContent: "flex-end",
    },
    text: {
      fontSize: "0.8rem",
    },
    background:{
        background:"#F4F4F4"
    },
    rejectButton:{
      background:"#D03200",
      color:"#fff",
      padding:"10px 2rem",
      border:"none",
      borderRadius:"20px",
      fontFamily:"inherit",
      display:"flex",
      alignItems:"center",
      fontSize:"0.85rem"

  },
  acceptButton:{
    background:"#4FD000",
    color:"#fff",
    padding:"10px 2rem",
    border:"none",
    borderRadius:"20px",
    fontFamily:"inherit",
    display:"flex",
    alignItems:"center",
    fontSize:"0.85rem"

},
  });

  const classes = useStyles();


    const handle_close=()=>{
        handleClose()
    }

    const [data,setData]=useState(null)

    const get_data=async()=>{
        const httpConfig = {
            method: "GET",
            tokenType: "admin",
            headers:{
              "Content-Type": "application/json",
            },
            params:{id:type.id}
          };

          try{
            const response=await httpInstance(`${VIEW_CHANGES}`,httpConfig)
            setData(response.data?.data)
          }
          catch{

          }
    }

    useEffect(()=>{
        get_data()
    },[type])

    //-------------------approve request-------------------------------------------//
    const approve_request=async()=>{
      const httpConfig = {
          method: "POST",
          tokenType: "admin",
          headers:{
            "Content-Type": "application/json",
          },
          params:{id:type.id}
        };
        try{
         const response=await httpInstance(`${APPROVE_REQUEST}`,httpConfig)
         get_tabel_data()
         handle_close()
         
          
        }
        catch{

        }
      }
      
  //------------------------Reject approve-------------------------------------//
  const reject_request=async()=>{
      const httpConfig = {
          method: "POST",
          tokenType: "admin",
          headers:{
            "Content-Type": "application/json",
          },
          params:{id:type.id}
        };
        try{
         await httpInstance(`${REJECT_REQUEST}`,httpConfig)
         get_tabel_data()
         handle_close()
          
        }
        catch{

        }
  }
 
  

  return (
    <div>
      <Dialog className={classes.root} open={show} onClose={handle_close}>
        <Box
          display="flex"
          className={classes.closeIcon}
          onClick={handle_close}
        >
          <CloseIcon style={{cursor:"pointer"}}/>
        </Box>

        <DialogContent>
            <Box p={5} className={classes.background}>
              {type.type==="Risk Thematic & Cap Percentage" &&  <InvestorChanges data={data}/> }
              {type.type==="Questionaire" &&  <QuestionaryChanges data={data} type={type}/> }
              {type.type==="Fundmanagement" &&  <FundChanges data={data} type={type}/> }
              {type.type==="Exit Trigger" &&  <FundChanges data={data} type={type}/> }
            </Box>

            <Box mt={2} px={3} display='flex' justifyContent="space-between">
              <button className={classes.rejectButton} onClick={reject_request} >
                Reject
                <CloseIcon style={{fontSize:"1.3rem"}}/>
              </button>
              <button className={classes.acceptButton}  onClick={approve_request}>
              Approve
                <DoneIcon style={{fontSize:"1.3rem"}}/>
              </button>
            </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ChangesView;
