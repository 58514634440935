import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  CircularProgress,
  IconButton,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import httpInstance from '../../../../utils/instance'
import {SIMULATE_GOLD_KYC} from '../../../../utils/endpoints'
import ErrorMessage from "../../../../components/Validation/ErrorMessage";

const RejectReason = ({show, handleClose,panData,get_kyc_data}) => {
    const useStyles = makeStyles({
        root: {
          "& .MuiDialogContent-root": {
            fontSize: "0.8rem",
            lineHeight: "25px",
            padding: "20px 25px",
          },
          "& .MuiDialog-paperWidthSm": {
            minWidth: "40%",
            maxWidth:"100%",
            maxHeight: "90%",
          },
        },
        dialogTitle: {
          //display: "flex",
          justifyContent: "center",
          alignItems: " center",
          //textAlign: "center",
          padding: "0px 10px 0px 25px",
          marginTop: "-10px",
          "& .MuiTypography-body1": {
            fontSize: "1.2rem",
            fontWeight: 500,
          },
        },
        closeIcon: {
          padding: "5px",
          justifyContent: "flex-end",
        },
        label:{
            fontSize:"0.8rem"
        },
        input:{
            width:"100%",
            height:"6rem",
            marginTop:"10px",
            border:"1px solid #dddddd",
            resize: "none",
            fontFamily:"inherit",
        },
        primaryButton:{
            background:"#326CF9",
            color:"#fff",
            padding:"10px 2rem",
            border:"none",
            borderRadius:"20px",
            fontFamily:"inherit",
            fontSize:"0.9rem",
            outline:"none"
        },
    });
    
    const classes = useStyles();

    const [reason,setReason]=useState("")
    const [error,setError]=useState("")

    const handle_close=()=>{
        setReason("")
        handleClose()
    }

    const handle_reason=(e)=>{
        setReason(e.target.value)
    }

    //-----------------------confirm reject------------------------//
    const confirm_reject=async()=>{
        if(!reason){
            setError(true)
        }else{
            setError(false)
        
        const httpConfig = {
            method: "POST",
            tokenType: "admin",
            headers:{
              "Content-Type": "application/json",
            },
            params:{userId:panData?.user?.userId,status:"rejected",reasonMessage:reason}
          };
          try{
            await httpInstance(`${SIMULATE_GOLD_KYC}`,httpConfig)
            handle_close()
            get_kyc_data()
          }
          catch{

          }
        }
    }
  return (
    <div>
         <Dialog className={classes.root} open={show} onClose={handle_close}>
        <Box
          display="flex"
          className={classes.closeIcon}
          onClick={handle_close}
        >
          <CloseIcon style={{cursor:"pointer"}}/>
        </Box>

        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Box display="flex" justifyContent="center">
            <Typography className={classes.title}>Please confirm reason for rejection</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
         <Typography className={classes.label}>Reason for Rejection</Typography>

            <textarea 
            className={classes.input}
            onChange={handle_reason}
            />
           {error && <ErrorMessage message={"Please enter the reason"}/>}

            <Box display="flex" justifyContent="center" mt={3}>
                <button className={classes.primaryButton} onClick={confirm_reject}>Confirm</button>
            </Box>
            
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default RejectReason