import React, { useState, useEffect } from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";
import InvestmentTable from "./InvestmentTable";
import { GET_GOLD_USER, GET_GOLD_SILVER_RATE } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { namespace } from "../../../environment";

const DetailInvestment = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      borderBottom: "1px solid #E6E3E3",
    },
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      textDecoration: "underline",
      fontFamily: "inherit",
    },

    boxBorder: {
      border: "2.31035px solid #DDDDDD",
      borderRadius: "5px",
    },

    borderRight: {
      borderRight: "2.31035px solid #DDDDDD",
    },
    header: {
      fontSize: "1.2rem",
      fontWeight: 500,
    },

    title: {
      fontSize: "0.8rem",
      fontWeight: "bold",
      fontFamily: "Poppins",
    },
    text: {
      fontSize: "0.69rem",
      fontFamily: "Poppins",
      fontWeight: 500,
    },
    textgrey: {
      fontSize: "0.69rem",
      fontFamily: "Poppins",
      fontWeight: 500,
      color: "#C0C0C0",
      marginTop: "1rem",
    },
    rupees: {
      fontFamily: "Open Sans",
    },
  }));

  const classes = useStyles();

  const [investment, setInvestment] = useState();
  const [currentValues, setCurrentValues] = useState([]);

  useEffect(async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const httpConfig1 = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_GOLD_USER}`, httpConfig);
      setInvestment(response?.data?.data);
      const rate = await httpInstance(`${GET_GOLD_SILVER_RATE}`, httpConfig1);
      setCurrentValues(rate.data?.data);
    } catch {}
  }, []);

  return (
    <div>
      {/* subtab */}
      <Box
        display="flex"
        alignItems="start"
        px={{ xs: 1, md: 17 }}
        pt={2}
        className={classes.root}
      >
        <Link
          to={`${namespace}/myportfolio?tab=digitalgold`}
          style={{ color: "black" }}
        >
          <Typography className={classes.textUnderline}>
            My Portfolio
          </Typography>
        </Link>
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography
            className={classes.subTabtext}
            display="flex"
            alignItems="start"
          >
            Detail Investment
          </Typography>
        </Box>
      </Box>
      {/* detail investment */}
      <Box
        display="flex"
        justifyContent="flex-start"
        px={15}
        mt={2}
        className={classes.backButton}
      >
        <Link to={`${namespace}/myportfolio?tab=digitalgold`}>
          <img src="/images/backIcon.svg" height="20px" width="20px" />
        </Link>
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography className={classes.header}>Investment Details</Typography>
      </Box>
      <Box px={{ xs: 5, md: 30, lg: 50 }}>
        <Box mt={1} className={classes.boxBorder} py={2}>
          <Grid container justifyContent="center">
            <Grid item xs={6} justifyContent="center">
              <Grid
                container
                justifyContent="center"
                direction="column"
                alignItems="center"
                style={{ borderRight: "2.31035px solid #DDDDDD" }}
              >
                <img src="/images/image 2.svg" height="80px" width="80px" />

                <Typography className={classes.title}>Gold Grams</Typography>
                <Grid container justifyContent="center" direction="row">
                  <Grid item sm={6}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography className={classes.textgrey}>
                        Total Investment
                      </Typography>
                      <Typography className={classes.text}>
                        {investment?.goldBalance.toFixed(4)} grams
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={6}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography className={classes.textgrey}>
                        Available to sell
                      </Typography>
                      <Typography className={classes.text}>
                        {investment?.transactionalGoldBalance.toFixed(4)} grams
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" direction="row">
                  <Grid item sm={6}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography className={classes.textgrey}>
                        Current Value
                      </Typography>
                      <Typography className={classes.text}>
                        <span className={classes.rupees}>₹</span>
                        {(
                          currentValues?.goldSellRate * investment?.goldBalance
                        ).toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item sm={6}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography className={classes.textgrey}>
                        Cost of Investment
                      </Typography>
                      <Typography className={classes.text}>
                        <span className={classes.rupees}>₹</span>
                        {investment?.totalGoldInvestment?.toLocaleString(
                          "en-IN",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} justifyContent="center">
              <Grid
                container
                justifyContent="center"
                direction="column"
                alignItems="center"
              >
                <img src="/images/image 3.svg" height="80px" width="80px" />

                <Typography className={classes.title}>Silver Grams</Typography>
                <Grid container justifyContent="center" direction="row">
                  <Grid item sm={6}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography className={classes.textgrey}>
                        Total Investment
                      </Typography>
                      <Typography className={classes.text}>
                        {investment?.silverBalance.toFixed(4)} grams
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={6}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography className={classes.textgrey}>
                        Available to sell
                      </Typography>
                      <Typography className={classes.text}>
                        {investment?.transactionalSilverBalance.toFixed(4)}{" "}
                        grams
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" direction="row">
                  <Grid item sm={6}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography className={classes.textgrey}>
                        Current Value
                      </Typography>
                      <Typography className={classes.text}>
                        <span className={classes.rupees}>₹</span>
                        {(
                          currentValues?.silverSellRate *
                          investment?.silverBalance
                        ).toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item sm={6}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography className={classes.textgrey}>
                        Cost of Investment
                      </Typography>
                      <Typography className={classes.text}>
                        <span className={classes.rupees}>₹</span>
                        {investment?.totalSilverInvestment?.toLocaleString(
                          "en-IN",
                          { minimumFractionDigits: 2 }
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default DetailInvestment;
