import React from 'react'
import DeliverySuccess from '../../containers/DigitalGold/Delivery/DeliverySuccess'
import Header from '../../components/header/Header'
import Footer from '../../components/Footer'

const Deliverysuccess = () => {
  return (
    <div>
        {/* <Header/> */}
        <DeliverySuccess/>
        {/* <Footer/> */}
    </div>
  )
}

export default Deliverysuccess