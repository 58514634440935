import React, { useState, useEffect } from "react";
import { useStyles } from "../styles";
import { Box } from "@material-ui/core";
import TitleLayoult from "../TitleLayoult";
import { useForm } from "react-hook-form";
import httpInstance from "../../../utils/instance";
import {
  VERIFY_EMAIL_OTP,
  EMAIL_LOGIN,
  GET_USER,
} from "../../../utils/endpoints";
import ErrorMessage from "../../Validation/ErrorMessage";
import { useNavigate, createSearchParams } from "react-router-dom";
import OtpInput from "react-otp-input";
import { namespace } from "../../../environment";

function EmailOtp({ step, descriptionData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setFocus,
  } = useForm();
  const classes = useStyles();

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [otp, setOpt] = useState("");
  const [resendmsg, setResendMsg] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [creationTime, setCreationTime] = useState("");
  const emailDomain = email ? email.substring(email.lastIndexOf("@") + 1) : "";
  const name = email ? email.substring(0, 2) : "";

  const handleChange = (code) => setOpt(code);
  useEffect(() => {
    setEmail(localStorage.getItem("email"));
    setSessionId(localStorage.getItem("sessionId"));
    setCreationTime(localStorage.getItem("creationTime"));
  }, [email]);
  const handleResentOtp = async () => {
    const httpConfig = {
      method: "POST",
      data: { emailId: email },
    };

    try {
      const response = await httpInstance(`${EMAIL_LOGIN}`, httpConfig);
      setResendMsg(response.data?.message);
    } catch (error) {}
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      emailAddress: email,
      userOTP: otp,
      sessionId: sessionId,
      creationTime: creationTime,
    };

    const httpConfig = {
      method: "POST",
      data: formData,
    };

    try {
      const response = await httpInstance(`${VERIFY_EMAIL_OTP}`, httpConfig);
      localStorage.setItem("user_token", response.data?.data?.accessToken);
      localStorage.setItem("refresh_token", response.data?.data?.refreshToken);
      localStorage.setItem("user_id", response.data?.data?.user?.id);

      const httpConfig1 = {
        method: "GET",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
      };

      const status = await httpInstance(`${GET_USER}`, httpConfig1);
      if (status.data?.data?.mpin) {
        navigate({
          pathname: `${namespace}/signIn`,
          search: `?${createSearchParams({ stepcount: "mpin" })}`,
        });
      } else {
        if (status.data?.data?.isMobileNumberVerified) {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "mpin_generate" })}`,
          });
        } else {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "2" })}`,
          });
        }
      }
    } catch (error) {
      if (
        error.response.data?.message === "Invalid OTP" ||
        error.response.data?.message === "OTP Expired"
      ) {
        setOtpError(true);
      }
    }
  };

  return (
    <div>
      <Box display={"flex"} justifyContent="center">
        <Box
          display={"flex"}
          alignItems="flex-start"
          flexDirection={"column"}
          p={3}
        >
          <Box>
            <TitleLayoult
              title="Verify your Email"
              description={`Please Enter the OTP sent to ${name}********${emailDomain} to verify your Email`}
            />
          </Box>
          <form onSubmit={handleOtpSubmit}>
            <Box>
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                separator={<span style={{ width: "8px" }}></span>}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{
                  border: "none",
                  borderRadius: "8px",
                  width: "40px",
                  height: "40px",
                  fontSize: "13px",
                  color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                  background: "#E6E6E6",
                }}
                focusStyle={{
                  border: "1px solid #CFD3DB",
                  outline: "none",
                }}
              />
            </Box>
            <p className={classes.resendOtpText} onClick={handleResentOtp}>
              Resend OTP
            </p>
            <p className={classes.verifiedKycText}>{resendmsg}</p>
            {otpError && (
              <ErrorMessage message="OTP you have entered is invalid or expired " />
            )}
            <Box mt={5} px={3} display="flex" justifyContent="center">
              <button className={classes.primaryButton} type="submit">
                Verify
              </button>
            </Box>
          </form>
        </Box>
      </Box>
      {/* <form onSubmit={handleSubmit(handleOtpSubmit)}>
        <Box mt={5} display={"flex"} alignItems="flex-start" flexDirection={"row"} className={classes.OtpBox}>
            <Grid item xs={1} md={1}>  
                <input
                name="otp1" 
                label="" 
                placeholder=""
                className={classes.otpinputBox}
                onKeyUp={(e)=>handleKeyDown(e)}
                {...register("otp1",{required:true,maxLength:1,onChange:(e)=>handleFocus(e)})}
                />  
                 
            </Grid> 

            <Grid item  xs={1} md={1}>  
                <input
                name="otp2" 
                label="" 
                placeholder="" 
                className={classes.otpinputBox}
                {...register("otp2",{required:true,maxLength:1,onChange:(e)=>handleFocus(e)})}
                />   
                
            </Grid> 

                <Grid  xs={1} md={1}>  
                <input
                name="otp3" 
                label="" 
                placeholder="" 
                className={classes.otpinputBox}
                {...register("otp3",{required:true,maxLength:1,onChange:(e)=>handleFocus(e)})}
                />   
            </Grid> 
            <Grid item xs={1} md={1}>  
                <input
                name="otp4" 
                label="" 
                register={register}
                placeholder=""
                className={classes.otpinputBox}
                {...register("otp4",{required:true,maxLength:1,onChange:(e)=>handleFocus(e)})}
                />  
                 
            </Grid> 
            <Grid item xs={1} md={1}>  
                <input
                name="otp5" 
                label="" 
                register={register}
                placeholder=""
                className={classes.otpinputBox}
                {...register("otp5",{required:true,maxLength:1,onChange:(e)=>handleFocus(e)})}
                />  
                 
            </Grid> 
            <Grid item xs={1} md={1}>  
                <input
                name="otp6" 
                label="" 
                placeholder=""
                className={classes.otpinputBox}
                {...register("otp6",{required:true,maxLength:1,onChange:(e)=>handleFocus(e)})}
                />  
                 
            </Grid> 
            
        </Box>
        <Box display="flex">
        {((errors.otp1 || errors.otp2 || errors.otp3 || errors.otp4 || errors.otp5 ||errors.otp6 ) || otpError) && 
            <ErrorMessage message="OTP you have entered is invalid "/>
        }
       
        </Box>
        <Box mt={5} sx={{width:"50%"}}>
          <button className={classes.primaryButton} type="submit">
            Verify
          </button>
        </Box>
        </form> */}
    </div>
  );
}

export default EmailOtp;
