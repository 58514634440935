import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import StarIcon from "@mui/icons-material/Star";
import { SEARCH_FUNDS } from "../../utils/endpoints";
import httpInstance from "../../utils/instance";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { namespace } from "../../environment";

const AlternativeFunds = ({ asset_catogory }) => {
  const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
      fontWeight: 600,
      padding: "0px 7rem",
      fontSize: "1.2rem",
    },
    "@media (min-width: 992px)": {
      tablefirstCell: {
        paddingLeft: "7rem",
        width: "45%",
      },
    },
    startIcon: {
      fontSize: "12px",
    },
    cell: {
      //width:"10%"
    },
  }));
  const classes = useStyles();

  const [alter_fund, setAlternative_fund] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const get_alternative_fund = async () => {
    const formData = {
      from: 0,
      size: 20,
      plan_name: "Broker",
      option_name: "growth",
      holding_period: "",
      asset_sub_category: asset_catogory,
      ratings: [],
    };
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };
    console.log(formData);
    if (asset_catogory != undefined) {
      try {
        const response = await httpInstance(`${SEARCH_FUNDS}`, httpConfig);
        setAlternative_fund(response.data?.data);
        setLoading(false);
      } catch {}
    }
  };

  useEffect(() => {
    get_alternative_fund();
  }, [asset_catogory]);

  const handle_route = (code) => {
    // navigate(`${namespace}/mutualfund/scheme-code/${code}`);
    window.open(`${namespace}/mutualfund/scheme-code/${code}`, "_blank");
    // window.location.reload();
  };

  return (
    <div>
      <Box mt={10}>
        <Typography className={classes.title}>Alternative Funds</Typography>
        {loading ? (
          <Box display="flex" justifyContent="center" mt={5}>
            <ReactLoading
              type="spinningBubbles"
              color="#0C97F0"
              height={"3%"}
              width={"3%"}
            />
          </Box>
        ) : (
          <div style={{ width: "auto", overflowX: "auto" }}>
            <Table
              style={{ marginTop: "2rem", borderTop: " 1px solid #E6E6E6" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ paddingLeft: "7rem" }}>
                    Fund Name
                  </TableCell>
                  <TableCell>Rating</TableCell>
                  <TableCell>Return (3Y)</TableCell>
                  <TableCell>Return (5Y)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {alter_fund?.map((ele) => {
                  return (
                    <TableRow
                      key={ele.scheme_code}
                      onClick={() => handle_route(ele.scheme_code)}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell className={classes.tablefirstCell}>
                        {ele.scheme_name}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {ele.vr_rating == "Not Rated" || ele?.vr_rating == ""
                          ? "Not Rated"
                          : ele.vr_rating?.length > 5
                          ? 0
                          : ele.vr_rating?.length}
                        {ele.vr_rating == "Not Rated" ||
                        ele?.vr_rating == "" ? (
                          ""
                        ) : (
                          <StarIcon style={{ fontSize: "13px" }} />
                        )}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {ele.performance?.oneY}%{" "}
                      </TableCell>
                      <TableCell
                        className={classes.cell}
                        style={{ width: "30%" }}
                      >
                        {ele.performance?.threeY}%{" "}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}
      </Box>
    </div>
  );
};

export default AlternativeFunds;
