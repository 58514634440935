import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import httpInstance from "../../../utils/instance";
import {
  GET_ASSISTED_PINS,
  DOWNLOAD_CSV,
  GET_ALL_ADMINS,
  DELETE_PIN,
} from "../../../utils/endpoints";
import Pagination from "../../../components/Admin/Pagination";
import moment from "moment";
import { CSVLink } from "react-csv";
import AddPins from "./AddPins";
import Confirmation from "../../../components/MutualFund/InfoMsg/Confirmation";

const Index = () => {
  const useStyles = makeStyles({
    root: {
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: "#0C97F0",
      },
      "& .MuiTypography-body1": {
        fontSize: "0.85rem",
      },
    },

    input: {
      border: "none",
      width: "100%",
      padding: "15px",
      fontFamily: "inherit",
    },

    primaryButton: {
      background: "#326CF9",
      color: "#fff",
      padding: "10px 2rem",
      border: "none",
      borderRadius: "20px",
      fontFamily: "inherit",
    },
    table: {
      margin: "1rem 0px",
      "& .MuiTableCell-root": {
        fontSize: "0.8rem",
      },
      "& .MuiTableHead-root": {
        background: "#F9FBFC",
        borderTopLeftRadius: " 4px",
        "& .MuiTableCell-root": {
          color: "#C0C5D0",
        },
      },
      "& .MuiTableBody-root": {
        background: "#fff",
      },
    },
  });
  const classes = useStyles();

  const [isAssisted, setIsAssisted] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [totalNumber, setTotalNumber] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [downloadData, setDownloadData] = useState("");
  const [search, setSearch] = useState("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [showAddPins, setShowAddPins] = useState(false);
  const [allAdmins, setAllAdmins] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [showDialog, setShowDailog] = useState(false);

  const get_all_admins = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      params: { isSuperAdminOnly: isSuperAdmin },
    };

    try {
      const response = await httpInstance(`${GET_ALL_ADMINS}`, httpConfig);
      setAllAdmins(response.data?.data);
    } catch {}
  };

  const get_data = async () => {
    const httpConfig = {
      method: "GET",
      //tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { isAssisted: isAssisted, pageNumber: currentPage, name: search },
    };

    const httpConfig1 = {
      method: "GET",
      //   tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_ASSISTED_PINS}`, httpConfig);
      setCustomers(response.data?.data);
      setTotalNumber(response.data.data?.count);
      setLoading(false);
      //   const download = await httpInstance(`${DOWNLOAD_CSV}`, httpConfig1);
      //   setDownloadData(download?.data);
    } catch {}
  };

  useEffect(() => {
    get_data();
  }, [currentPage, search, isAssisted]);

  const onPaginate = (pageNumber) => {
    if (pageNumber != currentPage) setCurrentPage(pageNumber);
  };

  const handle_search = (e) => {
    setSearch(e.target.value);
  };

  const handle_checkbox = (e) => {
    setIsAssisted(e.target.checked);
  };
  const formatDate = (date) => {
    const dateString = date;
    const dateObject = new Date(dateString);

    // Get date components
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1; // Months are zero-based, so we add 1 to get the correct month (1-12).
    const day = dateObject.getDate();

    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    return formattedDate;
  };

  const delete_pin = (id) => {
    setDeleteId(id);
    setShowDailog(true);
  };

  const handle_delete = async () => {
    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      params: { id: deleteId },
    };

    try {
      await httpInstance(`${DELETE_PIN}`, httpConfig);

      setShowDailog(false);
    } catch {}
  };
  return (
    <div>
      <Box p={3} pr={7} className={classes.root}>
        {/* <Box>
          <input
            value={search}
            className={classes.input}
            placeholder="Enter the name of customer"
            onChange={handle_search}
          />
          <FormControlLabel
            control={
              <Checkbox
                //checked={cancellation}
                onChange={handle_checkbox}
                color="primary"
              />
            }
            label="Assisted Services"
          />
        </Box> */}
        <Box display="flex" justifyContent="end">
          {/* <CSVLink data={downloadData} filename={"customer-database.csv"}>
            <button className={classes.primaryButton}>
              <img
                src="/images/admin/download.svg"
                height="15px"
                width="15px"
                style={{ marginRight: "10px" }}
              />
              Download in CSV
            </button>
          </CSVLink> */}
          <button
            className={classes.primaryButton}
            onClick={() => setShowAddPins(true)}
          >
            Add Pins
          </button>
        </Box>

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Pin</TableCell>
              <TableCell align="left">isDeactivated</TableCell>
              <TableCell align="left">ExpiresOn</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((ele) => {
              return (
                <TableRow key={ele.id}>
                  <TableCell>{ele.pin}</TableCell>
                  <TableCell>{`${ele.isDeactivated}`}</TableCell>
                  <TableCell>{formatDate(ele.expiresOn)} </TableCell>
                  <TableCell>
                    <img
                      src="/images/admin/delete.svg"
                      style={{ cursor: "pointer" }}
                      onClick={() => delete_pin(ele.id)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {!loading && (
          <Pagination
            totalNumber={totalNumber}
            itemsPerPage={10}
            onPaginate={onPaginate}
          />
        )}
      </Box>

      <AddPins
        show={showAddPins}
        handleClose={() => setShowAddPins(false)}
        get_all_admins={get_all_admins}
      />

      <Confirmation
        show={showDialog}
        handleClose={() => setShowDailog(false)}
        handle_yes={handle_delete}
        title="Delete Confirmation"
        text="Are you sure you want to delete this pin?"
      />
    </div>
  );
};

export default Index;
