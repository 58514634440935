import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { GET_TRANS_LIST_BYID } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import moment from "moment";
import ReactLoading from "react-loading";
import { namespace } from "../../../environment";

const OrderStatus = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      //minWidth:"30rem",
      border: "1px solid #C4C4C4",
      borderRadius: "6px",
    },

    verticleLine: {
      borderLeft: "0.5px dashed #C4C4C4",
      height: "50px",
      marginTop: "-5px",
      marginLeft: "7px",
    },

    boxBorder: {
      borderBottom: "1px solid #C4C4C4",
    },

    header: {
      fontSize: "1.2rem",
      fontWeight: 500,
      textAlign: "center",
    },
    title: {
      fontSize: "1.4rem",
    },
    text1: {
      fontSize: "0.9rem",
      fontWeight: 600,
    },

    textgrey: {
      fontSize: "0.75rem",
      color: "#C0C0C0",
      marginBottom: "10px",
    },

    text2: {
      fontSize: "0.75rem",
      fontWeight: 600,
      marginBottom: "10px",
    },

    orderText: {
      fontSize: "0.72rem",
      color: "#676767",
    },
    orderTextBold: {
      fontSize: "0.72rem",
      color: "#676767",
      fontWeight: 600,
    },
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      textDecoration: "underline",
      fontFamily: "inherit",
    },
    downloadText: {
      fontSize: "0.85rem",
      color: "#0C97F0",
    },
    rupees: {
      fontFamily: "Open Sans",
    },
    "@media(min-width:601px)": {
      root: {
        minWidth: "30rem",
      },
    },
  }));

  const classes = useStyles();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const type = searchParams.get("order_type");
  const id = searchParams.get("order_id");
  const status = searchParams.get("status");

  const [orderDetail, setOrderDetail] = useState();
  const [loading, setLoading] = useState(true);

  const get_trans_data = async () => {
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { type: type?.toLocaleLowerCase(), id: id },
    };

    try {
      const response = await httpInstance(`${GET_TRANS_LIST_BYID}`, httpConfig);
      setOrderDetail(response.data?.data);
      setLoading(false);
    } catch {}
  };

  useEffect(() => {
    get_trans_data();
  }, []);

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <Box
        display="flex"
        alignItems="start"
        px={{ xs: 2, md: 17 }}
        pt={2}
        style={{ borderBottom: "1px solid #E6E3E3" }}
      >
        <Link
          to={`${namespace}/myportfolio?tab=digitalgold`}
          style={{ color: "black" }}
        >
          <Typography className={classes.textUnderline}>
            My Portfolio
          </Typography>
        </Link>
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography
            className={classes.subTabtext}
            display="flex"
            alignItems="start"
          >
            {type == "buy" ? "Purchase" : type[0].toUpperCase() + type.slice(1)}{" "}
            Order Status
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={{ xs: 2, md: 15 }}
        mt={2}
        className={classes.backButton}
      >
        {/* <Link
          to={
            status == "progress"
              ? `${namespace}/myportfolio?tab=digitalgold`
              : orderDetail?.status == "PAYMENT_FAILED" ||
                orderDetail?.status == "SUCCESS" ||
                orderDetail?.status == "CREATED"
              ? `${namespace}/my-profile/my-orders`
              : `${namespace}/myportfolio/all-transactions`
          }
        > */}
        <img
          src="/images/backIcon.svg"
          height="20px"
          width="20px"
          onClick={navigateBack}
          style={{ cursor: "pointer" }}
        />
        {/* </Link> */}
      </Box>

      {loading ? (
        <Box p={5} display="flex" justifyContent="center">
          {" "}
          <ReactLoading
            type="spinningBubbles"
            color="#808080"
            height={"5%"}
            width={"5%"}
          />{" "}
        </Box>
      ) : (
        <Box>
          <Box px={{ xs: 2, md: 30 }}>
            <Typography className={classes.header}>
              {type == "buy"
                ? "Purchase"
                : type[0].toUpperCase() + type.slice(1)}{" "}
              Order Status
            </Typography>
            <Box className={classes.root} mt={2} p={2}>
              <Box
                className={classes.boxBorder}
                pb={4}
                px={{ xs: 2, md: 10 }}
                display="flex"
                justifyContent="space-between"
              >
                <Box>
                  <Typography className={classes.title}>
                    <span className={classes.rupees}>₹</span>
                    {orderDetail?.totalAmount?.toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                  <Typography className={classes.text1}>
                    Digital Gold ( {orderDetail?.metalType} ) {type}
                  </Typography>
                </Box>

                {type == "buy" && (
                  <a
                    href={`http://65.0.145.198/ff/uploads/invoice/invoice_${orderDetail?.transactionId}.pdf`}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography className={classes.downloadText}>
                      Download Invoice
                    </Typography>
                  </a>
                )}
              </Box>
              <Box className={classes.boxBorder} py={2} px={{ xs: 2, md: 10 }}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography className={classes.textgrey}>
                      Purchase Date
                    </Typography>
                    <Typography className={classes.textgrey}>
                      Quantity(g)
                    </Typography>
                    <Typography className={classes.textgrey}>
                      Order ID
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className={classes.text2}>
                      {moment(orderDetail?.createdAt).format("DD MMM YYYY")}
                    </Typography>
                    <Typography className={classes.text2}>
                      {orderDetail?.quantity}g
                    </Typography>
                    <Typography style={{ fontSize: "0.75rem" }}>
                      {orderDetail?.transactionId}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box py={4} px={{ xs: 2, md: 10 }}>
                <Typography className={classes.text1}>Order Status</Typography>
                <Box p={3}>
                  <Grid container>
                    <Grid item xs={2} sm={1} md={1}>
                      <img
                        src="/images/Icontick.svg"
                        height="15px"
                        width="15px"
                      />
                      <div className={classes.verticleLine}></div>
                    </Grid>
                    <Grid item xs={10} md={6}>
                      <Typography className={classes.orderTextBold}>
                        Payment completed{" "}
                      </Typography>
                      <Typography className={classes.orderText}>
                        {moment(orderDetail?.createdAt).format(
                          "DD MMM YYYY hh:mm A"
                        )}{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={2} sm={1} md={1}>
                      <img
                        src={
                          status
                            ? status == "complete"
                              ? "/images/Icontick.svg"
                              : "/images/Icontimer.svg"
                            : "/images/Icontimer.svg"
                        }
                        height="15px"
                        width="15px"
                      />
                    </Grid>
                    <Grid item xs={10} md={6}>
                      <Typography className={classes.orderTextBold}>
                        {status == "complete" && "Order Completed"}
                        {status == "failed" && "Failed"}
                        {status == "progress" && "Pending"}
                      </Typography>
                      {status == "progress" &&
                        (type == "delivery" ? (
                          <Typography className={classes.orderText}>
                            Expected deilvery by{" "}
                            <span className={classes.orderTextBold}>
                              10 working days
                            </span>
                          </Typography>
                        ) : (
                          <Typography className={classes.orderText}>
                            Expected by{" "}
                            <span className={classes.orderTextBold}>
                              48 hours
                            </span>
                          </Typography>
                        ))}
                      {/* {status == "complete" && <Typography className={classes.orderText}>{moment(orderDetail?.updatedAt).format('DD MMM YYYY hh:mm A')} </Typography>
                      
                      } */}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default OrderStatus;
