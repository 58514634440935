import React, { useEffect, useState } from "react";
import { Box, makeStyles, Typography, Grid } from "@material-ui/core";
import { GET_ORDERS, GET_ASSISTED_ORDERS } from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import { Link } from "react-router-dom";
import localeString from "../../../../hooks/LocaleString";
import { namespace } from "../../../../environment";

const Index = ({ category }) => {
  const useStyles = makeStyles({
    root: {
      border: "1px solid #DDDDDD",
      borderRadius: "5px",
    },
    title: {
      fontSize: "1.1rem",
      fontWeight: 500,
    },
    textBlue: {
      fontSize: "0.85rem",
      color: "#0C97F0",
      fontWeight: 500,
      cursor: "pointer",
    },
    type: {
      fontSize: "0.7rem",
      borderRadius: "30px",
      //background:"#FFD7C4",
      //color:"#F27E45",
      width: "fit-content",
      padding: "3px 20px",
      margin: "0px 15px",
      height: "fit-content",
    },
    rupees: {
      fontFamily: "Open Sans",
    },
    "@media (max-width:600px)": {
      type: {
        margin: "10px 0px",
      },
    },
  });
  const classes = useStyles();

  const [data, setData] = useState([]);

  const orderTypeObj = {
    1: "Lumpsum",
    2: "SIP",
    3: "Redemption",
    4: "Switch",
    5: "STP",
    6: "SWP",
  };

  const orderTypeColor = {
    1: "#F2AF1A",
    2: "#F27E45",
    3: "#48D4A5",
    4: "#07996B",
    5: "#4579F5",
    6: "#CB5D38",
  };

  const get_order = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        userId: localStorage.getItem("user_id"),
        isAllOrders: false,
        status: "Pending",
        limit: 1000,
        from: 0,
        isDiyOrder: true,
      },
    };

    try {
      const response = await httpInstance(
        `${category == "diy" ? GET_ORDERS : GET_ASSISTED_ORDERS}`,
        httpConfig
      );
      setData(response.data?.data);
    } catch {}
  };

  useEffect(() => {
    get_order();
  }, [category]);

  return (
    <div>
      {data?.length > 0 && (
        <Box mt={5}>
          <Typography className={classes.title}>
            Orders in progress ({`${data?.length}`})
          </Typography>
        </Box>
      )}
      {data?.map((fund, i) => {
        return (
          <Box key={i} className={classes.root} mt={3} p={2}>
            <Grid container display="flex" alignItems="center">
              <Grid item xs={3} md={1}>
                <img src={fund?.imgUrl} height="40px" width="50px" />
              </Grid>
              <Grid item xs={9} md={8}>
                <Box display={{ sm: "flex" }} alignItems="center">
                  {(fund?.orderType == 1 ||
                    fund?.orderType == 2 ||
                    fund?.orderType == 3 ||
                    fund?.orderType == 6) && (
                    <Typography>{fund?.fundName}</Typography>
                  )}
                  {(fund?.orderType == 4 || fund?.orderType == 5) && (
                    <Typography>{fund?.outFundName}</Typography>
                  )}
                  <Box
                    className={classes.type}
                    style={{
                      color: orderTypeColor[fund?.orderType],
                      background: `${orderTypeColor[fund?.orderType]}80`,
                    }}
                  >
                    {orderTypeObj[fund?.orderType]}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={7} md={2}>
                {fund?.units ? (
                  <Typography>
                    {" "}
                    {fund?.units} {`unit${fund?.units > 1 ? "s " : ""}`}
                  </Typography>
                ) : (
                  <Typography>
                    {" "}
                    <span className={classes.rupees}>₹</span>{" "}
                    {fund?.amount && localeString(fund?.amount)}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={5} md={1}>
                <Box display="flex" justifyContent="end">
                  <Link
                    to={`${namespace}/mutualfund/mutual-fund/order-status/${fund.orderId}`}
                    state={{ data: fund }}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography className={classes.textBlue}>
                      Order Status
                    </Typography>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </div>
  );
};

export default Index;
