import { Box } from "@material-ui/core";
import React from "react";
import { useStyles } from "../styles";
import { useForm } from "react-hook-form";



function TextInputField({
  label = "default label",
  placeholder ,
  value = "",
  register, 
  name,
  inputPattern,
  handleChange,
  disable,
  maxlength
}) {
  const classes = useStyles();

  //const { register, handleSubmit, formState: { errors } } = useForm();
  return (
    <React.Fragment>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="column"
        width={"100%"}
      >
        <label className={classes.label}>{label}</label>
        <input
          type="text"
          placeholder={placeholder}
          className={classes.inputBox}
          readOnly={disable}
          maxlength={maxlength}
          {...register(name,{
           
            required:true,
            pattern:inputPattern && inputPattern,
            onChange: (e)=>{handleChange && handleChange(e)}
          })} 
        />
      </Box>
    </React.Fragment>
  );
}

export default TextInputField;
