import React from 'react'
import Portfolio from '../../containers/Portfolio/index'
import Header from '../../components/header/Header'
import Footer from '../../components/Footer'

const index = () => {
  return (
    <div>
         {/* <Header/> */}
        <Portfolio/>
        {/* <Footer/> */}
    </div>
  )
}

export default index