import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "../signup/styles";

function TitleLayoult({
  title,
  description,
  align = "flex-start",
  justify = "center"
}) {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent={justify}
      alignItems={align}
      flexDirection="column"
      py={4}
    >
      <Typography className={classes.title}>{title}</Typography>
      <Box mt={1} style={{ width: "80%" }} textAlign={""}>
        <Typography className={classes.headingDescription} style={{ width: "20rem" }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
}

export default TitleLayoult;
