import React from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useLocation } from "react-router-dom";
import { useStyles } from "../../../../components/signup/styles";
import moment from "moment";
import SouthIcon from "@mui/icons-material/South";
import { namespace } from "../../../../environment";

const DetailOrder = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      // minWidth: "30rem",
      border: "1px solid #C4C4C4",
      borderRadius: "6px",
    },
    verticleLine: {
      borderLeft: "0.5px dashed #C4C4C4",
      height: "50px",
      marginTop: "-5px",
      marginLeft: "7px",
    },

    boxBorder: {
      borderBottom: "1px solid #C4C4C4",
    },

    header: {
      fontSize: "1.2rem",
      fontWeight: 500,
      textAlign: "center",
    },
    title: {
      fontSize: "1.4rem",
    },
    text1: {
      fontSize: "0.9rem",
      fontWeight: 600,
    },

    textgrey: {
      fontSize: "0.75rem",
      color: "#C0C0C0",
      marginBottom: "10px",
    },

    text2: {
      fontSize: "0.75rem",
      fontWeight: 600,
      marginBottom: "10px",
    },

    orderText: {
      fontSize: "0.72rem",
      color: "#676767",
    },
    orderTextBold: {
      fontSize: "0.72rem",
      color: "#676767",
      fontWeight: 600,
    },
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      textDecoration: "underline",
      fontFamily: "inherit",
    },

    rupees: {
      fontFamily: "Open Sans",
    },

    unitBox: {
      background: "#E6E6E6",
      borderRadius: "5px",
      fontSize: "0.6rem",
      color: "#676767",
      marginLeft: "3.2rem",
    },
    "@media(min-width:601px)": {
      root: {
        minWidth: "30rem",
      },
    },
  }));

  const classes = useStyles();

  const { state } = useLocation();
  const { data, order } = state;

  const Stepper = ({ title, subtTitle, img, verticleLine }) => {
    return (
      <Grid container>
        <Grid item xs={2} sm={1} md={1}>
          <img src={img} height="15px" width="15px" />
          {verticleLine && <div className={classes.verticleLine}></div>}
        </Grid>
        <Grid item xs={10} md={6}>
          <Typography className={classes.orderTextBold}>{title}</Typography>
          <Typography className={classes.orderText}>{subtTitle}</Typography>
        </Grid>
      </Grid>
    );
  };

  const orderTypeObj = {
    1: "Lumpsum",
    2: "SIP",
    3: "Redemption",
    4: "Switch",
    5: "STP",
    6: "SWP",
  };

  return (
    <div>
      <Box
        display="flex"
        alignItems="start"
        px={{ xs: 1, md: 17 }}
        pt={2}
        style={{ borderBottom: "1px solid #E6E3E3" }}
      >
        <Link
          to="/myportfolio?tab=mutualfund&category=diy"
          style={{ color: "black" }}
        >
          <Typography className={classes.textUnderline}>
            My Portfolio
          </Typography>
        </Link>
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography
            className={classes.subTabtext}
            display="flex"
            alignItems="start"
          >
            {orderTypeObj[data?.orderType]} Order Status
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={{ xs: 2, md: 17 }}
        mt={2}
        className={classes.backButton}
      >
        <Link
          to={
            order
              ? `${namespace}/my-profile/my-orders`
              : `/myportfolio?tab=mutualfund`
          }
        >
          <img src="/images/backIcon.svg" height="20px" width="20px" />
        </Link>
      </Box>
      <Box>
        <Box px={{ xs: 2, md: 30 }}>
          <Typography className={classes.header}>
            {orderTypeObj[data?.orderType]} Order Status
          </Typography>
          <Box className={classes.root} mt={2} p={2}>
            <Box
              className={classes.boxBorder}
              pb={4}
              px={{ xs: 2, md: 10 }}
              display="flex"
              justifyContent="space-between"
            >
              <Box>
                {data?.units ? (
                  <Typography className={classes.title}>
                    {" "}
                    {data?.units} {`unit${data?.units > 1 ? "s " : ""}`}
                  </Typography>
                ) : (
                  <Typography className={classes.title}>
                    <span className={classes.rupees}>₹</span>
                    {data?.amount}{" "}
                  </Typography>
                )}

                <Typography className={classes.text1}>
                  {(data?.orderType == 1 ||
                    data?.orderType == 2 ||
                    data?.orderType == 3 ||
                    data?.orderType == 6) &&
                    data?.fundName}
                  {(data?.orderType == 4 || data?.orderType == 5) &&
                    data?.outFundName}
                </Typography>
                {(data?.orderType == 4 || data?.orderType == 5) && (
                  <>
                    <SouthIcon />
                    <Typography className={classes.text1}>
                      {data?.inFundName}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
            <Box className={classes.boxBorder} py={2} px={{ xs: 2, md: 10 }}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography className={classes.textgrey}>Nav Date</Typography>
                  <Typography className={classes.textgrey}>
                    Folio no.
                  </Typography>
                  <Typography className={classes.textgrey}>Order ID</Typography>
                  <Typography className={classes.textgrey}>
                    Transaction Date
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.text2}>
                    {moment(data?.createdAt)
                      .subtract(1, "days")
                      .format("DD MMM YYYY")}
                  </Typography>
                  <Typography className={classes.text2}>
                    {data?.folioNumber ? data?.folioNumber : `-`}
                  </Typography>
                  <Typography
                    style={{ fontSize: "0.75rem", marginBottom: "10px" }}
                  >
                    {data?.orderId ? data?.orderId : "-"}
                  </Typography>
                  <Typography style={{ fontSize: "0.75rem" }}>
                    {moment(data?.createdAt).format("DD MMM YYYY hh:mm A")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box py={4} px={{ xs: 2, md: 10 }}>
              <Typography className={classes.text1}>Order Status</Typography>
              <Box p={3}>
                {data?.orderType == 1 || data?.orderType == 2 ? (
                  data?.status == "Pending" ? (
                    <>
                      <Stepper
                        title={"Order Placed on Neosurge "}
                        subtTitle={moment(data?.createdAt).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                        img={"/images/Icontick.svg"}
                        verticleLine={true}
                      />
                      <Stepper
                        title={
                          data?.submittedAt
                            ? "Payment Pending"
                            : "Payment Processing"
                        }
                        subtTitle={
                          data?.submittedAt
                            ? moment(data?.submittedAt).format(
                                "DD MMM YYYY hh:mm A"
                              )
                            : ""
                        }
                        img={
                          "/images/Icontimer.svg"
                          // data?.submittedAt
                          //   ? "/images/Icontick.svg"
                          //   : "/images/Icontimer.svg"
                        }
                        verticleLine={data?.submittedAt ? true : false}
                      />

                      {data?.submittedAt && (
                        <>
                          <Stepper
                            title={"Unit(s) allocation in Progress"}
                            subtTitle={"Expect by 2-3 working days"}
                            img={"/images/Icontimer.svg"}
                            verticleLine={false}
                          />

                          <Box p={1} mt={3} className={classes.unitBox}>
                            <Typography
                              style={{ fontSize: "0.8rem" }}
                            >{`We are currently awaiting the status on your unit allocation from
                       ${
                         data?.fundName
                       }. Dont worry, the NAV applicable will be as on ${moment(
                              data?.createdAt
                            )
                              .subtract(1, "days")
                              .format("DD MMM YYYY")}`}</Typography>
                          </Box>
                        </>
                      )}
                    </>
                  ) : data?.status == "Successful" ? (
                    <>
                      <Stepper
                        title={"Order Placed on Neosurge "}
                        subtTitle={moment(data?.createdAt).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                        img={"/images/Icontick.svg"}
                        verticleLine={true}
                      />
                      <Stepper
                        title={"Payment Successful"}
                        subtTitle={moment(data?.submittedAt).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                        img={"/images/Icontick.svg"}
                        verticleLine={true}
                      />
                      <Stepper
                        title={"Unit(s) allocation Completed"}
                        subtTitle={moment(data?.succeededAt).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                        img={"/images/Icontick.svg"}
                        verticleLine={false}
                      />
                    </>
                  ) : data?.status == "Failed" ? (
                    <>
                      <Stepper
                        title={"Order Placed on Neosurge "}
                        subtTitle={moment(data?.createdAt).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                        img={"/images/Icontick.svg"}
                        verticleLine={true}
                      />
                      <Stepper
                        title={"Payment Failed"}
                        subtTitle={moment(data?.failedAt).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                        img={"/images/failed.svg"}
                        verticleLine={false}
                      />
                    </>
                  ) : (
                    " "
                  )
                ) : (
                  <>
                    <Stepper
                      title={
                        data?.orderType == 3
                          ? "Redeem Order Placed"
                          : data?.orderType == 4
                          ? "Switch Order Placed"
                          : data?.orderType == 5
                          ? "STP Order Placed"
                          : data?.orderType == 6 && "SWP Order Placed"
                      }
                      subtTitle={moment(data?.createdAt).format(
                        "DD MMM YYYY hh:mm A"
                      )}
                      img={"/images/Icontick.svg"}
                      verticleLine={true}
                    />

                    <Stepper
                      title={
                        data?.status == "Pending"
                          ? "Order Processing"
                          : data?.status == "Successful"
                          ? "Order Successful  "
                          : "Order Failed"
                      }
                      subtTitle={""}
                      img={
                        data?.status == "Pending"
                          ? "/images/Icontimer.svg"
                          : data?.status == "Successful"
                          ? "/images/Icontick.svg"
                          : "/images/Icontimer.svg"
                      }
                      verticleLine={false}
                    />
                  </>
                )}

                {/* {data?.status=="Successful" && 
                     ((data.orderType==1 || data.orderType==2 ) &&
                     <>
                     <Stepper
                     title={ "Payment Successful"}
                     subtTitle={moment(data?.submittedAt).format("DD MMM YYYY hh:mm A")}
                     img={ "/images/Icontick.svg"}
                     verticleLine={true}
                     />
                     <Stepper
                    title={"Unit(s) allocation Completed"}
                    subtTitle={moment(data?.succeededAt).format("DD MMM YYYY hh:mm A")}
                    img={"/images/Icontimer.svg"}
                    verticleLine={false}
                    />
                     </>
                     ) 
                     
                  } */}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default DetailOrder;
