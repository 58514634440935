import React,{useEffect,useState} from 'react'
import BuySellSuccess from '../../../components/DashBoard/DigitalGold/BuySellSuccess';

const BuySuccess = () => {

  
  return (
    <BuySellSuccess line1="You have successfully Purchased Digital Gold!"/>
  )
}

export default BuySuccess