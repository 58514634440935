import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Grid,
} from "@material-ui/core";
import httpInstance from "../../../../utils/instance";
import { GET_USER_KYC_DATA } from "../../../../utils/endpoints";
import moment from "moment";
import ViewPanDetails from "./ViewPanDetails";
import { useSearchParams } from "react-router-dom";

const Index = () => {
  const useStyles = makeStyles({
    root: {
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: "#0C97F0",
      },
      "& .MuiTypography-body1": {
        fontSize: "0.85rem",
      },
    },

    input: {
      border: "none",
      width: "100%",
      padding: "15px",
      fontFamily: "inherit",
    },

    primaryButton: {
      background: "#326CF9",
      color: "#fff",
      padding: "10px 1rem",
      border: "none",
      borderRadius: "20px",
      fontFamily: "inherit",
      fontSize: "0.7rem",
      outline: "none",
      cursor: "pointer",
    },
    approvedButton: {
      background: "#4FD000",
      color: "#fff",
      padding: "10px 1rem",
      border: "none",
      borderRadius: "20px",
      fontFamily: "inherit",
      fontSize: "0.7rem",
      outline: "none",
    },
    rejectButton: {
      background: "#D03200",
      color: "#fff",
      padding: "10px 1rem",
      border: "none",
      borderRadius: "20px",
      fontFamily: "inherit",
      fontSize: "0.7rem",
      outline: "none",
    },
    table: {
      margin: "1rem 0px",
      "& .MuiTableCell-root": {
        fontSize: "0.8rem",
      },
      "& .MuiTableHead-root": {
        background: "#F9FBFC",
        borderTopLeftRadius: " 4px",
        "& .MuiTableCell-root": {
          color: "#C0C5D0",
        },
      },
      "& .MuiTableBody-root": {
        background: "#fff",
      },
    },
  });
  const classes = useStyles();

  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [view, setView] = useState(false);
  const [panData, setPanData] = useState(null);
  

  const [searchParams, setSearchParams] = useSearchParams();

  const status = searchParams.get("status");

  //--------get kyc data-----------------------------------------------------//
  const get_kyc_data = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      params: { status: status },
    };
    try {
      const response = await httpInstance(`${GET_USER_KYC_DATA}`, httpConfig);
      setData(response.data?.data);
      setFilterData(response.data?.data);
    } catch {}
  };

  useEffect(() => {
    get_kyc_data();
  }, [status]);

  //------------------Handle search----------------------------------------------------//
  const handle_search = (e) => {
    setSearch(e.target.value.toLowerCase());
  };

  useEffect(() => {
    const arry = data?.filter((ele) => {
      return ele.panName?.toLowerCase().includes(search);
    });

    setFilterData(arry);
  }, [search]);
  //----------------------------------//
  const handle_view = (data) => {
    setPanData(data);
    setView(true);
  };
  return (
    <div>
      <Box p={3} pr={7} className={classes.root}>
        <Box>
          <input
            //value={search}
            className={classes.input}
            placeholder="Enter the name of customer"
            onChange={handle_search}
          />
        </Box>

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left">ID</TableCell>
              <TableCell align="left">Submited date</TableCell>
              <TableCell align="left">Pan Name</TableCell>
              <TableCell align="left">Pan Number</TableCell>
              <TableCell align="left">Reason for Rejection</TableCell>
              <TableCell align="left">Pan Image</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterData?.map((ele) => {
              return (
                <TableRow key={ele.id}>
                  <TableCell>{ele.id}</TableCell>
                  <TableCell>
                    {moment(ele.createdAt).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>{ele.panName}</TableCell>
                  <TableCell>{ele.panNumber} </TableCell>
                  <TableCell>{ele.reason}</TableCell>
                  <TableCell>
                    <img src={ele.url} height="40px" width="40px" />
                  </TableCell>
                  {ele.kycStatus == "SUBMITTED" && (
                    <TableCell align="center">
                      <button
                        className={classes.primaryButton}
                        onClick={() => handle_view(ele)}
                      >
                        {" "}
                        View Details
                      </button>
                    </TableCell>
                  )}
                  {ele.kycStatus != "SUBMITTED" && (
                    <TableCell align="center">
                      <button
                        className={
                          ele.kycStatus == "APPROVED"
                            ? classes.approvedButton
                            : classes.rejectButton
                        }
                      >
                        {" "}
                        {ele.kycStatus == "APPROVED" ? "Approved" : "Rejected"}
                      </button>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>

      <ViewPanDetails
        show={view}
        handleClose={() => setView(false)}
        panData={panData}
        get_kyc_data={get_kyc_data}
      />
    </div>
  );
};

export default Index;
