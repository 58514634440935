import React from "react";
import {
  Box,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import SliderBar from "../../components/SliderBar";

function MonthlySIP({handleShowModal}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundImage: "url('./images/Component2.svg')",
      background: "#fff",
      padding: "0rem 0rem 8rem 0rem",
      backgroundPosition: "left",
      backdropFilter: "blur(10px)"
    },
    title1: {
      fontSize: "2rem",
      fontWeight: 500,
      color: "#000",
      marginBottom: "0.5rem",
      textAlign: "center"
    },
    heading: {
      fontSize: "2rem",
      fontWeight: "medium",
      color: "#2c2c2c",
      marginBottom: "1rem"
    },
    paragraph: {
      color: "#fff",
      fontWeight: "medium",
      fontSize: "1rem",
      lineHeight: "24px"
    },
    box1: {
      background: "#326CF9",
      borderRadius: "24px",
      display: "flex",
      flexDirection: "column",
      padding: "2rem"
    },
    box2: {
      background: "#48D4A5",
      borderRadius: "24px",
      display: "flex",
      flexDirection: "column",
      padding: "2rem"
    },
    box3: {
      background: "#F27E45",
      borderRadius: "24px",
      display: "flex",
      flexDirection: "column",
      padding: "2rem"
    },
    box4: {
      background: "#F2AF1A",
      borderRadius: "24px",
      display: "flex",
      flexDirection: "column",
      padding: "2rem"
    },
    mobile: {
      display: "none"
    },
    buttonHolder: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0px 0px 1rem 0px"
    },
    button: {
      border: "none",
      borderRadius: "40px",
      fontWeight: "bold",
      background: "#326CF9",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      padding: "1rem 3.2rem",
      cursor:"pointer"
    },
    "@media (max-width: 900px)": {
      main: {
        padding: "0 1rem",
        display: "none"
      },
      mobile: {
        display: "flex"
      }
    }
  }));
  const classes = useStyles();

  const [step, setStep] = React.useState(1);
  const [time, setTime] = React.useState(Date.now());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setStep((prevCount) => prevCount + 1);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (step === 5) {
    setStep(1);
  }

  return (
    <Box mb={3}>
      <Box py={8}>
        <Box className={classes.title1}>Monthly SIP</Box>
      </Box>
      <Box px={32} className={classes.main}>
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="center"
          alignItems="center"
          flexwrap="wrap"
        >
          <Grid item xs={12} lg={6}>
            <Box className={classes.box1}>
              <Box className={classes.paragraph}>
                Invest in Pure Gold through <br /> Monthly SIP
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <img
                  src={"/images/undraw_online_calendar.svg"}
                  height={128}
                  width={194}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box className={classes.box2}>
              <Box className={classes.paragraph}>
                Start as little as <br />
                1000 rupees / month
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <img
                  src={"/images/undraw_marketing.svg"}
                  height={128}
                  width={194}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box className={classes.box3}>
              <Box className={classes.paragraph}>
                Start as little as <br />
                1000 rupees / month
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <img
                  src={"/images/undraw_pay_online.svg"}
                  height={128}
                  width={194}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box className={classes.box4}>
              <Box className={classes.paragraph}>
                The smartest, safest and the easiest <br />
                way to accumulate 24k pure gold
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <img
                  src={"/images/undraw_revenue.svg"}
                  height={128}
                  width={194}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
     
      <Box
        px={2}
        className={classes.mobile}
        alignItems={"center"}
        justifyContent="center"
      >
        {step === 1 ? (
          <Box className={classes.box1} width="100%">
            <Box className={classes.paragraph}>
              Invest in Pure Gold through <br /> Monthly SIP
            </Box>
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
              <img
                src={"/images/undraw_online_calendar.svg"}
                height={128}
                width={194}
              />
            </Box>
          </Box>
        ) : (
          ""
        )}

        {step === 2 ? (
          <Box className={classes.box2} width="100%">
            <Box className={classes.paragraph}>
              Start as little as <br />
              1000 rupees / month
            </Box>
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
              <img
                src={"/images/undraw_marketing.svg"}
                height={128}
                width={194}
              />
            </Box>
          </Box>
        ) : (
          ""
        )}

        {step === 3 ? (
          <Box className={classes.box3} width="100%">
            <Box className={classes.paragraph}>
              Start as little as <br />
              1000 rupees / month
            </Box>
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
              <img
                src={"/images/undraw_pay_online.svg"}
                height={128}
                width={194}
              />
            </Box>
          </Box>
        ) : (
          ""
        )}
        {step === 4 ? (
          <Box className={classes.box4} width="100%">
            <Box className={classes.paragraph}>
              The smartest, safest and the easiest <br />
              way to accumulate 24k pure gold
            </Box>
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
              <img
                src={"/images/undraw_revenue.svg"}
                height={128}
                width={194}
              />
            </Box>
          </Box>
        ) : (
          ""
        )}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" mt={8}>
        <button className={classes.button} onClick={handleShowModal}>Get Started</button>
      </Box>
      <Box mt={6}>
        <SliderBar step={step} bar={4} />
      </Box>
    </Box>
  );
}

export default MonthlySIP;
