import React from 'react'
import SwitchSuccess from '../../../containers/Portfolio/MutualFund/SwitchFund/SwitchSuccess'

const Switch_success = () => {
  return (
    <div>
        <SwitchSuccess/>
    </div>
  )
}

export default Switch_success