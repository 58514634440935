import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Paper,
  InputBase,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SwitchCard from "./SwitchCard";
import {
  SWITCH_ALTERNATIVE_FUND,
  ASSISTED_SWITCH_FUNDS,
} from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import ReactLoading from "react-loading";
import SearchIcon from "@mui/icons-material/Search";
import { namespace } from "../../../../environment";

const Index = () => {
  const useStyles = makeStyles({
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      textDecoration: "underline",
      fontFamily: "inherit",
    },

    title: {
      fontSize: "1.1rem",
      fontWeight: 600,
    },
    textgrey: {
      fontSize: "0.8rem",
      color: "#626262",
    },
    searchBar: {
      padding: "5px 8px",
      display: "flex",
      alignItems: "center",
      margin: "20px 0px 30px 0px",
      borderRadius: "10px",
      "& .MuiSvgIcon-root": {
        opacity: 0.4,
      },
    },
    input: {
      marginLeft: "10px",
      flex: 1,
      width: 100,
    },
  });
  const classes = useStyles();

  const [alternativeFund, setAlternativeFund] = useState([]);
  const [filteredFund, setFilteredFund] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");

  const { state } = useLocation();
  const { schemeData, fundData, switchData, category } = state;

  const get_fund = async () => {
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        amc_code: fundData?.amc_code,
        scheme_code: fundData?.scheme_code,
        switch_amount: schemeData?.redeemableAmount,
      },
    };
    try {
      const response = await httpInstance(
        `${
          category == "diy" ? SWITCH_ALTERNATIVE_FUND : ASSISTED_SWITCH_FUNDS
        }`,
        httpConfig
      );
      setAlternativeFund(response.data?.data);
      setFilteredFund(response.data?.data);
      setLoading(false);
    } catch {}
  };

  useEffect(() => {
    get_fund();
  }, []);

  const handle_search = (e) => {
    let filteredArray = alternativeFund.filter((item) =>
      item.scheme_name.toLowerCase().includes(e.target.value)
    );
    setFilteredFund(filteredArray);
  };

  return (
    <div>
      <Box
        display="flex"
        alignItems="start"
        px={{ xs: 1, md: 17 }}
        pt={2}
        style={{ borderBottom: "1px solid #E6E3E3" }}
      >
        <Link
          to={`${namespace}/myportfolio?tab=mutualfund`}
          style={{ color: "black" }}
        >
          <Typography className={classes.textUnderline}>
            Mutual Funds
          </Typography>
        </Link>
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography className={classes.subTabtext}>SWITCH</Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={{ xs: 2, md: 17 }}
        mt={2}
        className={classes.backButton}
      >
        <Link to={`${namespace}/myportfolio?tab=mutualfund`}>
          <img src="/images/backIcon.svg" height="20px" width="20px" />
        </Link>
      </Box>

      <Box px={{ xs: 1, md: 17 }} mt={2}>
        <Typography className={classes.title}>SWITCH</Typography>
        <Typography className={classes.textgrey}>
          Transfer Invested money from one mutual fund to another within the
          same mutual fund family
        </Typography>

        <Box mt={4}>
          <Typography className={classes.textgrey}>TRANSFER TO</Typography>

          {/* search */}
          <Paper className={classes.searchBar} variant="outlined">
            <SearchIcon />
            <InputBase
              className={classes.input}
              placeholder="Search Funds"
              inputProps={{ "aria-label": "Search for Mutual Funds" }}
              onChange={handle_search}
            />
          </Paper>

          {/* list */}

          {!loading ? (
            filteredFund?.map((fund, i) => {
              return (
                <SwitchCard
                  key={i}
                  fundToData={fund}
                  schemeData={schemeData}
                  fundFromData={fundData}
                  switchData={switchData}
                />
              );
            })
          ) : (
            <Box mt={3} display="flex" justifyContent="center">
              <ReactLoading
                type="spinningBubbles"
                color="#0C97F0"
                height={"4%"}
                width={"4%"}
              />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default Index;
