import React from "react";
import { Box, makeStyles, Typography, Grid } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import moment from "moment";
import localeString from "../../../hooks/LocaleString";

const GoldListTrans = ({ orderData }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      borderBottom: "1px solid #DDDDDD",
      //borderRadius: "5px",
    },
    header: {
      fontSize: "0.85rem",
    },
    greyText: {
      fontSize: "0.85rem",
      color: "#C0C0C0",
    },
    successText: {
      color: "#6FDDB8",
      fontSize: "0.8rem",
      textAlign: "end",
      fontWeight: 500,
      paddingRight: "10px",
    },
    rupees: {
      fontFamily: "Open Sans",
    },
    failedText: {
      color: "#F27E45",
      fontSize: "0.8rem",
      textAlign: "end",
      fontWeight: 500,
      paddingRight: "10px",
    },
    pendingText: {
      color: "#F2AF1A",
      fontSize: "0.8rem",
      textAlign: "end",
      fontWeight: 500,
      paddingRight: "10px",
    },
  }));
  const classes = useStyles();
  return (
    <div>
      <Box className={classes.root} p={2}>
        <Grid container>
          <Grid item xs={9} md={5}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.header}>
                {orderData?.metalType + `(${orderData?.orderType})`}
              </Typography>
              {/* <Box className={classes.type} style={{color:orderTypeColor[ele?.orderType],background:`${orderTypeColor[ele?.orderType]}80`}}>{orderTypeObj[ele?.orderType]}</Box> */}
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Typography className={classes.header}>
              {orderData?.quantity} Units
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.header}>
              <span className={classes.rupees}>₹</span> {orderData?.totalAmount}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Box display="flex" justifyContent="end" alignItems="end">
              <Typography className={classes.greyText}>
                {moment(orderData?.updatedAt).format("DD MMM YYYY")}
              </Typography>
              <ChevronRightIcon style={{ cursor: "pointer" }} />
            </Box>
            <Typography
              className={
                orderData?.status == "Successful"
                  ? classes.successText
                  : orderData?.status == "Pending"
                  ? classes.pendingText
                  : classes.failedText
              }
            >
              {orderData?.status}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default GoldListTrans;
