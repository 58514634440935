import { Box, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import TitleLayoult from "../TitleLayoult";
import { useStyles } from "../styles";
import { useForm } from "react-hook-form";
import {
  ADHAR_VERIFICATION,
  CREAT_KYC,
  ESIGN_SUBMIT,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams } from "react-router-dom";
import ErrorMessage from "../../Validation/ErrorMessage";
import { namespace } from "../../../environment";

function AadharVerification({ step }) {
  const classes = useStyles();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [Lat, setLat] = useState("");
  const [Lng, setLng] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  //---------------------get cords-----------------------------------------------------//

  const success = ({ coords }, data) => {
    setLat(coords.latitude);
    setLng(coords.longitude);
    handleAdharSubmit(data, coords.latitude, coords.longitude);
  };

  const onError = (error) => {
    alert("Unable to retrieve your location");
  };

  const getCords = (data) => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser");
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
        success(position, data);
      }, onError);
    }
  };

  // const getCords=()=>{
  //   if (!navigator.geolocation) {
  //     alert('Geolocation is not supported by your browser');
  //   } else {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       setLat(position.coords.latitude);
  //       setLng(position.coords.longitude);
  //     }, () => {

  //     });
  //   }
  // }

  // useEffect(()=>{
  //   getCords()
  // },[navigator.geolocation.watchPosition(success, onError) ])

  const creat_kyc = async (lat, lng) => {
    const kycForm = {
      isFinalSubmit: true,
      latitude: lat,
      longitude: lng,
    };
    const httpConfig1 = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: kycForm,
    };

    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      await httpInstance(`${CREAT_KYC}`, httpConfig1);
      const response = await httpInstance(`${ESIGN_SUBMIT}`, httpConfig);

      window.location.replace(`${response.data?.data?.fpEsignRedirectUrl}`);
      // navigate({
      //   pathname: `${response.data?.data?.fpEsignRedirectUrl}`,
      // });
    } catch (error) {
      if (error.response?.data?.message) {
        setError(error.response?.data?.message);
        setLoading(false);
      } else {
        creat_kyc();
      }
    }
  };

  const handleAdharSubmit = async (data, lat, lng) => {
    setLoading(true);
    if (!lat || !lng) {
      alert("Unable to retrieve your location");
    } else {
      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: { aadhar_num: data.adhar },
      };

      try {
        await httpInstance(`${ADHAR_VERIFICATION}`, httpConfig);
        creat_kyc(lat, lng);
        // navigate({
        //   pathname: '/signIn',
        //   search: `?${createSearchParams({stepcount:"14"})}`,
        // });
      } catch {}
    }
  };

  const handleback = () => {
    navigate({
      pathname: `${namespace}/signIn`,
      search: `?${createSearchParams({ stepcount: "22" })}`,
    });
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={5}
        className={classes.backButton}
        onClick={handleback}
      >
        <img src="/images/backIcon.svg" />
      </Box>
      <Box display={"flex"} justifyContent="center">
        <Box display={"flex"} alignItems="flex-start" flexDirection={"column"}>
          <Box>
            <TitleLayoult
              title="Aadhar Verification"
              description="Please enter aadhar number for verification"
            />
          </Box>
          <form onSubmit={handleSubmit(getCords)}>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="flex-start"
              flexDirection="column"
              width={"100%"}
            >
              <label className={classes.label}>
                Enter last 4 digits of your Adhar
              </label>
              <input
                type="text"
                className={classes.inputBox}
                {...register("adhar", {
                  required: true,
                  pattern: /^[0-9]+$/,
                  maxLength: 4,
                })}
              />
              <ErrorMessage message="Aadhar Verification will take some time." />
              {errors.adhar && errors.adhar.type === "required" && (
                <ErrorMessage message="Enter last 4 digits of Adhar" />
              )}
              {errors.adhar && errors.adhar.type === "pattern" && (
                <ErrorMessage message="Please enter valid digits" />
              )}
              {errors.adhar && errors.adhar.type === "maxLength" && (
                <ErrorMessage message="Enter last 4 digits of Adhar" />
              )}

              {error && <ErrorMessage message={error} />}
            </Box>
            <Box mt={4}>
              <button className={classes.primaryButton} type="submit">
                {loading ? (
                  <CircularProgress size="1rem" color="inherit" />
                ) : (
                  "Verify"
                )}
              </button>
            </Box>
          </form>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default AadharVerification;
