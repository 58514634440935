import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const Terms_Conditions = () => {
  const useStyles = makeStyles({
    header: {
      fontSize: "1.8rem",
      fontWeight: 600,
      color: "#272727",
    },
    subTitle: {
      fontSize: "1.3rem",
      fontWeight: 500,
    },
    text: {
      fontSize: "0.9rem",
    },
  });
  const classes = useStyles();
  return (
    <div>
      <Box px={7} p={2}>
        <Typography className={classes.header}>Terms and Conditions</Typography>

        <Box mt={4}>
          <Typography>Last Updated on 16th July 2021</Typography>
        </Box>
        <Box mt={4}>
          <p className={classes.text}>
            These terms and conditions (the "Terms and Conditions") govern how
            users ("You" or "Your" or "User") access and register on the website{" "}
            <a href="https://www.bullsurge.in" target="_blank">
              www.bullsurge.in
            </a>
            , which is operated and managed by Bullsurge Private Limited, its
            affiliates, and group entities, collectively referred to as
            "Company" or "We" or "Us," and referred to as "the Website."
          </p>
        </Box>
        <Box mt={2}>
          <p className={classes.text}>
            Before enrolling on the Website or accessing any content or
            information through the Website, please read the Terms of Use and
            Privacy Policy carefully. The Company reserves the unrestricted
            right to alter or amend these Terms of Use at any time
            evidence/specifics without evidence/specifics having to notify You.
            The “Last Updated” legend above might help you figure out when these
            Terms of Use were last updated. You must review these Terms of Use
            for any modifications regularly. Approval of the revised Terms of
            Use signifies Your acceptance of the modifications and willingness
            to be legally bound by them.
          </p>
        </Box>
        <Box mt={2}>
          <p className={classes.text}>
            This document is an electronic record created by a computer system
            that does not require a physical or digital signature, according to
            the Information Technology Act of 2000. Acceptance of the terms and
            conditions signifies your agreement to use Bullsurge’s Website,
            services, and goods (collectively referred to as "Services") in
            accordance with Bullsurge's terms and conditions.{" "}
          </p>
        </Box>

        <Box mt={4}>
          <Typography className={classes.subTitle}>
            Website Interpretation
          </Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              Bullsurge Website is owned by Bullsurge Private Limited, a company
              incorporated under the Companies Act, 2013 with its registered
              office at 615 GBH Kharadi, Pune, Maharashtra, 411014. It is also
              registered as an Investment Adviser with the Securities and
              Exchange Board of India (‘SEBI') under the SEBI (Investment
              Advisers) Regulations, 2013 (‘IA Regulations'). Bullsurge website
              is used to provide investment and wealth management services to
              its consumers.
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>
            Operating the Website{" "}
          </Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              Users will be able to access or use the services only after they
              have completed the KYC, Investment Readiness, and Information
              Verification processes and have provided all of their personal
              information following the Know Your Client (“KYC”) guidelines
              issued by the Securities and Exchange Board of India (“SEBI”),
              RTAs, or any other regulatory / government agencies or authorities
              as may be required from time to time. Bullsurge may utilise your
              personal data and, in order to offer services to you, your
              personal information may be processed by, transferred to, or
              disclosed to third parties such as RTAs, KRAs, CERSAI, Statutory
              Bodies or Agencies, or 3rd party service providers & partners,
              etc. With your permission, we may use your personal information
              and usage of Bullsurge's website, and services to provide you with
              information, user experience, and marketing materials about other
              goods and services. By agreeing to this Agreement, you indicate
              that you consent to the use of your personal information for these
              extra purposes. By using any of the services of Bullsurge, you
              agree to these Terms and conditions. You can use the Services by
              logging in with your Bullsurge user id and password, which
              Bullsurge provides (Bullsurge Private Limited). You acknowledge
              that you are solely responsible for maintaining the security and
              confidentiality of your user ID and password at all times and that
              any disclosure of such information is at your own risk.{" "}
            </Typography>
            <Typography className={classes.text}>
              You are not permitted to use the Website/Service for any
              commercial purposes. You must always follow all relevant laws when
              using the Website / Service. You agree not to deceive, damage, or
              otherwise interfere with the Website's / Service's security
              measures or those that prohibit or restrict the use or copying of
              any material. Bullsurge does not supply, control, or approve any
              third-party data, products, or services in any way, even if such
              third-party data, products, or services are shown/featured on the
              Website, save for the Services specifically provided. You thus
              guarantee and indicate that you are a cautious, reasonable and
              legal citizen of India using the website/service.
            </Typography>
            <Typography className={classes.text}>
              In processing any transaction or any request arising from
              infrastructure problems such as server uptime, network
              availability, and connection, Bullsurge shall not be accountable
              for delay or failure.
            </Typography>
            <Typography className={classes.text}>
              You must take no steps to cause any kind of loss to Bullsurge.
            </Typography>
            <Typography className={classes.text}>
              Bullsurge will be notified of any substantial changes in your
              position and/or profile.{" "}
            </Typography>
            <Typography className={classes.text}>
              Bullsurge will rely on you for the latest data.
            </Typography>
            <Typography className={classes.text}>
              Bullsurge does not accept responsibility, liability, or guarantee
              the accuracy of the figures computed using the calculator given
              below for potential investments. The data material supplied is
              derived from reputable and trustworthy sources. Bullsurge, on the
              other hand, is not liable for any errors or inaccuracies, or any
              losses incurred as a result of information.
            </Typography>
            <Typography className={classes.text}>
              Bullsurge has the sole power and right to refuse a Customer's
              request to create a Bullsurge account. Bullsurge has the right to
              reveal or not disclose the reason for not creating such an
              account.
            </Typography>
            <Typography className={classes.text}>
              Customers' request to create or run their account on the address
              obtained from regulatory or government organisations & authorities
              may also refuse/suspend/ freeze the Customer's application.
            </Typography>
            <Typography className={classes.text}>
              The computers/tools/planners are meant to help you calculate the
              correct quantity. All these calculators/tools/planners are not
              enough and should not be utilised to design or implement an
              investment strategy. They are not adequate. You are asked to make
              your own investing decisions.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography className={classes.text}>
              In this way, I permit Bullsurge to share the signature of the KYC
              Registry Agency ("KRA") or CKYC to authenticate and process my
              requests with the RTAs and the Trustees.
            </Typography>
            <Typography className={classes.text}>
              I realise that the SEBI/AMFI or any other regulator defines or
              suggests all bodies within the scope of guidelines/regulations.
            </Typography>
            <Typography className={classes.text}>
              The Customer accepts and agrees to communicate to regulators or
              government authorities the information given by him/her as
              necessary or requested by him/her. The Customer understands and
              agrees to record his telephone calls for quality control,
              record-keeping reasons and similar objectives with the Customer
              Support Department (administered by itself or through any external
              supplier).
            </Typography>
            <Typography className={classes.text}>
              Customers would realise that their Bullsurge Login credentials
              allow them to log in. The Customer recognises and consents to
              confirm its credentials with Bullsurge Private Limited by signing
              on to the Bullsurge Website using the credentials.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography className={classes.text}>
              The customer shall understand and accept that all transactions
              made by the customers, including financial and non-financial, are
              via the Bullsurge website. The Bullsurge Website shall be used for
              all investments by the user at the sole discretion of the user and
              the User shall make all investments solely or according to the
              User's instructions. The Users hereby allow Bullsurge to use the
              information provided by Bullsurge or uploaded as its own will on
              the Bullsurge system, such as the User's name, PAN, age, mobile
              number, address and signature, or by regulations to send or share
              it with RTA. The User hereby allows Bullsurge to use his or her
              KYC information. If the information contained in the KYC and any
              investment or declarations or changes in signatures/signatories,
              etc. are changed, the Users shall solely be liable to inform the
              Bullsurge shall be entitled to further verification to validate
              the authenticity of its signature, if it is not updated,
              accessible or accessible to KYC records. These extra documents
              shall be provided by the User and such further steps may be
              requested by Bullsurge.
            </Typography>
            <Typography className={classes.text}>
              In case KRA/CKYC does not include user signature, the user will
              work with the company of the Bullsurge/RTA to supply additional
              information, as may be necessary for the completion of the
              verification by the Bullsurge/RTA.
            </Typography>
            <Typography className={classes.text}>
              The User hereby allows Bullsurge to share his/her signatures on
              the records for authentication and processing of applications with
              RTAs and trustees.
            </Typography>
            <Typography className={classes.text}>
              Any action made in good faith by the Common Fund/ its Registrar /
              Trustees /Bullsurge, based on the information/evidence/specifics
              available in the KRA/CKYC records will be a legitimate discharge
              for the Registrar / Trustees /Bullsurge. The user shall be free to
              remedy any actions made in good faith against its
              registrar/trustees / Bullsurge based on documents and information
              available with the KRA/CKYC.
            </Typography>
            <Typography className={classes.text}>
              The User therefore affirms and accepts that the information given
              by the User herein or afterwards after logging into the Bullsurge
              Website may be used for marketing and cross-selling purposes by
              Bullsurge.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography className={classes.text}>
              The User hereby declares that all funds invested in the schemes
              via the Bullsurge Website are from his / her own legitimate
              source/bank account and that the said investments do not violate
              any Act, Rules, Regulations, Notifications, or Directives of the
              provisions of the Income Tax Act, Anti-Money Laundering Laws,
              Anti-Corruption Laws, or any other applicable laws enacted by the
              government.{" "}
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>
            Links to other Sites
          </Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              The Website/Services may contain links, information, or pointers
              to other websites or services for payments, information
              verifications, information processing, transaction processing, or
              other required / appropriate services; however, you should not
              infer or assume that Bullsurge operates, controls, manages or is
              otherwise connected with these other websites. When you click on a
              link within the Website, Bullsurge may or may not notify you that
              you have left the Website and are subject to the terms and
              conditions, disclaimers (including privacy policies), and other
              policies of another website or its partners & service providers.
              Please read the terms of service and privacy policies of any other
              website before providing any private information or engaging in
              any transactions. You should not depend on Bullsurge's terms to
              govern your usage of another website. Bullsurge is not responsible
              for the content or practises of any other website, even if it
              connects to it or is managed by a firm affiliated with or
              otherwise linked with Bullsurge. You recognise and agree that
              Bullsurge is not responsible or liable to you for any information
              or other materials stored or supplied by websites other than the
              Website. Bullsurge reserves the right to charge customers a fee
              for using its Websites and services, as detailed in the Fees and
              Charges section of the Website. Bullsurge has the right to alter
              the price structure at any moment at its sole discretion, and you
              will be notified properly in advance of any such change in the
              cost structure. Any costs and fees are exclusive of taxes, and you
              are responsible for all government taxes, including but not
              limited to GST. All fees must be non-transferable.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography className={classes.text}>
              All payments must be made by Credit Card, Debit Card, UPI, Mobile
              Wallet, Net Banking, OTM, e-mandate / Bank Mandate, or any other
              means specified by Bullsurge from time to time.
            </Typography>
            <Typography className={classes.text}>
              I / We have received no rebates or gifts, and we have not been
              persuaded to do so.
            </Typography>
            <Typography className={classes.text}>
              I confirm that to invest in any units of the plan, I have read and
              understood the Scheme Information Documents / Statement of
              Additional Information. I have also read and comprehended the
              scheme's terms, conditions, details, rules, and regulations.
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>Confidentiality </Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              You agree that, as a result of your connection with Bullsurge and
              use of the Services, you may acquire information about the
              Services and/or Bullsurge ("Proprietary Information").
            </Typography>
            <Typography className={classes.text}>
              Such Proprietary Information shall be solely owned by Bullsurge
              and shall include, but is not limited to, the features and mode of
              operation of the Services, trade secrets, know-how, inventions
              (whether or not patentable), techniques, processes, programmes,
              ideas, algorithms, schematics, testing procedures, software design
              and architecture, computer code, internal documentation, design
              and function-specific information, and so on. Concerning this
              Proprietary Information:
            </Typography>
            <ol>
              <li className={classes.text}>
                You may not use (save as specifically permitted by this
                Agreement) or disclose Proprietary Information without the prior
                written approval of Bullsurge unless such Proprietary
                Information becomes generally publicly available as a result of
                your breach of this Agreement.
              </li>
              <li className={classes.text}>
                You undertake to take reasonable steps to keep the Proprietary
                Information and Services private.
              </li>
            </ol>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>
            Use and Protection of Intellectual Property Rights
          </Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              Copyright, trademarks, patents, trade secrets, and/or other
              related intellectual property laws protect the Bullsurge
              Application. Without the explicit written consent of Bullsurge, no
              information, content, or material from the Application may be
              copied, reproduced, republished, uploaded, posted, communicated,
              or distributed in any way. Subject to your acceptance of these
              Terms, you are granted a limited licence to use the Application
              for personal and non-commercial purposes. You undertake not to
              sell, licence, distribute, reproduce, alter, publicly perform or
              display, transmit, publish, edit, adapt, create derivative works
              from, or otherwise use the Bullsurge Application illegally.
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>Privacy</Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              You acknowledge that when using and accessing the Website and/or
              using the Services provided by the Website, you will supply Us
              with certain information and other data as specified in these
              Terms, which may or may not be otherwise publicly available.
              Please be aware that We respect the privacy and confidentiality of
              such data, and the terms relating to such private information and
              data as given by You under these Terms are regulated by the
              Website's Privacy Policy, which may be found at{" "}
              <a href="https://www.bullsurge.in/privacy-policy" target="_blank">
                https://www.bullsurge.in/privacy-policy
              </a>
              . By using and accessing the Website and using its Services, you
              also agree to the terms and conditions of our Privacy Policy.
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>
            Limitation of Liability, Indemnity, and Warranty
          </Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              In no event will Bullsurge or its directors, employees,
              associates, partners, or suppliers be liable to you for any loss
              or damage resulting from or concerning these terms and conditions
              or use of this website or investments made using this Website or
              availing of any product or services from any third-party service
              provider. You agree to indemnify Bullsurge or its directors,
              employees, associates, partners, or suppliers for all liabilities
              (including claims, damages, suits, or legal expenses incurred in
              defending itself about the foregoing) arising from I use or misuse
              of the Website, (ii) non-performance and/or non-observance of the
              duties and obligations under these terms and conditions, or due to
              your acts or omissions. You represent and declare that any data
              and information gave by you to Bullsurge or its directors,
              employees, affiliates, partners, or suppliers when using this
              Website (including to make investments) are complete, accurate,
              and authentic. Furthermore, you will be entirely responsible for
              any investment choice you make using the Services, and Bullsurge
              will not be liable for any loss or damage you or other users of
              this Website may suffer as a result of such an investment decision
              or any reliance on it. You expressly acknowledge and accept that
              your use of the Website is at your own risk.
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography className={classes.text}>
              BBullsurge and its directors, employees, associates, third-party
              partners, or suppliers disclaim all warranties or guarantees,
              whether statutory, express, or implied, including, but not limited
              to, implied warranties of merchantability, fitness, and
              non-infringement of proprietary rights, to the fullest extent
              permissible under applicable law. You may not rely on any advice
              or information, whether oral or written, acquired from Bullsurge
              or via the Website to generate any warranty or guarantee not
              specifically contained in this document.
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>Governing Laws</Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              The Terms of Use shall be regulated and construed following Indian
              law, without regard to considerations of conflict of laws. All
              disputes arising out of or in connection with shall be subject to
              the exclusive jurisdiction of the High Court Mumbai.
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>Communication</Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              Bullsurge may send You alerts and messages by e-mail, SMS, push
              notifications, conventional mail, or posts on the App/Website,
              among other methods. Notices to Bullsurge must be submitted by
              courier or registered mail to [legal@Bullsurge.com] unless
              otherwise specified below.
            </Typography>
            <Typography className={classes.text}>
              All notices, information, and other correspondence from Bullsurge
              will be sent to you through email or SMS at the email
              address/telephone number given by the User. If you receive any
              notices, information, or other communication by mail, it will be
              delivered to the postal address you have provided as your
              Communication address. If your email address, cellphone number, or
              correspondence address changes, you must notify Bullsurge in
              advance in the specified format so that it can make the necessary
              adjustments in its records.
            </Typography>
            <Typography className={classes.text}>
              Bullsurge makes no assurances that electronic messages will be
              transmitted properly or that they will be secure or virus-free.
              Bullsurge is not responsible for any loss, damage, expense, harm,
              or annoyance resulting from an email being lost, delayed,
              intercepted, corrupted, or otherwise changed, or for failing to be
              delivered for any reason beyond our reasonable control. All
              communications will be conducted in English.
            </Typography>
            <Typography className={classes.text}>
              You consent to be contacted by Bullsurge and its agents regarding
              the Services by phone or registered email id. You acknowledge and
              accept that if your phone number is on the TRAI's Do Not Disturb
              (DND) list, you will not receive SMS from Bullsurge. You agree to
              take measures to remove yourself from the DND list, and you agree
              not to hold Bullsurge responsible for any SMS you do not get
              during this time.
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>Severability</Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              If any part of this Agreement is found to be illegal, void,
              invalid, or otherwise unenforceable, that provision will be
              reduced or deleted to the extent necessary, with the remaining
              sections being valid and enforceable.
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>Survival</Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              Upon termination or expiration of this Agreement, any provision
              that, by its nature or stated terms, should remain shall continue
              as applicable to transfers and relationships made before such
              termination or expiration.
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>Assignment</Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              You can not assign or transfer the Terms of Use or any rights
              granted herein to any other party. The Company's rights under the
              Terms of Service are freely transferable by the Company to any
              third party without Your approval.
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>Force Majeure</Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              If the fulfilment of the parties' respective duties under this
              agreement is prohibited or delayed in whole or in part due to
              Natural disasters, war, arson, civil unrest, unavailability of any
              communication system, including the Internet, breach or virus in
              the processes or payment mechanism, sabotage, acts of government,
              computer hacking, unauthorised access to computer data and storage
              devices, computer crashes, breach of security and encryption
              codes, and other factors beyond a person's reasonable control
              (each a "Force Majeure Event"), then to the extent that either
              party is prevented or delayed from performing all or any part of
              their respective obligations under this Agreement despite due
              diligence and reasonable efforts to do so, such party shall be
              excused from performance hereunder for the duration of the Force
              Majeure Event. A Force Majeure Event shall not impact the parties'
              payment obligations unless there is a legal prohibition/embargo on
              making the payments.
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Terms_Conditions;
