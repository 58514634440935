import React, { useState, useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { namespace } from "../environment";
import { useSelector } from "react-redux";

function IntroSection({ offset, handleShowModal }) {
  const [refreshToken, setRefreshToken] = useState("");
  useEffect(() => {
    setRefreshToken(localStorage.getItem("refresh_token"));
  }, [localStorage]);
  console.log(refreshToken);
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundImage: "url('./images/introBG.svg')",
      padding: "8rem 0rem 14rem 0rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "column",
    },
    headingOne: {
      fontSize: "3rem",
      fontWeight: 600,
      color: "#2C2C2C",
      marginBottom: "1.2rem",
      textAlign: "center",
    },
    headingTwo: {
      fontSize: "3rem",
      fontWeight: 600,
      color: "#F3C04F",
      textAlign: "center",
    },
    button: {
      padding: "1rem 4rem",
      background: "#326CF9",
      color: "#fff",
      fontSize: "1rem",
      borderRadius: "40px",
      border: "none",
      margin: "1.8rem 0",
      cursor: "pointer",
    },
    "@media (max-width: 900px)": {
      root: {
        padding: "8rem 0rem 1rem 0rem",
      },
      headingOne: {
        fontSize: "1.6rem",
      },
      headingTwo: {
        fontSize: "1.6rem",
      },
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root} height="100%">
      <Box className={classes.headingOne}>Secure your future with</Box>
      <Box className={classes.headingTwo}>Mutual funds</Box>

      {refreshToken === "" ||
      typeof refreshToken === "undefined" ||
      refreshToken === null ? (
        <Link to={`${namespace}/onBoard`}>
          <button className={classes.button}>Invest Now</button>
        </Link>
      ) : (
        <Link to={`${namespace}/dashboard/mutualfund/diy`}>
          <button className={classes.button}>Invest Now</button>
        </Link>
      )}
    </div>
  );
}

export default IntroSection;
