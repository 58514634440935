import React from "react";
import { DigitalData, MutualData } from "../utils/content";
import Accordian from "../components/Accordian";
import { Box, Grid, makeStyles } from "@material-ui/core";

function FAQ({ type }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundImage: "url('./images/Strings.svg')",
      padding: "0rem 0rem 5rem 0rem",
      backgroundPosition: "left",
    },
    heading: {
      fontSize: "1.8rem",
      fontWeight: 600,
      color: "#272727",
    },
    FAQHolder: {
      width: "50%",
    },
    "@media (max-width: 900px)": {
      FAQHolder: {
        width: "100%",
      },
    },
  }));
  const classes = useStyles();
  return (
    <React.Fragment>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        py={{ xs: 5, md: 8 }}
        px={2}
      >
        <Box className={classes.heading}>Frequently Asked Questions</Box>
      </Box>
      <Grid lg={12}>
        <Box className={classes.FAQHolder} mx="auto">
          {type == "digitalGold"
            ? DigitalData.map(({ title, content }, key) => (
                <Accordian key={key} title={title} content={content} />
              ))
            : "" || type == "mutualFund"
            ? MutualData.map(({ title, content }, key) => (
                <Accordian key={key} title={title} content={content} />
              ))
            : ""}
        </Box>
      </Grid>
    </React.Fragment>
  );
}

export default FAQ;
