import React,{useState,useEffect} from 'react'
import {Box,Typography,makeStyles,Grid} from '@material-ui/core'


const ExpiryBanner = ({handle_resubscribe}) => {
    const useStyles=makeStyles({
        root: {
            background: "#CAE8FC",
            display: "flex",
            borderRadius:"10px",
           // marginTop:"1rem",
           "& p":{
            margin:"4px"
          }
           
          },
        head:{
            fontWeight: "bold",
            padding:"10px 0px"
        },
        text:{
            fontSize: "0.9rem",
        },
        image:{
            height:"200px",
            width:"200px"
        },
    
        primaryButton:{
            color: 'white',
            backgroundColor: "#326CF9",
            border:"none",
            borderRadius:'39px',
            fontSize:'15px',
            fontWeight:'500',
            padding:"10px 3rem",
            fontFamily:"inherit",
            cursor:"pointer",
            outline:"none",
            marginTop:"15px",
            '&:hover': {
              backgroundColor: "#326CF9",
            },
        },
        "@media (max-width: 600px)": {
            image:{
                height:"100px",
                width:"100px"
            },
          }  

    })
    const classes=useStyles()


  return (
    <div>
        <Box px={{xs:1.5,md:7.5}} >
            <Box className={classes.root} p={1}>
                <Grid container>
                <Grid item xs={5}>
                    <Box px={{xs:0,md:3}} py={{xs:5,md:0}}>
                    <img src="/images/expiry.svg" className={classes.image} />
                    </Box>
                </Grid>
               
                <Grid item xs={7}>
                <Box py={2} >
            <p className={classes.head}>Your Subscription is about to expire</p>
            <p className={classes.text}>Hey, </p>
            <p className={classes.text}>Your subscription is about to expire soon. Please resubscribe to avail benifits of Assisted Services</p>
             
            <button className={classes.primaryButton} onClick={handle_resubscribe}>Resubscribe</button>
            </Box>
                </Grid>
                </Grid>
            </Box>

        </Box>
    </div>
  )
}

export default ExpiryBanner