import React from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
    Box,
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent
  } from "@material-ui/core";

function ConnectSuccess({showSuccessModal,handleCloseSuccessModal}) {

    const useStyles = makeStyles((theme) => ({
        circularIcon:{
            padding:"5px",
          },
    }))

    const classes = useStyles();

  return (
    <div>
        <Dialog
          open={showSuccessModal}
          onClose={handleCloseSuccessModal} 
          PaperProps={{sx:{minWidth:"50%",alignItems:"center",borderRadius:32}}}
          
         >
          <Box py={4} px={3}>
            <DialogTitle >
            <Box sx={{ display: 'flex',justifyContent:"center",fontSize:"27px" }}>
              <CheckCircleOutlineIcon className={classes.circularIcon} style={{fill: "green",fontSize:"32px"}}/>
              Successful
            </Box>  
            </DialogTitle>
            <DialogContent px={6}>
                 <DialogContentText style={{fontSize:"18px"}}>Our team will reach out to you with further deatils</DialogContentText>
            </DialogContent>
            </Box>
        </Dialog>
    </div>
  )
}

export default ConnectSuccess