import React,{useState} from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import FAQ from "../../components/FAQ";
import FirstSection from "../../components/digitalGold/FirstSection";
import Intro from "../../components/digitalGold/Intro";
import MonthlySIP from "../../components/digitalGold/MonthlySIP";
import ThirdSection from "../../components/digitalGold/ThirdSection";
import ConnectModal from "../../components/ConnectModal";
import ConnectSuccess from "../../components/ConnectSuccess";
import Container from '@mui/material/Container'

function Index() {

  const [showModal, setshowModal] = useState(false)
  const [showSuccessModal,setSuccessModal]=useState(false)

  const handleCloseModal=()=>{
    setshowModal(false)
  }

  const handleShowModal=()=>setshowModal(true)
  const handleShowSuccess=()=>setSuccessModal(true)
  const handleCloseSuccessModal=()=>setSuccessModal(false)

  return (
   
      <div className="font-body">
       
        <Header handleShowModal={handleShowModal}/>
        <Intro handleShowModal={handleShowModal}/>
        <FirstSection handleShowModal={handleShowModal}/>
        <ThirdSection handleShowModal={handleShowModal} />
        <MonthlySIP handleShowModal={handleShowModal} />
        <FAQ type={"digitalGold"} />
        <Footer />
        

        <ConnectModal 
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          handleShowSuccess={handleShowSuccess}
        />

      <ConnectSuccess 
        handleCloseSuccessModal={handleCloseSuccessModal}
        showSuccessModal={showSuccessModal}
      /> 
      </div>
   
  );
}

export default Index;
