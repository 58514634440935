import React, { useState } from "react";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import {
  useNavigate,
  useLocation,
  useSearchParams,
  Link,
} from "react-router-dom";
import Step1 from "../../../components/signup/complient/Step1";
import EmailOtp from "../../../components/signup/complient/EmailOtp";

const Index = () => {
  const [step, setStep] = React.useState(1);
  const [descriptionData, setDescriptionData] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();

  const steps = searchParams.get("stepcount");

  const stepCount = (step) => {
    setStep(step);
  };

  const setData = (data) => {
    setDescriptionData({ ...descriptionData, ...data });
  };

  const useStyles = makeStyles((theme) => ({
    section1: {
      background: "#0C97F0",
      minHeight: "100vh",
      width: "30%",
      boxSizing: "border-box",
      position: "sticky",
      height: "100%",
    },
    sectionTwo: {
      height: "80vh",
      padding: "4rem 0",
      display: "flex",
      //alignItems: "center",
      //justifyContent: "center",
      flexDirection: "column",
      flex: 1,
      width: "70%",
      overflowY: "scroll",
      position: "sticky",
    },
    detailsBox: {
      margin: "0 auto",
    },
    headingOne: {
      fontSize: "1.6rem",
      color: "white",
      fontWeight: 400,
      padding: "0px 2rem",
    },
    headingTwo: {
      fontSize: "1.3rem",
      color: "#fff",
      fontWeight: 400,
      textAlign: "center",
    },
    headingThree: {
      fontSize: "0.9rem",
      color: "#fff",
      fontWeight: 400,
      textAlign: "center",
    },
    paragraph: {
      fontSize: "0.7rem",
      color: "white",
    },
    "@media (max-width: 480px)": {
      section1: {
        display: "none",
      },
    },
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="row" flex={1} height="100%">
        <Box className={classes.section1} py={4}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="space-between"
            justifyContent="space-around"
            height="100%"
            px={2}
          >
            <Box className={classes.headingOne} mb={6}>
              Investment <br /> made simple
            </Box>
            <img src="/images/Group 22509.svg" height="230" width="100%" />
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="center"
              flexDirection="column"
              className={classes.detailsBox}
            >
              <Box className={classes.headingTwo} mt={3} textAlign="center">
                Invest in <span style={{ color: "#6FDDB8" }}>Mutual Funds</span>
              </Box>
              <Box className={classes.headingThree} mt={2}>
                Bullsurge is secure and private
              </Box>
              <Link
                to="/terms-conditions"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box className={classes.paragraph} mt={1}>
                  Terms & Conditions *
                </Box>
              </Link>
            </Box>
          </Box>
        </Box>
        <Box className={classes.sectionTwo}>
          {steps === null ? (
            <Step1 step={stepCount} setData={setDescriptionData} />
          ) : (
            ""
          )}
          {steps === "1" ? (
            <EmailOtp step={stepCount} descriptionData={descriptionData} />
          ) : (
            ""
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Index;
