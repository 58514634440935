export  const asset_Sub_Category=[
  {value: "10 Y Gilt", name: "10 Y Gilt"},
{value: "Aggressive Hybrid Fund", name: "Aggressive Hybrid Fund"},
{value: "Arbitrage", name: "Arbitrage"},
{value: "Banking & PSU Fund", name: "Banking & PSU Fund"},
{value: "Conservative Hybrid Fund", name: "Conservative Hybrid Fund"},
{value: "Corporate Bond Fund", name: "Corporate Bond Fund"},
{value: "Country Specific - Brazil", name: "Country Specific - Brazil"},
{value: "Country Specific - China", name: "Country Specific - China"},
{value: "Country Specific - Japan", name: "Country Specific - Japan"},
{value: "Country Specific - Taiwan", name: "Country Specific - Taiwan"},
{value: "Country Specific - US", name: "Country Specific - US"},
{value: "Credit Risk Fund", name: "Credit Risk Fund"},
{value: "Debt - FOF", name: "Debt - FOF"},
{value: "Diversified", name: "Diversified"},
{value: "Dynamic Asset Allocation", name: "Dynamic Asset Allocation"},
{value: "Dynamic Bond", name: "Dynamic Bond"},
{value: "Equity - FOF", name: "Equity - FOF"},
{value: "Equity Savings Fund", name: "Equity Savings Fund"},
{value: "ETF", name: "ETF"},
{value: "Flexi - Cap", name: "Flexi - Cap"},
{value: "Floater Fund", name: "Floater Fund"},
{value: "Focused", name: "Focused"},
{value: "Fund of Fund", name: "Fund of Fund"},
{value: "Fund of Funds - Gold", name: "Fund of Funds - Gold"},
{value: "Gilt Fund", name: "Gilt Fund"},
{value: "Global - FOF", name: "Global - FOF"},
{value: "Hybrid - Domestic & Global Equity", name: "Hybrid - Domestic & Global Equity"},
{value: "Index", name: "Index"},
{value: "Large & Mid Cap", name: "Large & Mid Cap"},
{value: "Large Cap", name: "Large Cap"},
{value: "Liquid", name: "Liquid"},
{value: "Long Duration Fund", name: "Long Duration Fund"},
{value: "Low Duration Fund", name: "Low Duration Fund"},
{value: "Medium Duration Fund", name: "Medium Duration Fund"},
{value: "Medium to Long Duration Fund", name: "Medium to Long Duration Fund"},
{value: "Mid Cap", name: "Mid Cap"},
{value: "Money Market Fund", name: "Money Market Fund"},
{value: "Multi - Cap", name: "Multi - Cap"},
{value: "Multi Asset Allocation", name: "Multi Asset Allocation"},
{value: "Overnight Fund", name: "Overnight Fund"},
{value: "Region Specific - ASEAN", name: "Region Specific - ASEAN"},
{value: "Region Specific - ASIA", name: "Region Specific - ASIA"},
{value: "Region Specific - Emerging Markets", name: "Region Specific - Emerging Markets"},
{value: "Region Specific - Europe", name: "Region Specific - Europe"},
{value: "Sector Specific - Banks & Financial", name: "Sector Specific - Banks & Financial"},
{value: "Sector Specific - Diversified", name: "Sector Specific - Diversified"},
{value: "Sector Specific - FMCG", name: "Sector Specific - FMCG"},
{value: "Sector Specific - Pharma", name: "Sector Specific - Pharma"},
{value: "Sector Specific - Technology", name: "Sector Specific - Technology"},
{value: "Sector Specific - Transportation", name: "Sector Specific - Transportation"},
{value: "Short Duration Fund", name: "Short Duration Fund"},
{value: "Small Cap", name: "Small Cap"},
{value: "Solution Oriented - Children's Fund", name: "Solution Oriented - Children's Fund"},
{value: "Solution Oriented - Retirement", name: "Solution Oriented - Retirement"},
{value: "Tax Savings", name: "Tax Savings"},
{value: "Thematic - Agriculture & Food", name: "Thematic - Agriculture & Food"},
{value: "Thematic - Business Cycles", name: "Thematic - Business Cycles"},
{value: "Thematic - Commodities", name: "Thematic - Commodities"},
{value: "Thematic - Consumption", name: "Thematic - Consumption"},
{value: "Thematic - Contra", name: "Thematic - Contra"},
{value: "Thematic - Dividend Yield", name: "Thematic - Dividend Yield"},
{value: "Thematic - Energy", name: "Thematic - Energy"},
{value: "Thematic - ESG", name: "Thematic - ESG"},
{value: "Thematic - Infrastructure", name: "Thematic - Infrastructure"},
{value: "Thematic - Innovation", name: "Thematic - Innovation"},
{value: "Thematic - IPO", name: "Thematic - IPO"},
{value: "Thematic - Manfacturing", name: "Thematic - Manfacturing"},
{value: "Thematic - Mining & Metals", name: "Thematic - Mining & Metals"},
{value: "Thematic - MNC", name: "Thematic - MNC"},
{value: "Thematic - Precious Metals", name: "Thematic - Precious Metals"},
{value: "Thematic - PSU", name: "Thematic - PSU"},
{value: "Thematic - Quant", name: "Thematic - Quant"},
{value: "Thematic - Real Estate", name: "Thematic - Real Estate"},
{value: "Thematic - Rural & Agriculture", name: "Thematic - Rural & Agriculture"},
{value: "Thematic - Service Industries", name: "Thematic - Service Industries"},
{value: "Thematic - Shariah", name: "Thematic - Shariah"},
{value: "Ultra Short Duration Fund", name: "Ultra Short Duration Fund"},
{value: "Value Fund", name: "Value Fund"},
  
]