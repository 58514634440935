import React,{useState} from 'react'
import { Box,makeStyles } from '@material-ui/core'
import AssistedService from './AssistedService'
import ExitTriggers from './ExitTriggers'


const Index = () => {
    const useStyles=makeStyles({
        buttonBox: {
            background: "#EAEAEA",
            borderRadius: "8px",
            padding: "2px 5px",
          },
          primaryButton: {
            padding: "0.7rem 3rem",
            background: "#0C97F0",
            color: "#fff",
            fontSize: "0.9rem",
            fontFamily: "Poppins",
            fontWeight: 600,
            borderRadius: "5px",
            border: "none",
          },
          button: {
            background: "#EAEAEA",
            padding: "0.7rem 3rem",
            fontSize: "0.9rem",
            fontFamily: "Poppins",
            borderRadius: "5px",
            border: "none",
            cursor: "pointer",
            color:"#0C97F0"
          },
    })

    const classes=useStyles()

    const [value,setValue]=useState("assisted")

    
  return (
    <div>
        <Box p={3} pr={7}>
        <Box display="flex" justifyContent="center" my={3} >
        <Box className={classes.buttonBox}>
          <button
            className={
              value === "assisted" ? classes.primaryButton : classes.button
            }
            onClick={() => setValue("assisted")}
          >
           Assisted Services
          </button>
          <button
            className={
              value === "trigger" ? classes.primaryButton : classes.button
            }
            onClick={() => setValue("trigger")}
          >
            Exit Triggers
          </button>
        </Box>
      </Box>
       {value==="assisted" ? <AssistedService/> : <ExitTriggers/>}
      </Box>
    </div>
  )
}

export default Index