import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
function ThirdService({handleShowModal}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundImage: "url('./images/Component 24 – 2.svg')",
      padding: "0rem 0rem 5rem 0rem",
      backgroundPosition: "left",
    },
    backDrop: {
      background: "white",
      backdropFilter: "blur(4px)",
      position: "sticky",
      top: 0,
      boxshadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      paddingTop:"10rem"
    },
    heading: {
      color: "#2c2c2c",
      textAlign: "left",
      fontSize: "2rem",
      fontWeight: 500
    },
    description: {
      color: "#2c2c2c",
      textAlign: "left",
      fontWeight: 400,
      fontSize: "0.9rem"
    },
    subtitle: {
      fontSize: "1rem",
      fontWeight: 400,
      color: "#272727",
      cursor:"pointer"
    },
    wordBreak:{
      display:"block"
    },
    "@media (max-width: 768px)": {
      backDrop: {
        display: "flex",
        flexDirection: "row",
        paddingTop:"2rem"

      },
      videoPlayer: {
        width: "400px"
      },
      heading: {
        fontSize: "1.3rem",
        textAlign: "center"
      },
      wordBreak:{
        display:"none"
      },
    },
    "@media (max-width: 480px)": {
      backDrop: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column-reverse",
        paddingTop:"2rem"
      },
      videoPlayer: {
        width: "260px"
      },
      heading: {
        fontSize: "1.3rem",
        textAlign: "center"
      },
      description: {
        textAlign: "center"
      },
      textHolder: {
        display: "flex",
        alignItems: "center"
      },
      wordBreak:{
        display:"none"
      },
    }
  }));
  const classes = useStyles();
  return (
    <Container maxWidth="lg" style={{ position: "sticky", top: 0 }}>
      <Box
        display="flex"
        alignItems="start"
        flexDirection="row"
        justifyContent="space-between"
        height=""
        pt={8}
        className={classes.backDrop}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          className={classes.textHolder}
          mx={2}
        >
          <Box className={classes.heading}>Filtered Fund Houses</Box>
          <Box className={classes.description} my={4}>
            Our Inhouse Experts will suggest Funds based{" "}
            <br className={classes.wordBreak}/>
            on your inputs & Market conditions{" "}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="start"
            justifyContent="start"
            mt={1}
            className={classes.subtitle}
            onClick={handleShowModal}
          >
            <Box className={classes.subtitle} mr={3}>
              Learn more
            </Box>
            <img
              src="/images/Group 21264.svg"
              alt="BullSurge"
              height={25}
              width={25}
            />
          </Box>
        </Box>

        <Box display="flex" alignItems="start" justifyContent="flex-end">
          <video
            autoPlay
            muted
            playsInline
            loop
            controls={false}
            width="500"
            height="auto"
            className={classes.videoPlayer}
          >
            <source src="/images/FilteredFunds.mp4" type="video/mp4" />
          </video>
        </Box>
      </Box>
    </Container>
  );
}

export default ThirdService;
