import React, { useEffect, useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  Grid,
  makeStyles,
  Badge,
  CircularProgress,
} from "@material-ui/core";
import { Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  getNotification,
  readNotification,
  getNotificationCount,
  readAllNotification,
} from "../../redux/Admin/AdminNotification/AdminNotifiActions";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";

const LoaderComp = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "1rem",
        overflow: "hidden",
      }}
    >
      <CircularProgress size="2rem" />
    </div>
  );
};

const AdminNotification = () => {
  const useStyles = makeStyles({
    imageBack: {
      padding: "4px 5px 0px",
      background: "#ADDFFF",
      borderRadius: "50%",
      width: "fit-content",
      marginRight: "10px",
    },

    badgeColor: {
      cursor: "pointer",
      "& .MuiBadge-colorPrimary": {
        background: "#0C97F0",
      },
    },

    root: {
      minWidth: "25rem",
    },

    menu: {
      maxWidth: "20rem",
      minWidth:"20rem",
      "& .MuiMenuItem-root": {
        whiteSpace: "normal",
        borderBottom: "1px solid #DDDDDD",
        minWidth: "20rem !important",
        
        "& .MuiMenu-list": {
          //minWidth: "25rem !important",
        
        },
      },
      "&::-webkit-scrollbar": {
        width: "5px",
      },

      /* Track */
      "&::-webkit-scrollbar-track": {
        boxShadow: "none",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb ": {
        borderRadius: "10px",
        background: "#DDDDDD",
      },
    },

    unread: {
      background: "#e3f2fc",
    },

    text: {
      fontSize: "0.7rem",
      color: "#C0C0C0",
      margin: "5px 0px",
    },
    title: {
      fontSize: "0.85rem",
      fontFamily: "inherit",
      fontWeight: 600,
      margin: "5px 0px",
    },
    dateFont: {
      fontSize: "0.7rem",
      color: "#0C97F0",
      margin: "0px",
    },
    header: {
      fontSize: "1.1rem",
      fontWeight: 600,
      fontFamily: "inherit",
      margin: "3px",
    },
    headerBox: {
      borderBottom: "1px solid #DDDDDD",
    },
    text1: {
      fontSize: "0.7rem",
      color: "#0C97F0",
      cursor: "pointer",
      marginRight: "5px",
    },
    box:{
      minWidth:"50%"
    }
  });

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(1);
  const [isMore, setIsMore] = useState(true);


  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    dispatch(getNotification(page, handle_has_more, handle_page));
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setPage(1)
    setIsMore(true)
    setAnchorEl(null);
  };

  //------redux---------------------------------//
  const dispatch = useDispatch();
  const { adminNotification } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getNotificationCount());
    
  }, []);

  //------------------date format---------------------------------//

  const date_format = (date) => {
    let notifiDate = moment(date);
    if (moment().diff(notifiDate, "days") == 1) {
      return "Yesterday"; // '
    } else if (moment().diff(notifiDate, "days") == 0) {
      return "Today";
    } else {
      return moment(date).format("DD MMM");
    }
  };
  //-------------------------handle read---------------------------------//
  const handle_read = (id) => {
    const array = adminNotification?.notifications?.filter(
      (ele) => ele.id == id
    );
    if (!array[0].isRead) {
      dispatch(readNotification(id));
    }
  };

  //-------------------handle all read---------------------------//
  const handle_all_read = () => {
    dispatch(readAllNotification());
  };

  //-------------------handle has more---------------------------//
  const handle_has_more = () => {
    setIsMore(false);
  };

  //--------------------handle page------------------------//
  const handle_page = () => {
    setPage(page + 1);
  };

  //--------------------fetch Data-------------------------------//
  const fetchData = () => {
    dispatch(getNotification(page, handle_has_more, handle_page));
  };

  return (
    <div >
      <Badge
        badgeContent={adminNotification?.count}
        color="primary"
        className={classes.badgeColor}
        onClick={handleClick}
      >
        <img src="/images/bell1.svg" height="25px" width="25px" />
      </Badge>

      {adminNotification?.notifications && (
        
        <Menu
          
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          getContentAnchorEl={null}
          classes={{ paper: classes.menu}}
          id="page-component"
        >
          <Box
            px={2}
            className={classes.headerBox}
            pb={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <p className={classes.header}>Notifications</p>
           {adminNotification?.count > 0 && 
           <p className={classes.text1} onClick={handle_all_read}>
              Mark all as read
            </p>}
          </Box>

          
            <div  id="page-component" 
            style={{height:400,overflowX:"hidden"}}
            >
            <InfiniteScroll
              dataLength={adminNotification?.notifications?.length}
              next={fetchData}
              hasMore={isMore}
              loader={<LoaderComp />}
              //scrollableTarget="page-component"
              height={400}
              className={classes.menu}
              style={{overflowX:"hidden"}}
              endMessage={
                <p style={{ textAlign: 'center',fontSize:"0.85rem" }}>
                  No result
                </p>
              }
            >
              {adminNotification?.notifications?.map((ele) => {
                return (
                  <MenuItem
                    key={ele.id}
                    className={ele.isRead ? "none" : classes.unread}
                    onClick={() => handle_read(ele.id)}
                  >
                    <Box >
                      <Grid container spacing={2}>
                        <Grid item xs={2} style={{ paddingTop: "12px" }}>
                          <div className={classes.imageBack}>
                            <img
                              src="/images/about.svg"
                              height="30px"
                              width="30px"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={10} style={{ paddingRight: "15px" }}>
                          <p className={classes.title}>
                            {ele.notificationSubject}
                          </p>
                          <p className={classes.text}>{ele.notificationText}</p>
                          
                        </Grid>
                      </Grid>
                      <Box display="flex" justifyContent="end">
                            <p className={classes.dateFont}>
                              {date_format(ele.createdAt)}
                            </p>
                          </Box>
                    </Box>
                  </MenuItem>
                );
              })}
            </InfiniteScroll>
            </div>
           
        </Menu>
        
      )}
      
            
    </div>
  );
};

export default AdminNotification;
