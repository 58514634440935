import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Grid,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import ExitFundCard from "./ExitFundCard";
import {
  GET_FUND_LIST,
  INITIATE_TRIGGER,
  CONFIRM_TRIGGER,
  GET_TIMESTAMPS,
} from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import FilterComp from "../../../../components/Admin/FilterComp";
import FilterCompRadio from "../../../../components/Admin/FilterCompRadio";
import { asset_Sub_Category } from "../../../../components/Data/assetSubCatogory";
import ConfirmTrigger from "./ConfirmTrigger";
import InfiniteScroll from "react-infinite-scroll-component";
import ErrorMessage from "../../../../components/Validation/ErrorMessage";
import CustomToast from "../../../../components/UI/CustomToast";
import { useSelector } from "react-redux";

const LoaderComp = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "1rem",
        overflow: "hidden",
      }}
    >
      <CircularProgress size="2rem" />
    </div>
  );
};

const Index = () => {
  const useStyles = makeStyles({
    root: {
      width: "100%",
      "& .MuiAccordion-root:before": {
        height: "0px",
        top: -4,
      },
      "& .MuiAccordion-rounded:first-child ": {
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
      },
      "& .MuiAccordion-rounded:last-child ": {
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
      },
      "& .MuiFormControlLabel-root": {
        padding: "5px 0px",
      },
      " & .MuiAccordion-root.Mui-expanded": {
        margin: "0px",
      },
      "& .MuiAccordionSummary-root.Mui-expanded": {
        minHeight: "15px",
      },
      //  " & .MuiAccordionSummary-content.Mui-expanded":{
      //   marginBottom:"0px"
      //  }
    },

    checkBox: {
      fontSize: "0.8rem",
      "& .MuiTypography-body1": {
        fontSize: "0.85rem",
      },
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: "#326CF9",
      },
    },
    accordion: {
      borderRadius: "10px",
    },

    heading: {
      fontSize: "0.85rem",
      fontWeight: 500,
      "& .MuiIconButton-root": {
        padding: "0px",
      },
    },
    input: {
      border: "none",
      width: "100%",
      padding: "15px",
      fontFamily: "inherit",
    },
    text: {
      textAlign: "center",
      marginTop: "10px",
    },
    reset: {
      fontSize: "0.9rem",
      color: "#0C97F0",
      textAlign: "end",
      cursor: "pointer",
      marginTop: "1.5rem",
    },
    primaryButton: {
      background: "#326CF9",
      color: "#fff",
      padding: "10px 2rem",
      border: "none",
      borderRadius: "20px",
      fontFamily: "inherit",
      //margin: "0px 15px",
      fontSize: "0.85rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      cursor: "pointer",
    },
  });

  const classes = useStyles();

  const [showTrigger, setTrigger] = useState(false);
  const [reset, setReset] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [categories, setCategaories] = useState([]);
  const [fundList, setFundList] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(false);
  const [bulkCatogory, setBulkCategory] = useState([]);
  const [timestamps, setTimestamps] = useState([]);
  const [timestampId, setTimestampId] = useState(0);
  const { adminStore } = useSelector((state) => state);

  const show_trigger = async () => {
    if (categories.length > 0) {
      await initiate_bulk_trigger();
      setError(false);
    } else {
      setError("Please select Categories");
    }
  };

  const close_trigger = () => {
    setTrigger(false);
  };
  //--------------------reset filter----------------------------------//
  const handle_reset = () => {
    setReset(!reset);
    setFundList([]);
    setPage(0);
    setHasMore(true);
    setFilterData({});
  };

  //--------------------------------reset fundlist-----------------------------------//
  const reset_list = () => {
    setFundList([]);
    setPage(0);
    setHasMore(true);
  };

  //--------------------------get list------------------------------------------//
  const get_fund_list = async () => {
    const formData = {
      from: page == 0 ? 0 : page * 10,
      // from: 0,
      assisted: true,
      size: 10,
      major: true,
      minor: true,
      fundHouse: [],
    };
    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      data: { ...formData, ...filterData },
    };
    if (timestampId) {
      try {
        const response = await httpInstance(`${GET_FUND_LIST}`, httpConfig);

        if (response.data?.data == null || response.data?.responseCount < 10) {
          setHasMore(false);
          setFundList([...fundList, ...response.data?.data]);
        } else {
          console.log(response.data?.data);
          setFundList(response.data?.data);
          // setFundList(response.data?.data);
          setPage(page + 1);
          setHasMore(true);
        }
      } catch (error) {
        CustomToast(`${error?.response?.data?.message}`, "FAILED");
        setFundList([]);
        setHasMore(false);
      }
    } else {
      setHasMore(false);
    }
  };

  useEffect(() => {
    get_fund_list();
  }, [filterData]);

  //-----------------------filter catogory-------------------------------------------------------//
  const filter_category = (arry) => {
    reset_list();
    setCategaories(arry);
    setFilterData({ ...filterData, assetSubCategory: arry.toString() });
  };

  //------------------------------------handle search---------------------------------------------//
  const handle_search = (e) => {
    reset_list();
    setFilterData({ ...filterData, searchText: e.target.value });
  };
  //---------------------------------handle trigger/bulk trigger-----------------------------------//

  const initiate_trigger = async (data) => {
    setError(false);
    console.log(timestampId);
    const formData = {
      schemeName: data?.scheme_name,
      assetSubCategories: [data?.asset_sub_category],
      isTriggerByBulk: false,
      assistedServicesTimestampId: timestampId,
      planId: data?.scheme_code,
    };

    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    const httpConfig1 = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        assistedServicesTimestampId: timestampId,
      },
    };
    try {
      await httpInstance(`${INITIATE_TRIGGER}`, httpConfig);
      await httpInstance(`${CONFIRM_TRIGGER}`, httpConfig1);
      if (adminStore?.Adminuser?.role?.name == "Superadmin") {
        remove_from_list(data?.scheme_code);
      }
      CustomToast(
        `${
          adminStore?.Adminuser?.role?.name == "Superadmin"
            ? `Exit trigger sent successfully`
            : `Exit trigger request for approval sent`
        }`,
        "SUCCESS"
      );
    } catch (error) {
      CustomToast(`${error.response.data?.message}`, "FAILED");
    }
  };

  //-------------------------initiate bulk trigger---------------------------------------------//
  const initiate_bulk_trigger = async (type, data) => {
    setError(false);
    const formData = {
      schemeName: "",
      assetSubCategories: categories,
      isTriggerByBulk: true,
    };

    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };
    try {
      const response = await httpInstance(`${INITIATE_TRIGGER}`, httpConfig);
      setBulkCategory(response.data?.data);
      setTrigger(true);
    } catch (error) {
      CustomToast(`${error.response.data?.message}`, "FAILED");
    }
  };
  //---------------------remove fund---------------------------------------------//
  const remove_from_list = (scheme_code) => {
    const arry = fundList?.filter((ele) => {
      return scheme_code != ele.scheme_code;
    });
    setFundList(arry);
  };

  const get_timestamps = async () => {
    const httpConfig = {
      method: "GET",
      //tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_TIMESTAMPS}`, httpConfig);
      setTimestamps(response.data?.data);
    } catch (error) {}
  };

  useEffect(() => {
    get_timestamps();
  }, []);

  const filter_timestamp = (arry) => {
    setTimestampId(arry.toString());
    setFilterData({
      assistedServicesTimestampId: arry.toString(),
    });
  };

  return (
    <div className={classes.root}>
      <input
        //value={search}
        className={classes.input}
        placeholder="Enter the name of customer"
        onChange={handle_search}
      />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography className={classes.reset} onClick={handle_reset}>
            Reset
          </Typography>
          <FilterComp
            title={"Category"}
            data={asset_Sub_Category}
            handle_filter={filter_category}
            reset={reset}
          />
          {/* <Box display="flex" justifyContent="center" mt={1}>
            <button className={classes.primaryButton} onClick={show_trigger}>
              Send Bulk Trigger
            </button>
          </Box>
          {error && <ErrorMessage message={error} />} */}
          <FilterCompRadio
            title={"Timestamps"}
            data={timestamps}
            handle_filter={filter_timestamp}
            reset={reset}
          />
        </Grid>
        <Grid item xs={9}>
          <Box mt={5}>
            <InfiniteScroll
              dataLength={fundList?.length}
              next={get_fund_list}
              hasMore={hasMore}
              loader={<LoaderComp />}
              endMessage={
                <Typography className={classes.text}>
                  No result to display
                </Typography>
              }
              scrollThreshold={1}
            >
              {" "}
              {fundList?.map((ele, i) => {
                return (
                  <ExitFundCard
                    key={i}
                    fundData={ele}
                    initiate_trigger={initiate_trigger}
                    timestamps={timestamps}
                    fundList={fundList}
                  />
                );
              })}
            </InfiniteScroll>
          </Box>
        </Grid>
      </Grid>
      {/* confirm trigger */}
      <ConfirmTrigger
        show={showTrigger}
        handleClose={close_trigger}
        bulkCatogory={bulkCatogory}
        reset_list={reset_list}
      />
    </div>
  );
};

export default Index;
