import React,{useState,useEffect} from 'react'
import { Box,Typography,makeStyles} from '@material-ui/core'
import { borderRadius } from '@mui/system'
import {GET_BUY_LIST,GET_SELL_LIST,GET_GOLD_SILVER_RATE,GET_DELIVERY_LIST} from '../../../utils/endpoints'
import httpInstance from '../../../utils/instance'
import moment from 'moment'
import OrderprogressCard from '../../../components/Portfolio/DigitalGold/OrderprogressCard'

const Transactions = () => {
    const useStyles = makeStyles((theme) => ({
        root: {
            "& th": {
              fontSize:"0.9rem",
              fontWeight:500,
              backgroundColor:"#EAEAEA",
              padding:"16px 20px", 
              textAlign:"start" ,
              fontFamily:"Poppins",
                         
            },
            "& td": {
                fontSize:"0.8rem",
                fontWeight:400,
                backgroundColor:"#F9F9F9",
                padding:"16px 20px",   
                fontFamily:"Poppins",          
              },
              
            "& table":{
                borderCollapse: "separate",
                borderSpacing:"0 10px",
            },
            "& th:first-child,td:first-child":{
                borderRadius:"10px 0 0 10px",
            },
            "& th:last-child,td:last-child":{
                borderRadius:"0 10px 10px 0",
            }
            
          },
       
        header:{
            padding:"0px 20.5rem",
            fontWeight:600,
            color:"#272727",
            fontFamily:"Poppins",
            marginTop:"2rem"
        },
        buttonBox:{
            background:"#EAEAEA",
            borderRadius:"8px",
            padding:"2px 5px"
        },
        primaryButton:{
            padding: "0.7rem 3rem",
            background: "#0C97F0",
            color: "#fff",
            fontSize: "0.9rem",
            fontFamily:"Poppins",
            fontWeight: 600,
            borderRadius: "5px",
            border: "none",
          },
        button:{
            background: "#EAEAEA",
            padding: "0.7rem 3rem",
            fontSize: "0.9rem",
            fontFamily:"Poppins",
            borderRadius: "5px",
            border: "none",
            cursor: "pointer",
        },

        tableSpacing:{
            borderCollapse:"separate",
            borderSpacing:"0 15px",
        }
        ,
        "@media (max-width: 480px)": {
            header:{
                padding:"0px",
            }
        },
        "@media (max-width: 912px) and (min-width:481px)": {
            header:{
                padding:"0px 5rem",
            }
        }
    }))
    const classes = useStyles()

    const [value,setValue]=useState("buy")
    const [buyList,setBuyList]=useState([])
    const [sellList,setSellList]=useState([])
    const [rate,setRate]=useState({goldRate:0,silverRate:0})
    const [pendingOrder,setPendingOrder]=useState([])

    const get_data= async()=>{
        const httpConfig1 = {
            method: "POST",
            tokenType: "user",
            headers:{
              "Content-Type": "application/json",
            },
            params:{isGetAll:true}
          };

          const httpConfig = {
            method: "POST",
            tokenType: "user",
            headers:{
              "Content-Type": "application/json",
            },
          };

          try{
            const [buy,sell,delivery]=await Promise.all([
                httpInstance(`${GET_BUY_LIST}`,httpConfig1),
                httpInstance(`${GET_SELL_LIST}`,httpConfig1),
                httpInstance(`${GET_DELIVERY_LIST}`,httpConfig1)
            ]) 

                const sellarry=sell.data?.data?.filter((ele)=>{
                     return ele.augmontStatus=="Pending" && ele.status==="SUCCESS"}).map((ele)=>{
                        return {
                            id:ele.id,
                            metal:ele.metalType,
                            type:"Sell",
                            weight:ele.quantity,
                            amount:ele.totalAmount,
   
                        }
                       
                     })
                     
                

                

            const buyarry= buy.data?.data?.filter((ele)=>{
                 return  ele.status=="PROCESSING" }).map((ele)=>{
                    return {
                        id:ele.id,
                             metal:ele.metalType,
                             type:"Buy",
                             weight:ele.quantity,
                             amount:ele.totalAmount,
                    }
                 })
                
            
                 

            const deliveryarry= delivery.data?.data?.filter((ele)=>{
                return ele.augmontStatus=="Pending" && ele.status==="SUCCESS" }).map((ele)=>{
                    return{
                        id:ele.id,
                         metal:ele.deliveryGoldSilverProduct[0]?.sku.metalType,
                         type:"Delivery",
                         weight:ele.deliveryGoldSilverProduct[0]?.sku?.productWeight,
                         amount:ele.totalAmountPaid,
                    }
                })
                    
                
           

            setPendingOrder([...sellarry,...buyarry,...deliveryarry])
            setBuyList(buy.data?.data)
            // const sellresponse=await 
            setSellList(sell.data?.data)
            const rateData= await httpInstance(`${GET_GOLD_SILVER_RATE}`,httpConfig)
            setRate({goldRate:rateData.data?.data?.goldSellRate, silverRate:rateData.data?.data?.silverSellRate})


          }
          catch{

          }
    }

    useEffect(()=>{
        get_data()
    },[])

    const handleValue=(data)=>{
        setValue(data)
    }
  return (
    <div className={classes.root}>
        <Box  >
        <Typography className={classes.header}>Order in Progress({pendingOrder.length})</Typography>
            {pendingOrder?.map((ele)=>{
                return (
                    <OrderprogressCard 
                        key={ele.id}
                        data={ele}
                    />
                )
            })}
         
        </Box>
        <p className={classes.header}>Transactions</p>
        <Box display="flex" justifyContent="center">
            <Box  className={classes.buttonBox} >
            <button className={value==="buy"? classes.primaryButton : classes.button} onClick={()=>handleValue("buy")}>Buy</button>
            <button  className={value==="sell"? classes.primaryButton : classes.button} onClick={()=>handleValue("sell")}>Sell</button>
            </Box>
            
        </Box>
        <Box display="flex" justifyContent="center" mt={3} >
            <div style={{overflowX:"auto"}}>
            <table>
                <tr>
                    <th>Date</th> 
                    <th>Transaction ID</th>
                    <th>Grams</th>
                    <th>Amount</th>
                    <th>Current Value</th>
                </tr>
        
            {(value=="buy" ? buyList : sellList)?.map((ele)=>{
                return(
                    <tr key={ele.id}>
                    <td>{moment(ele.createdAt).format("DD MMM YYYY")}</td>
                    <td>{!ele.transactionId ? "Transaction Failed" : ele.transactionId}</td>
                    <td>{ !ele.quantity ? "-" :`${ele.metalType} ${ (ele.quantity)?.toFixed(4)}`}</td>
                    <td>{ele.totalAmount}</td>
                    {/* <td>{ rate?.goldRate}</td> */}
                    <td>{rate?.goldRate && ele.quantity ? (ele.metalType=="gold" ?  (ele.quantity * rate?.goldRate).toFixed(2) : (ele.quantity * rate?.silverRate).toFixed(2) ): '-' }</td>
                </tr> 
                )
            })}
                
            </table>
            </div>  
        </Box>
    </div>
  )
}

export default Transactions 
      