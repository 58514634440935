import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  CircularProgress,
  
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import RejectReason from "./RejectReason";
import httpInstance from '../../../../utils/instance'
import {SIMULATE_GOLD_KYC} from '../../../../utils/endpoints'

const ViewPanDetails = ({show, handleClose,panData,get_kyc_data}) => {
    const useStyles = makeStyles({
        root: {
          "& .MuiDialogContent-root": {
            fontSize: "0.8rem",
            lineHeight: "25px",
            padding: "20px 25px",
          },
          "& .MuiDialog-paperWidthSm": {
            minWidth: "60%",
            maxWidth:"100%",
            maxHeight: "90%",
          },
        },
        dialogTitle: {
          //display: "flex",
          justifyContent: "center",
          alignItems: " center",
          //textAlign: "center",
          padding: "0px 10px 0px 25px",
          marginTop: "-10px",
          "& .MuiTypography-body1": {
            fontSize: "1.2rem",
            fontWeight: 500,
          },
        },
        closeIcon: {
          padding: "5px",
          justifyContent: "flex-end",
        },
        text: {
          fontSize: "0.8rem",
        },
        rejectButton:{
          background:"#D03200",
          color:"#fff",
          padding:"10px 2rem",
          border:"none",
          borderRadius:"20px",
          fontFamily:"inherit",
          display:"flex",
          alignItems:"center",
          fontSize:"0.85rem",
          outline:"none" 
    
      },
      acceptButton:{
        background:"#4FD000",
        color:"#fff",
        padding:"10px 2rem",
        border:"none",
        borderRadius:"20px",
        fontFamily:"inherit",
        display:"flex",
        alignItems:"center",
        fontSize:"0.85rem" ,
        outline:"none" 
    },
    inputBox: {
        background: "#E6E6E6",
        padding: "0.7rem 0.95rem",
        borderRadius: "10px",
        border: "none",
        width: "100%",
        fontFamily:"inherit",
        marginTop:"5px",
        marginBottom:"10px"
      },
      });
    
      const classes = useStyles();

      const [reject,setReject]=useState(false)
      const [loading,setLoading]=useState(false)

      const handle_close=()=>{
        handleClose()
      }
      //-----------------------------approve request----------------------------------------//
      const approve_request=async()=>{
        setLoading(true)
        const httpConfig = {
            method: "POST",
            tokenType: "admin",
            headers:{
              "Content-Type": "application/json",
            },
            params:{userId:panData?.user?.userId,status:"approved"}
          };
          try{
            await httpInstance(`${SIMULATE_GOLD_KYC}`,httpConfig)
            handle_close()
            get_kyc_data()
            setLoading(false)
          }
          catch{
            setLoading(false)
          }
      }
      const reject_request=()=>{
        handle_close()
        setReject(true)       
    }
    
  return (
    <div>
        <Dialog className={classes.root} open={show} onClose={handle_close}>
        <Box
          display="flex"
          className={classes.closeIcon}
          onClick={handle_close}
        >
          <CloseIcon style={{cursor:"pointer"}}/>
        </Box>

        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Box display="flex" justifyContent="center">
            <Typography className={classes.title}>Verify User Details</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box mt={1} p={3} >
            <Typography className={classes.text}>Pan Name</Typography>
            <input 
                className={classes.inputBox}
                value={panData?.panName}
                readOnly
            />
            <Typography className={classes.text}>Pan Number</Typography>
            <input 
                className={classes.inputBox}
                value={panData?.panNumber}
                readOnly
            />
            <Typography className={classes.text}>Date of birth</Typography>
            <input 
                className={classes.inputBox}
                value={panData?.dob}
                readOnly
            />
            <Typography className={classes.text}>State</Typography>
            <input 
                className={classes.inputBox}
                value={panData?.user?.state?.name}
                readOnly
            />
             <Typography className={classes.text}>Pan Image</Typography>
             <Box pl={10} p={3}>
             <img src={panData?.url} height="200px" width="250px"/>
             </Box>

             <Box mt={2} px={10} display='flex' justifyContent="space-between">
              <button className={classes.rejectButton} onClick={reject_request} >
                Reject
               
              </button>
              <button className={classes.acceptButton}  onClick={approve_request}>
               {loading ? <CircularProgress size="1rem" color="inherit"/> : "Approve"}
               
              </button>
            </Box>
            </Box>

            
            
        </DialogContent>
      </Dialog>
      <RejectReason show={reject} handleClose={()=>setReject(false)} panData={panData} get_kyc_data={get_kyc_data}/>
    </div>
  )
}

export default ViewPanDetails