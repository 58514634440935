import React,{useState,useEffect} from 'react'
import {Box,Typography,makeStyles,Grid,FormControlLabel,Checkbox,IconButton,Menu,MenuItem} from '@material-ui/core'
import AutoMandate from './AutoMandate'
import AddBankForm from './AddBankForm'
import { useSelector } from 'react-redux'
import {GET_BANK_DETAILS,UPDATE_PRIMARY_BANK,UPDATE_INVESTOR_ACCOUNT,GET_MANDATE} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { fontSize } from '@mui/system'
import ReactLoading from 'react-loading';
import ConfirmPrimary from './ConfirmPrimary'




const Index = () => {
    const useStyles = makeStyles((theme) => ({
        root:{

        },
        header:{
            fontWeight:600
        },
        imageBack:{
          padding:"4px 5px 0px",
          background:"#ADDFFF",
          borderRadius:"50%"
        },
        title1:{
          fontSize:"0.8rem",
          padding:"0px 10px",
          fontWeight:600
        },
        text:{
          fontSize:"0.75rem",
          fontWeight:500
        },
        textblue:{
          fontSize:"0.75rem",
          fontWeight:500,
          color:"#0C97F0",
          display:"flex",
          alignItems:"center",
          marginLeft:"10px"
        },
        text2:{
          fontSize:"0.8rem",
          padding:"5px 0px"
        },
        text3:{
          fontSize:"0.8rem",
          padding:"5px 0px",
          fontWeight:600
        },
        borderBox:{
          border:"1px solid #C0C0C0",
          borderRadius:"5px",
          
        },

        

        blueBorderBox:{
          border:"3px solid #0C97F0",
          borderRadius:"5px"
        },

        gridPadding:{
          padding:"0px 7rem 0px 0px "
        },
        primaryButton: {
          padding: "0.8rem 2rem",
          background: "#326CF9",
          color: "#fff",
          fontSize: "0.8rem",
          fontWeight: 600,
          borderRadius: "45px",
          border: "none",
          cursor: "pointer",
          marginTop:"3rem",
          fontFamily:"inherit"
        },
        checkBox:{
          "& .MuiCheckbox-colorPrimary.Mui-checked":{
              color:"#0C97F0"
          },
          "& .MuiTypography-body1":{
              fontSize:"0.8rem"
          }
      },
      menu:{
        "& .MuiIconButton-root":{
          padding:"2px"
        },       
      },
      
      grid:{
        background:'#f5f8fa',  
        borderRadius:"5px",
        borderBottom:"1px solid #C0C0C0",
          "&:last-child":{
            borderBottom:"none",
          },     
      },

      gridCursor:{
        borderBottom:"1px solid #C0C0C0",
          "&:last-child":{
            borderBottom:"none",
          },
      }

    }))
    const classes = useStyles()

    const MenuComp=({id,handle_primary})=>{
      const [anchorEl, setAnchorEl] = useState(null);
      const [showModal,setShowModal]=useState(false)

      const open = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };

      const handle_change=()=>{
        handle_primary(id)
      }

      return(
        <>
          <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                       boxShadow:"rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px"
                      },
                    }}
                  >
                      <MenuItem  onClick={()=>setShowModal(true)} style={{fontSize:"0.8rem"}}>
                        Make primary
                      </MenuItem>

                      {/* handle_change(id) */}
                  
                  </Menu>

                  <ConfirmPrimary show={showModal} handleClose={()=>setShowModal(false)} changePrimary={handle_change}/>

                  </>
                  
      )
    }
//--------------------------------------------------------------------------------------------------------//
    const [addBank,setAddBank]=useState(false)
    const [bankData,setBankData]=useState([])
    const [isPrimary,setIsPrimary]=useState()
    const [mandate,setMandate]=useState([])
    const [bankMandate,setBankMandate]=useState()
    const [bankDetails,setBankDetails]=useState()
    
   

    const {userStore}=useSelector((state)=>state)

    const closeAddBank=()=>{
    setAddBank(false)
    setAddBank(false)
    }

   
    //----------------------------------get all banks------------------------------------------------------//
    const get_all_bank=async()=>{
      const httpConfig = {
        method: "GET",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response=await httpInstance(`${GET_BANK_DETAILS}`,httpConfig)
      setBankData(response.data?.data)

      const get_mandate=await httpInstance(`${GET_MANDATE}`,httpConfig)
      setMandate(get_mandate.data?.data)

     
     response.data?.data?.map((ele)=>{
        if(ele.isPrimaryAccount){
          setIsPrimary(ele.id)
          setBankDetails(ele)
          if(ele.mandateId){
            let arr_mandate=get_mandate.data?.data?.filter(mandate=>mandate.mandateId==ele.mandateId)
           
            setBankMandate(arr_mandate[0])
          }
        }
      })
    }
//---------------------------------------------------------------------------------//
    useEffect(()=>{
      get_all_bank()
    },[])

    //-----------------filter-bank------------------------------------------------------//

    const filterBank=(id)=>{
     
      const bank_detail=bankData?.filter((ele)=>{
        return id==ele.id
      })
      setBankDetails(bank_detail[0])
      
      if(bank_detail[0].mandateId){
        filter_mandate(bank_detail[0].mandateId)
      }else{
        setBankMandate(null)
      }
    }
    //------------------filter_mandate------------------------------//
    const filter_mandate=(mandate_id)=>{
      const arry=mandate?.filter((ele)=>{
        return ele.mandateId===mandate_id
        
      })
      
      setBankMandate(arry[0])
    }   
    
    //-----------------------update primary account----------------------------------------------------------//
    const set_primary_account=async(id)=>{
      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        params:{id:id}
      };
      const httpConfig1 = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        
      };
      try{
        await httpInstance(`${UPDATE_PRIMARY_BANK}`,httpConfig)
        await httpInstance(`${UPDATE_INVESTOR_ACCOUNT}`,httpConfig1)
      }
      catch{

      }
    }

    //--------------------------handle primary account---------------------------------------------------//
    const handle_primary=(id)=>{
      
        setIsPrimary(id)
        set_primary_account(id)
        //setAnchorEl(null);
      
    }

  return (
    <div>
       <Box px={{xs:2,md:20}} mt={5}>
            <Typography className={classes.header}>Bank Account Details</Typography>
      
       <Grid container  >
         <Grid item xs={12} md={6} className={classes.gridPadding}>
        {bankData.length>0 &&  <Box className={classes.borderBox} mt={2}>
              {bankData?.map((ele)=>{
                return(
                  <Box key={ele.id} display="flex" justifyContent="space-between" alignItems="center" p={2} className={bankDetails?.id==ele.id ? classes.grid : classes.gridCursor}>
                  <Grid container display="flex" alignItems='center' >
                   
                    <Grid item xs={5} onClick={()=>filterBank(ele.id)} style={{cursor:"pointer"}}>
                      <Box display="flex" alignItems="center">
                         <img src={ele.imageUrl} height="20px" width="20px"/>
                        <Typography className={classes.title1}>{ele.bankName}</Typography>
                      </Box>
                    </Grid>
                    <Grid  item xs={5} onClick={()=>filterBank(ele.id)} style={{cursor:"pointer"}}>
                        <Typography className={classes.title1}>{`XXXXXX${ele.accountNumber.slice(-4)}`}</Typography>
                    </Grid>
                    
                    <Grid item xs={2}>
                      
                    <Box className={classes.menu} display="flex" justifyContent="end">
                    {ele.id==isPrimary ?
                    
                     <Typography className={classes.textblue}>Primary</Typography> :
                     <MenuComp id={ele.id} handle_primary={handle_primary}/>

                      }
                    </Box>
                    </Grid>
                  </Grid>
                  
                    
                   
                </Box>
                )
              })}
          </Box>
        }

<Box display="flex" justifyContent="center">
      {userStore.user?.isOnBoardingCompleted &&  bankData.length<5 && <button className={classes.primaryButton} onClick={()=>setAddBank(true)}><img src="/images/plusIcon.svg" height="12px" width="12px" style={{marginRight:"5px"}}/> Add Another bank</button>}
       </Box>
          
            
         </Grid>
         <Grid item xs={6}>
        {bankDetails &&  <AutoMandate bankDetails={bankDetails}  bankMandate={bankMandate} isPrimary={isPrimary} get_all_bank={get_all_bank}/> }
        </Grid>

       </Grid>
       
       </Box>

       <AddBankForm dialog={addBank} closeDialog={closeAddBank} get_all_bank={get_all_bank}/>
    </div>
  )
}

export default Index