import React from "react";
import CuratedContainer from "../containers/CuratedContainer";
import {
  Box,
  Container,
  makeStyles,
} from "@material-ui/core";

function CuratedProfile({handleShowModal}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundImage: "url('./images/curatedBG.svg')",
      background: "transparent",
      padding: "5rem 0rem",
      backgroundPosition: "left",
      backdropFilter: "blur(10px)"
    },
    backDrop: {
      padding: "1rem 0"
    },
    title1: {
      fontSize: "1.6rem",
      fontWeight: 700,
      color: "#2c2c2c",
      marginBottom: "1rem"
    },
    description: {
      fontSize: "0.9rem",
      fontWeight: 400
    },
    titleStep: {
      color: "#A8A8A8",
      fontWeight: "bold",
      fontSize: "1.2rem",
      margin: "1.6em 0em"
    },
    titleStepDescription: {
      color: "#272727",
      fontWeight: 500,
      fontSize: "1.8rem"
    },
    paragraph: {
      fontSize: "1rem",
      fontWeight: "normal",
      color: "#2c2c2c"
    },
    buttonHolder: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0px 0px 1rem 0px"
    },
    button: {
      border: "none",
      borderRadius: "40px",
      fontWeight: "bold",
      background: "#326CF9",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      padding: "1rem 3.2rem",
      fontSize:"1rem",
      cursor:"pointer"
    },
    "@media (max-width: 900px)": {
      description: {
        margin: "0 auto",
        padding: "0 1rem",
        textAlign: "center"
      }
    }
  }));
  const classes = useStyles();
  return (
    <React.Fragment>
      <Box display="flex" alignItems="center" flexDirection="column" mt={8}>
        <Box className={classes.title1}>Curated Portfolio</Box>
        <Box className={classes.description}>
          Curated baskets of Mutual Funds that you can invest in with a single
          click.
        </Box>
      </Box>
      <Box className={classes.root}>
        <Container maxWidth="lg">
          <Box display="flex" flexDirection="column">
            <CuratedContainer handleShowModal={handleShowModal}/>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={8}
            >
              <button className={classes.button} onClick={handleShowModal}>Popular Portfolios</button>
            </Box>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
}

export default CuratedProfile;
