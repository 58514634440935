import { Box } from "@material-ui/core";
import React, { useState } from "react";
import TextInputField from "../Input/TextInputField";
import TitleLayoult from "../TitleLayoult";
import { useStyles } from "../styles";
import { useForm } from "react-hook-form";
import ErrorMessage from "../../Validation/ErrorMessage";
import { ADD_MOBILE_NUMBER } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import { namespace } from "../../../environment";

function Step2({ step, setData }) {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const resetMpin = searchParams.get("reset");

  const [error, setError] = useState("");

  const handlePhoneNumberSubmit = async (data) => {
    setData({ mobile: data.mobile });

    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: { mobileNumber: data.mobile },
    };

    try {
      const response = await httpInstance(`${ADD_MOBILE_NUMBER}`, httpConfig);
      setError("");
      navigate({
        pathname: `${namespace}/signIn`,
        search: `?${createSearchParams(
          resetMpin ? { stepcount: "3", reset: "mpin" } : { stepcount: "3" }
        )}`,
      });
    } catch (error) {
      setError(error.response.data?.message);
    }
  };

  return (
    <React.Fragment>
      <Box display={"flex"} justifyContent="center">
        <Box display={"flex"} alignItems="flex-start" flexDirection={"column"}>
          <Box>
            <TitleLayoult
              title="We are Excited to have you Onboard"
              description="Verify phone number for KYC"
            />
          </Box>

          <form onSubmit={handleSubmit(handlePhoneNumberSubmit)}>
            <Box mt={5}>
              <TextInputField
                name="mobile"
                label="Enter Phone Number"
                placeholder=""
                register={register}
                inputPattern={/^[0-9-+]{10,10}$/}
                handleChange={() => setError("")}
              />
            </Box>
            {errors.mobile && (
              <ErrorMessage message="Enter valid Phone Number" />
            )}
            {error && <ErrorMessage message={error} />}

            <Box mt={5}>
              <button className={classes.primaryButton} type="submit">
                Continue
              </button>
            </Box>
          </form>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Step2;
