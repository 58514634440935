import React, { useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getNotificationCount } from "../../redux/Notification/NotifiActions";
import { namespace } from "../../environment";

const PaymentSuccess = ({ message, message1, fundData }) => {
  const useStyles = makeStyles((theme) => ({
    title: {
      fontSize: "1.1rem",
      fontWeight: 500,
    },
    textgrey: {
      fontSize: "0.85rem",
      color: " #797979",
      marginTop: "2rem",
    },

    primaryButton: {
      padding: "0.8rem 5rem",
      background: "#326CF9",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      fontFamily: "inherit",
    },
  }));

  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotificationCount());
  }, []);

  return (
    <div>
      <Box display="flex" flexDirection="column" alignItems="center">
        <img src="/images/Group 22601.svg" height="220px" width="220px" />
        <Typography className={classes.title}>Congratulations!</Typography>
        <Typography className={classes.textgrey}>{message}</Typography>
        {message1 && (
          <Typography className={classes.textgrey} style={{ marginTop: 0 }}>
            {message1}
          </Typography>
        )}
        {fundData && (
          <Box display="flex" mt={4} px={{ xs: 5, md: 35 }}>
            <img src={fundData?.logo} height="30px" width="30px" />
            <Typography style={{ padding: "0px 10px" }}>
              {fundData.name}
            </Typography>
          </Box>
        )}

        <Box mt={10}>
          <Link to={`${namespace}/myportfolio?tab=mutualfund`}>
            <button className={classes.primaryButton}>
              Go to My Portfolio
            </button>
          </Link>{" "}
        </Box>
      </Box>
    </div>
  );
};

export default PaymentSuccess;
