import { Box, Typography } from "@material-ui/core";
import React from "react";
import TextInputField from "../Input/TextInputField";
import TitleLayoult from "../TitleLayoult";
import { useStyles } from "../styles";

function PanVerification({ step }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box display={"flex"} alignItems="flex-start" flexDirection={"column"}>
        <Box>
          <TitleLayoult
            title="Pan Verification"
            description="Please Enter your Pan Card Number for Verification"
          />
        </Box>

        <Box my={5}>
          <TextInputField name="pan" label="Enter Pan Number" placeholder="" />
        </Box>

        <Box mt={2}>
          <button
            className={classes.primaryButton}
            onClick={() => step("ConfirmPan")}
          >
            Verify
          </button>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default PanVerification;
