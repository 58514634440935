import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  CircularProgress,
  Tab,
  Tabs,
} from "@material-ui/core";
import PropTypes from "prop-types";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import OrderList from "./OrderList";
import Filter_OrderType from "./Filter_OrderType";
import httpInstance from "../../../utils/instance";
import { GET_ORDERS } from "../../../utils/endpoints";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { namespace } from "../../../environment";
import GoldList from "./GoldList";
import GoldListTrans from "./GoldListTrans";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import {
  GET_TRANS_BY_STATUS,
  GET_DELIVERY_LIST,
} from "../../../utils/endpoints";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LoaderComp = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "1rem",
        overflow: "hidden",
      }}
    >
      <CircularProgress size="2rem" />
    </div>
  );
};

const Index = () => {
  const useStyles = makeStyles((theme) => ({
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
    },

    text: {
      textAlign: "center",
      marginTop: "10px",
    },
    heading: {
      fontSize: "1.2rem",
      textAlign: "center",
      fontWeight: 500,
      marginBottom: "20px",
    },
    root: {
      "& .MuiTab-root": {
        fontFamily: "inherit",
        fontSize: "1rem",
        color: "black",
        opacity: 1,
        textTransform: "none",
        background: "none",
        minWidth: "auto",
      },
      "& .MuiTab-textColorInheri": {
        opacity: 0.9,
      },
    },

    activeTab: {
      "& .MuiTab-wrapper": {
        borderBottom: "2px solid #0C97F0",
        color: "#0C97F0",
        fontSize: "1rem",
      },
    },
  }));
  const classes = useStyles();

  const [status, setStatus] = useState("");
  const [orders, setOrders] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [value, setValue] = useState(0);

  const [delivery, setDelivery] = useState([]);
  const [buysell, setBuySell] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const get_gold_orders = async () => {
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { type: "buy/sell", status: "all", limit: 1000, from: 0 },
    };

    const httpConfig1 = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { status: "", limit: 1000, from: 0 },
    };

    try {
      const [buysell, delresponse] = await Promise.all([
        httpInstance(`${GET_TRANS_BY_STATUS}`, httpConfig),
        httpInstance(`${GET_DELIVERY_LIST}`, httpConfig1),
      ]);
      setDelivery(delresponse);
      setBuySell(buysell);
    } catch {}
  };

  const get_orders = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        userId: localStorage.getItem("user_id"),
        isAllOrders: status ? false : true,
        status: status,
        limit: 10,
        from: page == 0 ? 0 : page * 10,
        isDiyOrder: false,
      },
    };

    try {
      const response = await httpInstance(`${GET_ORDERS}`, httpConfig);
      if (response.data?.responseCount == 0) {
        setHasMore(false);
      } else {
        setOrders([...orders, ...response.data?.data]);
        setPage(page + 1);
      }
    } catch {}
  };

  useEffect(() => {
    get_orders();
    get_gold_orders();
  }, [status]);

  const handleStatus = (data) => {
    setOrders([]);
    setPage(0);
    setHasMore(true);
    setStatus(data);
  };

  const fetchData = () => {
    get_orders();
  };

  return (
    <div>
      <Box display="flex" alignItems="start" px={{ xs: 1, md: 17 }} pt={2}>
        <Typography className={classes.subTabtext}>My Profile</Typography>

        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography className={classes.textUnderline}>My Orders</Typography>
        </Box>
      </Box>

      <Box sx={{ width: "100%" }}>
        <Box px={{ md: 15 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className={classes.root}
            indicatorColor={""}
          >
            <Tab
              label="Mutual Funds"
              {...a11yProps(0)}
              className={value == 0 && classes.activeTab}
            />
            <Tab
              label="Digital Gold"
              {...a11yProps(1)}
              className={value == 1 && classes.activeTab}
            />
          </Tabs>
        </Box>
        <Box style={{ borderTop: "1px solid #E6E3E3", marginTop: "-12px" }}>
          <TabPanel value={value} index={0}>
            <Box px={{ xs: 2, md: 18 }} mt={5}>
              <Grid container spacing={5}>
                <Grid item xs={6} md={3}>
                  <Filter_OrderType
                    handleStatus={handleStatus}
                    status={status}
                  />
                </Grid>

                <Grid item xs={12} md={9}>
                  <InfiniteScroll
                    dataLength={orders?.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<LoaderComp />}
                    endMessage={
                      <Typography className={classes.text}>
                        No result to display
                      </Typography>
                    }
                  >
                    {orders?.map((data, i) => {
                      return (
                        <Link
                          to={`${namespace}/mutualfund/mutual-fund/order-status/${
                            data?.orderId ? data?.orderId : data?.mfOldId
                          }`}
                          state={{ data: data, order: true }}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {" "}
                          <OrderList key={i} orderData={data} />
                        </Link>
                      );
                    })}
                  </InfiniteScroll>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box px={{ xs: 2, md: 18 }} mt={5}>
              <Grid container spacing={5}>
                {/* <Grid item xs={6} md={1}></Grid> */}

                <Grid item xs={12} md={12}>
                  <Typography className={classes.heading}>
                    Gold Delivery
                  </Typography>
                  {delivery?.data?.data?.map((data, i) => {
                    return (
                      <Link
                        to={`${namespace}/myportfolio/my-order?order_type=delivery&order_id=${
                          data.id
                        }&status=${
                          data.status == "PAYMENT_FAILED"
                            ? "failed"
                            : data.status == "CREATED"
                            ? "progress"
                            : "complete"
                        }`}
                        state={{ data: data, order: true }}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        {" "}
                        <GoldList key={i} orderData={data} />
                      </Link>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.heading}>
                    Digital Gold
                  </Typography>
                  {buysell?.data?.data?.map((data, i) => {
                    return (
                      <Link
                        to={`${namespace}/myportfolio/my-order?order_type=buy&order_id=${
                          data.id
                        }&status=${
                          data.status == "PAYMENT_FAILED"
                            ? "failed"
                            : data.status == "CREATED"
                            ? "progress"
                            : "complete"
                        }`}
                        state={{ data: data, order: true }}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        {" "}
                        <GoldListTrans key={i} orderData={data} />
                      </Link>
                    );
                  })}
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
        </Box>
      </Box>

      {/* {orders ? 
        <Box px={{xs:2,md:18}} mt={5}>

          
            <Grid container spacing={5}>
                <Grid item xs={6} md={3}>
                    <Filter_OrderType handleStatus={handleStatus} status={status}/>
                </Grid>
                
                <Grid item xs={12}  md={9}>
                  {orders.length>0 ? 
                  orders?.map((data,i)=>{
                    return(
                      <Link to={`/mutualfund/mutual-fund/order-status/${data?.orderId? data?.orderId :  data?.mfOldId }`} state={{data:data,order:true}} style={{textDecoration:'none',color:"inherit"}}>  <OrderList key={i} orderData={data}/></Link>
                    )
                  }): 

                  <Box display="flex" justifyContent="center" >
                  <Typography>No data found</Typography>
                  </Box>
                }
                
                </Grid>
                
            </Grid>
        </Box> : 

        <Box display="flex" justifyContent="center" mt={5} px={{xs:2,md:18}}>
          <Typography>No data found</Typography>
        </Box>
      } */}
    </div>
  );
};

export default Index;
