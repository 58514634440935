import React from "react";
import Header from "../../../components/header/Header";
import Footer from "../../../components/Footer";
import Type from './type';

function index() {
  return (
    <React.Fragment>
      <div className="font-body">
        
        <Type />
        
        
      </div>
    </React.Fragment>
  );
}

export default index;
