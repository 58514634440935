import React from 'react'
import Modal from "@material-ui/core/Modal";
import {
    Box
  } from "@material-ui/core";
  import Contact from './Contact';

  const modalstyle = {
    backgroundImage: "url('/images/Components.svg')",
    backgroundRepeat:"no-repeat",
    backgroundPosition:"right bottom",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    boxShadow: 24,
    p: 4,
    background: "#fff",
    borderRadius: "1rem",
    padding:"30px",
    "@media (max-width: 480px)": {
        width: "90%",
        top:"64%",
        display:"column"
    }
  };


function ConnectModal(props) {
    const {showModal,handleCloseModal,handleShowSuccess,handleCloseSuccessModal}=props
  return (
    <Modal
          open={showModal}
          onClose={handleCloseModal}
          style={{ overflow: 'scroll' }}
          >
        <Box sx={modalstyle}>
    
        <Contact setModal={handleCloseModal} handleShowSuccess={handleShowSuccess}  />
        </Box>
      </Modal>

  )
}

export default ConnectModal