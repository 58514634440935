import React from 'react'
import FundManagement from '../../containers/Admin/FundManagement'

const Fund_management = () => {
  return (
    <div>
        <FundManagement/>
    </div>
  )
}

export default Fund_management