import React, { useEffect, useState } from "react";
import TitleLayoult from "../TitleLayoult";
import {
  Box,
  MenuItem,
  Select,
  Typography,
  FormControl,
  Checkbox,
  FormControlLabel,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TextInputField from "../Input/TextInputField";
import { useStyles } from "../styles";
import { useForm, Controller } from "react-hook-form";
import ErrorMessage from "../../Validation/ErrorMessage";
import {
  GET_ALL_STATES,
  ADD_ADDRESS,
  KYC_STATUS,
  GET_PERSONAL_DETAILS,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams } from "react-router-dom";
import { namespace } from "../../../environment";

function Step7({}) {
  const classes = useStyles();

  const defaultValues = {
    AddressLine1: "",
    AddressLine2: "",
    city: "",
    pincode: "",
    permanentstate: "",
    currentAddressLine1: "",
    currentAddressLine2: "",
    currentCity: "",
    currentPincode: "",
    currentState: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({ defaultValues });

  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        input: {
          padding: "8px 10px",
        },
      },
      MuiSelect: {
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  });

  const navigate = useNavigate();

  const [allStates, setAllStates] = useState([]);
  const [sameAsPermanent, setSameAsPermanent] = useState(true);
  const [error, setError] = useState("");
  const [isComplient, setIsComplient] = useState(false);

  const resetData = (data) => {
    reset({
      AddressLine1: data.permAddressLine1,
      AddressLine2: data.permAddressLine2,
      city: data.permCity,
      pincode: data.permPincode,
      permanentstate: data.permState?.id,
      currentAddressLine1: data.currAddressLine1,
      currentAddressLine2: data.currAddressLine2,
      currentCity: data.currCity,
      currentPincode: data.currPincode,
      currentState: data.currState.id,
    });
    setSameAsPermanent(data.permSameAsCurr);
  };

  useEffect(async () => {
    const httpConfig = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const [stateResponse, status] = await Promise.all([
        httpInstance(`${GET_ALL_STATES}`, httpConfig),
        httpInstance(`${KYC_STATUS}`, httpConfig1),
      ]);
      const states = stateResponse?.data?.data.filter((ele) => {
        return ele.countryId === 101;
      });
      setAllStates(states);
      setIsComplient(status.data?.data?.isKycCompliant);
      const deatils = await httpInstance(
        `${GET_PERSONAL_DETAILS}`,
        httpConfig1
      );
      resetData(deatils.data?.data);
    } catch (error) {}
  }, []);

  const handleCurrentAddress = (e) => {
    if (e.target.checked) {
      setSameAsPermanent(true);
    } else {
      setSameAsPermanent(false);
    }
  };

  const submit_address = async (data) => {
    const formData = {
      permAddressLine1: data.AddressLine1,
      permAddressLine2: data.AddressLine2,
      permCity: data.city,
      permPincode: data.pincode,
      permState: data.permanentstate,
      permCountry: 101,
      permSameAsCurr: sameAsPermanent,
      currAddressLine1: data.currentAddressLine1,
      currAddressLine2: data.currentAddressLine2,
      currCity: data.currentCity,
      currPincode: data.currentPincode,
      currState: data.currentState,
      currCountry: 101,
    };

    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      await httpInstance(`${ADD_ADDRESS}`, httpConfig);
      navigate({
        pathname: `${namespace}/signIn`,
        search: `?${createSearchParams(
          isComplient ? { stepcount: "9" } : { stepcount: "20" }
        )}`,
      });
    } catch (error) {
      if (error.response.data.statusCode === 400) {
        setError(error.response.data.message);
      }
    }
  };

  const handleback = () => {
    navigate({
      pathname: `${namespace}/signIn`,
      search: `?${createSearchParams({ stepcount: "7" })}`,
    });
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={5}
        className={classes.backButton}
        onClick={handleback}
      >
        <img src="/images/backIcon.svg" />
      </Box>
      <Box display={"flex"} justifyContent="center">
        <Box display={"flex"} alignItems="flex-start" flexDirection={"column"}>
          <Box>
            <TitleLayoult
              title="Address Details"
              description="Please make sure this address matches with your POA"
            />
          </Box>
          <Box
            //display={"flex"}
            alignItems="flex-start"
            flexDirection={"column"}
            justifyContent="flex-start"
          >
            <form onSubmit={handleSubmit(submit_address)}>
              <h3>Permanent Address</h3>
              <Box my={1}>
                <TextInputField
                  name="AddressLine1"
                  label="Address Line 1"
                  placeholder=""
                  register={register}
                />
                {errors.AddressLine1 && (
                  <ErrorMessage message="Please Enter Address" />
                )}
              </Box>
              <Box my={1}>
                <TextInputField
                  name="AddressLine2"
                  label="Address Line 2"
                  placeholder=""
                  register={register}
                />
                {errors.AddressLine2 && (
                  <ErrorMessage message="Please Enter Address" />
                )}
              </Box>
              <Box my={1}>
                <TextInputField
                  name="city"
                  label="City"
                  placeholder=""
                  register={register}
                />
                {errors.city && <ErrorMessage message="Please Enter City" />}
              </Box>
              <ThemeProvider theme={theme}>
                <Box
                  my={1}
                  display="flex"
                  alignItems={"flex-start"}
                  justifyContent="flex-start"
                  flexDirection="column"
                  width={"100%"}
                >
                  <label className={classes.label}>State</label>
                  <Box width={"100%"} className={classes.Select}>
                    <FormControl fullWidth className={classes.Select}>
                      <Controller
                        control={control}
                        name={`permanentstate`}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            disableUnderline
                            className={classes.selectItem}
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                              style: {
                                maxHeight: 250,
                                top: 5,
                                minWidth: 200,
                              },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            {allStates &&
                              allStates?.map((ele) => {
                                return (
                                  <MenuItem
                                    key={ele.id}
                                    className={classes.menuItem}
                                    value={ele.id}
                                  >
                                    {ele.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Box>
                  {errors.permanentstate && (
                    <ErrorMessage message="Please Select State" />
                  )}
                </Box>
              </ThemeProvider>
              <Box my={1}>
                <TextInputField
                  name="pincode"
                  label="Pin Code"
                  placeholder=""
                  register={register}
                  inputPattern={/^[0-9 ]+$/}
                />
                {errors.pincode && (
                  <ErrorMessage message="Please Enter Pin Code" />
                )}
              </Box>
              <Box my={1}>
                <FormControlLabel
                  classes={{ label: classes.label }}
                  label="Current Address same as Permanent Address?"
                  control={
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={sameAsPermanent === true}
                      onChange={handleCurrentAddress}
                    />
                  }
                />
              </Box>
              {!sameAsPermanent && (
                <div>
                  <h3>Current Address</h3>
                  <Box my={1}>
                    <TextInputField
                      name="currentAddressLine1"
                      label="Address Line 1"
                      placeholder=""
                      register={register}
                    />
                    {errors.currentAddressLine1 && (
                      <ErrorMessage message="Please Enter Address" />
                    )}
                  </Box>
                  <Box my={1}>
                    <TextInputField
                      name="currentAddressLine2"
                      label="Address Line 2"
                      placeholder=""
                      register={register}
                    />
                    {errors.currentAddressLine2 && (
                      <ErrorMessage message="Please Enter Address" />
                    )}
                  </Box>
                  <Box my={1}>
                    <TextInputField
                      name="currentCity"
                      label="City"
                      placeholder=""
                      register={register}
                    />
                    {errors.currentCity && (
                      <ErrorMessage message="Please Enter City" />
                    )}
                  </Box>
                  <ThemeProvider theme={theme}>
                    <Box
                      my={1}
                      display="flex"
                      alignItems={"flex-start"}
                      justifyContent="flex-start"
                      flexDirection="column"
                      width={"100%"}
                    >
                      <label className={classes.label}>State</label>
                      <Box width={"100%"} className={classes.Select}>
                        <FormControl fullWidth className={classes.Select}>
                          <Controller
                            control={control}
                            name={`currentState`}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                disableUnderline
                                className={classes.selectItem}
                                IconComponent={KeyboardArrowDownIcon}
                                MenuProps={{
                                  style: {
                                    maxHeight: 250,
                                    top: 5,
                                    minWidth: 200,
                                  },
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                                {...register("currentState", {
                                  required: true,
                                })}
                              >
                                {allStates &&
                                  allStates?.map((ele) => {
                                    return (
                                      <MenuItem
                                        key={ele.id}
                                        className={classes.menuItem}
                                        value={ele.id}
                                      >
                                        {ele.name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Box>
                      {errors.currentState && (
                        <ErrorMessage message="Please Select State" />
                      )}
                    </Box>
                  </ThemeProvider>
                  <Box my={1}>
                    <TextInputField
                      name="currentPincode"
                      label="Pin Code"
                      placeholder=""
                      register={register}
                      inputPattern={/^[0-9 ]+$/}
                    />
                    {errors.currentPincode && (
                      <ErrorMessage message="Please Enter Pin Code" />
                    )}
                  </Box>
                </div>
              )}
              {error && <ErrorMessage message={error} />}
              <Box mt={5}>
                <button className={classes.primaryButton} type="submit">
                  Next
                </button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Step7;
