import React from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/Footer'
import SellSuccess from '../../containers/DigitalGold/Payement/SellSuccess'

const sellSuccess = () => {
  return (
    <div>
         {/* <Header/> */}
            <SellSuccess/>
        {/* <Footer/> */}
    </div>
  )
}

export default sellSuccess