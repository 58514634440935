import React, { useEffect, useState } from "react";
import {
  Box,
  makeStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

const FilterComp = ({ data, title, handle_filter, reset }) => {
  const useStyles = makeStyles({
    checkBox: {
      fontSize: "0.8rem",
      "& .MuiTypography-body1": {
        fontSize: "0.85rem",
      },
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: "#326CF9",
      },
    },
    accordion: {
      borderRadius: "10px",
    },

    heading: {
      fontSize: "0.85rem",
      fontWeight: 500,
      "& .MuiIconButton-root": {
        padding: "0px",
      },
    },
  });

  const classes = useStyles();

  const [checkedArry, setCheckedArry] = useState([]);

  const handle_checkbox = (e) => {
    console.log(e.target.value);
    const { value, checked } = e.target;
    let arry;
    if (checked) {
      arry = [...checkedArry, value];
    } else {
      arry = checkedArry.filter((e) => e != value);
    }
    setCheckedArry(arry);
    handle_filter(arry);
  };

  useEffect(() => {
    setCheckedArry([]);
  }, [reset]);

  return (
    <div>
      <Accordion variant="outlined" className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.heading}
        >
          {title}
        </AccordionSummary>
        <SimpleBar style={{ maxHeight: 300 }}>
          <AccordionDetails className={classes.checkBox}>
            <FormGroup>
              {data?.map((ele, i) => {
                return (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        color="primary"
                        value={ele.value || ele.id.toString()}
                        checked={checkedArry.includes(
                          ele.value || ele.id.toString()
                        )}
                        onChange={handle_checkbox}
                      />
                    }
                    label={ele.name || ele.versionName}
                  />
                );
              })}
            </FormGroup>
          </AccordionDetails>
        </SimpleBar>
      </Accordion>
    </div>
  );
};

export default FilterComp;
