import React, { useEffect } from "react";
import Investment from "../../../components/DashBoard/DigitalGold/Investment";
import {
  Typography,
  makeStyles,
  Box,
  Grid,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getNotificationCount } from "../../../redux/Notification/NotifiActions";

import moment from "moment";
import { namespace } from "../../../environment";

const DeliverySuccess = () => {
  const useStyles = makeStyles((theme) => ({
    textgrey: {
      fontSize: "0.85rem",
      color: " #797979",
      fontFamily: "inherit",
      margin: "5px",
    },
    primaryButton: {
      padding: "0.8rem 5rem",
      background: "#326CF9",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      fontFamily: "inherit",
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotificationCount());
  }, []);
  return (
    <div>
      <Box>
        <Box
          display="flex"
          justifyContent="center"
          p={3}
          flexDirection="column"
          alignItems="center"
        >
          <img
            src="/images/undraw_on_the_way_re_swjt 2.svg"
            height="220px"
            width="220px"
          />
          <Typography style={{ fontWeight: 600, fontFamily: "inherit" }}>
            On the Way!
          </Typography>
          <Typography className={classes.textgrey}>
            Your Digital Gold delivery is on the way
          </Typography>
          <Typography
            className={classes.textgrey}
          >{`Expected Delivery Date : ${moment()
            .add(7, "days")
            .format("DD MMM YYYY")}`}</Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography className={classes.textgrey}>
            Your Current Digital Gold Investment
          </Typography>
        </Box>
        <Investment />
        <Box display="flex" justifyContent="center" mt={5}>
          {" "}
          <Link to={`${namespace}/myportfolio?tab=digitalgold`}>
            <button className={classes.primaryButton}>
              Go to My Portfolio
            </button>
          </Link>
        </Box>
      </Box>
    </div>
  );
};

export default DeliverySuccess;
