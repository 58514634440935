import React, { useState, useEffect } from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import Investment from "../../../components/DashBoard/DigitalGold/Investment";
import ProductCard from "../../../components/DashBoard/DigitalGold/ProductCard";
import httpInstance from "../../../utils/instance";
import { GET_PRODUCT } from "../../../utils/endpoints";
import { Link } from "react-router-dom";
import { namespace } from "../../../environment";

const GetDelivery = ({ handleSku, setProductName }) => {
  const useStyles = makeStyles((theme) => ({
    header: {
      fontSize: "1.2rem",
    },

    subHeader: {
      fontSize: "0.9rem",
    },
    buttonBox: {
      background: "#EAEAEA",
      borderRadius: "8px",
      padding: "2px 5px",
    },
    primaryButton: {
      padding: "0.4rem 3rem",
      background: "#0C97F0",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "5px",
      border: "none",
    },
    button: {
      background: "#EAEAEA",
      padding: "0.4rem 3rem",
      fontSize: "0.8rem",
      borderRadius: "5px",
      border: "none",
      cursor: "pointer",
    },
  }));
  const classes = useStyles();

  const [value, setValue] = useState("gold");
  const [gold, setGold] = useState([]);
  const [silver, setSilver] = useState([]);

  const get_product = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { metalType: "gold" },
    };

    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { metalType: "silver" },
    };

    try {
      const goldResponse = await httpInstance(`${GET_PRODUCT}`, httpConfig);
      const arr = goldResponse?.data?.data?.filter((ele) => {
        return ele.defaultImageUrl != null;
      });
      setGold(arr);

      const silverResponse = await httpInstance(`${GET_PRODUCT}`, httpConfig1);
      setSilver(silverResponse?.data?.data);
    } catch {}
  };

  useEffect(() => {
    get_product();
  }, []);

  const handleValue = (data) => {
    setValue(data);
  };

  return (
    <>
      <Box px={{ xs: 2, md: 17 }} pt={2}>
        <Link to={`${namespace}/myportfolio?tab=digitalgold`}>
          <img src="/images/backIcon.svg" height="20px" width="20px" />
        </Link>{" "}
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography className={classes.header}>
          Get Digital Gold Delivery
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        mt={4}
        flexDirection="column"
        alignItems="center"
      >
        <Typography className={classes.subHeader}>
          Your current Investments
        </Typography>
        <Investment />
      </Box>
      <Box display="flex" justifyContent="center" mt={4}>
        <Box className={classes.buttonBox}>
          <button
            className={
              value === "gold" ? classes.primaryButton : classes.button
            }
            onClick={() => handleValue("gold")}
          >
            Gold
          </button>
          <button
            className={
              value === "silver" ? classes.primaryButton : classes.button
            }
            onClick={() => handleValue("silver")}
          >
            Silver
          </button>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        mt={6}
        px={{ xs: 10, sm: 10, md: 50 }}
      >
        <Grid container spacing={7}>
          {(value == "gold" ? gold : silver).map((ele) => {
            return (
              <Grid item xs={12} md={6} key={ele.sku}>
                <ProductCard
                  data={ele}
                  handleSku={handleSku}
                  setProductName={setProductName}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default GetDelivery;
