import logo from "./logo.svg";
import "./App.css";
import Routes from "./AllRoutes";
import {Provider} from "react-redux";
import {useStore} from "./redux/store/confugureStore";
import {disableReactDevTools} from "@fvilers/disable-react-devtools";
import {ToastContainer} from "react-toastify";
import {useStyles} from "./components/signup/styles";
import {useEffect} from "react";

const App = () => {
  const store = useStore();
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    disableReactDevTools();
  }
  // useEffect(() => {
  //   const redirectURL = "https://mfunds.neosurge.money";
  //   window.location.href = redirectURL;
  // }, []);

  const classes = useStyles();

  return (
    <Provider store={store}>
      <Routes />
      <ToastContainer bodyClassName={classes.toastMessage} />
    </Provider>
  );
};

export default App;
