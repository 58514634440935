import React from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { namespace } from "../../../../environment";

const StopSuccess = ({ data, closeStop }) => {
  const useStyles = makeStyles({
    header: {
      fontSize: "1.5rem",
    },

    grid: {
      marginTop: "1rem",
      padding: "0px 15rem",
    },
    primaryButton: {
      padding: "0.8rem 5rem",
      background: "#326CF9",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      marginTop: "3rem",
      fontFamily: "inherit",
    },
    rupees: {
      fontFamily: "Open Sans",
    },
    "@media (max-width: 900px)": {
      grid: {
        padding: "0px",
      },
    },
  });
  const classes = useStyles();
  return (
    <div>
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <img src="/images/stop.svg" height="150px" width="150px" />
        <Box mt={3}>
          <Typography
            className={classes.header}
          >{`${data.type} Stopped`}</Typography>
        </Box>

        <Grid
          container
          className={classes.grid}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={2}>
            <img src={data.logo} height="40px" width="40px" />
          </Grid>

          <Grid item xs={7}>
            <Box display="flex" justifyContent="center">
              <Typography>{data.plan_name}</Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box display="flex" justifyContent="center">
              <Typography>
                <span className={classes.rupees}>₹</span>
                {data.sipAmount}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Link to={`${namespace}/myportfolio?tab=mutualfund&category=diy`}>
          <button className={classes.primaryButton} onClick={closeStop}>
            Ok, Got it
          </button>
        </Link>
      </Box>
    </div>
  );
};

export default StopSuccess;
