import React from "react";
import {
  Box,
  Container,
  Grid,
  makeStyles,
} from "@material-ui/core";
import SliderBar from "./SliderBar";

function StepsSection({ offset }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundImage: "url('./images/Strings.svg')",
      padding: "0rem 0rem 10rem 0rem",
      backgroundPosition: "left"
    },
    backDrop: {
      width: "100%",
      background: "transparent",
      backdropFilter: "blur(5px) opacity(1)",
      padding: "1.6rem 0",
      margin: "0rem 0rem 2rem 0rem",
      display: "flex",
      alignItems: "center",
      flexDirection: "row"
    },
    container: {
      display: "flex",
      flexDirection: "column"
    },
    title1: {
      fontSize: "2.4rem",
      fontWeight: 700,
      color: "#333",
      marginBottom: "2rem"
    },
    mobileTitle1: {
      fontSize: "1.2rem",
      fontWeight: 700,
      textAlign: "center",
      color: "#333",
      marginBottom: "2rem",
      display: "none",
      margin: "0 auto"
    },
    titleStep: {
      color: "#A8A8A8",
      fontWeight: "bold",
      fontSize: "1.2rem",
      margin: "1.6em 0em"
    },
    titleStepDescription: {
      color: "#272727",
      fontWeight: 500,
      fontSize: "1.8rem"
    },
    paragraph: {
      fontSize: "0.8em",
      width: "45%",
      fontWeight: "normal",
      color: "#000"
    },
    breakLine: {
      display: "none"
    },
    imageHolder: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end"
    },

    image: {
      height: "300px",
      width: "auto"
    },
    stepBox: {
      borderLeft: "1px solid #333",
      position: "absolute",
      margin: "2rem 0",
      flex: 1,
      height: "180px",
      display: "flex",
      alignItems: "start",
      justifyContent: "space-between",
      flexDirection: "column"
    },
    circle: {
      height: "12px",
      width: "12px",
      borderRadius: "100%",
      position: "relative",
      background: "#F3C04F",
      margin: "0px 0px 0px -6px",
      outline: "10px solid #fff"
    },
    stepContainer: {
      flexDirection: "row"
    },
    stepsTextHolder: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "space-around",
      flex: 1
    },
    "@media (max-width: 768px)": {
      title1: {
        display: "none"
      },
      container: {
        display: "flex",
        flexDirection: "column"
      },
      mobileTitle1: {
        display: "block",
        textAlign: "center"
      },
      stepContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start"
      }
    },
    "@media (max-width: 480px)": {
      title1: {
        display: "none"
      },
      container: {
        display: "flex",
        flexDirection: "column"
      },
      mobileTitle1: {
        display: "block",
        textAlign: "center"
      },
      stepContainer: {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "flex-start"
      },
      paragraph: {
        width: "100%"
      },
      imageHolder: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      },
      image: {
        margin: "2rem 0"
      }
    }
  }));
  const classes = useStyles();

  const [step, setStep] = React.useState(1);
  const [time, setTime] = React.useState(Date.now());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setStep((prevCount) => prevCount + 1);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (step === 4) {
    setStep(1);
  }
  return (
    <div className={classes.root}>
      <div className={classes.backDrop}>
        <Container maxWidth="lg">
          <Box display="flex" className={classes.container}>
            <Box className={classes.title1} sm={{ color: "blue" }}>
              Start Investing in
              <br  /> 3 simple steps!
            </Box>
            <Box className={classes.mobileTitle1} sm={{ color: "blue" }}>
              Start Investing in 3 simple steps!
            </Box>
            {step === 1 ? (
              <Grid container className={classes.stepContainer}>
                <Grid item xs={12} sm={6} lg={6}>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    alignItems="start"
                  >
                    <Box className={classes.stepBox}>
                      <Box className={classes.circle}></Box>
                      <Box className={classes.circle}></Box>
                      <Box className={classes.circle}></Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="start"
                      pl={5}
                      className={classes.stepsTextHolder}
                    >
                      <Box className={classes.titleStep}>Step 1</Box>
                      <Box className={classes.titleStepDescription}>
                        {" "}
                        Sign Up
                      </Box>
                      <h4 className={classes.paragraph}>
                        Create an investing account by signing up on our
                        platform.
                      </h4>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <Box className={classes.imageHolder} width="100%">
                    <img
                      src="/images/SignupT.gif"
                      style={{ height: "300px", width: "auto" }}
                      className={classes.image}
                    />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            {step === 2 ? (
              <Grid container className={classes.stepContainer}>
                <Grid item xs={12} sm={6} lg={6}>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    alignItems="start"
                  >
                    <Box className={classes.stepBox}>
                      <Box className={classes.circle}></Box>
                      <Box className={classes.circle}></Box>
                      <Box className={classes.circle}></Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="start"
                      pl={5}
                      className={classes.stepsTextHolder}
                    >
                      <Box className={classes.titleStep}>Step 2</Box>
                      <Box className={classes.titleStepDescription}>
                        {" "}
                        Fill the KYC
                      </Box>
                      <h4 className={classes.paragraph}>
                        Submit your ID proof, address proof and
                        <br /> other necessary documents to complete the process
                      </h4>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <Box className={classes.imageHolder} width="100%">
                    <img
                      src="/images/kyc.svg"
                      style={{ height: "300px", width: "auto" }}
                      className={classes.image}
                    />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            {step === 3 ? (
              <Grid container className={classes.stepContainer}>
                <Grid item xs={12} sm={6} lg={6}>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    alignItems="start"
                  >
                    <Box className={classes.stepBox}>
                      <Box className={classes.circle}></Box>
                      <Box className={classes.circle}></Box>
                      <Box className={classes.circle}></Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="start"
                      pl={5}
                      className={classes.stepsTextHolder}
                    >
                      <Box className={classes.titleStep}>Step 3</Box>
                      <Box className={classes.titleStepDescription}>
                        {" "}
                        Start Investing
                      </Box>
                      <h4 className={classes.paragraph}>
                        Buy and sell the mutual funds of your choices! Get
                        high-return recommendations
                      </h4>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <Box className={classes.imageHolder} width="100%">
                    <img
                      src="/images/step3.svg"
                      style={{ height: "300px", width: "auto" }}
                      className={classes.image}
                    />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Box>
        </Container>
      </div>
      <Box mt={6}>
        <SliderBar step={step} bar={3} />
      </Box>
    </div>
  );
}

export default StepsSection;
