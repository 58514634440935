import {
    GET_GOLD_USER_DATA,
    GET_USER_DATA,
    UPLOAD_PIC,
    VERIFY_LOADING,
    VERIFY_ERROR
  
  } from "./userActionTypes";

  const userInitialState = {
    goldUser: [],
    user:[],
    loading: false,
    error: null,
    
  };

  const wishlistReducers = (state = userInitialState, action) => {
    switch (action.type) {
      case GET_GOLD_USER_DATA: {
       
        return { ...state, goldUser: action.payload, loading: false, error: null };
      }
      case GET_USER_DATA: {
        
        return { ...state, user: action.payload, loading: false, error: null };
      }
      case UPLOAD_PIC: {
       
        return { ...state, user:{...state.user,profile_image:action.payload}, loading: false, error: null };
      }
      
      default: {
        return state;
      }
    }
  };
  
  export default wishlistReducers;