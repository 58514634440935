import React,{useState} from 'react'
import { Box, makeStyles,Typography,Accordion,AccordionSummary,AccordionDetails,CircularProgress  } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {GET_REPORTS} from '../../../utils/endpoints'
import httpInstance from '../../../utils/instance'
import { BASE_URL } from '../../../environment';
import ErrorMessage from '../../../components/Validation/ErrorMessage'

const Index = () => {
    const useStyles = makeStyles((theme) => ({
        root: {
          border: "1px solid #C4C4C4",
          borderRadius: "10px",
          // padding:"5px 85px",
          marginTop: "1rem",
          fontSize: "0.8rem",
          fontWeight: 400,
          //width:"55%"
          
        },
        boxWidth: {
            width: "35%",
          },
          subTabtext: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
          },
          textUnderline: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
          },

          box1:{
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.12)",
            borderRadius:"8px",
            border:"1px solid #DDDDDD",
            width: "35%",
          },

          header:{
            fontSize:"1.2rem"
          },
          primaryButton: {
            padding: "0.6rem 1.5rem",
            background: "#326CF9",
            color: "#fff",
            fontSize: "0.8rem",
            fontFamily:"inherit",
            fontWeight: 600,
            borderRadius: "45px",
            border: "1px solid #326CF9",
            marginRight: "15px",
            outline:"none",
            cursor:"pointer",
            "& .MuiCircularProgress-root":{
              padding:"0px 20px"
            }
          },

          accordion:{
            "& .MuiAccordionDetails-root":{
                justifyContent:"end",
                boxShadow:"none",
                display:"block"
            }
          }
    }))

    const classes=useStyles()

    const [loading,setLoading]=useState(false)
    const [error,setError]=useState('')

    const handle_download=async()=>{
      setLoading(true)
      setError("")
      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        params:{userId:localStorage.getItem("user_id")}
      };

      try{
        const response=await httpInstance(`${GET_REPORTS}`,httpConfig)
        window.open(`${BASE_URL}${response.data?.data}`, '_blank', 'noopener,noreferrer');
        setLoading(false)
      }
      catch(error){
        setLoading(false)
        setError(error.response.data?.message)
      }
    }
  return (
    <div>
            <Box
        display="flex"
        alignItems="start"
        px={{ xs: 1, md: 17 }}
        pt={2}
        style={{ borderBottom: "1px solid #E6E3E3",}}
      >
      
          <Typography className={classes.subTabtext}>
            My Profile
          </Typography>
       
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography
            className={classes.textUnderline}
          >
            Reports
          </Typography>
        </Box>
      </Box>
        <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        mt={3}
      >
        <Typography style={{fontSize:"1.2rem", fontWeight:500}}>Reports</Typography>

        <Box className={classes.box1} mt={2}>
            <Box p={2} style={{borderBottom:"1px solid #DDDDDD",}}>
            <Typography className={classes.header}>Mutual Funds</Typography>
            </Box>

            <Box className={classes.accordion}>
            <Accordion  >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                
                >
          <Typography style={{fontSize:"0.85rem"}}>Holding Reports</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" justifyContent="end">
             <button 
             className={classes.primaryButton} 
             onClick={handle_download}>
              {loading ? <CircularProgress size="1rem" color="inherit" /> : " Download"}
             
             </button>
          </Box> 
          <Box>
          {error && <ErrorMessage message={error}/>}
          </Box>
        </AccordionDetails>
      </Accordion>
            </Box>
                
        </Box>

        </Box>
    </div>
  )
}

export default Index