import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  CircularProgress,
  IconButton,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { GET_ALL_AMCS,ADD_AMCS } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import CustomToast from '../../../components/UI/CustomToast'


const AddAmcs = ({ show, handleClose,type }) => {
  const useStyles = makeStyles({
    root: {
      "& .MuiDialogContent-root": {
        fontSize: "0.8rem",
        lineHeight: "25px",
        padding: "20px 25px",
      },
      "& .MuiDialog-paperWidthSm": {
        minWidth: "50%",
        // maxWidth:"300px",
        maxHeight: "95%",
      },
    },
    dialogTitle: {
      //display: "flex",
      justifyContent: "center",
      alignItems: " center",
      //textAlign: "center",
      padding: "5px 10px 0px 25px",
      marginTop: "-10px",
      "& .MuiTypography-body1": {
        fontSize: "1.2rem",
        fontWeight: 600,
      },
    },
    closeIcon: {
      padding: "5px",
      justifyContent: "flex-end",
      cursor:"pointer"
    },
    text: {
      fontSize: "0.8rem",
    },
    textCancel: {
      fontSize: "0.8rem",
      cursor: "pointer",
    },
    textConform: {
      fontSize: "0.8rem",
      color: "#326CF9",
      cursor: "pointer",
    },
    boxBorder: {
      border: "1px solid #DDDDDD",
      borderRadius: "10px",
    },
    input: {
      border: "1px solid #DDDDDD",
      borderRadius: "10px",
      width: "100%",
      padding: "10px",
      fontFamily: "inherit",
    },
    primaryButton: {
      background: "#326CF9",
      color: "#fff",
      padding: "5px 1.5rem",
      border: "none",
      borderRadius: "20px",
      fontFamily: "inherit",
      boxShadow: "0px 2px 26px rgba(0, 0, 0, 0.15)",
      cursor: "pointer",
    },
    amcname: {
      fontSize: "0.9rem",
      color: "#686868",
    },
  });

  const classes = useStyles();

  const [allAmcs, setAllAmcs] = useState([]);
  const [filteredAmcs,setFilteredAmcs]=useState([])
  const [error,setError]=useState(false)
  const [amcCode,setAmcCode]=useState('')
  const [search,setSearch]=useState('')

  const handle_close = () => {
    handleClose();
  };

  const get_all_amcs = async () => {
   
    const httpConfig = {
      method: "GET",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      params:{name:""}
     
    };
    try {
      const response = await httpInstance(`${GET_ALL_AMCS}`, httpConfig);
      setAllAmcs(response.data?.data);
      setFilteredAmcs(response.data?.data)
    } catch {

    }
  };

  useEffect(() => {
    get_all_amcs();
  }, []);
  //-----------------------------search----------------------------------------//
  const handle_search=(e)=>{
    setSearch(e.target.value.toLowerCase())
  }

  useEffect(()=>{
    let arry=allAmcs?.filter((ele)=>{
      return ele.amcName.toLowerCase().includes(search)
    })
    setFilteredAmcs(arry)
  },[search])

  //-------------------------Add AMCS-------------------------------------//
  const add_amcs=async(code)=>{
    setError(false)
    setAmcCode(code)
    const httpConfig = {
        method: "POST",
        tokenType: "admin",
        headers: {
          "Content-Type": "application/json",
        },
        params:{type:type,amcCode:code}
      };
      try{
        await httpInstance(`${ADD_AMCS}`,httpConfig)
        CustomToast("AMC Added", "SUCCESS")
      }
      catch(error){
        setError(error.response.data?.message)
      }
  }
  return (
    <div>
      <Dialog className={classes.root} open={show} onClose={handle_close}>
        <Box
          display="flex"
          className={classes.closeIcon}
          onClick={handle_close}
        >
          <CloseIcon />
        </Box>

        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Box display="flex" justifyContent="center">
            <Typography className={classes.title}>Add AMCs</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box mt={1} p={3} className={classes.boxBorder}>
            <input className={classes.input} placeholder="Search AMCs" onChange={handle_search} />
            {filteredAmcs?.map((ele)=>{
                return(
                    <>
                    <Box
                    key={ele.id}
                    p={2}
                    className={classes.boxBorder}
                    mt={3}
                  >
                    <Grid container alignItems="center">
                      <Grid item xs={10}>
                        <Box display="flex">
                          <img
                                    src={ele.imgUrl}
                                    height="25px"
                                    width="25px"
                                    style={{ marginRight: "15px" }}
                                  />
                          <Typography className={classes.amcname}>
                            {ele.amcName}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={2} className={classes.switch}>
                        <Box display="flex" justifyContent="end">
                          <button className={classes.primaryButton} onClick={()=>add_amcs(ele.amcCode)}>Add</button>
                        </Box>
                      </Grid>
                    </Grid>
                    
                  </Box>
                   {error && ele.amcCode===amcCode && <ErrorMessage message={error}/>}
                   </>
                )
                
            })}
            
            </Box>
            
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddAmcs;
