import {
  GET_ADMIN_USER_DATA,
  UPLOAD_PIC,
    VERIFY_LOADING,
    VERIFY_ERROR
  
  } from "./userActionTypes";

  const userInitialState = {
    Adminuser:[],
    loading: false,
    error: null,
    
  };

  const AdminReducers = (state = userInitialState, action) => {
    switch (action.type) {
      case GET_ADMIN_USER_DATA: {
        
        return { ...state, Adminuser: action.payload, loading: false, error: null };
      }
      case UPLOAD_PIC: {
      
        return { ...state, Adminuser:{...state.Adminuser,profile_image:action.payload}, loading: false, error: null };
      }
      default: {
        return state;
      }
    }
  };
  
  export default AdminReducers;