import React, { useState } from "react";
import Step1 from "../../components/signup/complient/Step1";
import EmailOtp from "../../components/signup/complient/EmailOtp";
import Step2 from "../../components/signup/complient/Step2";
import PhoneOtp from "../../components/signup/complient/PhoneOtp";
import Step3 from "../../components/signup/complient/Step3";
import Step4 from "../../components/signup/complient/Step4";
import Step5 from "../../components/signup/complient/Step5";
import FatcaDetails from "../../components/signup/complient/FatcaDetails";
import Step6 from "../../components/signup/complient/Step6";
import Step7 from "../../components/signup/complient/Step7";
import Step8 from "../../components/signup/complient/Step8";
import Step9 from "../../components/signup/complient/Step9";
import Step10 from "../../components/signup/complient/Step10";
import Step11 from "../../components/signup/complient/Step11";
import Step12 from "../../components/signup/complient/Step12";
import Step13 from "../../components/signup/complient/Step13";
import Step14 from "../../components/signup/complient/Step14";
import UploadPan from "../../components/signup/nonComplient/UploadPan";
import ProofOfAddress from "../../components/signup/nonComplient/ProofOfAddress";
import Mpin from "../../components/signup/complient/Mpin";
import GenerateMpin from "../../components/signup/complient/GenerateMpin";

import PanVerification from "../../components/signup/nonComplient/PanVerification";
import TakePicture from "../../components/signup/nonComplient/TakePicture";
import TakeVideo from "../../components/signup/nonComplient/TakeVideo";
import AdharVerification from "../../components/signup/nonComplient/AadharVerification";

import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import {
  useNavigate,
  useLocation,
  useSearchParams,
  Link,
} from "react-router-dom";
import { namespace } from "../../environment";

function Complient() {
  const [step, setStep] = React.useState(1);
  const [descriptionData, setDescriptionData] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();

  const steps = searchParams.get("stepcount");

  const stepCount = (step) => {
    setStep(step);
  };

  const setData = (data) => {
    setDescriptionData({ ...descriptionData, ...data });
  };

  const useStyles = makeStyles((theme) => ({
    section1: {
      background: "#0C97F0",
      minHeight: "100vh",
      width: "30%",
      boxSizing: "border-box",
      position: "sticky",
      height: "100%",
    },
    sectionTwo: {
      height: "80vh",
      padding: "4rem 0",
      display: "flex",
      //alignItems: "center",
      //justifyContent: "center",
      flexDirection: "column",
      flex: 1,
      width: "70%",
      overflowY: "scroll",
      position: "sticky",
    },
    detailsBox: {
      margin: "0 auto",
    },
    headingOne: {
      fontSize: "1.6rem",
      color: "white",
      fontWeight: 400,
      padding: "0px 2rem",
    },
    headingTwo: {
      fontSize: "1.3rem",
      color: "#fff",
      fontWeight: 400,
      textAlign: "center",
    },
    headingThree: {
      fontSize: "0.9rem",
      color: "#fff",
      fontWeight: 400,
      textAlign: "center",
    },
    paragraph: {
      fontSize: "0.7rem",
      color: "white",
    },
    "@media (max-width: 480px)": {
      section1: {
        display: "none",
      },
    },
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="row" flex={1} height="100%">
        <Box className={classes.section1} py={4}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="space-between"
            justifyContent="space-around"
            height="100%"
            px={2}
          >
            <Box className={classes.headingOne} mb={6}>
              Investment <br /> made simple
            </Box>
            <img src="/images/Group 22509.svg" height="230" width="100%" />
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="center"
              flexDirection="column"
              className={classes.detailsBox}
            >
              <Box className={classes.headingTwo} mt={3} textAlign="center">
                Invest in <span style={{ color: "#6FDDB8" }}>Mutual Funds</span>
              </Box>
              <Box className={classes.headingThree} mt={2}>
                Bullsurge is secure and private
              </Box>
              <Link
                to="/terms-conditions"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box className={classes.paragraph} mt={1}>
                  Terms & Conditions *
                </Box>
              </Link>
            </Box>
          </Box>
        </Box>
        <Box className={classes.sectionTwo}>
          {/* {steps ===null ? <Step1 step={stepCount} setData={setDescriptionData}/> : ""}
          {steps === "1" ? <EmailOtp step={stepCount} descriptionData={descriptionData}/> : ""} */}
          {steps === "2" ? (
            <Step2 step={stepCount} setData={setDescriptionData} />
          ) : (
            ""
          )}
          {steps === "mpin" ? <Mpin setData={setDescriptionData} /> : ""}
          {steps === "mpin_generate" ? <GenerateMpin /> : ""}
          {steps === "3" ? (
            <PhoneOtp step={stepCount} descriptionData={descriptionData} />
          ) : (
            ""
          )}
          {steps === "4" ? (
            <Step3 step={stepCount} setData={setDescriptionData} />
          ) : (
            ""
          )}
          {/* ---------------complient flow------------------------------------------ */}

          {steps === "5" ? (
            <Step4
              step={stepCount}
              descriptionData={descriptionData}
              setData={setDescriptionData}
            />
          ) : (
            ""
          )}
          {steps === "6" ? <Step5 step={stepCount} /> : ""}
          {steps === "7" ? <FatcaDetails /> : ""}
          {steps === "8" ? <Step7 /> : ""}
          {steps === "9" ? <Step6 /> : ""}
          {steps === "10" ? <Step11 setData={setData} /> : ""}
          {steps === "11" ? <Step9 /> : ""}
          {steps === "12" ? <Step10 /> : ""}
          {steps === "13" ? <Step8 /> : ""}
          {steps === "14" ? <Step12 /> : ""}
          {steps === "17" ? <Step14 /> : ""}
          {/* //---------------------------non complient--------------------------------------------// */}

          {steps === "18" ? (
            <Step4
              title="Enter Pan Card Details"
              descriptionData={descriptionData}
              setData={setDescriptionData}
            />
          ) : (
            ""
          )}
          {steps === "19" ? <UploadPan /> : ""}
          {steps === "20" ? <ProofOfAddress /> : ""}
          {steps === "21" ? <TakePicture /> : ""}
          {steps === "22" ? <TakeVideo /> : ""}
          {steps === "23" ? <AdharVerification /> : ""}

          {/* 
          
         
           
         
          {step === 7 ? <Step7 step={stepCount} /> : ""}
          {step === 8 ? <Step8 step={stepCount} /> : ""}
          {step === 9 ? <Step9 step={stepCount} /> : ""}
          {step === 10 ? <Step10 step={stepCount} /> : ""}
          
          {step === 12 ? <Step12 step={stepCount} /> : ""}
          {step === 13 ? <Step13 step={stepCount} /> : ""}
          {step === 14 ? <Step14 step={stepCount} /> : ""} */}
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Complient;

{
  /* <Route path="/complient" element={<Complient />} />
        <Route path="/non-complient" element={<NonComplient />} />
        <Route path="/funds" element={<Funds />} /> */
}
