import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import ErrorMessage from "../../../../components/Validation/ErrorMessage";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SipDatePicker from "../../../../components/MutualFund/SipDatePicker";
import {
  GET_FUND_FP_DATA,
  CREATE_SWP,
  VERIFY_SWP_OTP,
} from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import moment from "moment";
import OTP from "../OTP";
import { namespace } from "../../../../environment";

const SwpDialog = ({
  show,
  onClose,
  schemeData,
  fundDetail,
  bankData,
  swpData,
}) => {
  const useStyles = makeStyles({
    root: {
      "& .MuiDialogContent-root": {
        fontSize: "0.8rem",
        lineHeight: "25px",
        padding: "20px 25px",
      },
      "& .MuiDialog-paperWidthSm": {
        minWidth: "30%",
        maxWidth: "300px",
        maxHeight: "100%",
      },
    },
    dialogTitle: {
      padding: "10px",
      "& .MuiTypography-body1": {
        fontSize: "1.3rem",
        fontWeight: 500,
      },
    },

    textgrey: {
      fontSize: "0.7rem",
      color: "#626262",
    },
    textBlue: {
      color: "#0C97F0",
    },
    inputBox: {
      background: "#E6E6E6",
      padding: "0.7rem 0.95rem",
      borderRadius: "10px",
      border: "none",
      width: "",
      fontFamily: "inherit",
      width: "-webkit-fill-available",
      marginBottom: "10px",
    },
    label: {
      fontSize: "0.7rem",
      fontWeight: 500,
    },
    primaryButton: {
      padding: "0.8rem 3rem",
      background: "#326CF9",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      fontFamily: "inherit",
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
    },
    "@media (max-width:600px)": {
      root: {
        "& .MuiDialog-paperWidthSm": {
          maxWidth: "90%",
        },
      },
    },
  });

  const classes = useStyles();

  const [date, setDate] = useState("");
  const [amount, setAmount] = useState("");
  const [installments, setInstallments] = useState("");
  const [comp, setComp] = useState("swp");
  const [id, setId] = useState("");
  const [error, setError] = useState(false);
  const [inputError, setInputError] = useState({
    amount: false,
    install: false,
    date: false,
  });
  const [loading, setLoading] = useState(false);
  const [loadVerify, setLoadVerify] = useState(false);
  const [otpError, setOtpError] = useState(false);

  const { isin } = useParams();
  const navigate = useNavigate();

  const handle_close = () => {
    setComp("swp");
    setAmount("");
    setInstallments("");
    onClose();
  };

  // const get_swp_data = async () => {
  //   const httpConfig = {
  //     method: "GET",
  //     tokenType: "user",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     params: { isin: isin },
  //   };
  //   try {
  //     const response = await httpInstance(`${GET_FUND_FP_DATA}`, httpConfig);
  //     setSwpData(response.data?.data);
  //   } catch {}
  // };

  // useEffect(() => {
  //   if (show) {
  //     get_swp_data();
  //   }
  // }, [show]);
  //--------------------------------handle Amount------------------------------------------//
  const handle_Amount = (e) => {
    e.target.value = e.target.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1")
      .toLowerCase();
    setAmount(e.target.value.replace(/^00+/, "0"));
    if (e.target.value < swpData?.minSwpAmount) {
      setInputError({
        ...inputError,
        amount: `Minimum SWP amount ${swpData?.minSwpAmount}`,
      });
    } else if (e.target.value > swpData?.maxSwpAmount) {
      setInputError({
        ...inputError,
        amount: `Maximum SWP amount ${swpData?.maxSwpAmount}`,
      });
    } else {
      setInputError({ ...inputError, amount: false });
    }
  };

  //----------------------------------handle Installments-----------------------------------//

  const handle_installments = (e) => {
    e.target.value = e.target.value
      .replace(/[^0-9]/g, "")
      .replace(/(\..*)\./g, "$1")
      .toLowerCase();
    setInstallments(e.target.value.replace(/^00+/, "0"));
    if (e.target.value < swpData?.minSwpInstallments) {
      setInputError({
        ...inputError,
        install: `Minimum installments ${swpData?.minSwpInstallments}`,
      });
    } else {
      setInputError({ ...inputError, install: false });
    }
  };

  //--------------------------------handle Date------------------------------------------------------//

  const handle_date = (date) => {
    let sip_date = date < 9 ? `0${date}` : date;
    let currentDate = moment().format("DD");
    let month_year;
    if (date < currentDate) {
      month_year = moment().add(1, "M").format("YYYY-MM");
    } else {
      month_year = moment().format("YYYY-MM");
    }
    setDate(`${month_year}-${sip_date}`);
    setInputError({ ...inputError, date: false });
  };

  //---------------------------------submit SWP--------------------------------------------------------//
  const confirm_SWP = async () => {
    if (!amount) {
      setInputError({ ...inputError, amount: `Please enter amount` });
    } else if (!installments) {
      setInputError({ ...inputError, install: "Please enter installments" });
    } else if (!date) {
      setInputError({ ...inputError, date: "Please select Date" });
    }
    if (amount && installments && date) {
      setInputError(false);
      setLoading(true);
      const formData = {
        userId: localStorage.getItem("user_id"),
        transactionTypeId: 6,
        fundAmount: amount,
        folioNumber: schemeData?.folioNumber,
        isin: schemeData?.isin,
        fundPlanId: fundDetail?.scheme_code,
        schemeName: fundDetail.scheme_name,
        imgUrl: fundDetail?.img_url,
        minSwpAmount: swpData?.minSwpAmount,
        maxSwpAmount: swpData?.maxSwpAmount,
        numberOfInstallments: installments,
        frequency: "monthly",
        startDate: date,
        endDate: null,
        swpAllowed: swpData?.isSwpAllowed,
      };
      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      try {
        const response = await httpInstance(`${CREATE_SWP}`, httpConfig);
        setId(response.data?.data);
        setComp("otp");
      } catch {
        setLoading(false);
        setError(error.response.data?.message);
      }
    }
  };

  //--------------------------------verify OTP--------------------------------------------------------//

  const verify_otp = async (otp) => {
    setLoadVerify(true);
    setOtpError(false);
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { id: id, otp: otp },
    };

    try {
      await httpInstance(`${VERIFY_SWP_OTP}`, httpConfig);
      navigate(`${namespace}/mutualfund/swp-success`, {
        state: {
          fromFund: { name: fundDetail?.scheme_name, img: fundDetail?.img_url },
          bankData: bankData,
        },
      });
    } catch (error) {
      handle_otp_error(error.response.data?.message);
    }
  };

  //-----------------handle OTP ERROR-------------------------------------//

  const handle_otp_error = (error) => {
    setLoadVerify(false);
    setOtpError(error);
  };

  return (
    <div>
      <Dialog className={classes.root} open={show}>
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Grid container>
            <Grid item xs={11}>
              <Box display="flex" justifyContent="center">
                <Typography className={classes.title}>Start SWP</Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box display="flex" justifyContent="end">
                <CloseIcon
                  onClick={handle_close}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {comp == "swp" && (
            <>
              <Box px={2}>
                <label className={classes.label}> Monthly SWP Amount</label>
                <input
                  className={classes.inputBox}
                  type="text"
                  onChange={handle_Amount}
                  value={amount}
                />
                {inputError.amount && (
                  <ErrorMessage message={inputError.amount} />
                )}
                <label className={classes.label}>Installments</label>
                <input
                  className={classes.inputBox}
                  type="text"
                  onChange={handle_installments}
                  value={installments}
                />
                {inputError.install && (
                  <ErrorMessage message={inputError.install} />
                )}
                <SipDatePicker
                  label="Date"
                  placeholder="Select SWP Date"
                  dates={swpData?.swpFrequencyData}
                  handle_sipDate={handle_date}
                />
                {inputError.date && <ErrorMessage message={inputError.date} />}
              </Box>
              <Box px={{ xs: 2, md: 14 }}>
                {error && <ErrorMessage message={error} />}
                {/* {inputError  && <ErrorMessage message={inputError}/> } */}
              </Box>

              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                mt={5}
              >
                <Typography className={classes.textgrey}>
                  By continuing, you agree to{" "}
                  <span className={classes.textBlue}>Terms and Conditions</span>
                </Typography>
                <button className={classes.primaryButton} onClick={confirm_SWP}>
                  {loading ? (
                    <CircularProgress size="1rem" color="inherit" />
                  ) : (
                    "Confirm SWP"
                  )}
                </button>
              </Box>
            </>
          )}

          {comp == "otp" && (
            <OTP
              verify_otp={verify_otp}
              id={id}
              transactionTypeId={4}
              handle_otp_error={handle_otp_error}
              otpError={otpError}
              loadVerify={loadVerify}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SwpDialog;
