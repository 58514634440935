import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import SliderBar from "../SliderBar";

function FirstSection({handleShowModal}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundImage: "url('./images/Comp.svg')",
      background: "#fff",
      padding: "0rem 0rem 0rem 0rem",
      backgroundPosition: "left",
      backdropFilter: "blur(10px)"
    },
    backDrop: {
      padding: "8rem 0rem 4rem 0rem",
      background: "transparent",
      backdropFilter: "blur(10px)"
    },
    title1: {
      fontSize: "2rem",
      fontWeight: 700,
      color: "#2c2c2c",
      marginBottom: "1rem"
    },
    heading: {
      fontSize: "2rem",
      fontWeight: "medium",
      color: "#2c2c2c",
      marginBottom: "1rem"
    },
    description: {
      color: "#2c2c2c",
      fontWeight: "regular",
      fontSize: "1rem",
      lineHeight: "24px"
    },
    paragraph: {
      fontSize: "1rem",
      fontWeight: "normal",
      color: "#2c2c2c"
    },
    boxShadowSection: {
      boxShadow: "none"
    },
    cursor:{
      cursor:"pointer"
    },
    "@media (max-width: 900px)": {
      root: {
        backgroundImage: "url(./images/Component4.svg)",
        backgroundRepeat: "no-repeat"
      },
      title1: {
        fontSize: "1.3rem",
        textAlign: "center"
      },
      heading: {
        fontSize: "1.2rem",
        textAlign: "center"
      },
      description: {
        fontSize: "1rem"
      },
      backDrop: {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "center"
      },
      imageContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        marginBottom: "2rem"
      },
      container: {
        width: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "center"
      }
    }
  }));
  const classes = useStyles();

  const [step, setStep] = React.useState(1);
  const [time, setTime] = React.useState(Date.now());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setStep((prevCount) => prevCount + 1);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (step === 3) {
    setStep(1);
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Box pb={10}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Box className={classes.title1}>Start Investing today with</Box>
            <Box className={classes.title1}>India’s Leader in Digital Gold</Box>
          </Box>
          <Container maxWidth="lg">
            {step === 1 ? (
              <Grid container>
                <Box
                  className={classes.backDrop}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  width="100%"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="start"
                    justifyContent="flex-start"
                    width="50%"
                    className={classes.container}
                  >
                    <Box className={classes.heading}>
                      100% Purity Guaranteed
                    </Box>
                    <Box className={classes.description}>
                      With our patner Augmont, you buy directly
                      <br /> from the manufacturers which ensures <br />
                      purity and safety
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      mt={3}
                      className={classes.cursor}
                      onClick={handleShowModal}
                    >
                      <Box mr={3} style={{fontWeight:500}}>
                        Learn more
                      </Box>
                      <img
                        src="/images/Group 21264.svg"
                        alt="BullSurge"
                        height={25}
                        width={25}
                      />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    width="50%"
                    className={classes.imageContainer}
                  >
                    <Box className={classes.boxShadowSection}>
                      <img
                        src="/images/Purity Check.svg"
                        alt="BullSurge"
                        height={260}
                        width={260}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ) : (
              ""
            )}
            {step === 2 ? (
              <Grid container>
                <Box
                  className={classes.backDrop}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  width="100%"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="start"
                    justifyContent="flex-start"
                    width="50%"
                    className={classes.container}
                  >
                    <Box className={classes.heading}>Get Delivery of Gold</Box>
                    <Box className={classes.description}>
                      Get your gold delivered to your home in <br />
                      forms of coins or jewellery with our trusted partners
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      mt={3}
                      className={classes.cursor}
                      onClick={handleShowModal}
                    >
                      <Box mr={3} style={{fontWeight:500}}>Learn more</Box>
                      <img
                        src="/images/Group 21264.svg"
                        alt="BullSurge"
                        height={25}
                        width={25}
                      />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    width="50%"
                    className={classes.imageContainer}
                  >
                    <Box className={classes.boxShadowSection}>
                      <img
                        src="/images/Delivery.svg"
                        alt="BullSurge"
                        height={260}
                        width={260}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ) : (
              ""
            )}
          </Container>
          <Box mt={1}>
            <SliderBar step={step} bar={2} />
          </Box>
        </Box>
      </div>
    </React.Fragment>
  );
}

export default FirstSection;
