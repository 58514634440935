import React,{useState,useEffect} from 'react'
import { Box,makeStyles,Typography,Grid,Select,MenuItem,FormControl,Checkbox} from '@material-ui/core'
import { PieChart, Pie, Cell, ResponsiveContainer,Legend,Sector} from 'recharts';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomSelect from '../../../components/Admin/CustomSelect';
import {GET_TOTAL_INVESTMENT,GET_TOTAL_THEMATIC} from "../../../utils/endpoints"
import httpInstance from "../../../utils/instance"
import localeString from '../../../hooks/LocaleString';

const TotalInvest = () => {
    const useStyles = makeStyles({
        root: {
            background: "#fff",
            borderRadius: "20px",
          },
          header:{
            color:"#686868",
            fontSize:"1rem",
            fontWeight:600,
            padding:"0px 10px"
        },
        title:{
            color:"#0C97F0"
        },
        select: {
          fontSize: "0.85rem",
          color: "black",
          padding: "3px 0px",
          borderRadius: "10px",
         
          minWidth:"20rem",
          "& .MuiSelect-select": {
            paddingTop:"10px ",
            paddingRight: "40px",
            paddingLeft: "10px",
            border:"1px solid #DDDDDD",
            borderRadius:"5px",
            "&:focus": {
              backgroundColor: "#fff",
            },
          },
          "& .MuiSvgIcon-root": {
            color: "black",
          },
          "& .MuiMenuItem-root":{
            fontSize: "0.85rem !important",
          }
        },
        menu:{
          boxShadow:"none",
          border:"1px solid #DDDDDD",
        },
        menuItem:{
          fontSize: "0.85rem !important",
        },
        LegendList: {
          //display: "grid",
          gridTemplateColumns: "1fr 1fr",
          listStyle: "none" /* Remove default bullets */,
          "& li": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            margin: "5px",
          },
        },
        cirecleBullet: {
          borderRadius: "50%",
          minWidth: "15px",
        },
        bulletLabel: {
          display: "flex",
          alignItems: "center",
          wordBreak: "break-all",
        },
        subTitle: {
          fontWeight: 600,
          padding: "0px 3rem",
        },
    
    })
    const classes = useStyles();
//-------------------------------custom legend---------------------------------------------//
    const Bullet = ({ backgroundColor, size }) => {
      return (
        <div
          className={classes.cirecleBullet}
          style={{
            backgroundColor,
            width: size,
            height: size,
          }}
        ></div>
      );
    };

     const CustomizedLegend = (props) => {
      const { payload } = props;
  
      return (
        <div>
          <ul className={classes.LegendList}>
            {payload.map((entry, index) => (
              <li key={index}>
                <Grid container>
                  <Grid item xs={8}>
                    <div className={classes.bulletLabel}>
                      <Bullet backgroundColor={entry.payload.fill} size="15px" />
  
                      <div style={{ marginLeft: "10px", marginRight: "20px" }}>
                        {entry.value}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                  ₹ {localeString(entry.payload.value)}
                  </Grid>
                </Grid>
              </li>
            ))}
          </ul>
        </div>
      );
    };

    const [totalInvest,setTotalInvest]=useState(null)
    const [thematic,setThematic]=useState(null)
    const [totalAmount,setTotalAmount]=useState()
    const [duration,setDuration]=useState(0)
    const [typefilter,setTypeFilter]=useState("all")
    const [catFilter,setCatFilter]=useState([])

    const get_total_invets=async()=>{
      const httpConfig = {
        method: "POST",
        tokenType: "admin",
        headers:{
          "Content-Type": "application/json",
        },
        params:{duration:duration}
      };

      try{
        const response=await httpInstance (`${GET_TOTAL_INVESTMENT}`,httpConfig)
        const response1=await httpInstance (`${GET_TOTAL_THEMATIC}`,httpConfig)
        let data=response.data?.data
        setTotalAmount(data.diy+data.assisted)
        setTotalInvest([
          {name:"DIY",value:data.diy},
          {name:"Assisted",value:data.assisted}
        ])
        setThematic([
          {name:"DIY",value:data.diy},
          {name:"Thematic",value:response1.data?.data?.thematic},
          {name:"Non Thematic",value:response1.data?.data?.nonthematic},
        ])
      }
      catch{

      }
    }

    useEffect(()=>{
      get_total_invets()
    },[duration])

    // useEffect(()=>{
    //   if(typefilter=="DIY"){
    //     setTotalAmount(totalInvest[0].value)
    //   }
    //   if(typefilter=="Assisted"){
    //     setTotalAmount(totalInvest[1].value)
    //   }

    // },[typefilter,catFilter,duration])
    

    const data = [
        { name: 'Group A', value: 800 },
        { name: 'Group B', value: 300 },
        
      ];

    const type=[
      //{value:"all",name:"All"},
      {value:"DIY",name:"DIY"},
      {value:"Assisted Services",name:"Assisted Services"},
      

    ]
    
    const category=[
      //{value:"all",name:"All"},
      {value:"Thematic",name:"Thematic"},
      {value:"Non Thematic",name:"Non Thematic"},
     
    ]

    const timeline=[
      {name:"Overall",value:"2"},
      {name:"Today",value:"1"},
      {name:"This Week",value:"7"},
      {name:"This Month",value:"30"},
      {name:"Last Year",value:"365"}
    ]

      const handleChange = (data) => {
        // const {
        //   target: { value },
        // } = event;
        // setType(
        //   // On autofill we get a stringified value.
        //   typeof value === 'string' ? value.split(',') : value,
        // );
     };

     const handle_duration=(data)=>{
      if(data=="2"){
        setDuration(0)
      }else{
        setDuration(data)
      }
      
     }

     //--------------handle type filter------------------------------------//
     const handle_typefilter=(data)=>{
        if(data.length===type.length){
          setTypeFilter("all")
        }else{
          setTypeFilter(data[0])
        }
     }
    
//--------------------------handle thematic filter----------------------------------------//
     const handle_category=(data)=>{
      if(data.length===category.length){
        setCatFilter("all")
      
     }else{
      setCatFilter(data[0])
     }
    }
  return (
    <div>
        <Box className={classes.root} my={3} p={3}>
        <Box display="flex">
            <img src="/images/admin/user.svg" height="20px" width="15px" />
            <Typography className={classes.header}>
                Total Investments :  <span className={classes.title}> ₹ {localeString(totalAmount)}</span>
            </Typography>
          </Box>
          <Grid container>
            <Grid item xs={8}>
            <ResponsiveContainer width={350} height={400}>
        <PieChart width={400} height={400}>
        <Legend
                content={<CustomizedLegend />}
                
                align="left"
                width="100%"
              />
          <Pie
            data={catFilter ? thematic :totalInvest}
            cx="50%"
            cy="50%"
            //labelLine={false}
            //outerRadius={130}
            fill="#8884d8"
            dataKey="value"
            isAnimationActive={true}
                       
          >
               <Cell   fill="#CAEAFF" 
               style={{
                filter: (typefilter=="DIY"|| typefilter=="all") &&`drop-shadow(0px 0px 5px #0C97F0`
                 
              }}
              
              stroke={(typefilter=="DIY"|| typefilter=="all") ? "#4479F3 " : "white"}
              strokeWidth={(typefilter=="DIY"|| typefilter=="all")? 2 :1} 
               />
              <Cell  
              style={{
                filter: ((typefilter=="Assisted Services" || typefilter==="all") || (catFilter=="Thematic" || catFilter=="all")) && `drop-shadow(0px 3px 5px #0C97F0`
              }}
              fill="#0C97F0"

              stroke={(typefilter=="Assisted Services" || typefilter==="all") || (catFilter=="Thematic" || catFilter=="all") ? "#4479F3 " : "white"}
              strokeWidth={(typefilter=="Assisted Services" || typefilter==="all") || (catFilter=="Thematic" || catFilter=="all") ? 2 :1} 
              />

            <Cell  
              style={{
                filter: (catFilter=="Non Thematic" || catFilter== "all")&& `drop-shadow(0px 3px 5px #0C97F0`
              }}
              fill="#48D4A5" 
              stroke={(catFilter=="Non Thematic" || catFilter== "all") ? "#4479F3 " : "white"}
              strokeWidth={(catFilter=="Non Thematic" || catFilter== "all") ? 2 : 1} 
              />

             
             
           
          </Pie>
          
        </PieChart>
      </ResponsiveContainer>
      
            </Grid>
            <Grid item xs={4}>
              <CustomSelect 
                handleSelect={handle_typefilter} 
                type={type}
                placeholder="DIY/Assisted Services/All"
                options={type}
                isToggle={false}
                />
               {typefilter!="DIY" && 
                <Box mt={4}>
                <CustomSelect 
                handleSelect={handle_category} 
                type={type}
                placeholder="Categorizations"
                options={category}
                isToggle={false}
                />
                </Box>
                  }
                <Box mt={4}>
                <CustomSelect 
                handleSelect={handle_duration} 
                type={type}
                placeholder="Timeline"
                options={timeline}
                isToggle={true}
                />
                </Box>
            </Grid>


          </Grid>
          </Box>

    </div>
  )
}

export default TotalInvest