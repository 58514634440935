import React from 'react'
import AllocationManagement from '../../containers/Admin/AllocationManagement'

const Allocation_management = () => {
  return (
    <div>
        <AllocationManagement/>
    </div>
  )
}

export default Allocation_management