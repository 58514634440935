import React from 'react'
import {
    Box,
    Typography,
    makeStyles,
    Container
  } from "@material-ui/core";
import { Subtitles } from '@mui/icons-material';

const About = () => {
    const useStyles = makeStyles((theme) => ({
        header:{
            fontSize:"1.5rem",
            fontWeight:500
        },

        text:{
            fontSize:"0.9rem"
        },

        subtitles:{
            fontSize:"1.1rem",
            fontWeight:500
        }
    }))

    const classes=useStyles()
  return (
    <div>
        <Container>
            <Typography className={classes.header}>ABOUT US</Typography>

            <Box my={2}>
                <Typography className={classes.text}>Augmont was incorporated in 2013 with the vision to provide a seamless, integrated offering to business and retail customers for anything related to gold. The idea was to leverage the power of technology combined with the strength of gold as an investment as well as a consumer good. Innovation is the bedrock of growth for Augmont. Whether it's innovation in processes, product design, or distribution, Augmont has been delivering more and efficiently to its customers.
                </Typography>
            </Box>

            <Typography className={classes.header}>AUGMONT GOLD FOR ALL</Typography>
            <Box my={2}>
                <Typography className={classes.text}>Augmont "GOLD FOR ALL" is a revolutionary Goldtech ecosystem to make Gold accessible, affordable, useful, and manageable for all phases of a customer’s life. It is the one-stop destination for gold and silver. Augmont has derived its name from the combination of the words Au and Ag, the chemical symbols for 'Gold' and 'Silver' respectively. The word “Augment” means to increase or to make something greater by adding to it.

                </Typography>
            </Box>
            <Box my={2}>
                <Typography className={classes.text}>Augmont Gold For All, products aims to touch every aspect of a customer’s life. Gold should be seen as a life-enabling companion for life. To this Augmont’s constant effort is to make bigger and more revolutionary contributions in the value chain of precious metal. Augmont’s unique DNA makes it our responsibility to revolutionize the entire gold ecosystem and help people transform their dreams into reality via easy access to gold.


                </Typography>
            </Box>

            <Box my={2}>
                <Typography className={classes.text}>It is a ‘Phygital’ business model through which we sell various gold products like Gold Loan, Digi Gold, EMI Gold, Sell Old Gold, and many more through our deeply entrenched jeweller franchise network, doorstep delivery, and our digital platform (web and app).
              </Typography>
            </Box>

            <Box my={2}>
                <Typography className={classes.text}>With Augmont Gold For All, we have simplified gold and the process across its lifecycle. Our gold tech ecosystem is aimed to make gold a life enabler for our consumers as well as for our jeweller partners.
              </Typography>
            </Box>

            <Typography className={classes.header}>VISION</Typography>
            <Box my={2}>
                <Typography className={classes.text}>Glittering a billion lives through the power of Gold
              </Typography>
            </Box>

            <Typography className={classes.header}>MISSION</Typography>
            <Box my={2}>
                <Typography className={classes.text}>To make Gold a life enabler for all our stakeholders
                </Typography>
            </Box>

            <Box mt={3}>
                <Typography className={classes.subtitles}>LEADING PIONEERS IN GOLD IN INDIA</Typography>
            </Box>

            <Box mt={2}>
                <Typography className={classes.subtitles}>Among India’s most reputed refinery & bullion companies with deep inroads in the close-knit jeweller community
            </Typography>
            </Box>
            <ul className={classes.text}>
                <li>Ability to financially hedge with the most efficient and effective price discovery</li>
                <li>Physically deliver across exchanges, ETFs, and other channels</li>
                <li>Capable of distributing bullion from 0.1 grams coins to 1 kg bars across India</li>
                <li>More flexible and adaptive to changes in government policies</li>
                <li>Operates at lower costs than international refineries with the same level of efficiency, if not better</li>
            </ul>

            <Box mt={2}>
                <Typography className={classes.subtitles}>Scale of Operations - Among the largest
                 </Typography>
            </Box>
            <ul className={classes.text}>
                <li>Leading Gold Refinery in India with annual sales surpassing US$ 3 bn</li>
                <li>Leading creator and redeemer of gold exchange-traded fund (ETF) units in India on all gold ETF schemes</li>
            </ul>

            <Box mt={2}>
                <Typography className={classes.subtitles}>ETF schemes
                 </Typography>
            </Box>
            <ul className={classes.text}>
                <li>Leading delivery provider of gold on commodity exchanges in India</li>
            </ul>

            <Box my={3}>
                <Typography className={classes.header}>ACCREDITATIONS AND ACCOMPLISHMENTS</Typography>
                
                <ul className={classes.text}>
                    <li>Augmont’s prices are used as the most common reference prices across India</li>
                    <li>The refinery is accredited by BIS and NABL</li>
                    <li>Awarded as the best platform and leading refinery year on year since 2009</li>
                    <li>"India Good Delivery” member for NSE and MCX</li>
                </ul>
            </Box>

            <Box>
                <Typography className={classes.subtitles}>THE TRAILBLAZERS</Typography>
                <Box my={2}>
                <Typography className={classes.text}>Augmont has created an extremely strong brand name in the retail space and as well among the jewellers (fraternity). The brand is the preferred partner among the retail and merchants due to the inevitable ecosystem developed that covers the entire value chain.
              </Typography>
            </Box>
            <Typography className={classes.text}>Augmont has been at the forefront of innovation
              </Typography>
              <ol className={classes.text}>
               <li>
               SPOT – World’s largest and India’s first physical gold/silver/platinum platform since 2008
               </li>
               <li>Bullion ++ - Borrowing & Lending of Gold</li>
               <li>Bullion India – India’s pioneer digital gold platform (rebranded as Augmont Digi-gold)</li>
               <li>OCDs- India’s first Optionally Convertible Debentures for commodities markets</li>
               <li>Bullion Futures - Instrumental in successfully devising delivery based contracts for gold & silver on commodity exchanges</li>
               <li>ETFs - World’s first Gold ETF was conceptualized by Augmont as Paper Gold in 2002 and was filed with SEBI.</li>
               <li>EMI Jewellery - World's largest investible jewelry product, in tamper-proof packaging. We are the pioneers in making Gold accessible to all.</li>
              </ol>
            </Box>
            <Box mt={2}>
                <Typography className={classes.text}>
                Augmont’s innovations have helped the jewellers in addressing the defaults and frauds of bullion brokers. Transparent pricing has saved 100s of crores of Rupees for jewellers/end consumers while buying gold.

                </Typography>
            </Box>
        </Container>
        
        
    </div>
  )
}

export default About