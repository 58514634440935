import React,{useEffect,useState} from 'react'
import {
    Box,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    IconButton,
    makeStyles,
    Typography,
    Select,
    MenuItem,
    CircularProgress
  } from "@material-ui/core";
  import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
  import CloseIcon from "@mui/icons-material/Close";
  import {GET_ALL_STATE,GET_CITY_BY_STATEID,ADD_DELIVERY_ADDRESS} from '../../../utils/endpoints'
  import httpInstance from '../../../utils/instance'
  import { useForm,Controller } from "react-hook-form";
  import ErrorMessage from '../../../components/Validation/ErrorMessage'
 


const AddAddress = ({dialog,closeDialog,get_address}) => {
    const useStyles = makeStyles((theme) => ({
        closeIcon: {
          padding: "5px",
          justifyContent: "flex-end",

        },
    
        title: {
          fontSize: "1.1rem",
          fontWeight: 500,
          textAlign: "center",
          fontFamily:"inherit"
        },
        dialogTitle: {
          display: "flex",
          justifyContent: "center",
          alignItems: " center",
          textAlign: "center",
          padding: "5px",
        },
        dialogPaper: {
          maxWidth: "23rem",
          justifyContent: "center",
        },
        image: {
          textAlign: "center",
        },
        text: {
          fontSize: "0.8rem",
          textAlign: "center",
          fontFamily:"inherit",
          marginTop:"10px"
        },
        primaryButton: {
          padding: "0.8rem 0.3rem",
          background: "#326CF9",
          color: "#fff",
          fontSize: "0.8rem",
          fontWeight: 600,
          borderRadius: "45px",
          border: "none",
          cursor: "pointer",
          width: "100%",
        },
        dialogContent: {
          padding: "8px 30px",
        },

        inputBox: {
            background: "#E6E6E6",
            padding: "0.6rem 0.6rem",
            borderRadius: "10px",
            border: "none",
            fontFamily:"inherit",
            width:"90%",
            marginBottom:"10px"
          },

          textarea:{
            background: "#E6E6E6",
            padding: "0.6rem 0.6rem",
            borderRadius: "10px",
            border: "none",
            fontFamily:"inherit",
            width:"90%",
            resize: "none",
            height:"4rem"
            
          },
          select: {
           
            borderRadius: "10px",
            background: "#E6E6E6",
            marginTop:"2px",
            marginBottom:"10px",
            "& .MuiSelect-select": {
              paddingRight:"40px",
              minWidth:"15.8rem",
              "&:focus": {
                backgroundColor: "transparent",
              },
            },
            "& .MuiSvgIcon-root": {
              fontSize:"1.5rem"
            },
            "& .MuiSelect-icon":{
              top:4,
              
            },
            "& .MuiInputBase-input":{
                padding:"10px 15px"
            },
            "& .MuiSelect-selectMenu":{
                fontSize:"0.8rem"
            },
            "& .MuiMenuItem-root":{
                fontSize:"0.8rem" 
            },
            line:{
              border: "1px solid red"
            }
          },

          menuItem:{
              "& .MuiMenuItem-root":{
                  fontSize:"0.9rem"
              }
          },
          "@media (max-width:600px)":{
            dialogPaper:{
                margin:"0px"            
            },
           
          }
      }));
    
      const classes = useStyles();
      const { register, handleSubmit, formState: { errors },reset,control } = useForm()

      const [allStates,setAllStates]=useState([])
      const [allCities,setAllCities]=useState([])
      const [spinner,setSpinner]=useState(false)

      useEffect(()=>{
        reset({
            fullName:"",
            pincode:'',
            address:'',
            mobileNumber:''
        })
      },[dialog])

      useEffect( async()=>{
        const httpConfig = {
            method: "GET",
            tokenType: "user",
            headers:{
              "Content-Type": "application/json",
            },
          }

          try{
            const response = await httpInstance(`${GET_ALL_STATE}`,httpConfig)
            setAllStates(response.data?.data)
          }
          catch{

          }

          
      },[])

      const handleStateChange= async(e)=>{
        const httpConfig = {
            method: "GET",
            tokenType: "user",
            headers:{
              "Content-Type": "application/json",
            },
            params:{state_id:e.target.value}
          }

          try{
            const response = await httpInstance(`${GET_CITY_BY_STATEID}`,httpConfig)
            setAllCities(response.data?.data)
          }
          catch{

          }
      }
      const handle_submit= async(data)=>{
          setSpinner(true)
        const formData={
            fullName:data.fullName,
            address:data.address,
            pincode:data.pincode,
            cityId:data.city,
            mobileNumber:data.mobileNumber

        }
        const httpConfig = {
            method: "POST",
            tokenType: "user",
            headers:{
              "Content-Type": "application/json",
            },
            data:formData
          }

          try{
            await httpInstance(`${ADD_DELIVERY_ADDRESS}`,httpConfig)
            setSpinner(false)
            closeDialog()
            get_address()
          }
          catch{
            setSpinner(false)
          }
      }


  return (
    <div>
         <Dialog
        open={dialog}
        onClose={closeDialog}
        classes={{ paper: classes.dialogPaper }}
      >
        <Box display="flex" className={classes.closeIcon}>
          {" "}
          <CloseIcon onClick={closeDialog} style={{cursor:"pointer"}}/>
        </Box>

        <DialogTitle
          classes={{ paper: classes.dialogPaper, root: classes.dialogTitle }}
        >
          <Typography className={classes.title}>Add New Address</Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
            
            <form onSubmit={handleSubmit(handle_submit)}>
            <label className={classes.text}>FullName</label>
              <input
               className={classes.inputBox}
               {...register("fullName",{
                   required:true,
                   pattern:/^(?=(?:^\w))([A-Za-z. ]+)$/
                
                })}
              />
              {errors.fullName && errors.fullName.type=="required" && <ErrorMessage message="Enter Fullname"/>}
              {errors.fullName && errors.fullName.type=="pattern" && <ErrorMessage message="Enter Valid Name"/>}
              <label className={classes.text}>Address</label>
              <textarea
               className={classes.textarea}
               {...register("address",{
                   required:true,
                   minLength:10,
                   maxLength:100
                
                })}
                ></textarea>
            
               {errors.address &&  errors.address.type=="required" && <ErrorMessage message="Enter Address"/>}
               {errors.address && errors.address.type=="minLength" && <ErrorMessage message="Minimum 10 characters required"/>}
               {errors.address && errors.address.type=="maxLength" && <ErrorMessage message="Maximum 100 characters are allowed"/>}
              <label className={classes.text}>State</label> <br/>
              
              <Select 
               className={classes.select}
                disableUnderline
                IconComponent={KeyboardArrowDownIcon}
                 displayEmpty
                 MenuProps={{
                    style: {
                        maxHeight: 350,                      
                      },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                 {...register("state",{required:true, onChange:(e)=>handleStateChange(e)})} 
              >
                  {allStates?.map((ele)=>{
                      return(
                        <MenuItem key={ele.stateId} value={ele.stateId} style={{fontSize:"0.8rem"}}>
                        {ele.name}
                      </MenuItem>
                      )
                  })}
                  
              </Select><br/>
              {errors.state && <ErrorMessage message="Select state"/>}
              <label className={classes.text}>City</label> <br/>
              <Select 
               className={classes.select}
                disableUnderline
                IconComponent={KeyboardArrowDownIcon}
                 displayEmpty
                 MenuProps={{
                    style: {
                        maxHeight: 350,                      
                      },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                 {...register("city",{required:true})}
                >
                    {allCities?.map((ele)=>{
                        return (
                            <MenuItem key={ele.cityId} value={ele.cityId} className={classes.menuItem} style={{fontSize:"0.8rem"}}>
                                   {ele.name}
                            </MenuItem>
                        )
                    })}
                    
              </Select>
              {errors.city && <ErrorMessage message="Select city"/>}
              <label className={classes.text}>PinCode</label>
              <input
               className={classes.inputBox}
               {...register("pincode",{
                   required:true,
                   validate: (value) => value > 100000 &&  value < 999999,
                })}
              />
               {errors.pincode && <ErrorMessage message="Enter Valid Pincode"/>}
              <label className={classes.text}>Mobile Number</label>
              <input
               className={classes.inputBox}
               {...register("mobileNumber",{
                   required:true,
                   pattern:/^(?=(?:^\w))([0-9]+)$/,
                   minLength:10,
                   maxLength:10
                })}
              />
              {errors.mobileNumber &&  errors.mobileNumber.type=="required" && <ErrorMessage message="Enter Mobile Number"/>}
              {errors.mobileNumber &&  (errors.mobileNumber.type=="pattern" ||  errors.mobileNumber.type=="minLength" ||  errors.mobileNumber.type=="maxLength") &&<ErrorMessage message="Enter Valid Mobile Number"/>}
              <Box my={3}>
            <button
              className={classes.primaryButton}
              type="submit"
             
            >
                {spinner ? <CircularProgress size="1rem" color="white"/> : "Add" }
              
            </button>
          </Box>
          </form>
          
          
          
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default AddAddress