import React, { useEffect, useState } from "react";
import { useStyles } from "../styles";
import { Box } from "@material-ui/core";
import TitleLayoult from "../TitleLayoult";
import httpInstance from "../../../utils/instance";
import { GET_USER, MPIN_LOGIN, FORGOT_MPIN } from "../../../utils/endpoints";
import ErrorMessage from "../../Validation/ErrorMessage";
import { useNavigate, createSearchParams } from "react-router-dom";
import OtpInput from "react-otp-input";
import { namespace } from "../../../environment";

function Mpin({ setData }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [mpin, setMpin] = useState("");
  const [mpinError, setMpinError] = useState("");

  const handleChange = (code) => setMpin(code);

  useEffect(async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await httpInstance(`${GET_USER}`, httpConfig);
      setPhoneNumber(response.data?.data?.mobileNumber);
    } catch {}
  }, []);

  //-------------------------------------SUBMIT OTP----------------------------------------------------------------------//
  const handleMpinSubmit = async (e) => {
    e.preventDefault();
    if (!mpin) {
      setMpinError("Please enter your MPIN");
    } else {
      setMpinError("");
      const formData = {
        mobileNumber: phoneNumber,
        mpin: mpin,
      };

      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      try {
        const response = await httpInstance(`${MPIN_LOGIN}`, httpConfig);
        sessionStorage.setItem("mpin", true);
        localStorage.setItem("mpin", true);
        const fund_id = localStorage.getItem("fund_id");
        if (fund_id) {
          navigate(`${namespace}/mutualfund/scheme-code/${fund_id}`);
        } else {
          navigate(`${namespace}/dashboard/mutualfund/diy`);
        }
        // navigate(-3);
      } catch (error) {
        sessionStorage.removeItem("mpin");
        setMpinError(error.response.data?.message);
      }
    }
  };

  const handle_forgotMpin = async () => {
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await httpInstance(`${FORGOT_MPIN}`, httpConfig);
      setData({ mobile: response.data?.message?.split(" ").pop() });
      navigate({
        pathname: `${namespace}/signIn`,
        search: `?${createSearchParams({ stepcount: "3", reset: "mpin" })}`,
      });
    } catch {}
    //navigate("/signIn?stepcount=2")
  };

  return (
    <div>
      <Box display={"flex"} justifyContent="center">
        <Box
          display={"flex"}
          alignItems="flex-start"
          flexDirection={"column"}
          p={3}
        >
          <Box>
            <TitleLayoult title="Please Enter your MPIN" />
          </Box>
          <div>
            <form onSubmit={handleMpinSubmit}>
              <OtpInput
                value={mpin}
                onChange={handleChange}
                numInputs={4}
                separator={<span style={{ width: "25px" }}></span>}
                isInputNum={true}
                isInputSecure={true}
                shouldAutoFocus={true}
                inputStyle={{
                  border: "none",
                  borderRadius: "8px",
                  width: "40px",
                  height: "35px",
                  fontSize: "13px",
                  color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                  background: "#E6E6E6",
                }}
                focusStyle={{
                  border: "1px solid #CFD3DB",
                  outline: "none",
                }}
              />

              {mpinError && <ErrorMessage message={mpinError} />}
              <p className={classes.resendOtpText} onClick={handle_forgotMpin}>
                Forgot MPIN ?
              </p>
              <Box mt={5} display="flex">
                <button
                  className={classes.primaryButton}
                  type="submit"
                  onClick={handleMpinSubmit}
                >
                  Continue
                </button>
              </Box>
            </form>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default Mpin;
