import React,{useEffect,useState} from 'react'
import {Box,Typography,makeStyles} from '@material-ui/core'
import {
    VERIFY_BANK_ACCOUNT
  } from "../../../utils/endpoints";
  import httpInstance from "../../../utils/instance";
import ErrorMessage from '../../../components/Validation/ErrorMessage';

const AccountVerify = ({closeDialog}) => {

    const useStyles = makeStyles((theme) => ({
        title:{
            fontSize:"0.8rem",
            fontWeight:500,
            textAlign:"center"
        },
        verifiedKycText:{
            fontSize:"0.7rem",
            color:"#48D4A5",
            marginRight:"5px"
          },
          rupees:{
            fontFamily:"Open Sans"
          }
    }))
    const classes = useStyles();

    const [verifyStatus,setVerifyStatus]=useState('')

    const verify_account= async()=>{
        const httpConfig = {
            method: "POST",
            tokenType: "user",
            headers: {
              "Content-Type": "application/json",
            },
          };
        
          try{
            const response= await httpInstance(`${VERIFY_BANK_ACCOUNT}`,httpConfig)
            setVerifyStatus(response.data.message)
            setTimeout(()=>{
              closeDialog()
            },3000)
            
          }
          catch{
            setVerifyStatus("Please Update Valid Bank Details")
          }
    }

    useEffect(()=>{
        verify_account()
    },[])

    
  return (
    <div>
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Typography className={classes.title}>We will be transferring <span className={classes.rupees}>₹</span> 1 to verify your account details</Typography>
            <img src="/images/undraw_savings.svg" height="200px" width="200px"/>

            {verifyStatus==='Bank verified'? <p className={classes.verifiedKycText}>Bank Account Verified</p> :
        
            <ErrorMessage message={verifyStatus}/>}
        </Box>
    </div>
  )
}

export default AccountVerify