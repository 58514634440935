import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  makeStyles,
  CircularProgress,
  Slider,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import SipDatePicker from "../../../components/MutualFund/SipDatePicker";
import { CREATE_SIP } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import number_ordinals from "../../../hooks/NumberOrdinal";
import MandateAlert from "./MandateAlert";
import { Code } from "@mui/icons-material";
import axios from "axios";
import {
  GET_CONSENT_SIP_OTP,
  VERIFY_CONSENT_SIP_OTP,
} from "../../../utils/endpoints";
import { namespace } from "../../../environment";

const Sip = ({ fundData, fpData, kycStatus, show_banner }) => {
  const [mandate, setMandate] = useState(false);

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiSlider-valueLabel": {
        "& > *": {
          backgroundColor: "white",
          color: "black",
        },
      },
    },
    inputBox: {
      background: "#E6E6E6",
      padding: "0.7rem 0.95rem",
      borderRadius: "10px",
      border: "none",
      width: "-webkit-fill-available",
      fontFamily: "inherit",
    },
    label: {
      fontSize: "0.7rem",
      fontWeight: 500,
    },
    primaryButton: {
      padding: "0.8rem 3rem",
      background: "#326CF9",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      fontFamily: "inherit",
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
    },
    thumb: {
      background: "#0C97F0;",
      width: "20px",
      height: "20px",
      boxShadow: "none !important",
      " &.Mui-focusVisible": {
        boxShadow: "none !important",
      },
      "&.Mui-active": {
        boxShadow: "none !important",
      },
    },
    mark: {
      background: "black",
    },
    rail: {
      background: "#cccc;",
      height: "10px",
      borderRadius: "10px",
    },
    track: {
      background: "#0C97F0;",
      height: "10px",
      borderRadius: "10px",
    },
    checkBox: {
      "& .MuiSvgIcon-root": {
        height: "0.9em",
      },
      "& .MuiTypography-body1": {
        fontSize: "0.75rem",
      },
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: "#0C97F0",
      },
    },
    textblue: {
      fontSize: "0.9rem",
      fontWeight: 500,
      color: "#0C97F0",
      display: "flex",
      alignItems: "center",
      marginLeft: "10px",
    },
    textgrey: {
      fontSize: "0.7rem",
      color: "#676767",
      marginBottom: "10px",
    },
  }));

  const classes = useStyles();

  const [apiError, setApiError] = useState("");
  const [error, setError] = useState("");
  const [siperror, setSipError] = useState("");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [installments, setInstallments] = useState(60);
  const [cancellation, setCancellation] = useState(true);
  const [sipDate, setSipDate] = useState("");
  const [nextSip, setNextSip] = useState("");
  const [handleOTP, setHandleOTP] = useState(0);
  const { userStore } = useSelector((state) => state);
  const navigate = useNavigate();
  const [otp, SetOTP] = useState("");
  const [consentId, SetConsentId] = useState("");
  const { code } = useParams();
  const [timer, setTimer] = useState(30);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [otpHasBeenSent, setotpHasBeenSent] = useState(true);

  useEffect(() => {
    setAmount("");
  }, [code]);

  const handle_amount = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "").toLowerCase();
    setAmount(e.target.value);
  };

  const handle_otp_value = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "").toLowerCase();
    SetOTP(e.target.value);
  };

  const handle_installments = (value) => {
    setInstallments(value);
  };

  const handle_cancellation = (e) => {
    setCancellation(e.target.checked);
  };

  const handle_sipDate = (date) => {
    let sip_date = date < 9 ? `0${date}` : date;
    let month_year = moment().format("YYYY-MM");
    setSipDate(`${month_year}-${sip_date}`);

    //-----------------next sip--------------------
    let today = moment().format("DD");
    let month = moment()
      .add(date > today ? 1 : 2, "months")
      .format("MMM");

    let next_sip = `${number_ordinals(date)} ${month}`;
    setNextSip(next_sip);
  };

  //----------------------submit sip----------------------------------------------------//
  const handle_OTP = async () => {
    if (sessionStorage.getItem("mpin")) {
      if (kycStatus == "successful") {
        let min_sip = fpData?.minSipAmount > 1000 ? fpData?.minSipAmount : 1000;
        if (amount < min_sip) {
          setError(`Minimum SIP Investment is ₹${min_sip}`);
        } else if (!sipDate) {
          setError("");
          setSipError("Select SIP date");
        } else {
          setError("");
          setSipError("");
          setLoading(true);
          setApiError("");
          //----------------store data--------------------
          const formDataOTP = {
            consentId: consentId,
            otp: otp,
          };

          const httpConfigOTP = {
            method: "POST",
            tokenType: "user",
            headers: {
              "Content-Type": "application/json",
            },
            data: formDataOTP,
          };

          const formData = {
            userId: userStore.user?.id,
            transactionTypeId: 2,
            fundPlanId: fundData?.scheme_code,
            fundSubPlanId: null,
            fundFolioNumber: fundData?.folio_number
              ? fundData?.folio_number
              : null,
            fundAmount: amount,
            numberOfInstallments: cancellation ? 1200 : installments,
            frequency: "MONTHLY",
            isin: fundData?.isin,
            startDate: sipDate,
            nav: fundData?.nav,
            isWeb: true,
            amcId: fundData?.amc_code,
            planName: fundData?.scheme_name,
            mfSipConsentId: consentId,
          };

          const httpConfig = {
            method: "POST",
            tokenType: "user",
            headers: {
              "Content-Type": "application/json",
            },
            data: formData,
          };

          try {
            const responseOTP = await httpInstance(
              `${VERIFY_CONSENT_SIP_OTP}`,
              httpConfigOTP
            );

            if (responseOTP["data"]["responseStatus"] == "Success") {
              const response = await httpInstance(`${CREATE_SIP}`, httpConfig);
              sessionStorage.setItem(
                "sip",
                JSON.stringify({
                  name: fundData?.scheme_name,
                  img: fundData?.img_url,
                })
              );
              navigate(`${namespace}/mutualfund/order-summary`, {
                state: {
                  orderData: response.data?.data,
                  type: "sip",
                  schemeCode: fundData?.scheme_code,
                },
              });
              setHandleOTP(0);
              //window.location.replace(response.data?.data?.tokenUrl);
            } else {
              setApiError(responseOTP.message?.message);
              setHandleOTP(0);
            }
          } catch (error) {
            if (
              error.response.data?.message.includes(
                `Mandate Account not created`
              )
            ) {
              setMandate(true);
            } else {
              setApiError(error.response.data?.message);
            }

            setLoading(false);
          }
        }
      } else {
        show_banner();
        setApiError(
          kycStatus == "submitted"
            ? "Your KYC is under review"
            : "Please complete your KYC"
        );
      }
    } else {
      navigate(`${namespace}/onBoard`);
    }
  };

  const handle_SIP = async () => {
    if (
      userStore?.user?.id == "" ||
      userStore?.user?.id == "undefined" ||
      userStore?.user?.id == null
    ) {
      navigate(`${namespace}/onBoard`);
    }

    if (
      amount <
      (fundData?.folio_number
        ? fpData?.minAdditionalInvestment
        : fpData?.minInitialInvestment)
    ) {
      setError(
        `Minimum Investment is ₹${
          fundData?.folio_number
            ? fpData?.minAdditionalInvestment
            : fpData?.minInitialInvestment
        }`
      );
    } else if (!sipDate) {
      setError("");
      setSipError("Select SIP date");
    } else {
      setError("");
      setHandleOTP(1);

      const httpConfig = {
        method: "GET",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await httpInstance(`${GET_CONSENT_SIP_OTP}`, httpConfig);
      SetOTP("");
      SetConsentId(response.data?.data.id);

      var timeLeft = 30; // set the time limit in seconds
      var timer = setInterval(function () {
        setIsTimerActive(true);
        setotpHasBeenSent(true);
        timeLeft--;
        setTimer(timeLeft);

        if (timeLeft <= 0) {
          clearInterval(timer);
          setotpHasBeenSent(false);
          setIsTimerActive(false);
        }
      }, 1000); // set the interval to 1000 milliseconds (1 second)
    }
  };

  useEffect(() => {
    let interval;
    if (isTimerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsTimerActive(false);
      setotpHasBeenSent(true);
    }
    return () => clearInterval(interval);
  }, [isTimerActive, timer]);

  return (
    <div>
      {handleOTP == 1 ? (
        <Box m={3} p={3}>
          <label className={classes.label}>Enter OTP</label>
          <input
            className={classes.inputBox}
            type="text"
            onChange={handle_otp_value}
            value={otp}
          />
          <div className="resendOTP">
            {otpHasBeenSent ? (
              <></>
            ) : (
              <>
                <p className="otpWillRecivie">
                  OTP has been sent you will receive shortly
                </p>
              </>
            )}
            {isTimerActive ? (
              <div>
                <p className="resendOTPTimer">Resend OTP in {timer} seconds</p>
              </div>
            ) : (
              <div>
                <p className="resendOTPRequest">
                  Didn't receive OTP?{" "}
                  <a href="javascript:void" onClick={handle_SIP}>
                    Resend OTP
                  </a>
                </p>
              </div>
            )}
          </div>

          {apiError && <ErrorMessage message={apiError} />}
          <button
            className={classes.primaryButton}
            onClick={handle_OTP}
            style={{ marginTop: "10px" }}
          >
            {loading ? (
              <CircularProgress size="1rem" color="inherit" />
            ) : (
              "Verify OTP"
            )}
          </button>
          <div>{error && <ErrorMessage message={error} />}</div>
        </Box>
      ) : (
        <Box p={3}>
          <label className={classes.label}>Enter Amount</label>
          <input
            className={classes.inputBox}
            type="text"
            onChange={handle_amount}
            value={amount}
          />
          <Typography className={classes.textgrey}>
            Note: Your first SIP on today
          </Typography>
          {error && <ErrorMessage message={error} />}

          <SipDatePicker
            label="SIP Date"
            placeholder="Select SIP Date"
            dates={fpData?.sipFrequencyData ? fpData?.sipFrequencyData : ""}
            handle_sipDate={handle_sipDate}
          />
          {siperror && <ErrorMessage message={siperror} />}
          {!cancellation && (
            <>
              <label className={classes.label}>Number of Installments</label>
              <Box className={classes.root} mt={5} px={2}>
                <Slider
                  classes={{
                    thumb: classes.thumb,
                    rail: classes.rail,
                    track: classes.track,
                    valueLabel: classes.valueLabel,
                    mark: classes.mark,
                  }}
                  defaultValue={60}
                  aria-label="Default"
                  valueLabelDisplay="on"
                  size="medium"
                  valueLabelFormat={(x) => {
                    return (
                      <p style={{ color: "black", fontSize: "0.7rem" }}>
                        {x}months
                      </p>
                    );
                  }}
                  min={fpData?.minSipInstallments}
                  max={120}
                  onChange={(e, value) => handle_installments(value)}
                />
              </Box>
            </>
          )}
          <Box className={classes.checkBox} mt={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={cancellation}
                  onChange={handle_cancellation}
                  color="primary"
                />
              }
              label="Till Cancellation"
            />
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mt={10}
          >
            {nextSip && (
              <Typography className={classes.textblue}>
                {`Your next SIP on ${nextSip}`}{" "}
              </Typography>
            )}
            {apiError && <ErrorMessage message={apiError} />}
            <button className={classes.primaryButton} onClick={handle_SIP}>
              {loading ? (
                <CircularProgress size="1rem" color="inherit" />
              ) : (
                "Start SIP"
              )}
            </button>
          </Box>
        </Box>
      )}
      <MandateAlert dialog={mandate} closeDialog={() => setMandate(false)} />
    </div>
  );
};

export default Sip;
