import React,{useState} from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/Footer'
import { Link,NavLink } from "react-router-dom";
import RateCaluclation from '../../containers/Dashboard/DigitalGold/RateCaluclation';
import Graph from '../../containers/Dashboard/DigitalGold/Graph'
import {CREATE_CHECKOUT,CREATE_ORDER} from '../../utils/endpoints'
import httpInstance from '../../utils/instance'
import OrderSummary from '../../containers/DigitalGold/Payement/OrderSummary';
import Kyc_status from '../../containers/Dashboard/Funds/KycStatusCheck/Kyc_status';
import { Box,makeStyles } from '@material-ui/core';
import {useSelector,useDispatch} from 'react-redux'
import Kyc_digitalgold from '../../containers/Dashboard/DigitalGold/Kyc_digitalgold';
import UnderReview from '../../containers/Dashboard/DigitalGold/GoldKyc/UnderReview';
import {getGoldUser} from '../../redux/User/userActions'
import Desclaimer from '../../containers/Dashboard/DigitalGold/Desclaimer';

function Index() {

  const useStyles=makeStyles({
    header:{
      position:"fixed"
    },
    "@media(max-width:600px)":{
      header:{
        position:"relative"
      },
    }
  })

  const classes=useStyles()

  const[checkoutRate,setCheckoutRate]=useState({})
  const [orderSummary,setOrderSummary]=useState(false)
  const [orderData,setOrderData]=useState({})
  const [tabData, setTabData]=useState("buy")
  const [goldKycmodal,setGoldKycModal]=useState(false)
  const [underReview, setUnderReview]=useState(false)
  

  const {userStore}=useSelector((state)=>state)
  const dispatch=useDispatch()

  const ChangeTabData=(data)=>{
    setTabData(data)
  }

  const handleOrderSummary=()=>{
    setOrderSummary(false)
  }

  const closeModal=()=>setGoldKycModal(false)
  const closeUnderReview=()=>setUnderReview(false)

  const createOrder= async(formData)=>{
    dispatch(getGoldUser())
    if(userStore?.goldUser){
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers:{
        "Content-Type": "application/json",
      },
      data:formData,
    }

    try{
      const response=await httpInstance(`${CREATE_CHECKOUT}`,httpConfig)
      setCheckoutRate(response.data?.data)
      
      create_order(response.data?.data)
    }
    catch{

    }
  }

  }

  const create_order= async (data)=>{
    let formData={
      metalType:data.metalType,
      amount:data.totalAmount,
      blockId:data.goldSilverRate?.blockId,
      lockPrice:data.metalType=="gold" ?data.goldSilverRate?.goldBuyRate : data.goldSilverRate?.silverBuyRate,
      type:data.type,
      
    }

    if(data.type=="sell"){
      formData={...formData,
        lockPrice:data.metalType=="gold" ?data.goldSilverRate?.goldSellRate : data.goldSilverRate?.silverSellRate,
        metalWeight:data.metalWeight.toFixed(4)}
    }

    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers:{
        "Content-Type": "application/json",
      },
      data:formData,
    }

    try{
      const response=await httpInstance(`${CREATE_ORDER}`,httpConfig)
      setOrderData(response.data?.data)
      setOrderSummary(true)
    }
    catch(error){
      if(error.response.data?.message.includes('1,80,000')){
        setGoldKycModal(true)
      }else if(error.response.data?.message.includes('48')){
        setUnderReview(true)
      }
      
    }
  }
  return (
      <div>
    {/* <Header/> */}
    {orderSummary? 
      <OrderSummary handleOrderSummary={handleOrderSummary} checkoutRate={checkoutRate} orderData={orderData}/> :
      
      <div >
        <Box style={{ borderTop:"1px solid  #E6E3E3",width:"100%"}} className={classes.header}></Box>
        <Box pt={2}>
       {(!userStore.goldUser || !userStore.isOnBoardingCompleted) && <Kyc_status/>}
       </Box>
       {/* desclaimer */}
       <Desclaimer/>


      <Kyc_digitalgold dialog={goldKycmodal} closeDialog={closeModal}/>
      <UnderReview dialog={underReview} closeDialog={closeUnderReview}/>
      <RateCaluclation createOrder={createOrder} ChangeTabData={ChangeTabData} />
        <Graph tabData={tabData}/>
        </div>
  }
        
    {/* <Footer/> */}
    </div>
  )
}

export default Index