import React,{useState,useEffect} from 'react'
import { Box,makeStyles,Typography,Grid } from '@material-ui/core'
import { BarChart,XAxis,YAxis,Tooltip ,Bar,CartesianGrid,Legend,ResponsiveContainer }  from "recharts";
import {SUCCESSFULL_RECOMMENDATION} from '../../../utils/endpoints'
import httpInstance from '../../../utils/instance'

const Recommendation = () => {
    const useStyles = makeStyles({
        root: {
            background: "#fff",
            borderRadius: "20px",
          },
          header:{
            color:"#686868",
            fontSize:"1rem",
            fontWeight:600,
            padding:"0px 10px"
        },
        text:{
          fontSize:"0.8rem",
        }
    })

    const classes = useStyles();

    const [data,setData]=useState([])

    const get_reccommendation=async()=>{
      const httpConfig = {
        method: "GET",
        tokenType: "admin",
        headers:{
          "Content-Type": "application/json",
        },
      };

      try{
        const response=await httpInstance(`${SUCCESSFULL_RECOMMENDATION}`,httpConfig)
        let arry=response.data?.data?.map((ele,i)=>{
          return {
            ...ele, xlable:i+1
          }
        })
        setData(arry)
      }
      catch{

      }
    }

    useEffect(()=>{
      get_reccommendation()
    },[])

    

  return (
    <div>
        <Box className={classes.root} mt={3} p={3}>
        <Box display="flex">
            <img src="/images/admin/user.svg" height="20px" width="15px" />
            <Typography className={classes.header}>
            Successful Recommendation Percentage
            </Typography>
          </Box>

          <Grid container>
            <Grid item xs={7}>
            <Box mt={4}>
          <ResponsiveContainer width={"100%"} height={250}>
          <BarChart  data={data}>
            <CartesianGrid  />
            <XAxis dataKey="xlable" tick={{fontSize:12}} />
            <YAxis tick={{fontSize:12}}/>
            <Tooltip />
            <Legend
            iconType="circle"
             iconSize={10}
             padding={5}
             wrapperStyle={{
             paddingLeft: "5rem"
            }}
            />

            <Bar name="Suggested Count" dataKey="suggestedCount" fill="#0C97F0" />
            <Bar  name="Invested Count"dataKey="investedCount" fill="#91D4FF" />
          </BarChart>
          </ResponsiveContainer>
          </Box>

       
            </Grid>
            <Grid item xs={5}>
            <Box mt={5}>
          <ol>
          {data?.map((ele=>{
            return(
              <li className={classes.text}>
                {ele.amcName}
                 </li>
            )
          }))}
          </ol>
        </Box>
            </Grid>
          </Grid>

          
          </Box>
        
    </div>
  )
}

export default Recommendation