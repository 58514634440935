import React,{useState} from "react";
import { Box, makeStyles, Grid, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import {ADD_QUESTION} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import Confirmation from "../../../components/MutualFund/InfoMsg/Confirmation";
import { useSelector } from "react-redux";
import CustomToast from "../../../components/UI/CustomToast";

const AddQuestion = ({handle_add,handle_delete}) => {
  const useStyles = makeStyles({
    root: {
      background: "#fff",
      borderRadius: "10px",
    },
    primaryButton: {
      background: "#326CF9",
      color: "#fff",
      padding: "5px 1.5rem",
      border: "none",
      borderRadius: "20px",
      fontFamily: "inherit",
      margin: "0px 15px",
      boxShadow: "0px 2px 26px rgba(0, 0, 0, 0.15)",
      display: "flex",
      alignItems: "center",
      cursor:"pointer"
    },
    answer: {
      margin: "3px 0px",
    },
    inputBox: {
      width: "100%",
      border: "1px solid #DDDDDD",
      borderRadius: "5px",
      margin: "0px 10px",
      padding: "5px",
      fontFamily: "inherit",
    },
  });
  const classes = useStyles();

  const [showDialog,setShowDailog]=useState(false)

  const {adminStore}=useSelector((state)=>state)

  const onSubmit= async(data)=>{
    const formData={
        question:data.question,
        choices:[
            {
                name:data.name0,
                weight:data.weight0
            },
            {
                name:data.name1,
                weight:data.weight1
            },
            {
                name:data.name2,
                weight:data.weight2
            },
            {
                name:data.name3,
                weight:data.weight3
            },
            {
                name:data.name4,
                weight:data.weight4
            }
        ]
    }

    const httpConfig = {
        method: "POST",
        tokenType: "admin",
        headers: {
          "Content-Type": "application/json",
        },
        data:formData
      };

      try{
        await httpInstance(`${ADD_QUESTION}`,httpConfig)
        handle_add()
        CustomToast(`${adminStore?.Adminuser?.role?.name=="Admin"  &&  `Approval request sent`}`,"SUCCESS")
      }
      catch{

      }
  }

  const { register, handleSubmit, formState: { errors } } = useForm();
  return (
    <div>
      <Box className={classes.root} px={5} py={3} mt={2}>
        <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={10}>
            <Box display="flex" alignItems="center">
              <input
                className={classes.inputBox}
                {...register("question", { required: true })}
              />
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box display="flex" alignItems="center" justifyContent="end">
              <button
                className={classes.primaryButton}
                type="submit"
              >
                Save
              </button>

              <img src="/images/admin/delete.svg" height="20px" width="20px" onClick={()=>setShowDailog(true)} style={{cursor:"pointer"}} />
            </Box>
          </Grid>
        </Grid>
        <Box mt={3}>
          <ol>
            {[...Array(5)].map((answer, i) => {
              return (
                <li key={i}>
                  <Box>

                    <Grid container spacing={2}>
                      <Grid item xs={10} className={classes.answer}>
                        <input
                          className={classes.inputBox}
                          {...register(`name${i}`, { required: true })}
                          
                        />
                      </Grid>
                      <Grid item xs={2} className={classes.answer}>
                        <Box
                          display="flex"
                          justifyContent="end"
                          alignItems="center"
                        >
                          <input
                            className={classes.inputBox}
                            name="weight"
                            {...register(`weight${i}`, { required: true })}
                          />
                          Points
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </li>
              );
            })}
          </ol>
        </Box>
        </form>
      </Box>

      <Confirmation 
        show={showDialog} 
        handleClose={()=>setShowDailog(false)} 
        handle_yes={handle_delete}
        title="Delete Confirmation"
        text="Are you sure you want to delete this question?"
        />
    </div>
  );
};

export default AddQuestion;
