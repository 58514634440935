import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { purple } from "@mui/material/colors";
import { useNavigate, createSearchParams, Link } from "react-router-dom";
import { namespace } from "../../../environment";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: "#326CF9",
  borderRadius: "39px",
  width: "175px",
  height: "49px",
  fontSize: "15px",
  fontWeight: "500",
  position: "absolute",
  //width: '174.56px',
  //height: '49px',
  left: "990px",
  top: "390px",
  "&:hover": {
    backgroundColor: "#326CF9",
  },
}));

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardContent: {
    display: "flex",
  },
  cardContent2: {
    //display:'flex',
    margin: "10px 0px",
  },
  mainCard: {
    //height:'190px',
    //width:'839px'
    border: "1px solid #DDDDDD",
    borderRadius: "10px",
    marginTop: "1rem",
    padding: "10px",
    "& p": {
      margin: "4px 0px 4px 0px",
    },
  },
  fundName: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "400",
    paddingLeft: "10px",
  },
  fundType: {
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: "400",
  },
  rating: {
    fontFamily: "Poppins",
    fontSize: "19.89px",
    fontWeight: "400",
  },

  nav: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
  },
  navValue: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "600",
  },
  perReturns: {
    fontFamily: "Poppins",
    color: "#6FDDB8",
    fontSize: "16px",
    fontWeight: "600",
  },
  yearReturns: {
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: "400",
  },
  primaryButton: {
    padding: "0.9rem 0px",
    fontFamily: "Poppins",
    background: "#326CF9",
    color: "#fff",
    fontSize: "0.9rem",
    fontWeight: 500,
    borderRadius: "45px",
    border: "none",
    cursor: "pointer",
    marginTop: "3rem",
    width: "100%",
  },

  ratingBox: {
    paddingLeft: "3rem",
  },

  starImag: {
    padding: "0px 4px",
  },
  rupees: {
    fontFamily: "Open Sans",
  },
  logo: {
    paddingRight: "10px",
  },
  "@media (max-width: 600px)": {
    ratingBox: {
      paddingLeft: "10px",
    },
  },
});

export default function SimpleCard({ fund }) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  const navigate = useNavigate();

  const handleFund = (scheme_code) => {
    navigate({
      pathname: `${namespace}/mutualfund`,
      search: `${createSearchParams({ scheme_code: scheme_code })}`,
    });
  };

  return (
    <Box className={classes.mainCard}>
      <Grid container>
        <Grid item md={1} xs={12}>
          <img src={fund?.img_url} height={54} width={54} />
        </Grid>
        <Grid item xs={12} md={8} container>
          <Grid item xs={12} className={classes.fundName}>
            <Link
              to={`${namespace}/mutualfund/scheme-code/${fund.scheme_code}`}
              state={{ data: fund }}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {fund?.scheme_name}
            </Link>
            <div className={classes.cardContent}>
              <Grid item xs={6}>
                <p className={classes.fundType}>{fund?.asset_sub_category}</p>
              </Grid>
              <Grid item xs={6}>
                <p className={classes.fundType}>{fund?.risk_profile}</p>
              </Grid>
            </div>
            {/* <div className={classes.cardContent2}> */}
            <Grid item xs={12} container className={classes.cardContent2}>
              <Grid item xs={6} md={3}>
                <p className={classes.nav}>NAV</p>
                <p className={classes.navValue}>
                  <span className={classes.rupees}>₹</span> {fund?.nav}
                </p>
              </Grid>
              <Grid item xs={6} md={3}>
                <p className={classes.perReturns}>{fund?.performance?.oneY}%</p>
                <p className={classes.yearReturns}>Returns( 1yr )</p>
              </Grid>
              <Grid item xs={6} md={3}>
                <p className={classes.perReturns}>
                  {fund?.performance?.threeY}%
                </p>
                <p className={classes.yearReturns}>Returns( 3yr )</p>
              </Grid>
              <Grid item xs={6} md={3}>
                <p className={classes.perReturns}>
                  {fund?.performance?.fiveY}%
                </p>
                <p className={classes.yearReturns}>Returns( 5yr )</p>
              </Grid>
            </Grid>
            {/* </div> */}
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} className={classes.ratingBox}>
          <p className={classes.rating}>Rating</p>

          <div className={classes.cardContent}>
            {fund?.vr_rating != "Not Rated" &&
              (fund?.vr_rating.length == 1 || fund?.vr_rating.length > 1) && (
                <img
                  src="/images/starRating.svg"
                  height={16}
                  width={16}
                  className={classes.starImag}
                />
              )}
            {fund?.vr_rating != "Not Rated" &&
              (fund?.vr_rating.length == 2 || fund?.vr_rating.length > 2) && (
                <img
                  src="/images/starRating.svg"
                  height={16}
                  width={16}
                  className={classes.starImag}
                />
              )}
            {fund?.vr_rating != "Not Rated" &&
              (fund?.vr_rating.length == 3 || fund?.vr_rating.length > 3) && (
                <img
                  src="/images/starRating.svg"
                  height={16}
                  width={16}
                  className={classes.starImag}
                />
              )}
            {fund?.vr_rating != "Not Rated" &&
              (fund?.vr_rating.length == 4 || fund?.vr_rating.length > 4) && (
                <img
                  src="/images/starRating.svg"
                  height={16}
                  width={16}
                  className={classes.starImag}
                />
              )}
            {fund?.vr_rating != "Not Rated" && fund?.vr_rating.length == 5 && (
              <img
                src="/images/starRating.svg"
                height={16}
                width={16}
                className={classes.starImag}
              />
            )}
            {fund?.vr_rating == "Not Rated" || fund?.vr_rating == ""
              ? "Not Rated"
              : ""}
          </div>
          <Link
            to={`${namespace}/mutualfund/scheme-code/${fund.scheme_code}`}
            state={{ data: fund }}
          >
            {" "}
            <button className={classes.primaryButton} variant="contained">
              Invest
            </button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}
