import React from 'react'
import DetailSip from '../../../containers/Portfolio/MutualFund/DetailSip.js'

const Sip = () => {
  return (
    <div>
        <DetailSip/>
    </div>
  )
}

export default Sip