import React,{useState,useEffect} from 'react'
import { Box,makeStyles,Typography } from '@material-ui/core'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Pagination = ({totalNumber,itemsPerPage,onPaginate}) => {
    const useStyles=makeStyles({
        root:{
            "& ul":{
                width: "100%",
                display: "flex",
            }  , 
            "& li":{
                display:"flex",
                alignItems:"center",
                color:" #0C97F0",
                listStyle: "none",
                textAlign: "center",
                cursor: "pointer",
                userSelect: "none",
                padding:"8px 15px",
                borderRadius:"50%",  
                margin:"0px 5px"            
            }                  
            },
        active:{
            color:"#ffff !important",
            background:"#0C97F0",
            padding:"8px 15px",
        },

        disable:{
            opacity:0.4
        }

    })
    const classes=useStyles()

    const [start,setStart]=useState(0)
    const [currentPage,setCurrentPage]=useState(1)

    const totalPages = Math.ceil(totalNumber/itemsPerPage)
    const pages = []
  
    for( let i = 0; i < totalPages; i++ ) pages[i] = i+1
   
  
    const currentPages = pages.splice(start, 5)

    const onClick = e => {   
        e.preventDefault()      
        const { textContent: value } = e.target   
        setCurrentPage(parseInt(value))
        
      }

      useEffect(() => {
    
        // if( currentPages.length < 1 ) return
        
        const currentPageIndex = currentPages.indexOf(currentPage)
        
        if( currentPageIndex > 3 ){
          if( currentPages.pop() !== totalPages ) 
          setStart(start+1)
        }
        
        if( currentPageIndex < 1 && currentPages.shift() !== 1 ) 
        setStart(start-1)
        
        onPaginate(currentPage)
        
      }, [currentPage])

    const handle_next=() => {
        if( currentPage < totalPages ) {
            setCurrentPage(currentPage+1)
        }
        
    }

    const handle_prvs=()=>{
        if( currentPage > 1 ) {
            setCurrentPage(currentPage-1)
        }
    }



  return (
    <div>
        <Box className={classes.root} display="flex" justifyContent="center">
            <li onClick={handle_prvs} className={currentPage==1 ? classes.disable:''}>
            <img src="/images/admin/leftArrow.svg" height="15px" width="20px" style={{marginRight:"10px"}}/>
                 Previous 
                 
            </li>
        {currentPages.map((page,key)=>{
            return(
                <li
                    key={key}
                    onClick={onClick}
                    className={currentPage==page ? classes.active :''}
                    >
                    {page}
                </li>
            )
        })}
        
        <li onClick={handle_next} className={(currentPage==totalPages || totalNumber<1 )? classes.disable:''}>
            Next 
            <img src="/images/admin/rightArrow.svg" height="15px" width="20px" style={{marginLeft:"10px"}}/>
        </li>
        
        </Box>
    </div>
  )
}

export default Pagination