import React from 'react'


function ErrorMessage({message}) {
  return (
    <div>
        <p style={{
            fontSize:"0.7rem",
            color:"#F32424",
            marginRight:"5px"
        }}>{message}
    </p>
    </div>
  )
}

export default ErrorMessage