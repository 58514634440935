import React from 'react'
import About from '../../containers/About/Aboutus'
import Header from '../../components/header/Header'
import Footer from '../../components/Footer'
import { isMobile } from "react-device-detect";

const AboutUs = () => {
  return (
    <div>
   {!isMobile && <Header/>}
    <About/>
 { !isMobile &&  <Footer/>}
    </div>
  )
}

export default AboutUs