import React,{useEffect, useState} from 'react'
import {Box,Typography,DialogTitle,DialogContent,makeStyles} from '@material-ui/core'
import { useForm,Controller } from "react-hook-form";
import DatePickerField from '../../../../components/signup/Input/DatePickerField';
import ErrorMessage from '../../../../components/Validation/ErrorMessage';
import { Link } from 'react-router-dom';


const PanCardData = ({handle_panDetails,panDetails,error}) => {
    const useStyles = makeStyles({
    
        title: {
          fontSize: "1.1rem",
          fontWeight: 500,
          textAlign: "center",
          fontFamily:"inherit"
        },
        dialogTitle: {
          display: "flex",
          justifyContent: "center",
          alignItems: " center",
          textAlign: "center",
          padding: "5px",
        },
        dialogPaper: {
          maxWidth: "55.5rem",
          justifyContent: "center",
        },
        image: {
          textAlign: "center",
        },
        text: {
          fontSize: "0.8rem",
          textAlign: "center",
          fontFamily:"inherit"
        },
        primaryButton: {
          padding: "0.8rem 3rem",
          background: "#326CF9",
          color: "#fff",
          fontSize: "0.8rem",
          fontWeight: 600,
          borderRadius: "45px",
          border: "none",
          cursor: "pointer",
          
        },
        dialogContent: {
          padding: "8px 30px",
        },
        inputBox: {
            background: "#E6E6E6",
            padding: "0.7rem 0.95rem",
            borderRadius: "10px",
            border: "none",
            width: "90%",
            fontFamily:"inherit"
          },
          label:{
            fontSize:"0.7rem",
            fontWeight:500
          },

          form:{
            maxWidth:"fit-content"
          }
      });

      const classes=useStyles()

    const { register, handleSubmit, formState: { errors },reset,control } = useForm();

    const [dob,setDob]=useState(null)
  const [dobError,setDobError]=useState(false)

  useEffect(()=>{
    if(panDetails){
      reset({
        panNumber:panDetails.panNumber,
        fullName:panDetails.fullName,
        
      })
      setDob(new Date(panDetails.dob))
    }
  },[])
  

  const today=new Date()

  const handleDob=(date)=>{
    setDob(date)
  }

  const submitPanDetails=(data)=>{
    if(!dob){
        setDobError(true)
    }else{
        setDobError(false)
    }
    const panData={
        panNumber:data.panNumber,
        fullName:data.fullName,
        dob:dob

    }

    handle_panDetails(panData)
  }

  return (
    <div>
        <Box>
        <DialogTitle
          classes={{  root: classes.dialogTitle }}
        >
          <Typography className={classes.title}>Confirm Pan Details</Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
         
         <Box className={classes.form}>
            <form onSubmit={handleSubmit(submitPanDetails)}>
            <Box my={1} >
            <label className={classes.label}>Enter Pan Card Number</label>
                <input
                    className={classes.inputBox}
                    {...register("panNumber",{required:true})}
                />
                {errors.panNumber  && <ErrorMessage message="Enter Pan Number"/>}
            </Box>
            <Box my={1} >
            <label className={classes.label}>Full Name</label>
                <input
                    className={classes.inputBox}
                    {...register("fullName",{required:true,
                    pattern:/^(?=(?:^\w))([A-Za-z. ]+)$/
                    })}
                />
                {errors.fullName && errors.fullName.type=="required" && <ErrorMessage message="Enter Full Name"/>}
                {errors.fullName && errors.fullName.type=="pattern" && <ErrorMessage message="Enter Valid Name"/>}
            </Box>
            <Box my={2}>
            <DatePickerField 
            name="DOB" 
            label="Date of Birth" 
            handleChange={handleDob}
            dateValue={dob}
            maximumDate={today.setFullYear(today.getFullYear()-18)}
            />
          {dobError && <ErrorMessage message="Select Date of Birth"/>}
        </Box>
          
          {error && <ErrorMessage message={error}/>}
          <Box my={3} display="flex" justifyContent="center">
         <button
              className={classes.primaryButton}
              type="submit" 
            >
              Confirm
            </button>
          </Box>
          </form>
          </Box>
        </DialogContent>
        </Box>
        
    </div>
  )
}

export default PanCardData