import React,{useState,useEffect} from 'react'
import { Box,makeStyles,FormControlLabel,Checkbox,Table,TableBody,TableHead,TableRow,TableCell } from '@material-ui/core'
import httpInstance from '../../../utils/instance'
import {GET_CUSTOMER,DOWNLOAD_CSV} from '../../../utils/endpoints'
import Pagination from '../../../components/Admin/Pagination'
import moment from 'moment'
import { CSVLink } from "react-csv";

const Index = () => {
    const useStyles=makeStyles({
        root:{
          "& .MuiCheckbox-colorPrimary.Mui-checked": {
            color: "#0C97F0",

          },
          "& .MuiTypography-body1":{
            fontSize:"0.85rem"
          }
        },

        input:{
            border:'none',
            width:"100%",
            padding:"15px",
            fontFamily:"inherit"
        },

        primaryButton:{
            background:"#326CF9",
            color:"#fff",
            padding:"10px 2rem",
            border:"none",
            borderRadius:"20px",
            fontFamily:"inherit"
        },
        table:{
            margin:"1rem 0px",
            "& .MuiTableCell-root":{
                fontSize:"0.8rem"
            },
            "& .MuiTableHead-root":{
                background:"#F9FBFC",
                borderTopLeftRadius:" 4px",
                "& .MuiTableCell-root":{
                    color:"#C0C5D0",
    
                }
            },
            "& .MuiTableBody-root":{
                background:"#fff",
            }
        }
       
      })
      const classes=useStyles()
      
      const [isAssisted,setIsAssisted]=useState(false)
      const [customers,setCustomers]=useState([])
      const [totalNumber,setTotalNumber]=useState(0)
      const [loading,setLoading]=useState(true)
      const [currentPage,setCurrentPage]=useState(1)
      const [downloadData,setDownloadData]=useState("")
      const [search,setSearch]=useState('')

      const get_data=async()=>{
        const httpConfig = {
            method: "POST",
            //tokenType: "user",
            headers:{
              "Content-Type": "application/json",
            },
            params:{isAssisted:isAssisted,pageNumber:currentPage,name:search}
          };

          const httpConfig1 = {
            method: "POST",
            //tokenType: "user",
            headers:{
              "Content-Type": "application/json",
            },
          };

          try{
            const response=await httpInstance(`${GET_CUSTOMER}`,httpConfig)
            setCustomers(response.data?.data?.customers)
            setTotalNumber(response.data.data?.count)
            setLoading(false)
            const download=await httpInstance(`${DOWNLOAD_CSV}`,httpConfig1)
            setDownloadData(download?.data)
          }
          catch{

          }
      }

      useEffect(()=>{
        get_data()
      },[currentPage,search,isAssisted])


      const onPaginate=(pageNumber)=>{
        if(pageNumber != currentPage) 
        setCurrentPage(pageNumber)
      }

    const handle_search=(e)=>{
      setSearch(e.target.value)
    }

    const handle_checkbox=(e)=>{
      setIsAssisted(e.target.checked)
    }
  return (
    <div>
        <Box p={3} pr={7} className={classes.root}>
        <Box>
            <input
            value={search}
            className={classes.input}
            placeholder="Enter the name of customer"
            onChange={handle_search}
            />
            <FormControlLabel
            control={
              <Checkbox
                //checked={cancellation}
                onChange={handle_checkbox}
                color="primary"
              />
            }
            label="Assisted Services"
          />
        </Box>
        <Box display="flex" justifyContent="end">
        <CSVLink data={downloadData} filename={"customer-database.csv"}>
            <button className={classes.primaryButton}>
                <img src="/images/admin/download.svg" height="15px" width="15px" style={{marginRight:"10px"}}/>
                Download in CSV
            </button>
            </CSVLink>
        </Box>
            
         <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Phone Number</TableCell>
                    <TableCell align="left">Join Date</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {customers.map((ele)=>{
                    return(
                        <TableRow key={ele.email}>
                        <TableCell>{ele.name}</TableCell>
                        <TableCell>{ele.email}</TableCell>
                        <TableCell>{ele.phone} </TableCell>
                        <TableCell>{moment(ele.joinDate).format("DD/MM/YYYY")}</TableCell>
                        </TableRow>
                    )
                })}
            
            </TableBody>
         </Table>
        {!loading && 
         <Pagination
            totalNumber={totalNumber}
            itemsPerPage={10}
            onPaginate={onPaginate}
         />
        }
        </Box>
    </div>
  )
}

export default Index