import React,{useState,useEffect} from "react";
import {
  Box,
  makeStyles,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Table,
  TableBody,TableHead,TableCell, TableRow
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {ALLOCATION_TO_FUNDHOUSE} from '../../../utils/endpoints'
import httpInstance from '../../../utils/instance'
import localeString from '../../../hooks/LocaleString'

const Allocation = () => {
  const useStyles = makeStyles({
    root: {
      background: "#fff",
      borderRadius: "20px",
      "& .MuiFormControl-root":{
        minWidth:"10rem"
      }
    },
    header: {
      color: "#686868",
      fontSize: "1rem",
      fontWeight: 600,
      padding: "0px 10px",
    },
    select: {
      fontSize: "0.85rem",
      color: "#fff",
      padding: "3px 0px",
      borderRadius: "10px",
      background:"#0C97F0",
      "& .MuiSelect-select": {
        paddingRight: "40px",
        paddingLeft: "10px",
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
      "& .MuiSelect-icon": {
        top: 4,
      },
    },
    table:{
        margin:"1rem 0px",
        "& .MuiTableCell-root":{
            fontSize:"0.8rem"
        },
        "& .MuiTableHead-root":{
            background:"#F9FBFC",
            "& .MuiTableCell-root":{
                color:"#C0C5D0",

            }
        }
    },

    menuItem:{
      fontSize:"0.85rem"
    },
    text:{
      textAlign:"center",
      fontSize:"1rem"
    }
  });
  const classes = useStyles();

  const [day,setDay]=useState(0)
  const [allocations,setAllocations]=useState([])

  const get_allocation=async()=>{
    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers:{
        "Content-Type": "application/json",
      },
      params:{duration:day}
    };
    try{
      const response=await httpInstance(`${ALLOCATION_TO_FUNDHOUSE}`,httpConfig)
      setAllocations(response.data?.data)
    }
    catch{

    }
  }

   useEffect(()=>{
    get_allocation()
   },[day])

   const handle_filter=(e)=>{
    setDay(e.target.value)
   }


  return (
    <div>
      <Box className={classes.root} mt={3} p={3}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <img src="/images/admin/user.svg" height="20px" width="15px" />
            <Typography className={classes.header}>
              Allocation to different Fund Houses
            </Typography>
          </Box>
          <FormControl sx={{ m: 1,minWidth:200 }}>
            <Select
              value={day}
              onChange={handle_filter}
              className={classes.select}
              disableUnderline
              IconComponent={KeyboardArrowDownIcon}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                style: {
                  maxHeight: 350,
                  top: 3,
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem className={classes.menuItem} value={1}>Today</MenuItem>
              <MenuItem className={classes.menuItem} value={7}>This Week</MenuItem>
              <MenuItem className={classes.menuItem} value={30}>This Month</MenuItem>
              <MenuItem className={classes.menuItem} value={365}>Last Year</MenuItem>
              <MenuItem className={classes.menuItem} value={0}>Overall</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Table className={classes.table}>
            <TableHead>
            <TableRow>
            <TableCell align="left">Fund Houses</TableCell>
            <TableCell align="left">Total Investment  Amount</TableCell>
            <TableCell align="left">Thematic </TableCell>
            <TableCell align="left">Non Thematic</TableCell>
            </TableRow>
            </TableHead>   
            
            <TableBody>    
                {allocations?.map((ele)=>{
                    return(
                        <TableRow>
                            <TableCell >{ele.fundHouse}</TableCell>
                            <TableCell>₹ {localeString(ele.total)} </TableCell>
                            <TableCell>₹ {localeString(ele.thematic)} </TableCell>
                            <TableCell>₹ {localeString(ele.nonThematic)} </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
              
        </Table>
        {allocations?.length==0 && <p className={classes.text}>No Result</p>}
      </Box>
    </div>
  );
};

export default Allocation;
