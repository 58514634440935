import React from "react";
import Timestamps from "../../containers/Admin/Timestamps";

const Timestamp = () => {
  return (
    <div>
      <Timestamps />
    </div>
  );
};

export default Timestamp;
