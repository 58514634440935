import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Container,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getUser,
  getGoldUser,
  creatMfAccount,
} from "../../redux/User/userActions";
import Avatar from "react-avatar";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { BASE_URL } from "../../environment";
import Notification from "./Notification";
import Search from "../UI/Search";
import { namespace } from "../../environment";

const MenuItems = ({ text, subText, image, navigate, subMenu, onClose }) => {
  const useStyles = makeStyles((theme) => ({
    menuText: {
      fontSize: "0.9rem",
      fontWeight: 400,
      "& p": {
        margin: "0px",
      },
    },
    imageBack: {
      padding: "4px 5px 0px",
      background: "#ADDFFF",
      borderRadius: "50%",
      width: "fit-content",
      marginRight: "10px",
    },
    subText: {
      fontSize: "0.7rem",
      color: "#676767",
    },
    gridContainer: {
      padding: "5px 0px",
      //borderBottom:"1px solid #D0D0D0"
    },
  }));

  const classes = useStyles();
  return (
    <MenuItem className={classes.menuText} onClick={navigate && navigate}>
      <Grid
        container
        className={classes.gridContainer}
        display="flex"
        alignItems="center"
      >
        {/* <Grid item xs={subMenu? 3 :2}>
                  <div className={classes.imageBack}><img src={image} height="25px" width="25px"/></div> 
                  </Grid> */}
        <Grid item xs={subMenu ? 9 : 10}>
          <p>{text}</p>
          {/* <p className={classes.subText}>{subText}</p> */}
        </Grid>
      </Grid>
    </MenuItem>
  );
};

function Header() {
  const [open, setOpen] = useState(false);
  const [nav, setNav] = useState("mutual");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [drawer, setDrawer] = useState(false);
  const [menu, setMenu] = useState("user");

  //--------------profile menu--------------------------------------//

  const handleClose = () => {
    setAnchorEl(null);
    setMenu("user");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const location = useLocation();
  const navigate = useNavigate();
  // const isLoggedIn = sessionStorage.getItem("mpin");
  const isLoggedIn = localStorage.getItem("user_token");
  const toggle = () => {
    setOpen(!open);
  };
  //-----------------------------------Notification Menu-----------------------------------------------//

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },

    menu: {
      "& .MuiList-padding": {
        padding: "15px 0px 15px 0px",
      },
    },
    header: {
      // borderBottom: "1px solid #E5E3E3",
      //paddingBottom:"20px",
      background: "white",
      position: "sticky",
      top: 0,
      width: " 100%",
      zIndex: 1000,
    },
    connectButton: {
      border: "2px solid #326CF9",
      color: "#326CF9",
      borderRadius: "30px",
      padding: "0.6rem 1.8rem",
      fontWeight: 600,
      fontSize: "0.8rem",
      textDecoration: "none",
    },
    links: {
      fontSize: "0.9rem",
      fontWeight: "medium",
    },
    primaryButton: {
      padding: "0.8rem 2rem",
      background: "#326CF9",
      color: "#fff",
      fontSize: "0.9rem",
      fontWeight: 600,
      borderRadius: "5px",
      border: "none",
      cursor: "pointer",
    },

    navlink: {
      "&:hover": {
        color: "#0C97F0",
        fontWeight: 400,
      },
      textDecoration: "none",
      color: "black",
      fontWeight: 400,
    },
    navColor: {
      color: "#0C97F0",
      fontWeight: 500,
      textDecoration: "none",
    },

    mobileHeader: {
      display: "none",
    },

    menuEmail: {
      fontSize: "0.8rem",
      fontWeight: 400,
    },

    menuName: {
      fontSize: "0.9rem",
      fontWeight: 500,
    },

    "@media (max-width: 480px)": {
      header: {
        display: "none",
      },

      mobileHeader: {
        display: "block",
      },
    },
  }));

  const classes = useStyles();

  const dispatch = useDispatch();
  const { userStore } = useSelector((state) => state);

  const handleNavColor = (nav) => {
    setNav(nav);
  };

  const showDrawer = () => {
    setDrawer(true);
  };

  const closeDrawer = () => setDrawer(false);

  useEffect(() => {
    if (location.pathname == `${namespace}/dashboard/digitalgold`) {
      setNav("gold");
    } else if (location.pathname.includes(`${namespace}/myportfolio`)) {
      setNav("port");
    } else if (location.pathname.includes(`${namespace}/mutualfund`)) {
      setNav("mutual");
    } else if (
      location.pathname == `${namespace}/digitalgold/payment-success`
    ) {
      setNav("");
    } else if (
      location.pathname == `${namespace}/myportfolio/delivery-success`
    ) {
      setNav("");
    } else if (location.pathname.includes(`${namespace}/my-profile`)) {
      setNav("");
    }
  }, [location.pathname]);

  useEffect(async () => {
    dispatch(creatMfAccount());
    dispatch(getUser());
    dispatch(getGoldUser());
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("user_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("desclaimer");
    localStorage.removeItem("banner");
    sessionStorage.removeItem("mpin");
    localStorage.removeItem("mpin");
    localStorage.removeItem("email");
    localStorage.removeItem("creationTime");
    localStorage.removeItem("fund_id");
    navigate(`${namespace}/onBoard`);
  };

  const handle_menu = (value) => {
    setMenu(value);
  };

  const Profile = () => {
    return (
      <Box display="flex" alignItems="center">
        <Menu
          id="account-menu"
          anchorEl={anchorEl}
          onClose={handleClose}
          open={Boolean(anchorEl)}
          className={classes.menu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          getContentAnchorEl={null}
        >
          {menu == "user" ? (
            <div>
              <Link
                to={`${namespace}/my-profile/basic-details`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box
                  px={2}
                  pb={2}
                  style={{ borderBottom: "1px solid #cccc" }}
                  display="flex"
                  alignItems="center"
                  onClick={handleClose}
                >
                  {userStore.user?.profile_image ? (
                    <Avatar
                      src={`${BASE_URL}${userStore.user?.profile_image}`}
                      size="35"
                      round={true}
                    />
                  ) : (
                    <Avatar
                      name={userStore.user?.userName?.charAt(0)}
                      size="35"
                      round={true}
                      textSizeRatio={1}
                    />
                  )}
                  <Box px={2}>
                    <Typography className={classes.menuName}>
                      {userStore.user?.userName}
                    </Typography>
                    <Typography className={classes.menuEmail}>
                      {userStore.user?.emailId}
                    </Typography>
                  </Box>
                  <ChevronRightIcon style={{ cursor: "pointer" }} />
                </Box>
              </Link>

              <NavLink
                to={`${namespace}/my-profile/bank-and-autoMandate`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItems
                  text={"AutoMandates"}
                  subText="Edit Bank & Auto Mandates"
                  image={"/images/bankautomandate.svg"}
                  navigate={handleClose}
                  //link={'/my-profile/bank-and-autoMandate'}
                />
              </NavLink>
              <NavLink
                to={`${namespace}/my-profile/edit-address`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItems
                  text={"Delivery Address"}
                  subText="Edit Delivery Address for Digital Gold "
                  image={"/images/address.svg"}
                  navigate={handleClose}
                  //link={'/my-profile/edit-address'}
                />
              </NavLink>
              <NavLink
                to={`${namespace}/my-profile/change-mpin`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItems
                  text={"Security Options"}
                  subText="Change Mpin"
                  image={"/images/security.svg"}
                  link={""}
                  navigate={handleClose}
                />
              </NavLink>
              {userStore?.user?.isOnBoardingCompleted && (
                <NavLink
                  to={`${namespace}/my-profile/nominee-details`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <MenuItems
                    text={"Nominee Details"}
                    subText="Edit Nominees"
                    image={"/images/report.svg"}
                    navigate={handleClose}
                  />
                </NavLink>
              )}

              <NavLink
                to={`${namespace}/my-profile/my-orders`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItems
                  text={"Orders"}
                  subText="Mutual fund Orders"
                  image={"/images/report.svg"}
                  navigate={handleClose}
                />
              </NavLink>
              <MenuItems
                text={"About us"}
                subText="Terms & Conditions, Privacy Policy, FAQs"
                image={"/images/about.svg"}
                link={""}
                navigate={() => handle_menu("about")}
              />

              <NavLink
                to={`${namespace}/my-profile/reports`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItems
                  text={"Reports"}
                  subText="Mutual Fund reports"
                  image={"/images/report.svg"}
                  navigate={handleClose}
                />
              </NavLink>

              <MenuItems
                text={"Logout"}
                image={"/images/logout.svg"}
                link={""}
                navigate={handleLogout}
              />
            </div>
          ) : (
            <div>
              <Box px={2} display="flex" alignItems="center">
                <img
                  src="/images/backIcon.svg"
                  height="15px"
                  width="15px"
                  style={{ cursor: "pointer" }}
                  onClick={() => handle_menu("user")}
                />
                <Typography style={{ fontSize: "0.9rem", marginLeft: "10px" }}>
                  About us
                </Typography>
              </Box>

              <NavLink
                to={{ pathname: "//www.neosurge.money/about" }}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItems
                  text={"About us"}
                  subMenu={true}
                  //ubText="Terms & Conditions, Privacy Policy, FAQs"
                  image={"/images/about.svg"}
                  link={""}
                />
              </NavLink>

              <NavLink
                to={`${namespace}/faq`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItems
                  text={"FAQs"}
                  image={"/images/faq.svg"}
                  subMenu={true}
                />
              </NavLink>

              <NavLink
                to={`${namespace}/terms-conditions`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItems
                  text={"Terms & Conditions"}
                  image={"/images/terms.svg"}
                  subMenu={true}
                />
              </NavLink>
            </div>
          )}
        </Menu>
      </Box>
    );
  };

  return (
    <Fragment>
      <Box className={classes.header}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
          px={6}
        >
          <Grid item lg={3}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="start"
              alignItems="center"
            >
              <NavLink to="//www.neosurge.money/">
                <img src="/Neosurge.svg" height={80} width={160} />
              </NavLink>
            </Box>
          </Grid>
          {isLoggedIn ? (
            <Grid item lg={6}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <NavLink
                  to={`${namespace}/dashboard/mutualfund/diy`}
                  className={
                    nav === "mutual" ? classes.navColor : classes.navlink
                  }
                  //style={{ color: "black", textDecoration: "none" }}
                  onClick={() => handleNavColor("mutual")}
                >
                  Mutual Funds
                </NavLink>
                <Box mx={4}>
                  <NavLink
                    to={`${namespace}/dashboard/digitalgold`}
                    className={
                      nav === "gold" ? classes.navColor : classes.navlink
                    }
                    onClick={() => handleNavColor("gold")}
                  >
                    Digital Gold
                  </NavLink>
                </Box>
                <NavLink
                  to={`${namespace}/myportfolio?tab=mutualfund&category=diy`}
                  className={
                    nav === "port" ? classes.navColor : classes.navlink
                  }
                  onClick={() => handleNavColor("port")}
                >
                  My Portfolio
                </NavLink>
              </Box>
            </Grid>
          ) : (
            <>
              <Grid item lg={4}>
                <Search />
              </Grid>
              <Grid item lg={3}></Grid>
            </>
          )}
          <Grid item lg={3}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="end"
              alignItems="center"
              mt={2}
            >
              {/* <img src="/images/Vector.svg" height="20px" width="20px"/> */}
              {isLoggedIn ? (
                <>
                  <Box display="flex" alignItems="center" mx={4}>
                    <Notification />
                  </Box>
                  {userStore.user?.profile_image ? (
                    <Avatar
                      src={`${BASE_URL}${userStore.user?.profile_image}`}
                      size="30"
                      round={true}
                      textSizeRatio={1.6}
                    />
                  ) : (
                    <Avatar
                      name={userStore.user?.userName?.charAt(0)}
                      size="30"
                      round={true}
                      textSizeRatio={1.6}
                      onClick={handleClick}
                    />
                  )}

                  <KeyboardArrowDownOutlinedIcon
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    style={{ cursor: "pointer" }}
                  ></KeyboardArrowDownOutlinedIcon>
                  <Profile />
                </>
              ) : (
                <>
                  <Link to={`${namespace}/onBoard`}>
                    <button className={classes.primaryButton}>
                      Login / Register
                    </button>
                  </Link>
                </>
              )}
            </Box>
          </Grid>
        </Box>
      </Box>

      {/* .................Mobile Header.................................................................. */}
      <Box className={classes.mobileHeader}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={2}
        >
          <img src="/Neosurge.svg" height={80} width={160} />
          {isLoggedIn && (
            <>
              <Box display="flex" alignItems="center">
                <Notification />
              </Box>
              <Box display="flex" alignItems="center">
                {userStore.user?.profile_image ? (
                  <Avatar
                    src={`${BASE_URL}${userStore.user?.profile_image}`}
                    size="30"
                    round={true}
                    textSizeRatio={1.6}
                  />
                ) : (
                  <Avatar
                    name={userStore.user?.userName?.charAt(0)}
                    size="30"
                    round={true}
                    textSizeRatio={1.6}
                    onClick={handleClick}
                  />
                )}

                <KeyboardArrowDownOutlinedIcon
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  style={{ cursor: "pointer" }}
                ></KeyboardArrowDownOutlinedIcon>
                <Profile />
              </Box>
            </>
          )}
          <img
            src="/images/hamburger.svg"
            height="22px"
            width="22px"
            onClick={showDrawer}
          />

          <Drawer anchor="top" open={drawer} onClose={closeDrawer}>
            <List>
              {isLoggedIn ? (
                <>
                  <ListItem>
                    <ListItemText>
                      <Link
                        to={`${namespace}/dashboard/mutualfund/diy`}
                        style={{ color: "black", textDecoration: "none" }}
                      >
                        Mutual Funds
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <Link
                        to={`${namespace}/dashboard/digitalgold`}
                        style={{ color: "black", textDecoration: "none" }}
                      >
                        Digital Gold
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <Link
                        to={`${namespace}/myportfolio?tab=mutualfund&category=diy`}
                        style={{ color: "black", textDecoration: "none" }}
                      >
                        My Portfolio
                      </Link>
                    </ListItemText>
                  </ListItem>
                </>
              ) : (
                <>
                  <ListItem>
                    <ListItemText>
                      <Link
                        to={`${namespace}/onBoard`}
                        style={{ color: "black", textDecoration: "none" }}
                      >
                        Login/Register
                      </Link>
                    </ListItemText>
                  </ListItem>
                </>
              )}
            </List>
          </Drawer>
        </Box>
      </Box>
    </Fragment>
  );
}

export default Header;
