import React,{useState} from 'react'
import { Box, makeStyles,Typography,IconButton,TextField,InputAdornment } from "@material-ui/core";
import {CHANGE_MPIN} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useForm } from "react-hook-form";
import ErrorMessage from '../../../components/Validation/ErrorMessage';
import CustomToast from '../../../components/UI/CustomToast';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const Index = () => {
    const useStyles = makeStyles((theme) => ({
        root:{
            boxShadow:"0px 0px 10px rgba(0, 0, 0, 0.12)",
            borderRadius:"8px",
            border:"1px solid #DDDDDD",
            width:'25%',
            padding:"20px"
            
        },
        subTabtext: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
          },
          textUnderline: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
          },

          inputBox: {
            background: "#E6E6E6",
            padding: "0.3rem 0.6rem",
            borderRadius: "10px",
            border: "none",
            fontFamily:"inherit",
            marginBottom:"10px",
            width:"-webkit-fill-available",
            "& .MuiInputBase-root":{
              fontSize:"0.9rem",
            }
          },
          text: {
            fontSize: "0.8rem",
            textAlign: "center",
            fontFamily:"inherit",
            marginTop:"10px",
            fontWeight:500
          },
          primaryButton: {
            padding: "0.6rem 1.5rem",
            background: "#326CF9",
            color: "#fff",
            fontSize: "0.8rem",
            fontFamily:"inherit",
            fontWeight: 600,
            borderRadius: "45px",
            border: "1px solid #326CF9",
            marginRight: "15px",
            cursor:"pointer",
            "& .MuiCircularProgress-root":{
              padding:"0px 20px"
            }
          },

          iconButton:{
            padding:"2px"
          }

    }))
    const classes=useStyles()
    const { register, handleSubmit, formState: { errors },setValue,reset  } = useForm();

    const [error,setError]=useState('')
    const [viewPassword,setViewPassword]=useState({current:false,new:false,confirm:false})

    const handle_viewPin=(type)=>{
      setViewPassword({...viewPassword,[type]:!(viewPassword[type])})
    }


 
    const change_mpin= async(formdata)=>{
        setError(false)
        if(formdata?.newPin!=formdata?.confirmPin){
            setError("Mpin is not matching ")
        }else{
        const httpConfig = {
            method: "POST",
            tokenType: "user",
            headers: {
              "Content-Type": "application/json",
            },
            data:{oldMpin:formdata?.correntPin,newMpin:formdata?.newPin}
          }; 

          try{
            await httpInstance(`${CHANGE_MPIN}`,httpConfig)
            CustomToast("Mpin Changed Successfully","SUCCESS")
            reset({
                correntPin:'',
                newPin:'',
                confirmPin:''
            })
          }
          catch(error){
            setError(error.response.data?.message)
          }
        }
    }
  return (
    <div>
        
        <Box
        display="flex"
        alignItems="start"
        px={{ xs: 1, md: 17 }}
        pt={2}
        style={{ borderBottom: "1px solid #E6E3E3",}}
      >
      
          <Typography className={classes.subTabtext}>
            My Profile
          </Typography>
       
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography
            className={classes.textUnderline}
          >
            Security Options
          </Typography>
        </Box>
      </Box>
        <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        mt={3}
      >
        <Typography style={{fontSize:"1.2rem", fontWeight:500}}>Change Mpin</Typography>
        <Box className={classes.root} mt={3}>
        <form onSubmit={handleSubmit(change_mpin)}>
        <label className={classes.text}>Enter Current Mpin</label><br/>
            <TextField 
                className={classes.inputBox}
                inputProps={{ maxLength: 4 }}
                type= {viewPassword.current ? "text":"password"}
                {...register("correntPin", {
                    required:true,
                    maxLength: 4,
                    minLength:4,
                    onChange:(e)=>setValue("correntPin", e.target.value.replace(/[^0-9]/g, ""))
                  })}
                  InputProps={{ 
                    disableUnderline: true,
                    endAdornment:(
                      <InputAdornment position="end" >
                      <IconButton
                        className={classes.iconButton}
                        onClick={()=>handle_viewPin("current")}
                        //onMouseDown={this.handleMouseDownPassword}
                      >
                       {viewPassword.current ? <VisibilityIcon/> : <VisibilityOffIcon /> }
                      </IconButton>
                      </InputAdornment>
                      )
                  
                  }}
            /><br/>
            {errors.correntPin && 
            (errors.correntPin.type=="required" ? <ErrorMessage message={"Please Enter Current Mpin"}/> :<ErrorMessage message={"Mpin length must be of 4 numbers "}/>)
            
            }
        <label className={classes.text}>Enter New Mpin</label><br/>
            <TextField 
                className={classes.inputBox}
                inputProps={{ maxLength: 4 }}
                type={viewPassword.new ? "text":"password"}
                {...register("newPin", {
                    required:true,
                    maxLength: 4,
                    minLength:4,
                    onChange:(e)=>setValue("newPin", e.target.value.replace(/[^0-9]/g, ""))
                  })}
                  InputProps={{ 
                    disableUnderline: true,
                    endAdornment:(
                      <InputAdornment position="end" >
                      <IconButton
                        className={classes.iconButton}
                        onClick={()=>handle_viewPin("new")}
                        //onMouseDown={this.handleMouseDownPassword}
                      >
                        {viewPassword.new ? <VisibilityIcon/> : <VisibilityOffIcon /> }
                      </IconButton>
                      </InputAdornment>
                      )
                  
                  }}
            /><br/>
            {errors.newPin && 
            ( errors.newPin.type=="required" ? <ErrorMessage message={"Please Enter New Mpin"}/> :<ErrorMessage message={"Mpin length must be of 4 numbers "}/>)
            
            }
            <label className={classes.text}>Confirm Mpin</label><br/>
            <TextField 
                className={classes.inputBox}
                inputProps={{ maxLength: 4 }}
                type={viewPassword.confirm ? "text":"password"}
                {...register("confirmPin", {
                    required:true,
                    maxLength: 4,
                    minLength:4,
                    onChange:(e)=>setValue("confirmPin", e.target.value.replace(/[^0-9]/g, ""))
                  })}
                InputProps={{ 
                  disableUnderline: true,
                  endAdornment:(
                    <InputAdornment position="end" >
                    <IconButton
                      className={classes.iconButton}
                      onClick={()=>handle_viewPin("confirm")}
                      //onMouseDown={this.handleMouseDownPassword}
                    >
                      {viewPassword.confirm ? <VisibilityIcon/> : <VisibilityOffIcon /> }
                    </IconButton>
                    </InputAdornment>
                    )
                
                }}

            /><br/>
            {errors.confirmPin && 
           ( errors.confirmPin.type=="required" ? <ErrorMessage message={"Please Confirm New Mpin"}/> :<ErrorMessage message={"Mpin length must be of 4 numbers "}/>)
           
           
           }


           {error && <ErrorMessage message={error}/>}
           <Box display="flex" justifyContent="center" mt={3}><button className={classes.primaryButton}>Change Mpin</button></Box> 
           </form>
        </Box>
        </Box>
    </div>
  )
}

export default Index