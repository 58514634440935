import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Paper,
  InputBase,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ReactLoading from "react-loading";
import SearchIcon from "@mui/icons-material/Search";
import StpCard from "./StpCard";
import { GET_STP_ALTERNATIVE } from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";

const Index = () => {
  const useStyles = makeStyles({
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      textDecoration: "underline",
      fontFamily: "inherit",
    },

    title: {
      fontSize: "1.1rem",
      fontWeight: 600,
    },
    textgrey: {
      fontSize: "0.8rem",
      color: "#626262",
    },
    searchBar: {
      padding: "5px 8px",
      display: "flex",
      alignItems: "center",
      margin: "20px 0px 30px 0px",
      borderRadius: "10px",
      "& .MuiSvgIcon-root": {
        opacity: 0.4,
      },
    },
    input: {
      marginLeft: "10px",
      flex: 1,
      width: 100,
    },
  });
  const classes = useStyles();

  const [fundList, setFundList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();
  const { fundData, schemeData, stpData } = state;
  const [filteredFund, setFilteredFund] = useState([]);

  const get_fund_list = async () => {
    setLoading(true);
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        amc_code: fundData?.amc_code,
        scheme_code: fundData?.scheme_code,
        stp_amount: schemeData?.redeemableAmount,
      },
    };
    try {
      const response = await httpInstance(`${GET_STP_ALTERNATIVE}`, httpConfig);
      setFundList(response.data?.data);
      setFilteredFund(response.data?.data);
      setLoading(false);
    } catch {}
  };

  useEffect(() => {
    get_fund_list();
  }, []);

  const handle_search = (e) => {
    let filteredArray = fundList.filter((item) =>
      item.scheme_name.toLowerCase().includes(e.target.value)
    );
    setFilteredFund(filteredArray);
  };

  return (
    <div>
      <Box
        display="flex"
        alignItems="start"
        px={{ xs: 1, md: 17 }}
        pt={2}
        style={{ borderBottom: "1px solid #E6E3E3" }}
      >
        <Link
          to="/myportfolio?tab=mutualfund&category=diy"
          style={{ color: "black" }}
        >
          <Typography className={classes.textUnderline}>
            Mutual Funds
          </Typography>
        </Link>
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography className={classes.subTabtext}>
            Systematic Withdrawal Plan
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={17}
        mt={2}
        className={classes.backButton}
      >
        <Link to="/myportfolio?tab=mutualfund&category=diy">
          <img src="/images/backIcon.svg" height="20px" width="20px" />
        </Link>
      </Box>

      <Box px={{ xs: 1, md: 17 }} mt={2}>
        <Typography className={classes.title}>
          STP ( Systematic Transfer Plan)
        </Typography>
        <Typography className={classes.textgrey}>
          Tansfer a fixed amount of money every month from a mutual fund
        </Typography>

        <Box mt={4}>
          <Typography className={classes.textgrey}>
            SELECT THE FUND YOU WANT TO START STP FOR
          </Typography>

          {/* search */}
          <Paper className={classes.searchBar} variant="outlined">
            <SearchIcon />
            <InputBase
              className={classes.input}
              placeholder="Search Funds"
              inputProps={{ "aria-label": "Search for Mutual Funds" }}
              onChange={handle_search}
            />
          </Paper>

          {/* list */}

          {!loading ? (
            filteredFund?.map((fund, i) => {
              return (
                <StpCard
                  key={i}
                  fundToData={fund}
                  schemeData={schemeData}
                  fundFromData={fundData}
                  stpData={stpData}
                />
              );
            })
          ) : (
            <Box mt={3} display="flex" justifyContent="center">
              <ReactLoading
                type="spinningBubbles"
                color="#0C97F0"
                height={"4%"}
                width={"4%"}
              />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default Index;
