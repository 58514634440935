import React,{useState,useEffect} from 'react'
import { Box,makeStyles,Typography,Table,TableRow,TableHead,TableBody,TableCell } from '@material-ui/core'
import {GET_RECENT_ACTION} from '../../../utils/endpoints'
import httpInstance from '../../../utils/instance'
import moment from 'moment'
import Pagination from '../../../components/Admin/Pagination'

const actionType={
    RATIO:"Ratio",
    QUESTIONNAIRE_ADD:"Questionnaire Added",
    QUESTIONNAIRE_DELETE:"Questionnaire Deleted",
    QUESTIONNAIRE_UPDATE:"Questionnaire Updated",
    ASSISTED_ADD:"Assisted Added",
    ASSISTED_REMOVE:"Assisted Removed",
    TRIGGER:"Trigger"
}

const RecentActions = () => {
    const useStyles = makeStyles({
        root: {
            background: "#fff",
            borderRadius: "20px",
          },
          header:{
            color:"#686868",
            fontSize:"1rem",
            fontWeight:600,
            padding:"0px 10px"
        },
        table:{
            margin:"1rem 0px",
            "& .MuiTableCell-root":{
                fontSize:"0.8rem"
            },
            "& .MuiTableHead-root":{
                background:"#D3F0FF",
                "& .MuiTableCell-root":{
                    color:"#272727",
                    fontWeight:600
    
                }
            },
            "& .MuiTableBody-root":{
                background:"#F9FBFC",
            }
        }
    })

    const classes = useStyles();

    const [actions,setActions]=useState([])
    const [totalNumber,setTotalNumber]=useState(0)
    const [loading,setLoading]=useState(true)
    const [currentPage,setCurrentPage]=useState(1)

    const get_action= async()=>{
        const httpConfig = {
            method: "GET",
            //tokenType: "user",
            headers:{
              "Content-Type": "application/json",
            },
            params:{isAllData:true,pageNumber:currentPage}
          };

          try{
            const response=await httpInstance(`${GET_RECENT_ACTION}`,httpConfig)
            setActions(response.data?.data?.recentActions)
            setTotalNumber(response.data.data?.count)
            setLoading(false)
          }
          catch{

          }
    }

    useEffect(()=>{
        get_action()
    },[currentPage])

    const onPaginate=(pageNumber)=>{
        if(pageNumber != currentPage) 
        setCurrentPage(pageNumber)
      }
  return (
    <div>
        <Box className={classes.root} mt={3} p={3}>
        <Box display="flex">
            <img src="/images/admin/user.svg" height="20px" width="15px" />
            <Typography className={classes.header}>
            Recent Actions
            </Typography>
          </Box>

          <Table className={classes.table}>
            <TableHead>
            <TableRow>
                    <TableCell align="left">Change</TableCell>
                    <TableCell align="left">Actions</TableCell>
                    <TableCell align="left">Admin</TableCell>
                    <TableCell align="left">Approved by</TableCell>
                    <TableCell align="left">Date</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
                {actions?.map((ele)=>{
                    return(
                        <TableRow key={ele.id}>
                            <TableCell>{ele.type}</TableCell>
                            <TableCell>{ele.justification}</TableCell>
                            <TableCell>{ele.requestedBy.userName}</TableCell>
                            <TableCell>{ele.approvedBy.userName}</TableCell>
                            <TableCell>{moment(ele.updatedAt).format("DD/MM/YYYY")}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
        {!loading && 
         <Pagination
            totalNumber={totalNumber}
            itemsPerPage={10}
            onPaginate={onPaginate}
         />
        }
        </Box>
    </div>
  )
}

export default RecentActions