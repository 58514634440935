import React, { useState } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import GetDelivery from "./GetDelivery";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
  Link,
} from "react-router-dom";
import GoldCoin from "./GoldCoin";
import DeliveryAddress from "./DeliveryAddress";
import DeliveryOrder from "./DeliveryOrder";
import { namespace } from "../../../environment";

const Index = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      borderBottom: "1px solid #E6E3E3",
    },
    text: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      textDecoration: "underline",
      fontFamily: "inherit",
    },
    link: {
      textDecoration: "none",
      color: "black",
    },
  }));
  const classes = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get("tab");

  const [sku, setSku] = useState("");
  const [name, setName] = useState("");
  const [addressId, setAddressId] = useState();
  const [metal, setMetal] = useState("");
  const [checkoutData, setCheckoutData] = useState();

  const handleSku = (data) => {
    setSku(data);
  };

  const setProductName = (data) => {
    setName(data);
  };

  const handleAddressId = (id) => {
    setAddressId(id);
  };

  const handleMetal = (type) => {
    setMetal(type);
  };

  const handleCheckoutData = (data) => {
    setCheckoutData(data);
  };

  return (
    <div>
      <Box
        display="flex"
        alignItems="start"
        px={{ xs: 1, md: 17 }}
        pt={2}
        className={classes.root}
      >
        <Link
          to={`${namespace}/myportfolio?tab=digitalgold`}
          className={classes.link}
        >
          {" "}
          <Typography className={classes.textUnderline}>
            My Portfolio
          </Typography>
        </Link>
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Link
            to={`${namespace}/myportfolio/delivery?tab=getDelivery`}
            className={classes.link}
          >
            {" "}
            <Typography
              className={
                tab == "getDelivery" ? classes.text : classes.textUnderline
              }
              display="flex"
              alignItems="start"
            >
              Get Digital Gold Delivery
            </Typography>
          </Link>
        </Box>
        {tab != "" && tab != "getDelivery" && (
          <Box display="flex">
            <ChevronRightIcon style={{ height: "1rem" }} />
            <Link
              to={`${namespace}/myportfolio/delivery?tab=delivery-address`}
              className={classes.link}
            >
              {" "}
              <Typography
                className={
                  tab == "delivery-address"
                    ? classes.text
                    : classes.textUnderline
                }
                display="flex"
                alignItems="start"
              >
                Select Address
              </Typography>
            </Link>
          </Box>
        )}
        {tab != "" && tab != "getDelivery" && tab != "delivery-address" && (
          <Box display="flex">
            <ChevronRightIcon style={{ height: "1rem" }} />
            <Link
              to={`${namespace}/myportfolio/delivery?tab=goldcoin`}
              className={classes.link}
            >
              {" "}
              <Typography
                className={
                  tab == "goldcoin" ? classes.text : classes.textUnderline
                }
                display="flex"
                alignItems="start"
              >
                {name}
              </Typography>
            </Link>
          </Box>
        )}

        {tab != "" &&
          tab != "getDelivery" &&
          tab != "delivery-address" &&
          tab != "goldcoin" && (
            <Box display="flex">
              <ChevronRightIcon style={{ height: "1rem" }} />
              <Link
                to={`${namespace}/myportfolio/delivery?tab=order-summary`}
                className={classes.link}
              >
                {" "}
                <Typography
                  className={
                    tab == "order-summary"
                      ? classes.text
                      : classes.textUnderline
                  }
                  display="flex"
                  alignItems="start"
                >
                  Order Summary
                </Typography>
              </Link>
            </Box>
          )}
      </Box>
      {tab === "getDelivery" && (
        <GetDelivery handleSku={handleSku} setProductName={setProductName} />
      )}
      {tab === "delivery-address" && (
        <DeliveryAddress handleAddressId={handleAddressId} />
      )}
      {tab === "goldcoin" && (
        <GoldCoin
          sku={sku}
          addressId={addressId}
          handleMetal={handleMetal}
          handleCheckoutData={handleCheckoutData}
        />
      )}
      {tab === "order-summary" && (
        <DeliveryOrder
          sku={sku}
          addressId={addressId}
          metal={metal}
          checkoutRate={checkoutData}
        />
      )}
    </div>
  );
};
export default Index;
