import React from "react";
import FirstService from "./assistedServicesComponents/FirstService";
import SecondService from "./assistedServicesComponents/SecondService";
import ThirdService from "./assistedServicesComponents/ThirdService";
import {
  Box,
  Fade,
  makeStyles,
} from "@material-ui/core";
import SliderBar from "./SliderBar";


function AssistedServices({ handleShowModal}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      
      background: "#fff",
      padding: "0rem 0rem 5rem 0rem",
      backgroundPosition: "left",
      backdropFilter: "blur(10px)",
      backgroundRepeat: "no repeat"
    },
    backDrop: {
      padding: "1rem 0",
      

    },
    title1: {
      fontSize: "1.6rem",
      fontWeight: 700,
      color: "#2c2c2c",
      marginBottom: "1rem"
    },
    titleStep: {
      color: "#A8A8A8",
      fontWeight: "bold",
      fontSize: "1.2rem",
      margin: "1.6em 0em"
    },
    titleStepDescription: {
      color: "#272727",
      fontWeight: 500,
      fontSize: "1.8rem"
    },
    paragraph: {
      fontSize: "1rem",
      fontWeight: "normal",
      color: "#2c2c2c"
    },
    buttonHolder: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0px 0px 1rem 0px"
    },
    button: {
      border: "none",
      borderRadius: "40px",
      fontWeight: "bold",
      background: "#326CF9",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      padding: "1rem 3.2rem",
      fontSize:"1rem",
      cursor:"pointer"
    },
    assisted: {
      zIndex:10,
      position:"sticky",

      display: "block",
      "&::-webkit-scrollbar": {
        width: "0 !important"
      }
    },
    MobileAssisted: {
      display: "none"
    },
    "@media (max-width: 768px)": {
      paragraph: {
        textAlign: "center"
      },
      assisted: {
        display: "none"
      },
      buttonHolder: {
       paddingTop:"3rem"
        
      },
      MobileAssisted: {
        display: "block"
      }
    }
  }));
  const classes = useStyles();

  const [step, setStep] = React.useState(1);
  const [time, setTime] = React.useState(Date.now());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setStep((prevCount) => prevCount + 1);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

 
    if (step === 4) {
      setStep(1);
    }
 

  
  return (
    <div className={classes.root}>
      <div className={classes.backDrop}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Box className={classes.title1}>Assisted Services</Box>
          <Box className={classes.paragraph}>
            We Map the right portfolios for you based on your investment plans
          </Box>
        </Box>
        <Box className={classes.MobileAssisted}>
          
          {step == 1 ? (
            <Fade>
              <FirstService handleShowModal={handleShowModal}/>
            </Fade>
          ) : step == 2 ? (
            <Fade>
              <ThirdService handleShowModal={handleShowModal}/>
            </Fade>
          ) : step == 3 ? (
            <Fade>
              <SecondService handleShowModal={handleShowModal}/>
            </Fade>
          ) : (
            ""
          )}
          
        </Box>
        <Box className={classes.assisted}>
          <FirstService handleShowModal={handleShowModal} />
          <ThirdService handleShowModal={handleShowModal}/>
          <SecondService handleShowModal={handleShowModal}/>
        </Box>

        <Box mt={6}>
          <SliderBar step={step} bar={3} />
        </Box>
        <Box className={classes.buttonHolder} mt={0}>
          <button className={classes.button} onClick={handleShowModal}>Get started</button>
        </Box>
      </div>
    </div>
  );
}

export default AssistedServices;
