import React from 'react'
import { Outlet } from 'react-router-dom';
import Header from '../header/Header';
import Footer from '../Footer';
import ReactGA from 'react-ga';



const MainLayout = () => {
  ReactGA.initialize('UA-223175415-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div>
        <>
          <Header />
          <Outlet />
          <Footer/>
        </>
    </div>
  )
}

export default MainLayout