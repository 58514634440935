import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { useStyles } from "../styles";
import { useForm, Controller } from "react-hook-form";
import ErrorMessage from "../../Validation/ErrorMessage";
import {
  GET_ALL_RELATIONSHIP,
  ADD_NOMINEES,
  GET_NOMINEES,
  DELETE_NOMINEE,
  KYC_STATUS,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams } from "react-router-dom";
import moment from "moment";
import Nominees from "./Nominees";
import CustomToast from "../../UI/CustomToast";
import { namespace } from "../../../environment";

function NomineeForm({ type }) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    defaultValues: {
      relationShips1: "",
      relationShips2: "",
      relationShips3: "",
      gaurdRelationShip1: "",
      gaurdRelationShip2: "",
      gaurdRelationShip3: "",
    },
  });

  const navigate = useNavigate();

  const [nomineesCount, setNomineesCount] = useState(1);
  const [nomineesObj, setNomineesObj] = useState({});
  const [relation, setRelation] = useState([]);
  const [dob, setDob] = useState({
    dob1: null,
    dob2: null,
    dob3: null,
  });
  const [dob1Error, setDob1Error] = useState(false);
  const [dob2Error, setDob2Error] = useState(false);
  const [dob3Error, setDob3Error] = useState(false);
  const [isMinor, setIsMinor] = useState({});
  const [percentageError, setPercentageError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allNomineeData, setAllNomineeData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [isComplient, setIsComplient] = useState(false);

  const resetData = (data) => {
    setNomineesObj({
      ...nomineesObj,
      nominee2: data[1] && true,
      nominee3: data[2] && true,
    });
    setDob({
      dob1: data[0]?.nomineeDateOfBirth,
      dob2: data[1]?.nomineeDateOfBirth,
      dob3: data[2]?.nomineeDateOfBirth,
    });
    setIsMinor({
      ...isMinor,
      age1: get_age(data[0]?.nomineeDateOfBirth),
      age2: get_age(data[1]?.nomineeDateOfBirth),
      age3: get_age(data[2]?.nomineeDateOfBirth),
    });
  };

  const get_kyc_status = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${KYC_STATUS}`, httpConfig);
      setIsComplient(response.data?.data?.isKycCompliant);
    } catch {}
  };

  useEffect(() => {
    get_kyc_status();
  }, []);

  const get_all_nominees = async () => {
    const httpConfig = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await httpInstance(
        `${GET_ALL_RELATIONSHIP}`,
        httpConfig
      );
      setRelation(response?.data?.data);
      const nominee = await httpInstance(`${GET_NOMINEES}`, httpConfig1);
      setAllNomineeData(nominee.data?.data);
      setNomineesCount(nominee.data?.data.length);
      resetData(nominee.data?.data);
    } catch {}
  };

  useEffect(() => {
    get_all_nominees();
  }, [flag]);

  const handleAddNominees = () => {
    if (nomineesCount < 3) {
      if (nomineesCount == 1) {
        setNomineesObj({ ...nomineesObj, nominee2: true });
        setDob({ ...dob, dob2: null });
      } else if (nomineesCount == 2 && nomineesObj.nominee2 === true) {
        setNomineesObj({ ...nomineesObj, nominee3: true });
        setDob({ ...dob, dob3: null });
      } else {
        setNomineesObj({ ...nomineesObj, nominee2: true });
        setDob({ ...dob, dob3: null });
      }
      setNomineesCount(nomineesCount + 1);
    }
  };

  const get_age = (date) => {
    let year = moment(date).format("YYYY-MM-DD");
    const age = moment().diff(year, "years", false);
    return age;
  };

  const handleDob1Change = (date) => {
    setDob({ ...dob, dob1: date });
    setIsMinor({ ...isMinor, age1: get_age(date) });
  };

  const handleDob2Change = (date) => {
    setIsMinor({ ...isMinor, age2: get_age(date) });
    setDob({ ...dob, dob2: date });
  };

  const handleDob3Change = (date) => {
    setIsMinor({ ...isMinor, age3: get_age(date) });
    setDob({ ...dob, dob3: date });
  };

  //-----------------------------delete nomimess by id---------------------------------------------------------------//

  const delete_nominee = async (nomineesId, index) => {
    const formData = new FormData();
    formData.append("id", nomineesId);
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      await httpInstance(`${DELETE_NOMINEE}`, httpConfig);
      setFlag(!flag);
    } catch {}
  };

  const handleDelete = (count) => {
    if (count === "2") {
      setNomineesObj({ ...nomineesObj, nominee2: false });
      setNomineesCount(nomineesCount - 1);
      delete_nominee(allNomineeData[1].id, 1);
    } else if (count === "3") {
      setNomineesObj({ ...nomineesObj, nominee3: false });
      setNomineesCount(nomineesCount - 1);
      delete_nominee(allNomineeData[2].id, 2);
    }
  };
  //-------------------------------------------------------------------------------------------------------//

  const submit_nominees = async (data) => {
    let nominee1Data = {
      nomineeFullName: data.fullName1.trim(),
      nomineeDateOfBirth: moment(dob.dob1).format("YYYY-MM-DD"),
      nomineeRelationTypeId: data.relationShips1,
      allocationPercentage: parseInt(data.percentage1),
    };
    if (data.guardianName1) {
      nominee1Data = {
        ...nominee1Data,
        guardianName: data.guardianName1.trim(),
        guardianRelationshipId: data.gaurdRelationShip1,
      };
    }
    if (allNomineeData && allNomineeData[0]?.id)
      nominee1Data = { ...nominee1Data, id: allNomineeData[0]?.id };
    // if(!(nominee1Data.nomineeDateOfBirth)){
    //   setDobError({...dobError,dob1Error:true})
    // }

    //check_dobError(dob.dob1, "dob1Error");
    if (dob.dob1 === null) {
      setDob1Error(true);
    } else {
      setDob1Error(false);
    }
    let formData = [{ ...nominee1Data }];

    if (nomineesObj.nominee2) {
      let nomineeData = {
        nomineeFullName: data.fullName2.trim(),
        nomineeDateOfBirth: moment(dob.dob2).format("YYYY-MM-DD"),
        nomineeRelationTypeId: data.relationShips2,
        allocationPercentage: parseInt(data.percentage2),
      };
      if (data.guardianName2) {
        nomineeData = {
          ...nomineeData,
          guardianName: data.guardianName2.trim(),
          guardianRelationshipId: data.gaurdRelationShip2,
        };
      }
      if (allNomineeData && allNomineeData[1]?.id)
        nomineeData = { ...nomineeData, id: allNomineeData[1]?.id };
      if (!dob.dob2) {
        setDob2Error(true);
      } else {
        setDob2Error(false);
      }
      //check_dobError(dob.dob2, "dob2Error");
      formData = [...formData, { ...nomineeData }];
    }

    if (nomineesObj.nominee3) {
      let nomineeData = {
        nomineeFullName: data.fullName3.trim(),
        nomineeDateOfBirth: moment(dob.dob3).format("YYYY-MM-DD"),
        nomineeRelationTypeId: data.relationShips3,
        allocationPercentage: parseInt(data.percentage3),
      };
      if (data.guardianName3) {
        nomineeData = {
          ...nomineeData,
          guardianName: data.guardianName3.trim(),
          guardianRelationshipId: data.gaurdRelationShip3,
        };
      }
      if (allNomineeData && allNomineeData[2]?.id)
        nomineeData = { ...nomineeData, id: allNomineeData[2]?.id };
      //check_dobError(dob.dob3, "dob3Error");
      if (!dob.dob3) {
        setDob3Error(true);
      } else {
        setDob3Error(false);
      }
      formData = [...formData, { ...nomineeData }];
    }

    //---------------totla percente--------------------------------------------------//
    const totalPercentage = formData.reduce((accumulator, object) => {
      return accumulator + object.allocationPercentage;
    }, 0);

    if (totalPercentage > 100) {
      setPercentageError("Total Percentage Exceeded");
    } else if (totalPercentage < 100) {
      setPercentageError("Total of 100% Percentage needed");
    } else {
      setPercentageError("");
    }

    //------------------check dob error-------------------------------------------------------//

    //--------------------------------------------------------------//
    // const isDateError = Object.values(dobError).every(
    //   (value) => value === false
    // );
    if (!dob1Error && !dob2Error && !dob3Error) {
      setLoading(true);
      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: { nomineeData: formData },
      };

      try {
        await httpInstance(`${ADD_NOMINEES}`, httpConfig);
        setLoading(false);
        type
          ? CustomToast("Nominee Details Updated", "SUCCESS")
          : navigate({
              pathname: `${namespace}/signIn`,
              search: `?${createSearchParams({ stepcount: "11" })}`,
            });
      } catch (error) {
        //setPercentageError(error.response.data.message)
        setLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Box display={"flex"} justifyContent="center">
        <Box display={"flex"} alignItems="flex-start" flexDirection={"column"}>
          {/* <Box>
          <TitleLayoult
            title="Add Nominees"
            description="Please add Nominees for your Mutual Fund Investments"
          />
        </Box> */}

          <form onSubmit={handleSubmit(submit_nominees)}>
            <Nominees
              count="1"
              register={register}
              errors={errors}
              handleDobChange={handleDob1Change}
              dobValue={dob.dob1}
              age={isMinor.age1}
              dobError={dob1Error}
              relation={relation}
              control={control}
              reset={reset}
              nomineeData={allNomineeData && allNomineeData[0]}
              allNoominees={allNomineeData}
            />

            {nomineesObj.nominee2 && (
              <Nominees
                count="2"
                register={register}
                errors={errors}
                handleDobChange={handleDob2Change}
                dobValue={dob.dob2}
                age={isMinor.age2}
                handleDelete={handleDelete}
                dobError={dob2Error}
                relation={relation}
                control={control}
                reset={reset}
                nomineeData={allNomineeData && allNomineeData[1]}
                allNoominees={allNomineeData}
              />
            )}
            {/* {nomineesObj.nominee2 && (
            <Nominees
              count="2"
              register={register}
              errors={errors}
              handleDobChange={handleDob3Change}
              dobValue={dob.dob2}
              age={isMinor.age2}
              handleDelete={handleDelete}
              dobError={dob2Error}
              relation={relation}
              control={control}
              reset={reset}
              nomineeData={allNomineeData && allNomineeData[1]}
              allNoominees={allNomineeData}
            />
          )} */}
            {nomineesObj.nominee3 && (
              <Nominees
                count="3"
                register={register}
                errors={errors}
                handleDobChange={handleDob3Change}
                dobValue={dob.dob3}
                age={isMinor.age3}
                handleDelete={handleDelete}
                dobError={dob3Error}
                relation={relation}
                control={control}
                reset={reset}
                nomineeData={allNomineeData && allNomineeData[2]}
                allNoominees={allNomineeData}
              />
            )}

            <Box
              display={"flex"}
              alignItems={"flex-start"}
              justifyContent="flex-start"
              flexDirection={"row"}
              onClick={handleAddNominees}
              py={1}
            >
              <img src="/images/Group.svg" height={16} width={16} />
              <Box fontSize={12} ml={1} style={{ cursor: "pointer" }}>
                Add More
              </Box>
            </Box>

            {percentageError && <ErrorMessage message={percentageError} />}

            <Box mt={2}>
              <button className={classes.primaryButton} type="submit">
                {loading ? (
                  <CircularProgress size="1rem" color="inherit" />
                ) : type ? (
                  "Save"
                ) : (
                  "Next"
                )}
              </button>
            </Box>
          </form>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default NomineeForm;
