import {
  Box,
  CircularProgress,
  Select,
  FormControl,
  MenuItem,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import TitleLayoult from "../TitleLayoult";
import AddressProofFile from "../uploader/AddressProofFile";
import { useStyles } from "../styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useForm, Controller } from "react-hook-form";
import ErrorMessage from "../../Validation/ErrorMessage";
import TextInputField from "../Input/TextInputField";
import DatePickerField from "../Input/DatePickerField";
import moment from "moment";
import { UPLOAD_ADDRESS_PROOF, GET_KYC_DATA } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams } from "react-router-dom";
import { namespace } from "../../../environment";

function ProofofAddress({ step }) {
  const classes = useStyles();

  const defaultValues = {
    proofType: "",
    proofNumber: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm({ defaultValues, mode: "onChange" });

  const navigate = useNavigate();

  const [upload, setUpload] = React.useState(false);
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [uploadButton, setEnableUploadButton] = React.useState(false);
  const [issueDate, setIssueDate] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [issueDateError, setIssueDateError] = useState(false);
  const [expiryDateError, setExpiryDateError] = useState(false);
  const [showdate, setShowDate] = useState(false);
  const [document, setDocument] = useState({});
  const [validateError, setValidateError] = useState(false);
  const [adharNum, setAdharNum] = useState("");
  const [proofType, setProofType] = useState("");

  //---------------------validation------------------------------//
  const validPassport = /^[A-Z]{1}[0-9]{7}$/;
  const validVoterId = /^[A-Z]{3}[0-9]{7}$/;
  const validDriving =
    /^(([A-Z]{2}[0-9]{2})|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
  const validAdhar = /^[0-9]$/;

  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        input: {
          padding: "8px 10px",
        },
      },
      MuiSelect: {
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  });

  //--------------------------------get address data--------------------------------------------------------------------//
  const get_address_details = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await httpInstance(`${GET_KYC_DATA}`, httpConfig);
      const addressData = response.data?.data;
      reset({
        proofType: addressData.adressProofType?.id,
        proofNumber: addressData.addressProofNum,
      });
      if (
        addressData.adressProofType?.id == 1 ||
        addressData.adressProofType?.id == 3
      ) {
        setShowDate(true);
      }
      setProofType(addressData.adressProofType?.id);
      setIssueDate(
        new Date(
          moment(addressData.addressProofIssueDate, "DD/MM/YYYY").format(
            "YYYY/MM/DD"
          )
        )
      );
      setExpiryDate(
        new Date(
          moment(addressData.addressProofExpiryDate, "DD/MM/YYYY").format(
            "YYYY/MM/DD"
          )
        )
      );
      setDocument({
        front: addressData.userKycFilesData.addressProofFrontPublicUrl,
        back: addressData.userKycFilesData.addressProofBackPublicUrl,
      });
    } catch {}
  };

  useEffect(() => {
    get_address_details();
  }, []);

  const handleForntImage = (file) => {
    setFrontImage(file[0]);
  };

  const handleBackImage = (file) => {
    setBackImage(file[0]);
  };

  const handleissueDate = (date) => {
    setIssueDate(date);
  };

  const handleExpiryDate = (date) => {
    setExpiryDate(date);
  };

  const handleProofType = (e) => {
    setProofType(e.target.value);
    if (e.target.value == 1 || e.target.value == 3) {
      setShowDate(true);
    } else {
      setShowDate(false);
    }
  };

  useEffect(() => {
    if (document.front) {
      setEnableUploadButton(true);
    } else if (frontImage && backImage) {
      setEnableUploadButton(true);
    }
  }, [frontImage, backImage, document]);

  const handleUploadButton = () => setEnableUploadButton(false);

  const handle_Upload = () => {
    if (showdate && !issueDate) {
      setIssueDateError(true);
      setExpiryDateError(false);
    } else if (showdate && !expiryDate) {
      setExpiryDateError(true);
      setIssueDateError(false);
    } else {
      setUpload(true);
      setLoading(true);
      setIssueDateError(false);
      setExpiryDateError(false);
    }
  };

  //-----------------------verification address proof-----------------------------------------------//
  const verify_proof = (type, number) => {
    if (type == 1) {
      if (!validPassport.test(number)) {
        setLoading(false);
        setUpload(false);
        return false;
      } else {
        return true;
      }
    } else if (type == 2) {
      if (!validVoterId.test(number)) {
        setLoading(false);
        setUpload(false);
        return false;
      } else {
        return true;
      }
    } else if (type == 3) {
      if (!validDriving.test(number)) {
        setLoading(false);
        setUpload(false);
        return false;
      } else {
        return true;
      }
    } else if (type == 4) {
      if (!/^([0-9]{4})$/.test(number)) {
        setLoading(false);
        setUpload(false);
        return false;
      } else {
        return true;
      }
    }
  };
  //---------------------------------handle adhar number-----------------------------------//
  const handleAdhar = (e) => {
    const { value } = e.target;
    if (proofType == 4) {
      if (value.length !== 8) {
        if (value.length === 4 || value.length === 9 || value.length === 14) {
          setValue("proofNumber", value + " ");
        }
      }
    }
  };
  //---------------------------------------------------------------------------//
  const handle_submit = async (data) => {
    //setValidateError(false)
    //verify_proof(data.proofType, data.proofNumber)

    if (showdate && !issueDate) {
      setIssueDateError(true);
    } else if (showdate && !expiryDate) {
      setIssueDateError(false);
      setExpiryDateError(true);
    } else if (!verify_proof(data.proofType, data.proofNumber)) {
      setValidateError(true);
    } else if (uploadButton) {
      setExpiryDateError(false);

      const formData = new FormData();
      formData.append(`proof_type_id`, data.proofType);
      formData.append(`proof_number`, data.proofNumber);
      if (data.proofType == 1 || data.proofType == 3) {
        formData.append(
          `proof_issue_date`,
          moment(issueDate).format("DD-MM-YYYY")
        );
        formData.append(
          `proof_expiry_date`,
          moment(expiryDate).format("DD-MM-YYYY")
        );
      }
      formData.append(`proof_front_file`, frontImage);
      formData.append(`proof_back_file`, backImage);

      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      try {
        await httpInstance(`${UPLOAD_ADDRESS_PROOF}`, httpConfig);
        navigate({
          pathname: `${namespace}/signIn`,
          search: `?${createSearchParams({ stepcount: "9" })}`,
        });
      } catch {}
    } else {
      setExpiryDateError(false);
    }
  };

  const handleback = () => {
    navigate({
      pathname: `${namespace}/signIn`,
      search: `?${createSearchParams({ stepcount: "8" })}`,
    });
  };

  let date = new Date();

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={5}
        className={classes.backButton}
        onClick={handleback}
      >
        <img src="/images/backIcon.svg" />
      </Box>
      <Box display={"flex"} justifyContent="center">
        <form onSubmit={handleSubmit(handle_submit)}>
          <Box
            display={"flex"}
            alignItems="flex-start"
            flexDirection={"column"}
          >
            <TitleLayoult
              title={"Proof of Address"}
              description={"Please Upload Proof of Address"}
              align=""
            />

            <Box alignItems="flex-start" flexDirection={"column"}>
              <ThemeProvider theme={theme}>
                <Box
                  my={2}
                  // display="flex"
                  alignItems={"flex-start"}
                  justifyContent="flex-start"
                  flexDirection="column"
                >
                  <label className={classes.label}>
                    Please select the proof of address you want to add
                  </label>
                  <Box width={"100%"} className={classes.Select}>
                    <FormControl fullWidth className={classes.Select}>
                      <Controller
                        control={control}
                        name={`proofType`}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            disableUnderline
                            className={classes.selectItem}
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                              style: {
                                maxHeight: 250,
                                top: 5,
                                minWidth: 200,
                              },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                            {...register("proofType", {
                              onChange: (e) => handleProofType(e),
                            })}
                          >
                            <MenuItem className={classes.menuItem} value={1}>
                              Passport
                            </MenuItem>
                            <MenuItem className={classes.menuItem} value={2}>
                              Voter Id
                            </MenuItem>
                            <MenuItem className={classes.menuItem} value={3}>
                              Driving Licence
                            </MenuItem>
                            {/* <MenuItem className={classes.menuItem} value={4}>
                      Aadhar Card
                    </MenuItem> */}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Box>
                  {errors.proofType && (
                    <ErrorMessage message="Please Select Proof Type" />
                  )}
                </Box>
              </ThemeProvider>

              <Box my={2}>
                <TextInputField
                  // className={classes.inputBox}
                  name="proofNumber"
                  label="Address Proof Number"
                  placeholder={proofType == 4 && "Please enter last 4 digits"}
                  register={register}
                  maxlength={proofType == 4 && "4"}
                  // {...register('proofNumber',{
                  handleChange={() => setValidateError(false)}
                  // })}

                  //inputPattern={/^(?=(?:^\w))([A-Za-z0-9 ]+)$/}
                />
                {/* {errors.proofNumber && errors.proofNumber.type === "required" && (
              <ErrorMessage message="Enter Address Proof Number" />
            )}
            {errors.proofNumber && errors.proofNumber.type === "pattern" && (
              <ErrorMessage message="Enter valid Number" />
            )} */}
                {validateError && <ErrorMessage message="Enter valid Number" />}
              </Box>
              {showdate && (
                <>
                  <Box my={2}>
                    <DatePickerField
                      name="issueDate"
                      label="Address Proof Issue Date"
                      placeholder=""
                      handleChange={handleissueDate}
                      dateValue={issueDate}
                      maximumDate={moment().subtract(1, "day").toDate()}
                    />
                    {issueDateError && (
                      <ErrorMessage message="Select Issue Date" />
                    )}
                  </Box>
                  <Box my={2}>
                    <DatePickerField
                      name="expiryDate"
                      label="Address Proof Expiry Date"
                      placeholder=""
                      handleChange={handleExpiryDate}
                      dateValue={expiryDate}
                      minimumDate={date.setDate(date.getDate() + 1)}
                    />
                    {expiryDateError && (
                      <ErrorMessage message="Select Expiry Date" />
                    )}
                  </Box>
                </>
              )}
            </Box>
            <Box my={4}>
              <AddressProofFile
                // uploadCounter={uploadStepCount}
                setFile={handleForntImage}
                handleUploadButton={handleUploadButton}
                upload={upload}
                document={document.front}
                //handleSubmit={handle_submit}
                description="Click or Drag to Upload Front side of the document"
              />
            </Box>
            <Box>
              <AddressProofFile
                //uploadCounter={uploadStepCount}
                setFile={handleBackImage}
                handleUploadButton={handleUploadButton}
                upload={upload}
                //handleSubmit={handle_submit}
                document={document.back}
                description="Click or Drag to Upload Back side of the document"
              />
            </Box>
          </Box>
          <Box
            mt={3}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            {uploadButton ? (
              <button className={classes.primaryButton} onClick={handle_Upload}>
                {loading ? (
                  <CircularProgress size="1rem" color="inherit" />
                ) : (
                  "Next"
                )}
              </button>
            ) : (
              <button className={classes.disabledButton} type="submit">
                {" "}
                Next
              </button>
            )}
          </Box>
        </form>
      </Box>
    </React.Fragment>
  );
}

export default ProofofAddress;
