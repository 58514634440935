import React, { useState } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { useLocation, Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { INITIATE_PAYMENT } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import LocaleString from "../../../hooks/LocaleString";
import number_ordinals from "../../../hooks/NumberOrdinal";
import { namespace } from "../../../environment";

const OrderSummary = () => {
  const useStyles = makeStyles({
    root: {
      padding: "10px 30% ",
    },
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      textDecoration: "underline",
      fontFamily: "inherit",
    },

    header: {
      fontSize: "1.2rem",
      fontWeight: 500,
    },

    title: {
      fontSize: "1.1rem",
    },
    primaryButton: {
      padding: "0.8rem 4rem",
      background: "#326CF9",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      marginTop: "1rem",
    },
    rupees: {
      fontFamily: "Open Sans",
    },

    "@media (max-width:600px)": {
      root: {
        padding: "10px ",
      },
    },

    "@media (min-width:601px) and (max-width:768px)": {
      root: {
        padding: "10px 23% ",
      },
    },
  });
  const classes = useStyles();

  const { state } = useLocation();
  const { orderData, type, schemeCode } = state;

  const [loading, setLoading] = useState(false);

  const initiate_payment = async () => {
    setLoading(true);
    if (type == "sip") {
      window.location.replace(orderData.tokenUrl);
    } else {
      const formData2 = {
        userId: localStorage.getItem("user_id"),
        paymentType: "NETBANKING",
        euin: orderData.euin,
        referenceCode: "",
        id: orderData.id,
        isWeb: true,
      };
      const httpConfig1 = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData2,
      };
      sessionStorage.removeItem("sip");
      sessionStorage.setItem("lumsumId", orderData?.id);

      try {
        const response = await httpInstance(`${INITIATE_PAYMENT}`, httpConfig1);
        window.location.replace(response.data?.data?.tokenUrl);
      } catch {
        setLoading(false);
      }
    }
  };

  console.log(orderData);
  return (
    <div>
      <Box
        display="flex"
        alignItems="start"
        px={{ xs: 1, md: 17 }}
        pt={2}
        style={{ borderBottom: "1px solid #E6E3E3" }}
      >
        <Link
          to={`${namespace}/myportfolio?tab=mutualfund&category=diy`}
          style={{ color: "black" }}
        >
          <Typography className={classes.textUnderline}>
            Mutual Funds
          </Typography>
        </Link>
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography className={classes.subTabtext}>Order Summary</Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={{ xs: 1, md: 18 }}
        mt={2}
        className={classes.backButton}
      >
        <Link to={`${namespace}/mutualfund/scheme-code/${schemeCode}`}>
          <img src="/images/backIcon.svg" height="20px" width="20px" />
        </Link>
      </Box>
      <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography className={classes.header}>Order Summary</Typography>
        <Box display="flex" alignItems="center" mt={6}>
          <img
            src={orderData?.amcLogo?.imgUrl}
            height="50px"
            width="50px"
            style={{ marginRight: "15px" }}
          />
          <Typography className={classes.title}>
            {orderData?.planName}
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={8} md={8}>
            <Box
              display="flex"
              alignItems="start"
              flexDirection="column"
              mt={3}
            >
              {type == "sip" && <p>SIP Date</p>}
              <p>
                Amount(<span className={classes.rupees}>₹</span>)
              </p>
              <p>Purchase Type</p>
              <p>Folio No.</p>
            </Box>
          </Grid>
          <Grid item xs={4} md={4}>
            <Box
              display="flex"
              alignItems="start"
              flexDirection="column"
              mt={3}
            >
              {type == "sip" && (
                <p>{number_ordinals(orderData?.startDay)} of every month</p>
              )}
              <p>{LocaleString(orderData?.amount)}</p>
              <p>{orderData?.type}</p>
              <p>{orderData?.folioNumber ? orderData?.folioNumber : "-"}</p>
            </Box>
          </Grid>
        </Grid>
        <button className={classes.primaryButton} onClick={initiate_payment}>
          {loading ? (
            <CircularProgress size="1rem" color="inherit" />
          ) : (
            "Proceed to pay"
          )}
        </button>
      </Box>
    </div>
  );
};

export default OrderSummary;
