import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";

function FirstService({handleShowModal}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundImage: "url('./images/Component 24 – 2.svg')",
      padding: "0rem 0rem 3rem 0rem",
      backgroundPosition: "left",
      position: "sticky",
      top: 0
    },
    backDrop: {
      background: "white",
      backdropFilter: "blur(4px)",
      display: "flex",
      alignItems: "start",
      flexDirection: "row",
      justifyContent: "space-between",
      position: "sticky",
      top: 0,
      paddingTop:"10rem"
     
    },
    heading: {
      color: "#2c2c2c",
      textAlign: "left",
      fontSize: "2rem",
      fontWeight: 500
    },
    description: {
      color: "#2c2c2c",
      textAlign: "left",
      fontWeight: 400,
      fontSize: "0.9rem"
    },
    subtitle: {
      fontSize: "1rem",
      fontWeight: 400,
      color: "#272727",
      cursor:"pointer"
    },
    videoPlayer: {
    },
    textHolder: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start"
    },
    
    "@media (max-width: 768px)": {
      textHolder: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "center"
      },
      heading: {
        fontSize: "1.3rem",
        textAlign: "center"
      },
      backDrop: {
        display: "flex",
        flexDirection: "row",
        paddingTop:"2rem"
      },
      videoPlayer: {
        width: "300px",
       
      }
    },
    "@media (max-width: 480px)": {
      textHolder: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      },
      heading: {
        fontSize: "1.3rem",
        textAlign: "center",
        margin: 0,
        padding: 0
      },
      backDrop: {
        display: "flex",
        flexDirection: "column-reverse",
        paddingTop:"2rem"
      },
      videoPlayer: {
        width: "260px",
      },
      description: {
        width: "100%",
        textAlign: "center"
      },
      backDrop: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column-reverse"
      }
    }
  }));
  const classes = useStyles();

  return (
    <Container  maxWidth="lg" style={{ position: "sticky", top: 0 }}>
      <Box height="" pt={8} className={classes.backDrop}>
        <Box className={classes.textHolder} mx={2}>
          <Box className={classes.heading}>Alerts on Accumulation & Exits</Box>
          <Box className={classes.description} my={4}>
            Our Experts suggest pefect time for{" "}
            <br  />
            Invest & exit for Best Returns
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="start"
            justifyContent="start"
            mt={1}
            className={classes.subtitle}
            onClick={handleShowModal}
          >
            <Box className={classes.subtitle} mr={3} >
              Learn more
            </Box>
            <img
              src="/images/Group 21264.svg"
              alt="BullSurge"
              height={25}
              width={25}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="start" justifyContent="flex-end">
          <video
            autoPlay
            muted
            playsInline
            loop
            controls={false}
            width="480"
            height="auto"
            className={classes.videoPlayer}
          >
            <source src="/images/Trigger.mp4" type="video/mp4" />
          </video>
        </Box>
      </Box>
    </Container>
  );
}

export default FirstService;
