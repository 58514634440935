import React from "react";
import { Box, makeStyles, Typography, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MySip from "./MySip";
import { namespace } from "../../../../environment";

const Index = () => {
  const useStyles = makeStyles({
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      textDecoration: "underline",
      fontFamily: "inherit",
    },
  });
  const classes = useStyles();
  return (
    <div>
      <Box
        display="flex"
        alignItems="start"
        px={{ xs: 1, md: 17 }}
        pt={2}
        style={{ borderBottom: "1px solid #E6E3E3" }}
      >
        <Link
          to={`${namespace}/myportfolio?tab=mutualfund`}
          style={{ color: "black" }}
        >
          <Typography className={classes.textUnderline}>
            Mutual Funds
          </Typography>
        </Link>
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography className={classes.subTabtext}>
            My Systematic Investment
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={{ xs: 1, md: 18 }}
        mt={2}
        className={classes.backButton}
      >
        <Link to={`${namespace}/myportfolio?tab=mutualfund`}>
          <img src="/images/backIcon.svg" height="20px" width="20px" />
        </Link>
      </Box>

      <Box px={{ xs: 2, md: 17 }}>
        <MySip />
      </Box>
    </div>
  );
};

export default Index;
