import React,{useEffect, useState} from 'react'
import { Box,makeStyles,Table,TableRow,TableHead,TableBody,TableCell } from '@material-ui/core'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Confirmation from '../../../components/MutualFund/InfoMsg/Confirmation';
import {APPROVAL_LIST,APPROVE_REQUEST,REJECT_REQUEST} from '../../../utils/endpoints'
import httpInstance from '../../../utils/instance'
import ChangesView from './ChangesView';


const ApprovealTable = () => {
    const useStyles=makeStyles({
        root:{
            "& .MuiTypography-body1":{
              fontSize:"0.85rem"
            }
          },
          table:{
            margin:"1rem 0px",
            "& .MuiTableCell-root":{
                fontSize:"0.8rem"
            },
            "& .MuiTableHead-root":{
                background:"#F9FBFC",
                borderTopLeftRadius:" 4px",
                "& .MuiTableCell-root":{
                    color:"#C0C5D0",
    
                }
            },
            "& .MuiTableBody-root":{
                background:"#fff",
            }
        },
        approveBotton:{
            padding:"0px 5px",
            color:"#fff",
            background:"#4FD000",
            borderRadius:"5px",
            border:"none",
        },
        rejectButton:{
                padding:"0px 5px",
                color:"#fff",
                background:"#D03200",
                borderRadius:"5px",
                border:"none",
                margin:"0px 20px"
        }
    })
    const classes=useStyles()

    const [data,setData]=useState([])
    const [requestId,setRequestId]=useState('')
    const [showApprove,setShowApprove]=useState(false)
    const [showReject,setShowReject]=useState(false)
    const [view,setView]=useState(false)
    const [type,setType]=useState({})

    const get_data= async()=>{
        const httpConfig = {
            method: "GET",
            tokenType: "admin",
            headers:{
              "Content-Type": "application/json",
            },
            params:{userId:localStorage.getItem("admin_userId")}
          };
          try{
            const response=await httpInstance(`${APPROVAL_LIST}`,httpConfig)
            setData(response.data?.data)
          }
          catch{

          }
    }

    useEffect(()=>{
        get_data()
    },[])

    //------------------handle approve/reject--------------------------------//
    const handle_approve=(id)=>{
        setRequestId(id)
        setShowApprove(true)
    }

    //---------------handle reject--------------------------------//
    const handle_reject=(id)=>{
        setRequestId(id)
        setShowReject(true)
    }

    
    //-------------------approve request-------------------------------------------//
    const approve_request=async()=>{
        const httpConfig = {
            method: "POST",
            tokenType: "admin",
            headers:{
              "Content-Type": "application/json",
            },
            params:{id:requestId}
          };
          try{
           const response=await httpInstance(`${APPROVE_REQUEST}`,httpConfig)
           get_data()
           setShowApprove(false)
            
          }
          catch{

          }
        }
        
    //------------------------Reject approve-------------------------------------//
    const reject_request=async()=>{
        const httpConfig = {
            method: "POST",
            tokenType: "admin",
            headers:{
              "Content-Type": "application/json",
            },
            params:{id:requestId}
          };
          try{
           await httpInstance(`${REJECT_REQUEST}`,httpConfig)
           get_data()
           setShowReject(false)
            
          }
          catch{

          }
    }
    //------------------------view changes-------------------------------------------//
    const handle_view=(data)=>{
        setType({
            id:data?.id,
            type:data?.type,
            justification:data?.justification
        
        })
        setView(true)
    }


  return (
    <div>
        {data.length>0 &&
         <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell align="left">Change</TableCell>
                    <TableCell align="left">Justification</TableCell>
                    <TableCell align="left">Admin</TableCell>
                    <TableCell align="center">View Edits</TableCell>
                    <TableCell align="left">Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data?.map((ele)=>{
                    return(
                        <TableRow key={ele.id}>
                        <TableCell>{ele.type}</TableCell>
                        <TableCell>{ele.justification}</TableCell>
                        <TableCell>{ele.requestedBy?.userName}</TableCell>
                        <TableCell align="center"><img src="/images/admin/eye 1.svg" height="20px" width="20px" onClick={()=>handle_view(ele)}
                        style={{cursor:"pointer"}}/></TableCell>
                        <TableCell>
                            <button className={classes.approveBotton} onClick={()=>handle_approve(ele.id)}><DoneIcon/></button>
                            <button className={classes.rejectButton} onClick={()=>handle_reject(ele.id)}><CloseIcon/></button>
                        </TableCell>
                        </TableRow>
                    )
                })}
                        
                   
            
            </TableBody>
         </Table>
        }

    <Confirmation 
        show={showApprove} 
        handleClose={()=>setShowApprove(false)} 
        handle_yes={approve_request}
        title="Confirmation"
        text="Do you want to approve this request?"
        />

    <Confirmation 
        show={showReject} 
        handleClose={()=>setShowReject(false)} 
        handle_yes={reject_request}
        title="Confirmation"
        text="Do you want to reject this request?"
        />

    <ChangesView 
        show={view}
        handleClose={()=>setView(false)}
        type={type}
        get_tabel_data={get_data}
    />
    </div>
  )
}

export default ApprovealTable