import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import httpInstance from "../../../utils/instance";
import { GET_TIMESTAMPS, DELETE_TIMESTAMPS } from "../../../utils/endpoints";
import Pagination from "../../../components/Admin/Pagination";
import moment from "moment";
import AddTimestamp from "./AddTimestamp";
import Confirmation from "../../../components/MutualFund/InfoMsg/Confirmation";

const Index = () => {
  const useStyles = makeStyles({
    root: {
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: "#0C97F0",
      },
      "& .MuiTypography-body1": {
        fontSize: "0.85rem",
      },
    },

    input: {
      border: "none",
      width: "100%",
      padding: "15px",
      fontFamily: "inherit",
    },

    primaryButton: {
      background: "#326CF9",
      color: "#fff",
      padding: "10px 2rem",
      border: "none",
      borderRadius: "20px",
      fontFamily: "inherit",
    },
    table: {
      margin: "1rem 0px",
      "& .MuiTableCell-root": {
        fontSize: "0.8rem",
      },
      "& .MuiTableHead-root": {
        background: "#F9FBFC",
        borderTopLeftRadius: " 4px",
        "& .MuiTableCell-root": {
          color: "#C0C5D0",
        },
      },
      "& .MuiTableBody-root": {
        background: "#fff",
      },
    },
  });
  const classes = useStyles();

  const [timestamps, setTimestamps] = useState([]);
  const [totalNumber, setTotalNumber] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [showTimestamp, setShowTimestamp] = useState(false);
  const [showDialog, setShowDailog] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [status, setStatus] = useState(0);
  const get_data = async () => {
    const httpConfig = {
      method: "GET",
      //tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_TIMESTAMPS}`, httpConfig);
      setTimestamps(response.data?.data);
      setTotalNumber(response.data.data?.count);
      setLoading(false);
    } catch {}
  };

  useEffect(() => {
    get_data();
  }, [currentPage, search]);

  const onPaginate = (pageNumber) => {
    if (pageNumber != currentPage) setCurrentPage(pageNumber);
  };

  const delete_timestamp = (id) => {
    setDeleteId(id);
    setShowDailog(true);
  };
  const handle_delete = async () => {
    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      params: { id: deleteId, isActive: 0 },
    };

    try {
      await httpInstance(`${DELETE_TIMESTAMPS}`, httpConfig);
      get_data();
      setShowDailog(false);
    } catch {}
  };

  // const handle_search = (e) => {
  //   setSearch(e.target.value);
  // };
  const handle_update = (id) => {
    setShowTimestamp(true);
    setUpdateId(id);
    setStatus(1);
  };

  return (
    <div>
      <Box p={3} pr={7} className={classes.root}>
        {/* <Box>
          <input
            value={search}
            className={classes.input}
            placeholder="Enter the name of customer"
            onChange={handle_search}
          />
          
        </Box> */}
        <Box display="flex" justifyContent="end">
          <button
            className={classes.primaryButton}
            onClick={() => {
              setShowTimestamp(true);
              setStatus(0);
            }}
          >
            Add Timestamps
          </button>
        </Box>

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Id</TableCell>
              <TableCell align="left">Version Name</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">createdAt</TableCell>
              <TableCell align="left">updatedAt</TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timestamps?.map((ele) => {
              return (
                <TableRow key={ele.id}>
                  <TableCell>{ele.id}</TableCell>
                  <TableCell>{ele.versionName}</TableCell>
                  <TableCell>{ele.isActive ? "Active" : "Deactive"} </TableCell>
                  <TableCell>
                    {moment(ele.createdAt).format("DD MMMM YYYY hh:mm A")}
                  </TableCell>
                  <TableCell>
                    {moment(ele.updatedAt).format("DD MMMM YYYY hh:mm A")}
                  </TableCell>
                  <TableCell>
                    <button
                      className={classes.primaryButton}
                      onClick={() => handle_update(ele.id)}
                    >
                      Edit
                    </button>
                  </TableCell>
                  <TableCell>
                    <img
                      src="/images/admin/delete.svg"
                      style={{ cursor: "pointer" }}
                      onClick={() => delete_timestamp(ele.id)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {!loading && (
          <Pagination
            totalNumber={totalNumber}
            itemsPerPage={10}
            onPaginate={onPaginate}
          />
        )}
      </Box>
      <AddTimestamp
        show={showTimestamp}
        handleClose={() => setShowTimestamp(false)}
        get_all_timestamps={get_data}
        timestamps={timestamps}
        updateId={updateId}
        status={status}
      />
      <Confirmation
        show={showDialog}
        handleClose={() => setShowDailog(false)}
        handle_yes={handle_delete}
        title="Delete Confirmation"
        text="Are you sure you want to delete this timestamp?"
      />
    </div>
  );
};

export default Index;
