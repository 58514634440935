import React from 'react'
import ApprovealTable from './ApprovealTable'
import AdminsTable from './AdminsTable'
import { Box } from '@material-ui/core'
import { useSearchParams } from 'react-router-dom'
import GoldKyc from './GoldKyc'


const Index = () => {
  const [searchParams,setSearchParams]=useSearchParams()

    const type=searchParams.get("type")

   
  return (
    <div>
        <Box p={3} pr={7}>
          {type=="admin" ? 
          <>
        <ApprovealTable/>
        <AdminsTable/> 
        </> :
        
        <GoldKyc/>

        }
        </Box>
    </div>
  )
}

export default Index