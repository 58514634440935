import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { SEARCH_BY_ISIN, GET_FUND_FP_DATA } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import moment from "moment";
import localeString from "../../../hooks/LocaleString";
import Redeem from "../MutualFund/Redeem";
import SWP from "../MutualFund/SWP";
import { Link, useNavigate } from "react-router-dom";
import { namespace } from "../../../environment";

const FundTable = ({ holdings, bankData, category }) => {
  const useStyles = makeStyles({
    root: {
      border: "1px solid #DDDDDD",
      borderBottom: "none",
      marginTop: "2rem",
      "& .MuiTableCell-root": {
        maxWidth: "9rem",
        borderRight: "1px solid #DDDDDD",
        "&:last-child": {
          borderRight: "none",
        },
        //borderBottom:"1px solid #DDDDDD",
      },
    },

    headerRow: {
      background: "#B3E1FF",
      "& .MuiTableCell-root": {
        //color:"#fff",
        borderRight: "1px solid #fff",
        "&:last-child": {
          borderRight: "none",
        },
      },
    },

    tableHeader: {
      fontSize: "0.8rem",
    },

    loss: {
      fontSize: "0.8rem",
      color: "#F32424",
    },

    link: {
      textDecoration: "none",
      color: "inherit",
    },
    pulse: {
      //color: "#fff",
      fontSize: "0.8rem",
      animation: "$pulse 3s infinite",
    },
    "@keyframes pulse": {
      "0%": {
        background: "#fff",
      },
      "70%": {
        background: "#f78a72",
        color: "#fff",
      },
      "100%": {
        background: "#f57e64",
        color: "#fff",
      },
    },
    exitText: {
      fontSize: "0.75rem",
      marginTop: "10px",
    },
  });

  const classes = useStyles();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [redeem, setRedeem] = useState(false);
  const [fundDetail, setFundDetail] = useState([]);
  const [swp, setSwp] = useState(false);
  const [fpFundData, setFpFundData] = useState(null);

  const swp_close = () => {
    setSwp(false);
    navigate(`${namespace}/myportfolio?tab=mutualfund&category=diy`, {
      replace: true,
    });
  };

  // -------------Menu comp---------------------------------------------------------------------
  const MenuComp = ({ schemeData }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [fundData, setFundData] = useState(null);
    const [fpData, setFpData] = useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      get_fundData();
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const get_fundData = async () => {
      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        params: { isin: schemeData?.isin },
      };

      const httpConfig1 = {
        method: "GET",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        params: { isin: schemeData?.isin },
      };

      try {
        const [fpdata, plData] = await Promise.all([
          httpInstance(`${GET_FUND_FP_DATA}`, httpConfig1),
          httpInstance(`${SEARCH_BY_ISIN}`, httpConfig),
        ]);
        //const response= await httpInstance(`${SEARCH_BY_ISIN}`,httpConfig)
        setFpData(fpdata.data?.data);
        setFundData(plData.data?.data[0]);
        // navigate(`/mutualfund/scheme-code=${response.data?.data[0]?.scheme_code}`)
      } catch {}
    };

    //----------------handle_redeem----------------------------------------------------//

    const handle_redeem = () => {
      navigate(`${namespace}/myportfolio/redeem/${schemeData?.isin}`, {
        replace: true,
      });
      setData(schemeData);
      setFpFundData(fpData);
      setFundDetail(fundData);
      setRedeem(true);
    };

    //----------------------------handle SWP--------------------------------------------------------//
    const handle_swp = () => {
      navigate(`${namespace}/myportfolio/swp/${schemeData?.isin}`, {
        replace: true,
      });
      setData(schemeData);
      setFundDetail(fundData);
      setFpFundData(fpData);
      setSwp(true);
    };

    return (
      <>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          style={{ padding: "2px" }}
        >
          <MoreVertIcon />
        </IconButton>
        {fundData && (
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
              },
            }}
          >
            <Link
              to={
                category == "diy"
                  ? `/mutualfund/scheme-code/${fundData?.scheme_code}`
                  : `/dashboard/mutualfund/assisted-services/invest-detail`
              }
              className={classes.link}
            >
              <MenuItem style={{ fontSize: "0.8rem" }}>Invest</MenuItem>
            </Link>
            {fpData?.isRedemptionAllowed && (
              <MenuItem style={{ fontSize: "0.8rem" }} onClick={handle_redeem}>
                Redeem
              </MenuItem>
            )}
            {category == "diy" && fpData?.isSwpAllowed && (
              <MenuItem style={{ fontSize: "0.8rem" }} onClick={handle_swp}>
                Start SWP
              </MenuItem>
            )}
            {fpData?.isSwitchOutAllowed && (
              <Link
                to={`/myportfolio/switch/${schemeData?.isin}`}
                state={{
                  schemeData: schemeData,
                  fundData: fundData,
                  switchData: fpData,
                  category: category,
                }}
                className={classes.link}
              >
                <MenuItem style={{ fontSize: "0.8rem" }}>Switch</MenuItem>
              </Link>
            )}
            {category == "diy" && fpData?.isStpOutAllowed && (
              <Link
                to={`/myportfolio/stp/${schemeData?.isin}`}
                state={{
                  schemeData: schemeData,
                  fundData: fundData,
                  stpData: fpData,
                }}
                className={classes.link}
              >
                <MenuItem style={{ fontSize: "0.8rem" }}>Start STP</MenuItem>
              </Link>
            )}
            <Link
              to={`/myportfolio/all-fund-transactions/${schemeData?.isin}`}
              state={{ schemeData: schemeData, fundData: fundData }}
              className={classes.link}
            >
              <MenuItem style={{ fontSize: "0.8rem" }}>
                All Transactions
              </MenuItem>
            </Link>

            {/* handle_change(id) */}
          </Menu>
        )}
      </>
    );
  };

  return (
    <div>
      <TableContainer className={classes.root}>
        <Table>
          <TableHead className={classes.headerRow}>
            <TableRow style={{ borderBottom: "1px solid #DDDDDD" }}>
              <TableCell className={classes.tableHeader} align="center">
                Scheme
              </TableCell>
              {/* <TableCell className={classes.tableHeader} align="center">Inv. Since</TableCell>
                        <TableCell className={classes.tableHeader} align="center">Sensex</TableCell> */}
              <TableCell className={classes.tableHeader} align="center">
                Inv. Cost
              </TableCell>
              <TableCell className={classes.tableHeader} align="center">
                Units
              </TableCell>
              <TableCell className={classes.tableHeader} align="center">
                Nav
              </TableCell>
              {/* <TableCell className={classes.tableHeader} align="center">Cur. Nav</TableCell> */}
              <TableCell className={classes.tableHeader} align="center">
                Total
              </TableCell>
              <TableCell className={classes.tableHeader} align="center">
                Gain/Loss
              </TableCell>
              <TableCell className={classes.tableHeader} align="center">
                Abs rtn
              </TableCell>
              {/* <TableCell className={classes.tableHeader} align="center">XIRR</TableCell> */}
              <TableCell
                className={classes.tableHeader}
                align="center"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {holdings?.schemes?.map((ele, i) => {
              return (
                <TableRow key={i}>
                  <TableCell
                    className={
                      ele.exitTriggerGenerated
                        ? classes.pulse
                        : classes.tableHeader
                    }
                    align="center"
                  >
                    {ele.name}
                    {ele.exitTriggerGenerated && (
                      <Typography className={classes.exitText}>
                        (Exit from this Scheme)
                      </Typography>
                    )}
                  </TableCell>
                  {/* <TableCell className={classes.tableHeader} align="center">{moment(ele.asOn).format("DD/MM/YY")}</TableCell> */}
                  {/* <TableCell className={classes.tableHeader} align="center">22/04/18</TableCell> */}
                  <TableCell className={classes.tableHeader} align="center">
                    {localeString(ele.investedAmount)}
                  </TableCell>
                  <TableCell className={classes.tableHeader} align="center">
                    {ele.holdingUnits}
                  </TableCell>
                  {/* <TableCell className={classes.tableHeader} align="center">{ele.averageBuyingValue}</TableCell> */}
                  <TableCell className={classes.tableHeader} align="center">
                    {ele.nav}
                  </TableCell>
                  <TableCell className={classes.tableHeader} align="center">
                    {localeString(ele.currentValue)}
                  </TableCell>
                  <TableCell
                    className={
                      ele.gainOrLoss < 0 ? classes.loss : classes.tableHeader
                    }
                    align="center"
                  >
                    {localeString(ele.gainOrLoss)}
                  </TableCell>
                  <TableCell className={classes.tableHeader} align="center">
                    {ele.absoluteReturns}
                  </TableCell>
                  {/* <TableCell className={classes.tableHeader} align="center">{ele.xirr.toFixed(3)}</TableCell> */}
                  <TableCell className={classes.tableHeader} align="center">
                    <MenuComp schemeData={ele} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Redeem
        show={redeem}
        handleClose={() => setRedeem(false)}
        schemeData={data}
        bankData={bankData}
        fundDetail={fundDetail}
        fpFundData={fpFundData}
        category={category}
      />
      <SWP
        show={swp}
        onClose={swp_close}
        schemeData={data}
        bankData={bankData}
        fundDetail={fundDetail}
        swpData={fpFundData}
        category={category}
      />
    </div>
  );
};

export default FundTable;
