import React,{useState} from 'react'
import { Box,makeStyles,Grid, Typography,FormControlLabel,Radio} from '@material-ui/core'

const Question = ({data,index,store_answers,answers}) => {
    const useStyles=makeStyles({
        question:{
            color:"#326CF9",
            fontWeight:500
        },
        answerBox:{
            border:'1px solid #DDDDDD',
            borderBottom:0
        },
        radioButton:{
            "& .MuiRadio-colorPrimary.Mui-checked ":{
                color:"#3278CC"
            },
            borderBottom:"1px solid #DDDDDD",
            "& .MuiTypography-body1":{
                fontSize:"0.85rem"
            }
        }
    })
    const classes=useStyles()

    const [answerId,setAnswerId]=useState('')

    const handle_answer=(e)=>{
        setAnswerId(e.target.value)
        store_answers({id:index,quizeChoiceMapId:e.target.value})
    }

   
  return (
    <div>
        <Box>
            <Grid container>
                <Grid item xs={7}>
                    <Typography className={classes.question}>Question {index+1}</Typography>
                    <p>{data?.question}</p>
                </Grid>
                <Grid item xs={5}>
                    <Box className={classes.answerBox}>
                    {data?.choices?.map((ele)=>{
                                return(
                                    <Box key={ele.id} className={classes.radioButton} px={2} py={1}>
                            
                                    <FormControlLabel value={ele.id} control={<Radio color="primary" onChange={handle_answer} checked={answers?.some((choice=>choice.quizeChoiceMapId==ele.id))}/> } label={ele.name}/>
                                    </Box>
                                    
                                )
                            })}
                       

                    </Box>
                </Grid>

            </Grid>
        </Box>
    </div>
  )
}

export default Question