import React, { useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import {
  INITAITE_ASSISTED_PAYMENT,
  GET_CONSENT_LUMPSUM_OTP,
  INVEST_ASSISTED_FUND,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import ErrorMessage from "../../../components/Validation/ErrorMessage";

const InvsestDialogue = ({ show, handleClose, fundData, totalAmount }) => {
  const useStyles = makeStyles({
    root: {
      "& .MuiDialogContent-root": {
        fontSize: "0.8rem",
        lineHeight: "25px",
        //padding:"20px 25px"
      },
      "& .MuiDialog-paperWidthSm": {
        minWidth: "30%",
        maxWidth: "300px",
        maxHeight: "100%",
      },
      "& .MuiDialogTitle-root ": {
        padding: "5px",
      },
    },
    dialogTitle: {
      //display: "flex",
      justifyContent: "center",
      alignItems: " center",
      marginTop: "-10px",
      "& .MuiTypography-body1": {
        fontSize: "1rem",
        fontWeight: 600,
      },
    },
    closeIcon: {
      padding: "2px",
      justifyContent: "flex-end",
    },
    inputBox: {
      background: "#E6E6E6",
      padding: "0.7rem 0.95rem",
      borderRadius: "10px",
      border: "none",
      width: "-webkit-fill-available",
      fontFamily: "inherit",
      marginBottom: "15px",
    },
    label: {
      fontSize: "0.8rem",
      marginBottom: "5px",
      fontWeight: 600,
    },
    primaryButton: {
      color: "white",
      backgroundColor: "#326CF9",
      border: "none",
      borderRadius: "39px",
      fontSize: "15px",
      fontWeight: "500",
      padding: "10px 5rem",
      fontFamily: "inherit",
      cursor: "pointer",
      margin: "0rem 1rem",
    },
  });

  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [otp, SetOTP] = useState("");
  const [consentId, SetConsentId] = useState("");
  const [timer, setTimer] = useState(30);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [otpHasBeenSent, setotpHasBeenSent] = useState(true);
  const [handleOTP, setHandleOTP] = useState(0);
  const [apiError, setApiError] = useState("");
  const [error, setError] = useState("");
  const [paymentId, setpaymentId] = useState("");

  const handle_otp_value = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "").toLowerCase();
    SetOTP(e.target.value);
  };

  const confirm_payment = async () => {
    console.log("paymentId", paymentId);
    setLoading(true);
    const formData = {
      userId: localStorage.getItem("user_id"),
      paymentType: "NETBANKING",
      euin: "",
      referenceCode: "",
      ids: paymentId,
      isWeb: true,
      mfLumpsumConsentId: consentId,
    };
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      const response = await httpInstance(
        `${INITAITE_ASSISTED_PAYMENT}`,
        httpConfig
      );
      window.location.replace(`${response.data?.data?.tokenUrl}`);
    } catch {
      setLoading(false);
    }
  };

  const handle_otp = async () => {
    setError("");
    setHandleOTP(1);
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await httpInstance(
      `${GET_CONSENT_LUMPSUM_OTP}`,
      httpConfig
    );
    // console.log("response.data?.data.otp", response.data?.data.otp);
    SetOTP("");
    SetConsentId(response.data?.data.id);
    if (response.data?.data.id) {
      const formData = {
        userId: localStorage.getItem("user_id"),
        mfLumpsumConsentId: response.data?.data.id,
        suggestionList: fundData?.map((ele) => {
          return {
            id: ele.id,
            fundId: ele.fundId,
            name: ele.name,
            type: ele.type,
            amount: ele.amount,
            isThematic: ele.isThematic,
          };
        }),
      };
      const httpConfig1 = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };
      try {
        const response = await httpInstance(
          `${INVEST_ASSISTED_FUND}`,
          httpConfig1
        );
        console.log(response.data?.data);
        setpaymentId(response.data?.data);
        // setShowInvest(true);
      } catch {}
    }

    var timeLeft = 30; // set the time limit in seconds
    var timer = setInterval(function () {
      setIsTimerActive(true);
      setotpHasBeenSent(true);
      timeLeft--;
      setTimer(timeLeft);

      if (timeLeft <= 0) {
        clearInterval(timer);
        setotpHasBeenSent(false);
        setIsTimerActive(false);
      }
    }, 1000); // set the interval to 1000 milliseconds (1 second)
  };

  return (
    <div>
      <Dialog className={classes.root} open={show} onClose={handleClose}>
        <Box display="flex" className={classes.closeIcon}>
          {" "}
        </Box>
        <DialogTitle className={classes.dialogTitle}>
          <Box
            display="flex"
            className={classes.closeIcon}
            onClick={handleClose}
          >
            <CloseIcon style={{ cursor: "pointer" }} />
          </Box>
        </DialogTitle>

        <DialogContent>
          {handleOTP == 1 ? (
            <Box m={3} p={3}>
              <label className={classes.label}>Enter OTP</label>
              <input
                className={classes.inputBox}
                type="text"
                onChange={handle_otp_value}
                value={otp}
              />
              <div className="resendOTP">
                {otpHasBeenSent ? (
                  <></>
                ) : (
                  <>
                    <p className="otpWillRecivie">
                      OTP has been sent you will receive shortly
                    </p>
                  </>
                )}
                {isTimerActive ? (
                  <div>
                    <p className="resendOTPTimer">
                      Resend OTP in {timer} seconds
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className="resendOTPRequest">
                      Didn't receive OTP?{" "}
                      <a href="javascript:void" onClick={handle_otp}>
                        Resend OTP
                      </a>
                    </p>
                  </div>
                )}
              </div>
              {apiError && <ErrorMessage message={apiError} />}
              <button
                className={classes.primaryButton}
                onClick={confirm_payment}
                style={{ marginTop: "10px" }}
              >
                {loading ? (
                  <CircularProgress size="1rem" color="inherit" />
                ) : (
                  "Verify OTP"
                )}
              </button>
              <div>{error && <ErrorMessage message={error} />}</div>
            </Box>
          ) : (
            <>
              <lable className={classes.label}>Amount</lable>
              <br />
              <input
                className={classes.inputBox}
                value={totalAmount}
                disabled
              />

              <Box display="flex" justifyContent="center" mt={20} mb={5}>
                <button className={classes.primaryButton} onClick={handle_otp}>
                  {loading ? (
                    <CircularProgress size="1rem" color="inherit" />
                  ) : (
                    " Confirm"
                  )}
                </button>
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InvsestDialogue;
