import { Box } from "@material-ui/core";
import React from "react";
import TitleLayoult from "../TitleLayoult";
import { useStyles } from "../styles";

function Step13({ step }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box display={"flex"} alignItems="flex-start" flexDirection={"column"}>
        <TitleLayoult
          title={"Great"}
          description={
            "Your Automandate request has been submitted successfully "
          }
          align="center"
          justify="center"
        />
        <Box
          style={{ border: "1px solid #e6e6e6", width: "100%" }}
          p={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-around"
        >
          <img src="/images/Canara_Bank.svg" height={60} width={60} />
          <Box style={{ color: "#272727" }} fontSize={14}>
            CANARA LIMITED
          </Box>
          <Box style={{ color: "#C4C4C4" }} fontSize={14}>
            XXXXXXX4741
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems={"flex-start"}
          justifyContent="space-between"
          flexDirection={"row"}
          style={{ width: "100%" }}
          p={1}
        >
          <Box
            display="flex"
            alignItems={"flex-start"}
            justifyContent="flex-start"
            flexDirection={"column"}
          >
            <Box style={{ color: "#C4C4C4" }} fontSize={14} fontWeight={500}>
              Expected Complition time
            </Box>
            <Box
              style={{ color: "#272727" }}
              fontSize={14}
              fontWeight={500}
              mt={1}
            >
              2-7 working days
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems={"flex-start"}
            justifyContent="flex-start"
            flexDirection={"column"}
          >
            <Box style={{ color: "#C4C4C4" }} fontSize={14} fontWeight={500}>
              Autopay limit
            </Box>
            <Box
              style={{ color: "#272727" }}
              fontSize={14}
              fontWeight={500}
              mt={1}
            >
              <span className={classes.rupees}>₹</span>1,00,000
            </Box>
          </Box>
        </Box>
        <Box
          mt={6}
          width="100%"
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <button className={classes.primaryButton} onClick={() => step(14)}>
            Done
          </button>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Step13;
