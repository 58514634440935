import { Box, Typography, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import httpInstance from "../../../utils/instance";
import { GET_GOLD_USER } from "../../../utils/endpoints";
import { useNavigate } from "react-router-dom";
import { namespace } from "../../../environment";
const Investment = ({ currentValue, detail }) => {
  const useStyles = makeStyles((theme) => ({
    boxBorder: {
      border: "2.31035px solid #DDDDDD",
      borderRadius: "5px",
      cursor: "pointer",
    },

    borderRight: {
      borderRight: "2.31035px solid #DDDDDD",
    },

    title: {
      fontSize: "0.8rem",
      fontWeight: "bold",
      fontFamily: "Poppins",
    },
    text: {
      fontSize: "0.65rem",
      fontFamily: "Poppins",
    },
    currentValue: {
      fontSize: "0.7rem",
      fontFamily: "Poppins",
      fontWeight: 500,
      marginTop: "10px",
    },
    rupees: {
      fontFamily: "Open Sans ",
    },
  }));

  const classes = useStyles();

  const navigate = useNavigate();

  const [investment, setInvestment] = useState();

  useEffect(async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_GOLD_USER}`, httpConfig);
      setInvestment(response?.data?.data);
    } catch {}
  }, []);

  const handlDetailPage = () => {
    navigate(`${namespace}/myportfolio/digitalgold/detail-investment`);
  };

  return (
    <Box display="flex" justifyContent="center" mt={1}>
      <Box
        display="flex"
        justifyContent="center"
        className={classes.boxBorder}
        onClick={detail && handlDetailPage}
      >
        <Box display="flex" justifyContent="center" py={2}>
          <Box
            className={classes.borderRight}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            px={{ xs: 2, md: 6 }}
          >
            <img src="/images/image 2.svg" height="50px" />
            <Typography className={classes.title}>Gold Grams</Typography>
            {investment && (
              <Typography className={classes.text}>
                {`${investment?.transactionalGoldBalance?.toFixed(4)} grams`}{" "}
              </Typography>
            )}
            {currentValue && (
              <Typography className={classes.currentValue}>
                {`Current Value - `}
                <span className={classes.rupees}>₹</span>
                {`${currentValue?.goldRate?.toFixed(2)}`}{" "}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          px={{ xs: 2, md: 6 }}
          py={2}
        >
          <img src="/images/image 3.svg" height="50px" />
          <Typography className={classes.title}>Silver Grams</Typography>
          {investment && (
            <Typography
              className={classes.text}
            >{`${investment?.transactionalSilverBalance?.toFixed(
              4
            )} grams`}</Typography>
          )}
          {currentValue && (
            <Typography className={classes.currentValue}>
              {`Current Value - `}
              <span className={classes.rupees}>₹</span>
              {`${currentValue?.silverRate?.toFixed(2)}`}{" "}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Investment;
