import React from 'react'
import ActiveUsers from './ActiveUsers'
import { Box } from '@material-ui/core'
import Allocation from './Allocation'
import Recommendation from './Recommendation'
import RecentActions from './RecentActions'
import TotalInvest from './TotalInvest'

const Index = () => {
  return (
    <div>
      <Box mt={2} >
         <ActiveUsers/>
         <Allocation/>
         <Recommendation/>
         <RecentActions/>
         <TotalInvest/>
      </Box>
    </div>
  )
}

export default Index