import React,{useState,useEffect} from 'react'
import { Box,makeStyles,Table,TableRow,TableHead,TableBody,TableCell,Divider, Grid } from '@material-ui/core'
import {GET_ALL_RISK_THEMATIC,UPDATE_RISK_THEMATIC} from '../../../utils/endpoints'
import httpInstance from '../../../utils/instance'
import { Typography } from '@mui/material'
import ErrorMessage from '../../../components/Validation/ErrorMessage'
import { useSelector } from 'react-redux'
import CustomToast from '../../../components/UI/CustomToast'


const InvestTable = () => {
    const useStyles=makeStyles({
        root:{
            background:"#fff",
            borderRadius:"10px"
        },
        primaryButton:{
            background:"#326CF9",
            color:"#fff",
            padding:"5px 1.5rem",
            border:"none",
            borderRadius:"20px",
            fontFamily:"inherit",
            boxShadow: "0px 2px 26px rgba(0, 0, 0, 0.15)",
            cursor:"pointer"
        },
        table:{
            borderTop:"1px solid #DDDDDD",
        },
        cellBorder:{
            "&.MuiTableCell-root":{
            borderRight:"1px solid #DDDDDD",
            minWidth:"10rem"  
            }
        },

        gridBorder:{
            borderRight:"1px solid #DDDDDD",
            paddingBottom:"5px"
        },
        cell:{
            "&.MuiTableCell-root":{
                padding:"0px",
                paddingTop:"5px",
                borderRight:"1px solid #DDDDDD",
                maxWidth:"19rem",
                
            }
        },
        subCell:{
            "&.MuiTableCell-root":{
                padding:"0px",   
                borderRight:"1px solid #DDDDDD",   
                      
            }  
        },
        gridItem:{
            padding:"16px",
            borderRight:"1px solid #DDDDDD",
        },
        gridItem1:{
            padding:"16px",
        },

        inputBox:{
            width:"30%",
            border:"1px solid #DDDDDD",
            borderRadius:"5px",
            margin:"0px 10px",
            padding:"5px",
            fontFamily:"inherit"
        }

    })
    const classes=useStyles()
    const [data,setData]=useState(null)
    const [edit,setEdit]=useState(false)
    const [editObj,setEditObj]=useState({input:[]})
    const [error,setError]=useState(false)

    const {adminStore}=useSelector((state)=>state)

    const get_data=async()=>{
        const httpConfig = {
            method: "GET",
            //tokenType: "user",
            headers:{
              "Content-Type": "application/json",
            },
          };

          try{
        
            const response=await httpInstance(`${GET_ALL_RISK_THEMATIC}`,httpConfig)
            setData(response.data?.data)

            const arry=response.data?.data?.map((ele)=>{
                return {
                    id:ele.id,
                    riskType:ele.riskProfile.id,
                    thematicPercentage:ele.thematicPercentage,
                    capPercentage:ele.capPercentage,
                    low:ele.riskProfile.low,
                    high:ele.riskProfile.high
                }
                
            })
            setEditObj(arry)
          }
          catch(error){
            
          }
    }

    useEffect(()=>{
        get_data()
    },[])

    const handle_edit=()=>{
        if(edit){
            handle_save()
        }else{
            setEdit(true)
        }
        
    }

    const handle_change=(e,id)=>{
        e.target.value = e.target.value.replace(/[^0-9]/g, "").toLowerCase();
        const editData=editObj?.map((ele)=>{
            if(ele.id==id){
                return {...ele,[e.target.name]:e.target.value}
            }else{
                return ele
            }
        })

        setEditObj(editData)
    }

    const handle_save= async()=>{
        setError(false)
        const httpConfig = {
            method: "POST",
            tokenType: "admin",
            headers:{
              "Content-Type": "application/json",
            },
            data:{input:editObj}
          };

          try{
            await httpInstance(`${UPDATE_RISK_THEMATIC}`,httpConfig)
            if(adminStore?.Adminuser?.role?.name=="Admin"){
                CustomToast(`Approval request sent`,"SUCCESS")
            }else{
                get_data()
            }                     
            setEdit(false)
          }

          catch(error){
            setError(error.response.data?.message)
          }

    }

   

  return (
    <div>
        <Box pr={15} pl={3} mt={5}>
        <Box className={classes.root} >
            <Box display="flex" justifyContent="end" px={5} py={2}>
                <button className={classes.primaryButton} onClick={handle_edit}>
                    {edit ? "Save" :"Edit"}
                </button>
            </Box>

            <Box display="flex" justifyContent="end" px={5} >
            {error && <ErrorMessage message={error}/>}
            </Box>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                    <TableCell align='center' className={classes.cellBorder}>Investment Type</TableCell>
                    <TableCell align='center' className={classes.cell}>
                        Points Range
                        <Divider  />
                        <Grid container>
                            <Grid item xs={6} className={classes.gridBorder}>
                            Min
                            </Grid>
                            <Grid item xs={6}>
                            Max
                            </Grid>
                            
                        </Grid>
                        
                    </TableCell>
                    <TableCell align='center' >Thematic: Cap Ratio</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((ele,i)=>{
                        return(
                            <TableRow key={i}>
                    <TableCell align='center' className={classes.cellBorder}>{ele.riskProfile?.name} </TableCell>
                    <TableCell align='center' className={classes.subCell}>
                    <Grid container>
                            <Grid item xs={6} className={classes.gridItem}>
                                {edit ? 
                                <input 
                                    className={classes.inputBox}
                                    name="low"
                                    value={editObj[i].low}
                                    onChange={(e)=>handle_change(e,ele.id)}
                                    required
                                    /> 
                                : ele.riskProfile?.low}  Points
                               
                            </Grid>
                            <Grid item xs={6} className={classes.gridItem1}>
                            {edit ? 
                            <input
                            className={classes.inputBox}
                            value={editObj[i].high}
                            name="high"
                            onChange={(e)=>handle_change(e,ele.id)}
                            /> : ele.riskProfile?.high} Points
                            </Grid>
                            
                        </Grid>
                    </TableCell>
                    <TableCell align='center'>
                        {edit ?
                        <Box display="flex" justifyContent="center">
                            <input
                            className={classes.inputBox}
                            name="thematicPercentage"
                            value={editObj[i].thematicPercentage}
                            onChange={(e)=>handle_change(e,ele.id)}
                            /> 
                            :
                            <input
                            className={classes.inputBox}
                            name="capPercentage"
                            value={editObj[i].capPercentage}
                            onChange={(e)=>handle_change(e,ele.id)}
                            />
                        </Box> : 
                            

                            `${ele.thematicPercentage} : ${ele.capPercentage}`
                        }
                         </TableCell>
                    </TableRow>
                        )
                    })}
                    
                </TableBody>
            </Table>

        </Box>
        </Box>
    </div>
  )
}

export default InvestTable