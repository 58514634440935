import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TitleLayoult from "../TitleLayoult";
import FileUpload from "../uploader/FileUpload";
import { useStyles } from "../styles";
import { UPLOAD_CANCEL_CHECK, GET_KYC_DATA } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams } from "react-router-dom";
import { namespace } from "../../../environment";

function Step11({ setData }) {
  const classes = useStyles();

  const navigate = useNavigate();

  const [checkUrl, setCheckUrl] = useState("");
  const [nextbutton, setNextButton] = useState(false);

  useEffect(async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_KYC_DATA}`, httpConfig);
      setCheckUrl(
        response.data?.data?.userKycFilesData?.cancelledChequePublicUrl
      );
      if (response.data?.data?.userKycFilesData?.cancelledChequePublicUrl) {
        setNextButton(true);
      }
    } catch {}
  }, []);

  const submit_cancel_check = async (acceptedFiles) => {
    const formData = new FormData();
    formData.append("cancel_cheque_file", acceptedFiles[0]);

    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      await httpInstance(`${UPLOAD_CANCEL_CHECK}`, httpConfig);
      setData({ checkFile: acceptedFiles[0] });
      //handleNextButton()
      setNextButton(true);
      // navigate({
      //   pathname: '/signIn',
      //   search: `?${createSearchParams({stepcount:"11"})}`,
      // });
    } catch {}
  };

  const handleNext = () => {
    navigate({
      pathname: `${namespace}/signIn`,
      search: `?${createSearchParams({ stepcount: "11" })}`,
    });
  };

  const handleback = () => {
    navigate({
      pathname: `${namespace}/signIn`,
      search: `?${createSearchParams({ stepcount: "9" })}`,
    });
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={5}
        className={classes.backButton}
        onClick={handleback}
      >
        <img src="/images/backIcon.svg" />
      </Box>
      <Box display={"flex"} justifyContent="center">
        <Box display={"flex"} alignItems="flex-start" flexDirection={"column"}>
          <TitleLayoult
            title={"Upload Cancelled cheque"}
            description={"Confirm bank Details"}
            align=""
          />

          <Box>
            <FileUpload
              handleSubmit={submit_cancel_check}
              fileUrl={checkUrl}
              handleNext={handleNext}
              nextbutton={nextbutton}
              validation={/\.(jpg)$/}
              type=".jpg"
            />
          </Box>
          {/* {uploadCount === 3 ? (
          <Box mt={3}>
            <button className={classes.primaryButton} onClick={() => step(12)}>
              Next
            </button>
          </Box>
        ) : (
          ""
        )} */}
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Step11;
