import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Grid,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import Filters from "./Filters";
import FundCard from "./FundCard";
import {
  GET_FUND_LIST,
  ADD_FUND_TO_ASSISTED,
  REMOVE_FROM_ASSISTED,
  GET_TIMESTAMPS,
} from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomToast from "../../../../components/UI/CustomToast";
import { useSelector } from "react-redux";

const LoaderComp = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "1rem",
        overflow: "hidden",
      }}
    >
      <CircularProgress size="2rem" />
    </div>
  );
};

const Index = () => {
  const useStyles = makeStyles({
    input: {
      border: "none",
      width: "100%",
      padding: "15px",
      fontFamily: "inherit",
    },
    text: {
      textAlign: "center",
      marginTop: "10px",
    },
  });
  const classes = useStyles();

  const [filterData, setFilterData] = useState({});
  const [fundList, setFundList] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isAssisted, setIsAssisted] = useState(true);
  const [timestamps, setTimestamps] = useState([]);
  const { adminStore } = useSelector((state) => state);

  //--------------------------get list------------------------------------------//
  const get_fund_list = async () => {
    const formData = {
      from: page == 0 ? 0 : page * 10,
      assisted: true,
      size: 10,
      major: true,
      minor: true,
      fundHouse: [],
    };
    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      data: { ...formData, ...filterData },
    };
    try {
      const response = await httpInstance(`${GET_FUND_LIST}`, httpConfig);
      if (response.data?.responseCount == 0) {
        setHasMore(false);
      } else {
        setFundList([...fundList, ...response.data?.data]);

        setPage(page + 1);
      }
      setIsAssisted(httpConfig?.data?.assisted);
    } catch {
      setHasMore(false);
    }
  };

  useEffect(() => {
    get_fund_list();
  }, [filterData]);

  //---------------------------------set filter------------------------------------//

  const set_filter = (data) => {
    setFundList([]);
    setPage(0);
    setHasMore(true);
    setFilterData({ ...filterData, ...data });
  };
  //--------------------------------reset fundlist-----------------------------------//
  const reset_list = () => {
    setFundList([]);
    setPage(0);
    setHasMore(true);
  };
  //------------------------------reset filter----------------------------------------//
  const reset_filter = () => {
    setFundList([]);
    setPage(0);
    setHasMore(true);
    setFilterData({});
  };
  //------------------------------------handle search---------------------------------------------//
  const handle_search = (e) => {
    reset_list();
    setFilterData({ ...filterData, searchText: e.target.value });
  };

  //-----------------------------add/remove from fundlist---------------------------------------//
  const remove_from_list = (type, schemeCode) => {
    let arry;
    if (type == "remove") {
      if (isAssisted) {
        arry = fundList?.filter((ele) => {
          return schemeCode != ele.scheme_code;
        });
      } else {
        arry = fundList?.map((ele) => {
          if (schemeCode == ele.scheme_code) {
            return { ...ele, assisted: false };
          } else {
            return { ...ele };
          }
        });
      }
    } else {
      arry = fundList?.map((ele) => {
        if (schemeCode == ele.scheme_code) {
          return { ...ele, assisted: true };
        } else {
          return { ...ele };
        }
      });
    }
    setFundList(arry);
  };

  //-------------------------------add Fund-------------------------------//
  const handle_add_fund = async (data) => {
    const formData = {
      fundId: data?.scheme_code,
      name: data?.scheme_name,
      isin: data?.isin,
      amcCode: data?.amc_code,
      imgUrl: data?.img_url,
    };
    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      await httpInstance(`${ADD_FUND_TO_ASSISTED}`, httpConfig);
      if (adminStore?.Adminuser?.role?.name == "Superadmin") {
        CustomToast("Fund added to Assisted Services", "SUCCESS");
        remove_from_list("add", data?.scheme_code);
      } else {
        CustomToast("Approval request sent", "SUCCESS");
      }
    } catch {}
  };

  //-------------------------------REMOVE FUND--------------------------------------------------//
  const handle_remove_fund = async (data, timestamp_id) => {
    console.log(timestamp_id);
    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        amcCode: data?.amc_code,
        fundId: data?.scheme_code,
        imgUrl: data?.img_url,
        name: data?.scheme_name,
        assistedServicesTimestampId: timestamp_id,
      },
    };

    try {
      await httpInstance(`${REMOVE_FROM_ASSISTED}`, httpConfig);
      if (adminStore?.Adminuser?.role?.name == "Superadmin") {
        CustomToast("Fund removed from Assisted Services", "SUCCESS");
        remove_from_list("remove", data?.scheme_code);
      } else {
        CustomToast("Approval request sent", "SUCCESS");
      }
    } catch (error) {
      CustomToast(`${error.response.data?.message}`, "FAILED");
    }
  };

  const get_timestamps = async () => {
    const httpConfig = {
      method: "GET",
      //tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_TIMESTAMPS}`, httpConfig);
      setTimestamps(response.data?.data);
    } catch {}
  };

  useEffect(() => {
    get_timestamps();
  }, []);

  return (
    <div>
      <input
        //value={search}
        className={classes.input}
        placeholder="Search for Funds"
        onChange={handle_search}
      />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Filters set_filter={set_filter} reset_filter={reset_filter} />
        </Grid>
        <Grid item xs={9}>
          <Box mt={5}>
            <InfiniteScroll
              dataLength={fundList?.length}
              next={get_fund_list}
              hasMore={hasMore}
              loader={<LoaderComp />}
              endMessage={
                <Typography className={classes.text}>
                  No result to display
                </Typography>
              }
              scrollThreshold={1}
            >
              {fundList?.map((ele, i) => {
                return (
                  <FundCard
                    key={i}
                    fundData={ele}
                    handle_add_fund={handle_add_fund}
                    handle_remove_fund={handle_remove_fund}
                    timestamps={timestamps}
                  />
                );
              })}
            </InfiniteScroll>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Index;
