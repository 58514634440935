import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import ErrorMessage from "../../../../components/Validation/ErrorMessage";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GENERATE_SWITCH,
  VERIFY_SWITCH_OTP,
  GET_FUND_FP_DATA,
} from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import OTP from "../OTP";
import { namespace } from "../../../../environment";

const SwitchDialog = ({
  show,
  handleClose,
  fundToData,
  fundFromData,
  category,
}) => {
  const useStyles = makeStyles({
    root: {
      "& .MuiDialogContent-root": {
        fontSize: "0.8rem",
        lineHeight: "25px",
        padding: "20px 25px",
      },
      "& .MuiDialog-paperWidthSm": {
        minWidth: "50%",
        maxWidth: "300px",
        maxHeight: "100%",
      },
    },
    dialogTitle: {
      padding: "10px",
      "& .MuiTypography-body1": {
        fontSize: "1.3rem",
        fontWeight: 500,
      },
    },

    text: {
      fontSize: "0.8rem",
    },
    textGrey: {
      fontSize: "0.8rem",
      color: "#9D9D9D",
      marginTop: "10px",
    },
    inputBox: {
      background: "#B3E1FF",
      border: "none",
      borderRadius: "5px",
      padding: "10px",
      width: "-webkit-fill-available",
      color: "#0C97F0",
    },

    inputDisabled: {
      background: "#DDDDDD",
      border: "none",
      borderRadius: "5px",
      padding: "10px",
      width: "-webkit-fill-available",
    },

    rupeesSymbol: {
      position: "absolute",
      left: "8px",
      top: "6px",
      color: "#0C97F0",
      fontFamily: "Open Sans",
    },
    checkBox: {
      display: "flex",
      justifyContent: "end",
      "& .MuiSvgIcon-root": {
        height: "0.9em",
      },
      "& .MuiTypography-body1": {
        fontSize: "0.75rem",
        color: "#9D9D9D",
      },
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: "#0C97F0",
      },
      "& .MuiFormControlLabel-root": {
        marginRight: "0px",
      },
    },
    primaryButton: {
      padding: "0.8rem 3rem",
      background: "#326CF9",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      fontFamily: "inherit",
    },
    rupees: {
      fontFamily: "Open Sans",
    },
    minimumText: {
      fontSize: "0.7rem",
      color: "#9D9D9D",
      marginLeft: "30px",
    },
  });

  const classes = useStyles();

  const [switchType, setSwitchType] = useState("units");
  const [switchInFpData, setSwitchInFpData] = useState(null);
  const [units, setUnits] = useState("");
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comp, setComp] = useState("switch");
  const [loadVerify, setLoadVerify] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [id, setId] = useState("");
  const [inputError, setInputError] = useState("");

  const { state } = useLocation();
  const { schemeData, switchData } = state;
  const navigate = useNavigate();

  const get_switchIn_data = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { isin: fundToData?.isin },
    };
    try {
      const response = await httpInstance(`${GET_FUND_FP_DATA}`, httpConfig);
      setSwitchInFpData(response.data?.data);
    } catch {}
  };

  useEffect(() => {
    if (show) {
      get_switchIn_data();
    }
  }, [show]);

  const handle_close = () => {
    setLoading(false);
    setUnits("");
    setAmount("");
    handleClose();
    setComp("switch");
    setSwitchType("units");
  };

  const handle_switch_type = (value) => {
    setUnits("");
    setAmount("");
    setInputError("");
    setError("");
    setSwitchType(value);
  };

  const handleAmount = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "").toLowerCase();
    setAmount(e.target.value);
    let maxAmount = Math.max(
      switchData?.minSwitchOutAmount,
      switchInFpData?.minSwitchInAmount
    );
    if (e.target.value < maxAmount) {
      setInputError(`Minimum Switch Amount ${maxAmount}`);
    } else if (e.target.value > schemeData?.redeemableAmount) {
      setInputError(`Available Switch Amount ${schemeData?.redeemableAmount}`);
    } else {
      setInputError("");
    }
  };

  const handleUnits = (e) => {
    e.target.value = e.target.value.replace(/[^0-9.]/g, "").toLowerCase();
    setUnits(e.target.value);
    if (e.target.value < switchData?.minSwitchOutUnits) {
      setInputError(`Minimum Switch Amount ${switchData?.minSwitchOutUnits}`);
    } else if (e.target.value > schemeData?.redeemableUnits) {
      setInputError(`Available Switch Units ${schemeData?.redeemableUnits}`);
    } else {
      setInputError("");
    }
  };

  const handle_switch_all = (e) => {
    if (e.target.checked) {
      handle_switch_type("all");
      //setAmount(schemeData?.redeemableAmount)
    } else {
      setSwitchType("");
    }
  };
  //------------------------------confirm redeem-------------------------------------------//
  const submit_switch = async () => {
    if (
      (switchType == "units" && !units) ||
      (switchType == "amount" && !amount) ||
      switchType == ""
    ) {
      setError("Please enter Switch Units / Amount");
    } else if (!inputError) {
      setError("");
      setLoading(true);

      const formData = {
        userId: localStorage.getItem("user_id"),
        transactionTypeId: 4,
        fundAmount:
          switchType == "amount"
            ? amount
            : switchType == "all"
            ? schemeData?.redeemableAmount
            : 0,
        folioNumber: schemeData?.folioNumber,
        nav: schemeData?.nav,
        schemeOutIsin: schemeData?.isin,
        schemeInIsin: fundToData?.isin,
        schemeOutFundPlanId: fundFromData?.scheme_code,
        schemeInFundPlanId: fundToData?.scheme_code,
        switchOutMinAmt: fundFromData?.switch_min_amt,
        switchInAllowed: true,
        switchInSchemeName: fundToData?.scheme_name,
        switchOutSchemeName: fundFromData?.scheme_name,
        switchInImgUrl: fundToData?.img_url,
        switchOutImgUrl: fundFromData?.img_url,
        units: switchType == "units" ? units : null,
        isAssisted: category == "assisted" ? true : false,
      };

      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      try {
        const response = await httpInstance(`${GENERATE_SWITCH}`, httpConfig);
        setId(response.data?.data?.id);
        setComp("otp");
      } catch (error) {
        setLoading(false);
        setError(error.response.data?.message);
      }
    }
  };

  //---------------------------verify OTP---------------------------------------------------//
  const verify_otp = async (otp) => {
    setLoadVerify(true);
    setOtpError(false);
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { id: id, otp: otp },
    };

    try {
      await httpInstance(`${VERIFY_SWITCH_OTP}`, httpConfig);
      navigate(`${namespace}/mutualfund/switch-success`, {
        state: {
          fromFund: {
            name: fundFromData?.scheme_name,
            img: fundFromData?.img_url,
          },
          toFund: { name: fundToData?.scheme_name, img: fundToData?.img_url },
        },
      });
    } catch (error) {
      setLoadVerify(true);
      handle_otp_error(error.response.data?.message);
    }
  };

  const handle_otp_error = (error) => {
    setLoadVerify(false);
    setOtpError(error);
  };
  return (
    <div>
      <Dialog className={classes.root} open={show}>
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Grid container>
            <Grid item xs={11}>
              <Box display="flex" justifyContent="center">
                <Typography className={classes.title}>Switch</Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box display="flex" justifyContent="end">
                <CloseIcon
                  onClick={handle_close}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {comp == "switch" ? (
            <>
              <Box px={{ xs: 2, md: 14 }}>
                <Grid container>
                  <Grid item xs={8} className={classes.text}>
                    <Box display="flex" alignItems="center">
                      <input
                        type="radio"
                        id="r1"
                        checked={switchType == "units"}
                        onChange={() => handle_switch_type("units")}
                        style={{
                          height: "16px",
                          width: "16px",
                          marginRight: 10,
                        }}
                      />
                      <label htmlFor="r1"> Enter Units</label>
                      <br />
                    </Box>
                    <Typography
                      className={classes.minimumText}
                    >{`Minimum Switch Units ${switchData?.minWithdrawalUnits}`}</Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.text}>
                    <Box>
                      <input
                        type="text"
                        className={
                          switchType == "units"
                            ? classes.inputBox
                            : classes.inputDisabled
                        }
                        disabled={switchType == "units" ? false : true}
                        value={units}
                        onChange={handleUnits}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Box py={2} style={{ borderBottom: "1px solid #C4C4C4" }}>
                  <Grid container>
                    <Grid item xs={8} className={classes.text}>
                      <Box display="flex" alignItems="center">
                        <input
                          type="radio"
                          id="r2"
                          checked={switchType == "amount"}
                          onChange={() => handle_switch_type("amount")}
                          style={{
                            height: "16px",
                            width: "16px",
                            marginRight: 10,
                          }}
                        />
                        <label htmlFor="r2"> Enter Amount</label>
                        <br />
                      </Box>
                      <Typography
                        className={classes.minimumText}
                      >{`Minimum Switch Amount ${Math.max(
                        switchData?.minSwitchOutAmount,
                        switchInFpData?.minSwitchInAmount
                      )}`}</Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.text}>
                      <Box style={{ position: "relative" }}>
                        {switchType == "amount" && (
                          <span className={classes.rupeesSymbol}>₹</span>
                        )}
                        <input
                          type="text"
                          className={
                            switchType == "amount"
                              ? classes.inputBox
                              : classes.inputDisabled
                          }
                          style={{ paddingLeft: "18px" }}
                          disabled={switchType == "amount" ? false : true}
                          value={amount}
                          onChange={handleAmount}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box px={{ xs: 2, md: 14 }}>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography className={classes.textGrey}>
                      Available Amount
                    </Typography>
                    <Typography className={classes.textGrey}>
                      Available Units
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Box display="flex" justifyContent="end">
                      {" "}
                      <Typography className={classes.textGrey}>
                        <span className={classes.rupees}>₹ </span>
                        {schemeData?.redeemableAmount}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="end">
                      <Typography className={classes.textGrey}>
                        {schemeData?.redeemableUnits}
                      </Typography>
                    </Box>
                    <Box className={classes.checkBox}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            onChange={handle_switch_all}
                            checked={switchType == "all"}
                          />
                        }
                        label="Switch All"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box px={{ xs: 2, md: 14 }}>
                {error && <ErrorMessage message={error} />}
                {inputError && <ErrorMessage message={inputError} />}
              </Box>
              <Box mt={5} mb={10} display="flex" justifyContent="center">
                <button
                  className={classes.primaryButton}
                  onClick={submit_switch}
                >
                  {loading ? (
                    <CircularProgress size="1rem" color="inherit" />
                  ) : (
                    "Confirm"
                  )}
                </button>
              </Box>
            </>
          ) : (
            <Box px={{ xs: 2, md: 15 }}>
              <OTP
                verify_otp={verify_otp}
                id={id}
                transactionTypeId={4}
                handle_otp_error={handle_otp_error}
                otpError={otpError}
                loadVerify={loadVerify}
              />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SwitchDialog;
