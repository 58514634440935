import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import All_funds from './all_funds';
import { Container } from '@material-ui/core';




function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tab_box:{
    padding:"0px 4rem"
  },
  tab_style: {
    width: "100%",
    background:"white",
    color:"black",
    borderBottom : '1px solid #E5E3E3',
    "& .MuiTab-root":{
      fontFamily:"inherit",
      fontSize:"1rem",
      color:"black",
      opacity:1,
      textTransform: "none",
      background:"none",
      minWidth:"auto",
      padding:"0px"
    },
    "& .MuiTab-textColorInheri":{
      opacity:0.9
    },
    "& .MuiTabs-root":{
      height:"42px",
      minHeight:"42px",
      padding:"0px"
    },
    "& .MuiTabs-flexContainer":{
      display:"flex",
      justifyContent:"space-between"
    },
    "& .MuiTabs-scrollable":{
      overflowY:"clip"
    }
   
      
  },
  tabWidth:{
    width:'25%',
    color:'#272727',
    fontSize:'15px',
    fontFamily:'Poppins',
    fontWeight:'400'
  },
  activeTab:{
    "& .MuiTab-wrapper":{
      borderBottom:"2px solid #0C97F0",
      color:"#0C97F0",
      paddingBottom:"5px",
     
    }
   
  },
  
  inActiveTab:{
    "& .MuiTab-wrapper":{
      paddingBottom:"5px",
     
    }
   
  },
  "@media (max-width: 600px)": {
    tab_box:{
      padding:"20px"
    },
    activeTab:{
      "& .MuiTab-wrapper":{
        padding:"10px"      
      }    
    },
    inActiveTab:{
      "& .MuiTab-wrapper":{
        padding:"10px"
       
      }
    },
}
  
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [type,setType]=React.useState('all')




  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
        <Container className={classes.tab_box}>
       
            <div className={classes.tab_style}>
             
                
                    <Tabs indicatorColor=" "  value={value} onChange={handleChange}  variant="scrollable" allowScrollButtonsMobil={true}>
                      
                   <Tab  label="All Funds" {...a11yProps(0)} className={value==0 ? classes.activeTab :classes.inActiveTab} />
                   <Tab label="Equity Funds" {...a11yProps(1)} className={value==1 ? classes.activeTab :classes.inActiveTab} />
                   <Tab  label="Hybrid Funds" {...a11yProps(2)} className={value==2 ? classes.activeTab :classes.inActiveTab}/>
                   <Tab  label="Commodity Funds" {...a11yProps(3)} className={value==3? classes.activeTab :classes.inActiveTab}/>
                   <Tab  label="Fixed Income Funds" {...a11yProps(4)} className={value==4 ? classes.activeTab :classes.inActiveTab}/>
                   <Tab  label="Global Funds" {...a11yProps(5)} className={value==5 ? classes.activeTab :classes.inActiveTab}/>
                    </Tabs>
                   
            </div>
       
      </Container>
      <Box py={4}>
      <TabPanel value={value} index={0}>
        <All_funds type="all"/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <All_funds type="Equity"/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <All_funds type="Hybrid"/>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <All_funds type="Commodity"/>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <All_funds type="Fixed Income"/>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <All_funds type="Global"/>
      </TabPanel>


      </Box>
    </div>
  );
}
