import React, { useState } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  super_admin_nav,
  admin_nav,
} from "../../utils/Data/Admin/AdminSideNav";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { namespace } from "../../environment";

const SideNav = () => {
  const drawerWidth = 260;
  const useStyles = makeStyles({
    drawer: {
      width: drawerWidth,
    },

    drawerPaper: {
      width: drawerWidth,
    },
    list: {
      "& .MuiTypography-body1": {
        fontSize: "0.85rem",
        fontWeight: 500,
      },
      marginTop: "15px",
    },

    listItem: {
      padding: "15px 25px",
      opacity: 0.5,
      " &:hover": {
        opacity: 1,
      },
    },

    activeListItem: {
      padding: "15px 25px",
    },

    listText: {
      padding: " 0px 12px",
      color: "#0C97F0",
    },
    expandIcon: {
      color: "#0C97F0",
    },
  });
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [openUser, setOpenUser] = useState(false);

  const { adminStore } = useSelector((state) => state);

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleOpenUser = () => {
    setOpenUser(!openUser);
  };

  const handle_navigate = (path) => {
    navigate(path);
  };

  const NavItem = ({ text, path, icon, type, params }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const query = searchParams.get(params);

    return (
      <ListItem
        button
        className={
          query === type || location.pathname === path
            ? classes.activeListItem
            : classes.listItem
        }
        onClick={() => handle_navigate(path)}
      >
        {icon && <img src={icon} height="15px" width="15px" />}
        <ListItemText className={classes.listText} primary={text} />
      </ListItem>
    );
  };

  const NavExpandItem = ({
    text,
    path,
    icon,
    handleExpand,
    isExpand,
    type,
  }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const params = searchParams.get("type");

    return (
      <>
        <ListItem
          button
          className={
            params === type || location.pathname === path
              ? classes.activeListItem
              : classes.listItem
          }
          onClick={handleExpand}
        >
          {icon && <img src={icon} height="15px" width="15px" />}
          <ListItemText className={classes.listText} primary={text} />
          {isExpand ? (
            <ExpandLessIcon className={classes.expandIcon} />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} />
          )}
        </ListItem>
      </>
    );
  };

  return (
    <div>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        anchor="left"
        classes={{ paper: classes.drawerPaper }}
      >
        <Box p={1} display="flex">
          <img src="/Neosurge.svg" height="60px" width="150px" />
          <img src="/images/menu.svg" height="50px" width="30px" />
        </Box>
        <List className={classes.list}>
          {/* {(adminStore?.Adminuser?.role?.name=="Superadmin" ? super_admin_nav : admin_nav)?.map((item)=>{
          return(            
            <ListItem button key={item.text} className={location.pathname===item.path ? classes.activeListItem : classes.listItem} onClick={()=>handle_navigate(item.path)}>
              <img src={item.icon} height="15px" width="15px" />
              <ListItemText className={classes.listText} primary={item.text} />
            </ListItem>
         
          )
        })} */}
          <NavItem
            text="Dashboard"
            icon="/images/admin/dashboard.svg"
            path={`${namespace}/admin/dashboard`}
          />
          <NavItem
            text="Allocation  Management"
            icon="/images/admin/allocation management.svg"
            path={`${namespace}/admin/allocation-management`}
          />

          <NavExpandItem
            text="User Management"
            icon="/images/admin/usermanagement.svg"
            path={`${namespace}/admin/user-management`}
            handleExpand={handleClick}
            isExpand={open}
          />
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List>
              {adminStore?.Adminuser?.role?.name == "Superadmin" && (
                <NavItem
                  text="Admin"
                  path={`${namespace}/admin/user-management?type=admin`}
                  type="admin"
                  params="type"
                />
              )}
              <NavExpandItem
                text="User"
                // icon="/images/admin/usermanagement.svg"
                path={`${namespace}/admin/user-management?type=user`}
                handleExpand={handleOpenUser}
                isExpand={openUser}
                type="user"
              />
              <Collapse in={openUser} timeout="auto" unmountOnExit>
                <List>
                  <NavItem
                    text="Submitted"
                    path={`${namespace}/admin/user-management?type=user&status=submitted`}
                    params="status"
                    type="submitted"
                  />
                  <NavItem
                    text="Approved"
                    path={`${namespace}/admin/user-management?type=user&status=approved`}
                    params="status"
                    type="approved"
                  />
                  <NavItem
                    text="Rejected"
                    path={`${namespace}/admin/user-management?type=user&status=rejected`}
                    params="status"
                    type="rejected"
                  />
                </List>
              </Collapse>
            </List>
          </Collapse>
          {adminStore?.Adminuser?.role?.name == "Admin" && (
            <NavItem
              text="User Status"
              icon="/images/admin/usermanagement.svg"
              path={`${namespace}/admin/user-status`}
            />
          )}
          <NavItem
            text="Customer Database"
            icon="/images/admin/customerdatabase.svg"
            path={`${namespace}/admin/customer-database`}
          />
          <NavItem
            text="Investor Insights"
            icon="/images/admin/investor insight.svg"
            path={`${namespace}/admin/investor-insights`}
          />
          <NavItem
            text="Fund Management"
            icon="/images/admin/fundmanagement.svg"
            path={`${namespace}/admin/fund-management`}
          />
          <NavItem
            text="Assisted Pins"
            icon="/images/admin/usermanagement.svg"
            path={`${namespace}/admin/assisted-pins`}
          />
          <NavItem
            text="Timestamps"
            icon="/images/admin/usermanagement.svg"
            path={`${namespace}/admin/timestamps`}
          />
        </List>
      </Drawer>
    </div>
  );
};

export default SideNav;
