import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  makeStyles,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import { SEARCH_FUNDS } from "../../utils/endpoints";
import httpInstance from "../../utils/instance";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { namespace } from "../../environment";

const LoaderComp = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "1rem",
        overflow: "hidden",
      }}
    >
      <CircularProgress size="2rem" />
    </div>
  );
};

const Search = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      position: "relative",
    },
    searchBox: {
      borderRadius: "30px",
      boxShadow:
        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
      padding: "12px",
      "& input:focus": {
        outline: "none",
      },
      "& .box:focus-within": {
        border: "thin solid black",
      },
      //   "& input":{
      //  textAlign:"center",
      //     "& ::placeholder": {
      //       textAlign: "center",
      //     },
      //     " & :-moz-placeholder" :{
      //       textAlign: "center"
      //     }
      //   }
    },

    inputBox: {
      border: "none",
      width: "90%",
      padding: "0px 12px",
      fontSize: "0.9rem",
    },
    containerBox: {
      borderRadius: "3px",
      //boxShadow:"rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",

      position: "absolute",
      border: "1px solid #cccc",
      width: "92%",
      padding: "12px 15px",
      marginTop: "1px",
      background: "#fff",
      maxHeight: "300px",
      overflowY: "auto",
    },
    text: {
      fontSize: "0.8rem",
      padding: "5px",
    },
    "@media (max-width:600px)": {
      searchBox: {
        padding: "8px",
      },
    },
  }));

  const classes = useStyles();

  const [text, setText] = useState("");
  const [search, setSearch] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [autofill, setAutoFill] = useState(false);

  const navigate = useNavigate();

  const get_data = async () => {
    const formData = {
      from: page == 0 ? 0 : page * 10,
      size: 10,
      plan_name: "Broker",
      option_name: "growth",
      holding_period: "",
      search_text: text,
      ratings: [],
    };

    const httpConfig = {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      const response = await httpInstance(`${SEARCH_FUNDS}`, httpConfig);
      if (response.data?.responseCount == 0) {
        setHasMore(false);
      } else {
        setSearch([...search, ...response.data?.data]);
        setPage(page + 1);
      }
    } catch {}
  };

  const handle_search = (e) => {
    setAutoFill(true);
    setPage(0);
    setSearch([]);
    setText(e.target.value);
  };

  useEffect(() => {
    get_data();
  }, [text]);

  const fetchData = () => {
    get_data();
  };

  //close menu on outside click

  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (autofill && ref.current && !ref.current.contains(e.target)) {
        setAutoFill(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [autofill]);

  const handle_navigate = (data) => {
    navigate(`${namespace}/mutualfund/scheme-code/${data?.scheme_code}`, {
      state: { data: data },
    });
    setAutoFill(false);
  };

  return (
    <div className={classes.root} ref={ref}>
      <Box className={classes.searchBox} display="flex">
        <SearchIcon style={{ opacity: 0.5 }} />
        <input
          className={classes.inputBox}
          placeholder={"Search for Mutual Funds"}
          onChange={handle_search}
          //onFocus={(e) => e.target.placeholder = ''}
        />
      </Box>

      {autofill && (
        <div className={classes.containerBox} id="search-component">
          <InfiniteScroll
            dataLength={search?.length}
            next={fetchData}
            hasMore={hasMore}
            loader={<LoaderComp />}
            scrollableTarget={"search-component"}
          >
            {search.length > 0 ? (
              search?.map((ele, i) => {
                return (
                  <Box
                    display="flex"
                    key={i}
                    style={{
                      borderBottom: "1px solid #cccc",
                      cursor: "pointer",
                    }}
                    p={1}
                    onClick={() => handle_navigate(ele)}
                  >
                    <img src={ele.img_url} height="30px" width="30px" />
                    <Typography className={classes.text}>
                      {ele.scheme_name}
                    </Typography>
                  </Box>
                );
              })
            ) : (
              <Typography
                className={classes.text}
                style={{ textAlign: "center" }}
              >
                No Result
              </Typography>
            )}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

export default Search;
