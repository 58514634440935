import React,{useState} from 'react'
import PanCardData from './PanCardData'
import UploadPan from './UploadPan'
import {UPDATE_PROFILE_KYC} from '../../../../utils/endpoints'
import httpInstance from '../../../../utils/instance'
import moment from 'moment'
import { getGoldUser } from '../../../../redux/User/userActions'
import { useDispatch } from 'react-redux/es/hooks/useDispatch'

const Index = ({closeDialog}) => {

    const [panDetails,setPanDetails]=useState()
    const [panUpload,setPanUpload]=useState(false)
    const [error, setError]=useState('')

    const dispatch=useDispatch()

    const handle_panDetails=(data)=>{
        setPanDetails(data)
        setPanUpload(true)
    }

    const submit_kyc= async(file)=>{

      
        const formData=new FormData()

        formData.append('panName',panDetails.fullName)
        formData.append('panNumber',panDetails.panNumber)
        formData.append('dob',moment(panDetails.dob).format('YYYY-MM-DD'))
        formData.append('panImage',file[0])

        const httpConfig = {
            method: "POST",
            tokenType: "user",
            headers:{
              "Content-Type": "application/json",
            },
            data:formData
          };

          try{
            await httpInstance(`${UPDATE_PROFILE_KYC}`,httpConfig)
            dispatch(getGoldUser())
            closeDialog()
          }
          catch(error){
           if(error.response.data?.message.includes('Invalid format of DOB')) {
            setError("Invalid DOB")
           }else{
            setError(error.response.data?.message)
           }  
            setPanUpload(false)
          }
    }

   
   
  return (
    <div>
        {panUpload ?  <UploadPan submit_kyc={submit_kyc}/> : 
        <PanCardData handle_panDetails={handle_panDetails} panDetails={panDetails} error={error}/> }
    </div>
  )
}

export default Index