import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import { useNavigate, createSearchParams } from "react-router-dom";
import {
  GET_TRANS_BY_STATUS,
  GET_DELIVERY_TRANS,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import moment from "moment";
import { namespace } from "../../../environment";

const OrderprogressCard = ({ data }) => {
  const useStyles = makeStyles((theme) => ({
    header: {
      padding: "0px 20.5rem",
      fontWeight: 600,
      color: "#272727",
      fontFamily: "Poppins",
    },

    boxCard: {
      borderRadius: "5px ",
      padding: "10px 20px",
      boxShadow:
        "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
      border: "1px solid #cccc",
    },

    text: {
      fontSize: "0.9rem",
    },

    buy: {
      fontSize: "0.7rem",
      borderRadius: "30px",
      background: "#FFE19E",
      color: "#F2AF1A",
      width: "fit-content",
      padding: "3px 20px",
    },

    sell: {
      fontSize: "0.7rem",
      borderRadius: "30px",
      background: "#FFD7C4",
      color: "#F27E45",
      width: "fit-content",
      padding: "3px 20px",
    },

    orderStatus: {
      color: "#0C97F0",
      fontWeight: 600,
      marginLeft: "5px",
      cursor: "pointer",
    },

    orderTime: {
      fontSize: "0.7rem",
      padding: "5px",
      marginTop: "6px",
    },
    rupees: {
      fontFamily: "Open Sans",
    },
    text2: {
      fontSize: "0.7rem",
    },

    "@media (max-width: 480px)": {
      header: {
        padding: "0px",
      },
      text2: {
        textAlign: "end",
      },
    },
    "@media (max-width: 912px) and (min-width:481px)": {
      header: {
        padding: "0px 5rem",
      },
    },
    "@media (min-width: 1025px)": {
      card: {
        padding: "0px 22rem",
      },
    },
  }));

  const classes = useStyles();

  const [orderProgress, setOrderProgress] = useState([]);

  const navigate = useNavigate();

  const get_order_progress = async () => {
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { type: "buy/sell", status: "PROCESSING", limit: 1000, from: 0 },
    };

    const httpConfig1 = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { status: "PROCESSING", limit: 1000, from: 0 },
    };

    try {
      const [buysell, delresponse] = await Promise.all([
        httpInstance(`${GET_TRANS_BY_STATUS}`, httpConfig),
        httpInstance(`${GET_DELIVERY_TRANS}`, httpConfig1),
      ]);

      let delArr = delresponse?.data?.data.map((ele) => {
        return {
          id: ele.id,
          orderType: "Delivery",
          metalType: ele.deliveryGoldSilverProduct[0]?.sku?.metalType,
          createdAt: ele.createdAt,
          totalAmount: ele.deliveryGoldSilverProduct[0]?.totalAmount,
          quantity: ele.deliveryGoldSilverProduct[0]?.quantity,
          status: ele.status,
        };
      });

      let allPending = [...buysell.data?.data, ...delArr].sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setOrderProgress(allPending);
    } catch {}
  };

  useEffect(() => {
    get_order_progress();
    return () => {
      setOrderProgress([]);
    };
  }, []);

  const handleOrderStatus = (type, id) => {
    navigate({
      pathname: `${namespace}/myportfolio/my-order`,
      search: `?${createSearchParams({
        order_type: type.toLowerCase(),
        order_id: id,
        status: "progress",
      })}`,
    });
  };
  return (
    <div>
      {orderProgress.length > 0 && (
        <Box>
          <Typography className={classes.header}>
            Order in Progress({orderProgress?.length})
          </Typography>
        </Box>
      )}
      {orderProgress.map((ele, i) => {
        return (
          <Box mt={5} key={i}>
            <Box
              display="flex"
              justifyContent="center"
              className={classes.card}
              mt={4}
            >
              <Grid container className={classes.boxCard}>
                <Grid item xs={6}>
                  <Typography className={classes.text}>
                    Digital Gold ({ele.metalType})
                  </Typography>
                  <Box display={{ md: "flex" }} alignItems="center">
                    <Box
                      className={
                        ele.orderType == "BUY" ? classes.buy : classes.sell
                      }
                      mt={1}
                    >
                      {ele.orderType[0] + ele.orderType.slice(1).toLowerCase()}
                    </Box>
                    <span className={classes.orderTime}>
                      ({moment(ele.createdAt).format("DD MMM YYYY")})
                    </span>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    display="flex"
                    justifyContent="end"
                    flexDirection="column"
                    alignItems="end"
                  >
                    <Typography>
                      {ele.quantity}g (<span className={classes.rupees}>₹</span>
                      {ele.totalAmount})
                    </Typography>
                    <p className={classes.text2}>
                      {ele.status == "SUCCESS"
                        ? `Expected in ${
                            ele.orderType == "Delivery" ? "10" : "2"
                          } days`
                        : ""}{" "}
                      {"  "}
                      <span
                        className={classes.orderStatus}
                        onClick={() => handleOrderStatus(ele.orderType, ele.id)}
                      >
                        {" "}
                        Order Status
                      </span>
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        );
      })}
    </div>
  );
};

export default OrderprogressCard;
