import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TitleLayoult from "../TitleLayoult";
import { useStyles } from "../styles";
import ErrorMessage from "../../Validation/ErrorMessage";
import { VERIFY_BANK_ACCOUNT } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams } from "react-router-dom";
import { namespace } from "../../../environment";

function Step10({ step }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [verifyStatus, setVerifyStatus] = useState("");

  useEffect(async () => {
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${VERIFY_BANK_ACCOUNT}`, httpConfig);
      setVerifyStatus(response.data.message);
    } catch {
      setVerifyStatus("Please Update Valid Bank Details");
    }
  }, []);

  const redirect_bankDetails = () => {
    navigate({
      pathname: `${namespace}/signIn`,
      search: `?${createSearchParams({ stepcount: "11" })}`,
    });
  };

  const handleNext = () => {
    navigate({
      pathname: `${namespace}/signIn`,
      search: `?${createSearchParams({ stepcount: "13" })}`,
    });
  };

  const handleback = () => {
    navigate({
      pathname: `${namespace}/signIn`,
      search: `?${createSearchParams({ stepcount: "11" })}`,
    });
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={5}
        className={classes.backButton}
        onClick={handleback}
      >
        <img src="/images/backIcon.svg" />
      </Box>
      <Box display={"flex"} justifyContent="center">
        <Box display={"flex"} alignItems="center" flexDirection={"column"}>
          <TitleLayoult
            title={"Bank Account Verification"}
            description={
              "We will be transferring ₹ 1 to verify your account details"
            }
            align={"center"}
          />

          <Box>
            <img
              src="/images/undraw_savings.svg"
              style={{ height: "16rem", width: "16rem" }}
            />
          </Box>
          {verifyStatus === "Bank verified" ? (
            <p className={classes.verifiedKycText}>Bank Account Verified</p>
          ) : (
            <ErrorMessage message={verifyStatus} />
          )}
          <Box mt={3}>
            {verifyStatus === "Please Update Valid Bank Details" ? (
              <button
                className={classes.primaryButton}
                onClick={redirect_bankDetails}
              >
                Update Bank Details
              </button>
            ) : (
              <button
                className={
                  verifyStatus ? classes.primaryButton : classes.disabledButton
                }
                onClick={handleNext}
              >
                Next
              </button>
            )}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Step10;
