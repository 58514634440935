import React from 'react'
import NomineeDetials from '../../containers/MyProfile/NomineeDetails'
import Report from '../../containers/MyProfile/Reports'

const Nominee_details = () => {
  return (
    <div><NomineeDetials/></div>
  )
}

export default Nominee_details