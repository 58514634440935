export default function  number_ordinals(number){
    const ordinalRules = new Intl.PluralRules("en", {
        type: "ordinal"
      });
      const suffixes = {
        one: "st",
        two: "nd",
        few: "rd",
        other: "th"
      };
      const suffix = suffixes[ordinalRules.select(number)];
      return (number + suffix);
}

