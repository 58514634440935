import { Fragment, useEffect, useRef, useState } from "react";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { Container } from "@material-ui/core";
import Funds_filter from "./funds_filter";
import List_funds from "./list_funds";
import { SEARCH_FUNDS, GET_ALL_AMC } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";

function AllFunds({ type }) {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    header: {
      border: "1px solid none",
    },
    connectButton: {
      border: "2px solid #326CF9",
      color: "#326CF9",
      borderRadius: "30px",
      padding: "0.6rem 1.8rem",
      fontWeight: 600,
      fontSize: "0.8rem",
      textDecoration: "none",
    },
    links: {
      fontSize: "0.9rem",
      fontWeight: "medium",
    },
  }));

  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [allAmc, setAllAmc] = useState([]);
  const [allFunds, setAllFunds] = useState([]);
  const [filterData, setFilterData] = useState({});

  const set_page = () => setPage(page + 1);

  const get_allFunds = async () => {
    const formData = {
      from: page == 0 ? 0 : page * 10,
      size: 10,
      plan_name: "Broker",
      option_name: "growth",
      holding_period: "",
      asset_category: type == "all" ? "" : type,
      ratings: [],
    };
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: { ...formData, ...filterData },
    };

    try {
      const response = await httpInstance(`${SEARCH_FUNDS}`, httpConfig);
      if (response.data?.responseCount == 0) {
        setHasMore(false);
      } else {
        setAllFunds([...allFunds, ...response.data?.data]);
        set_page();
      }
    } catch {}
  };

  useEffect(() => {
    get_allFunds();
  }, [filterData]);

  //----------------------------get all AMC-------------------------------------------------------------------//
  const get_all_amc = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_ALL_AMC}`, httpConfig);
      setAllAmc(response.data?.data);
    } catch {}
  };

  useEffect(() => {
    get_all_amc();
  }, []);

  const resetAll = () => {
    setAllFunds([]);
    setPage(0);
    setHasMore(true);
  };

  // ---------------------------------serach filter---------------------------------------------------------------//
  const search_filter = async (text) => {
    resetAll();
    setFilterData({ ...filterData, search_text: text });
  };

  //--------------------------------AMC Filter-------------------------------------------------------------------//
  const amc_filter = (amc) => {
    resetAll();
    setFilterData({ ...filterData, amc_code: amc });
  };
  //-------------------------------Risk profile filter---------------------------------------------//
  const risk_filter = (risk) => {
    resetAll();
    setFilterData({ ...filterData, risk_profile: risk });
  };

  //--------------------------sub asset filter---------------------------------------------//
  const subAsset_filter = (asset) => {
    resetAll();
    setFilterData({ ...filterData, asset_sub_category: asset });
  };

  //------------------------------Rating filter--------------------------------------------//
  const rating_filter = (rate) => {
    resetAll();
    setFilterData({ ...filterData, ratings: rate });
  };

  //---------------------resset filter-----------------------------------//
  const reset_filter = () => {
    resetAll();
    setFilterData({});
  };

  return (
    <Fragment>
      <Box className={classes.header} px={{ xs: 0, md: 6 }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item md={4}>
              <Funds_filter
                allAmc={allAmc}
                amc_filter={amc_filter}
                risk_filter={risk_filter}
                subAsset_filter={subAsset_filter}
                rating_filter={rating_filter}
                reset_filter={reset_filter}
              />
            </Grid>
            <Grid item md={8}>
              <List_funds
                allFunds={allFunds}
                get_allFunds={get_allFunds}
                noMore={hasMore}
                search_filter={search_filter}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
}

export default AllFunds;
