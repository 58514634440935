import React, { useEffect, useState } from "react";
import { useStyles } from "../styles";
import { Box } from "@material-ui/core";
import TitleLayoult from "../TitleLayoult";
import httpInstance from "../../../utils/instance";
import { GET_USER, SETUP_MPIN } from "../../../utils/endpoints";
import ErrorMessage from "../../Validation/ErrorMessage";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import OtpInput from "react-otp-input";
import { namespace } from "../../../environment";

function GenerateMpin({}) {
  const classes = useStyles();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const resetMpin = searchParams.get("reset");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [mpin, setMpin] = useState("");
  const [mpinError, setMpinError] = useState("");

  const handleChange = (code) => setMpin(code);

  useEffect(async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await httpInstance(`${GET_USER}`, httpConfig);
      setPhoneNumber(response.data?.data?.mobileNumber);
    } catch {}
  }, []);

  //-------------------------------------SUBMIT OTP----------------------------------------------------------------------//
  const handleMpinSubmit = async (e) => {
    e.preventDefault();
    if (mpin.length < 4) {
      setMpinError("Please enter 4 digit Mpin");
    } else {
      setMpinError(false);
      const formData = {
        mobileNumber: phoneNumber,
        mpin: mpin,
      };

      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      try {
        const response = await httpInstance(`${SETUP_MPIN}`, httpConfig);
        if (resetMpin) {
          navigate(`${namespace}/dashboard/mutualfund/diy`);
        } else {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "4" })}`,
          });
        }
      } catch (error) {
        setMpinError(error.response.data?.message);
      }
    }
  };

  return (
    <div>
      <Box display={"flex"} justifyContent="center">
        <Box
          display={"flex"}
          alignItems="flex-start"
          flexDirection={"column"}
          p={3}
        >
          <Box>
            <TitleLayoult title="Please Create your MPIN" />
          </Box>
          <form onSubmit={handleMpinSubmit}>
            <OtpInput
              value={mpin}
              onChange={handleChange}
              numInputs={4}
              separator={<span style={{ width: "25px" }}></span>}
              isInputNum={true}
              shouldAutoFocus={true}
              inputStyle={{
                border: "none",
                borderRadius: "8px",
                width: "40px",
                height: "35px",
                fontSize: "13px",
                color: "#000",
                fontWeight: "400",
                caretColor: "blue",
                background: "#E6E6E6",
              }}
              focusStyle={{
                border: "1px solid #CFD3DB",
                outline: "none",
              }}
            />

            {mpinError && <ErrorMessage message={mpinError} />}
            <Box mt={5} display="flex">
              <button className={classes.primaryButton} type="submit">
                Continue
              </button>
            </Box>
          </form>
        </Box>
      </Box>
    </div>
  );
}

export default GenerateMpin;
