import React from 'react'
import EditAddress from '../../containers/MyProfile/EditAddress'

const Edit_Address = () => {
  return (
    <div>
        <EditAddress/>
    </div>
  )
}

export default Edit_Address