import React from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

const Filter_OrderType = ({ handleStatus, status }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      border: "1px solid #DDDDDD",
      borderRadius: "5px",
    },
    box1: {
      borderBottom: "1px solid #DDDDDD",
    },
    checkBox: {
      fontSize: "0.85rem",
      "& .MuiTypography-body1": {
        fontSize: "0.85rem",
      },
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: "#0C97F0",
      },
    },
  }));
  const classes = useStyles();

  const handle_checkbox = (e) => {
    if (e.target.checked) {
      handleStatus(e.target.value);
    } else {
      handleStatus("");
    }
  };

  return (
    <div>
      <Box className={classes.root} p={2}>
        <Box className={classes.box1}>
          <Typography>Filters</Typography>
        </Box>
        <Box className={classes.checkBox}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value="Pending"
                onChange={handle_checkbox}
                checked={status === "Pending"}
              />
            }
            label="Orders in progress"
          />

          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value="Successful"
                onChange={handle_checkbox}
                checked={status === "Successful"}
              />
            }
            label="Successful Orders"
          />

          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value="Failed"
                onChange={handle_checkbox}
                checked={status === "Failed"}
              />
            }
            label="Unsuccessful Orders"
          />
        </Box>
      </Box>
    </div>
  );
};

export default Filter_OrderType;
