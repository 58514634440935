import React from "react";
import {
  Box,
  makeStyles,
  Container,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { namespace } from "../../../environment";

const MandateAlert = ({ dialog, closeDialog }) => {
  const useStyles = makeStyles({
    closeIcon: {
      padding: "5px",
      justifyContent: "flex-end",
    },

    title: {
      fontSize: "1.1rem",
      fontWeight: 500,
      textAlign: "center",
      fontFamily: "inherit",
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "center",
      alignItems: " center",
      textAlign: "center",
      padding: "5px",
    },
    dialogPaper: {
      // maxWidth: "25.5rem",
      justifyContent: "center",
    },
    image: {
      textAlign: "center",
    },
    text: {
      fontSize: "0.8rem",
      textAlign: "center",
      fontFamily: "inherit",
    },
    primaryButton: {
      padding: "0.8rem 3rem",
      background: "#326CF9",
      color: "#fff",
      fontSize: "0.9rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
    },
    dialogContent: {
      padding: "8px 30px 30px",
    },
  });

  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={dialog}
        onClose={closeDialog}
        classes={{ paper: classes.dialogPaper }}
      >
        <Box display="flex" className={classes.closeIcon}>
          {" "}
          <CloseIcon onClick={closeDialog} style={{ cursor: "pointer" }} />
        </Box>

        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Typography className={classes.title}>No Mandate Found</Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Box className={classes.image}>
            <img
              src="/images/undraw_cancel_u-1-it 1.svg"
              width="150px"
              height="150px"
            />
          </Box>
          <Typography className={classes.text}>
            It seems like no mandate is created for the selected primary bank.
          </Typography>
          <Typography className={classes.text}>
            You ned to create mandate before creating an SIP Order
          </Typography>

          <Box my={3} display="flex" justifyContent="center">
            <Link to={`${namespace}/my-profile/bank-and-autoMandate`}>
              <button
                className={classes.primaryButton}
                type="button"
                //onClick={handleRetakeKyc}
              >
                Create Mandate
              </button>
            </Link>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MandateAlert;
