import React from 'react'
import { Box,Typography } from '@material-ui/core'
import FileUpload from '../../../../components/signup/uploader/FileUpload'

const UploadPan = ({submit_kyc}) => {
    const submit_pan=(file)=>{
        submit_kyc(file)
    }
  return (
    <div>
        <Typography style={{textAlign:"center", fontSize:"1.1rem", fontWeight:500}}>Upload Pan</Typography>
        <Box p={4}>
        <FileUpload  
        handleSubmit={submit_pan} 
        validation={/\.(png|jpg)$/} 
        type=".png/.jpg"
        />
      </Box>
    </div>
  )
}

export default UploadPan