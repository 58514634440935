import React, { useEffect, useState } from "react";
import { Box, makeStyles, Typography, Grid } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { GET_MY_INVESTMENT } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import moment from "moment";
import { Link } from "react-router-dom";

const SipList = () => {
  const useStyles = makeStyles({
    root: {
      border: "1px solid #DDDDDD",
      borderRadius: "5px",
    },

    greyText: {
      color: "#C0C0C0",
    },

    title: {
      fontSize: "1.1rem",
      fontWeight: 500,
    },
    buttonBox: {
      background: "#EAEAEA",
      borderRadius: "8px",
      padding: "2px 5px",
    },
    primaryButton: {
      padding: "0.7rem 3rem",
      background: "#0C97F0",
      color: "#fff",
      fontSize: "0.9rem",
      fontFamily: "Poppins",
      fontWeight: 600,
      borderRadius: "5px",
      border: "none",
      outline: "none",
    },
    button: {
      background: "#EAEAEA",
      padding: "0.7rem 3rem",
      fontSize: "0.9rem",
      fontFamily: "Poppins",
      borderRadius: "5px",
      border: "none",
      cursor: "pointer",
      outline: "none",
    },
    rupees: {
      fontFamily: "Open Sans",
    },
    type: {
      fontSize: "0.7rem",
      borderRadius: "30px",
      //background:"#FFD7C4",
      //color:"#F27E45",
      width: "fit-content",
      padding: "3px 20px",
      margin: "0px 15px",
      height: "fit-content",
    },

    "@media (max-width:600px)": {
      primaryButton: {
        padding: "0.7rem 1rem",
      },
      button: {
        padding: "0.7rem 1rem",
      },
      type: {
        margin: "10px 0px",
      },
    },
  });
  const classes = useStyles();

  const [investements, setInvestments] = useState([]);
  const [inactiveInvest, setInactiveInvest] = useState([]);
  const [value, setValue] = useState("active");

  const orderTypeObj = {
    2: "SIP",
    5: "STP",
    6: "SWP",
  };
  const orderTypeColor = {
    2: "#F27E45",
    5: "#4579F5",
    6: "#CB5D38",
  };

  const get_investment = async () => {
    const userId = localStorage.getItem("user_id");
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { userId: userId, isActive: true },
    };

    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { userId: userId, isActive: false },
    };

    try {
      const [response1, response2] = await Promise.all([
        httpInstance(`${GET_MY_INVESTMENT}`, httpConfig),
        httpInstance(`${GET_MY_INVESTMENT}`, httpConfig1),
      ]);
      setInvestments(response1.data?.data);
      setInactiveInvest(response2.data?.data);

      // const inactive= await httpInstance(`${GET_INACTIVE_SIP}`,httpConfig)
      // setInactiveSip(inactive.data?.data)
    } catch {}
  };

  useEffect(() => {
    get_investment();
  }, []);

  return (
    <div>
      {(investements?.length > 0 || inactiveInvest?.length > 0) && (
        <>
          <Box mt={5}>
            <Typography className={classes.title}>
              My Systematic Investments
            </Typography>
          </Box>

          <Box display="flex" justifyContent="center" my={3}>
            <Box className={classes.buttonBox}>
              <button
                className={
                  value === "active" ? classes.primaryButton : classes.button
                }
                onClick={() => setValue("active")}
              >
                Active
              </button>
              <button
                className={
                  value === "inactive" ? classes.primaryButton : classes.button
                }
                onClick={() => setValue("inactive")}
              >
                Inactive
              </button>
            </Box>
          </Box>
        </>
      )}

      {(value == "active" ? investements : inactiveInvest)?.map((ele, i) => {
        return (
          <>
            <Box key={i} className={classes.root} mt={3} p={2}>
              <Grid container display="flex" alignItems="center">
                <Grid item xs={3} md={1}>
                  <img src={ele.imgUrl} height="40px" width="50px" />
                </Grid>
                <Grid item xs={9} md={6}>
                  <Box display={{ sm: "flex" }} alignItems="center">
                    <Typography>{ele.plan} </Typography>
                    <Box
                      className={classes.type}
                      style={{
                        color: orderTypeColor[ele?.orderType],
                        background: `${orderTypeColor[ele?.orderType]}80`,
                      }}
                    >
                      {orderTypeObj[ele?.orderType]}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Box display="flex" justifyContent="start" pl={2}>
                    {value === "active" && (
                      <Typography className={classes.greyText}>
                        {`Next ${orderTypeObj[ele.orderType]} on`}{" "}
                        {moment(ele.nextInstallmentDate).format("MMM DD YYYY")}
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Box display="flex" justifyContent="space-around">
                    <Typography>
                      <span className={classes.rupees}>₹</span>{" "}
                      {ele.amount.toLocaleString("en-IN")}
                    </Typography>
                    <Link
                      to={`/myportfolio/my-systematic-investment/${ele.orderId}`}
                      state={{ fundData: ele, status: value }}
                      style={{ color: "inherit" }}
                    >
                      <ChevronRightIcon style={{ cursor: "pointer" }} />
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        );
      })}
    </div>
  );
};

export default SipList;
