import React, { useState } from "react";
import {
  Box,
  makeStyles,
  Grid,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import TimestampPopup from "./timestampPopup";
const FundCard = ({
  fundData,
  handle_add_fund,
  handle_remove_fund,
  timestamps,
}) => {
  const useStyles = makeStyles({
    root: {
      background: "#ffff",
      borderRadius: "10px",
    },

    primaryButton: {
      background: "#326CF9",
      color: "#fff",
      padding: "10px 2rem",
      border: "none",
      borderRadius: "20px",
      fontFamily: "inherit",
      //margin: "0px 15px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },

    subText: {
      fontSize: "0.85rem",
    },
  });
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [showTimestamp, setShowTimestamp] = useState(false);

  return (
    <div>
      <Box mt={2}>
        <Box p={2} className={classes.root}>
          <Grid container alignItems="center">
            <Grid item xs={9}>
              <Box display="flex">
                <img
                  src={fundData?.img_url}
                  height="35px"
                  width="35px"
                  style={{ marginRight: "15px" }}
                />
                <Box>
                  <Typography>{fundData?.scheme_name} </Typography>
                </Box>
              </Box>
              <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={7}>
                  <Typography
                    style={{ marginLeft: "8px" }}
                    className={classes.subText}
                  >
                    {fundData?.asset_sub_category}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.subText}>
                    {fundData?.risk_profile}
                  </Typography>
                </Grid>
              </Grid>
              {/* <Box display="flex" >
                                <Typography style={{marginRight:"6rem"}} className={classes.subText}>{fundData?.asset_sub_category}</Typography>
                                <Typography className={classes.subText}>{fundData?.risk_profile}</Typography>

                            </Box> */}
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" justifyContent="end">
                {fundData?.assisted ? (
                  <button
                    className={classes.primaryButton}
                    onClick={() =>
                      handle_remove_fund(fundData, timestamps?.[0]?.id)
                    }
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size="1rem" />
                    ) : (
                      "Remove"
                    )}{" "}
                  </button>
                ) : (
                  <button
                    className={classes.primaryButton}
                    // onClick={() => handle_add_fund(fundData)}
                    onClick={() => setShowTimestamp(true)}
                  >
                    Add
                  </button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <TimestampPopup
        show={showTimestamp}
        handleClose={() => setShowTimestamp(false)}
        fundData={fundData}
        timestamps={timestamps}
        // get_all_timestamps={get_data}
        // timestamps={timestamps}
      />
    </div>
  );
};

export default FundCard;
