import React from "react";
import { Box, makeStyles } from "@material-ui/core";


function SliderBar({ step, bar }) {
  const useStyles = makeStyles((theme) => ({
    boxHolder: {
      display: "none",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      padding: "1rem"
    },
    box: {
      background: "#c7c7c7",
      padding: "0.3rem 2.6rem",
      borderRadius: "4px",
      marginRight:"10px"
    },
    boxTransparent: {
      background: "transparent",
      padding: "0.3rem 2.6rem",
      borderRadius: "4px",
     
    },
    boxActive: {
      background: "#0C97F0",
      padding: "0.3rem 2.6rem",
      borderRadius: "4px",
      marginRight:"10px"
    },
    "@media (max-width: 768px)": {
      boxHolder: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        padding: "1rem"
      },
      box:{
        marginRight:"5px",
        marginLeft:"5px"
      },
      boxActive:{
        marginRight:"5px",
        marginLeft:"5px"
      }
    },
    "@media (max-width: 280px)": {
      box:{
        marginRight:"5px",
        marginLeft:"5px"
      },
      boxActive:{
        marginRight:"5px",
        marginLeft:"5px"
      }
    }
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      {bar == 4 ? (
        <Box className={classes.boxHolder}>
          <React.Fragment>
            {step === 1 ? (
              <React.Fragment>
                <Box className={classes.boxActive}></Box>
                <Box className={classes.box}></Box>
                <Box className={classes.box}></Box>
                <Box className={classes.box}></Box>
              </React.Fragment>
            ) : (
              ""
            )}
            {step === 2 ? (
              <React.Fragment>
                <Box className={classes.box}></Box>
                <Box className={classes.boxActive}></Box>
                <Box className={classes.box}></Box>
                <Box className={classes.box}></Box>
              </React.Fragment>
            ) : (
              ""
            )}
            {step === 3 ? (
              <React.Fragment>
                <Box className={classes.box}></Box>
                <Box className={classes.box}></Box>
                <Box className={classes.boxActive}></Box>
                <Box className={classes.box}></Box>
              </React.Fragment>
            ) : (
              ""
            )}
            {step === 4 ? (
              <React.Fragment>
                <Box className={classes.box}></Box>
                <Box className={classes.box}></Box>
                <Box className={classes.box}></Box>
                <Box className={classes.boxActive}></Box>
              </React.Fragment>
            ) : (
              ""
            )}
          </React.Fragment>
        </Box>
      ) : (
        ""
      )}
      {bar == 3 ? (
        <Box className={classes.boxHolder}>
          <React.Fragment>
            {step === 1 ? (
              <React.Fragment>
                <Box className={classes.boxActive}></Box>
                <Box className={classes.box}></Box>
                <Box className={classes.box}></Box>
              </React.Fragment>
            ) : (
              ""
            )}
            {step === 2 ? (
              <React.Fragment>
                <Box className={classes.box}></Box>
                <Box className={classes.boxActive}></Box>
                <Box className={classes.box}></Box>
              </React.Fragment>
            ) : (
              ""
            )}
            {step === 3 ? (
              <React.Fragment>
                <Box className={classes.box}></Box>
                <Box className={classes.box}></Box>
                <Box className={classes.boxActive}></Box>
              </React.Fragment>
            ) : (
              ""
            )}
          </React.Fragment>
        </Box>
      ) : (
        ""
      )}
      {bar == 2 ? (
        <Box className={classes.boxHolder}>
          <React.Fragment>
            {step === 1 ? (
              <React.Fragment>
                <Box className={classes.boxTransparent}></Box>
                <Box className={classes.boxActive}></Box>
                <Box className={classes.box}></Box>

                <Box className={classes.boxTransparent}></Box>
              </React.Fragment>
            ) : (
              ""
            )}
            {step === 2 ? (
              <React.Fragment>
                <Box className={classes.boxTransparent}></Box>
                <Box className={classes.box}></Box>
                <Box className={classes.boxActive}></Box>
                <Box className={classes.boxTransparent}></Box>
              </React.Fragment>
            ) : (
              ""
            )}
            {step === 3 ? (
              <React.Fragment>
                <Box className={classes.box}></Box>
                <Box className={classes.box}></Box>
                <Box className={classes.boxActive}></Box>
              </React.Fragment>
            ) : (
              ""
            )}
          </React.Fragment>
        </Box>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default SliderBar;
