import React from 'react'
import BuySellSuccess from '../../../components/DashBoard/DigitalGold/BuySellSuccess'

const SellSuccess = () => {
  return (
    <BuySellSuccess line1="You have successfully sold your Digital Gold!"
    line2="The balance will be transfred to your account in 3-4 working days."/>
  )
}

export default SellSuccess