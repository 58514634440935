import React, { useState } from "react";
import { DigitalData, MutualData } from "../../utils/content";
import Accordian from "../../components/Accordian";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from "@material-ui/core";
import Mf_Faq from "./Mf_Faq";

const Faq = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundImage: "url('./images/Strings.svg')",
      padding: "0rem 0rem 5rem 0rem",
      backgroundPosition: "left",
    },
    header: {
      fontSize: "1.8rem",
      fontWeight: 600,
      color: "#272727",
    },
    FAQHolder: {
      width: "50%",
    },

    rootAcc: {
      width: "100%",
      border: "none",
      boxShadow: "none",
      borderBottom: "0.5px solid #272727",
      padding: "0.6rem 0rem",
      "&:last-child": {
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: "bold",
      fontFamily: "Poppins",
      color: "#474747",
    },
    content: {
      fontSize: theme.typography.pxToRem(14),
    },
    primaryButton: {
      padding: "0.7rem 3rem",
      background: "#0C97F0",
      color: "#fff",
      fontSize: "0.9rem",
      fontFamily: "Poppins",
      fontWeight: 600,
      borderRadius: "5px",
      border: "none",
      outline: "none",
    },
    button: {
      background: "#EAEAEA",
      padding: "0.7rem 3rem",
      fontSize: "0.9rem",
      fontFamily: "Poppins",
      borderRadius: "5px",
      border: "none",
      cursor: "pointer",
      outline: "none",
    },
    buttonBox: {
      background: "#EAEAEA",
      borderRadius: "8px",
      padding: "2px 5px",
    },
    "@media (max-width: 900px)": {
      FAQHolder: {
        width: "100%",
      },
      button: {
        padding: "0.7rem 2rem",
      },
      primaryButton: {
        padding: "0.7rem 2rem",
      },
    },
  }));
  const classes = useStyles();

  const [button, setButton] = useState("mf");

  const handle_button = (type) => {
    setButton(type);
  };

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        py={{ xs: 5, md: 8 }}
        px={2}
      >
        <Box className={classes.header}>Frequently Asked Questions</Box>

        <Box className={classes.buttonBox} mt={3}>
          <button
            className={button === "mf" ? classes.primaryButton : classes.button}
            onClick={() => handle_button("mf")}
          >
            Mutual Fund
          </button>
          <button
            className={
              button === "gold" ? classes.primaryButton : classes.button
            }
            onClick={() => handle_button("gold")}
          >
            Digital Gold
          </button>
        </Box>
      </Box>
      <Grid container>
        <Box className={classes.FAQHolder} mx="auto">
          {button == "mf" ? (
            <Mf_Faq />
          ) : (
            <>
              <Accordian
                title={"What is the DigiGold product sold by Augmont?"}
                content="DigiGold facilitates the purchase of physical bullion (i.e. bars of Gold/Silver) for as
                low as Re. 1 with the ease of online access. The customer can request for the
                delivery of Gold/Silver purchased from Augmont anytime they want in the form of
                coins/ bars and jewellery and it will be delivered at your doorstep. Customers can
                also sell the bullion (bought from us) in a secured and convenient manner back to
                Augmont."
              />

              <Accordian
                title="What is the purity of the gold/silver that Augmont offers to customers in
the DigiGold product?"
                content="Augmont, through its online platform, offers 24 carat 999 Gold and 24 carat 999
Silver."
              />

              <Accordion className={classes.rootAcc}>
                <AccordionSummary
                  expandIcon={
                    <img src="/images/Vector 5.svg" height="20" width="20" />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Why buy DigiGold from Augmont Gold for All?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.content}>
                    Opening an Account
                    <ul>
                      <li>
                        Ease of Account Opening - Opening an Account with us is
                        a quite simple, hassle- free process.
                      </li>
                      <li>
                        Minimum Verification - Only mobile number verification
                        is required for successful registration.
                      </li>
                      <li>
                        No Additional Costs - No brokerage is charged. No
                        charges for storage and insurance.
                      </li>
                      <li>
                        Small Quantity - Augmont enables the purchase of Gold
                        and Silver for as low as Rs. 1
                      </li>
                      <li>
                        Better Prices - The bullion is available at wholesale
                        market prices.
                      </li>
                      <li>
                        Buy Back - You can sell the bullion bought from us at
                        very low spreads.
                      </li>
                      <li>
                        Delivery - The customer can avail delivery of bullion at
                        their doorstep.
                      </li>
                      <li>
                        Dedicated Customer Service - Contact the Customer
                        Service Desk by Email, Whatsapp and Call during business
                        hours of 10:00 am to 7:00 pm from Monday to Saturday
                        except on public holidays.
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordian
                title="How does ‘Augmont’ ensure a Fair and competitive price?"
                content="The Buy &amp; Sell prices are quoted on the basis of wholesale prices in the spot
market. We share the benefit of wholesale market prices with our customers,
making us more competitive as compared to others."
              />

              <Accordian
                title="How to get started?"
                content="On Augmont Gold for All, any resident Indian with a valid
PAN/Form 60 and a bank account can start purchasing DigiGold. You must simply
register by entering your mobile number and verifying it with the OTP supplied to
you."
              />

              <Accordian
                title="What is the quantity of Gold/Silver that I can buy and sell back to
Augmont?"
                content="You can buy any quantity of Gold/Silver on Augmont. The minimum amount of
purchase allowed is Re.1 However, you are allowed to sell only the Gold/Silver that
you have bought from us. You are not allowed to sell the gold or silver until 48
hours from the purchase time for security reasons."
              />

              <Accordian
                title="How does ‘Augmont’ ensure a Fair and competitive price"
                content="The Buy and Sell prices are calculated using wholesale spot market pricing. We
pass on the benefits from wholesale market prices to our consumers, making us
more competitive than the competition."
              />

              <Accordian
                title="Is GST included in the quoted prices? "
                content="All our prices quoted on the website
are exclusive of GST and other applicable taxes. The applicable GST is added to
the quoted price at the final stage of checkout."
              />

              <Accordian
                title="Why is there a difference in the Buy and Sell price on Augmont?"
                content="The price for Gold/Silver (i.e. Buy/Sell) is displayed on the Augmont website, and
there is always a little disparity between the buy and sell prices. The spread varies
depending on a variety of factors such as price volatility, supply, and external
market circumstances. On the platform, we transperately quote live prices.
Furthermore, GST, which is imposed on the buy price, is not levied on the sell
price, and there are other charges for payment gateways, trustees, and other
services that contribute to the difference between the buy and sell prices."
              />

              <Accordian
                title="What happens to the proceeds when I sell my gold/silver?"
                content="You receive the sale proceeds in your confirmed bank account when you sell your
gold or silver. Within three working days, the funds will be credited to your bank
account. You can contact our customer care if there are any delays."
              />

              <Accordian
                title="Are there any charges that I will have to bear while selling back to
Augmont?"
                content="You can sell back at the market prices which are displayed on the website on a
real- time basis. There are no additional charges."
              />

              <Accordian
                title="What is the Minimum Order Quantity (MOQ) to buy bullion?"
                content="You can buy gold and silver for as low as Re. 1 up to four decimal grams"
              />

              <Accordian
                title="Where can I find the invoice for each order?"
                content="You can download the invoice from the My Orders section in the buy reports."
              />

              <Accordian
                title="How can orders be monitored?"
                content="Customers can monitor their activities on the website by viewing all transactional
details in the ‘My Orders&#39; section. To keep track of all the transactions, there are
separate reports such as Buy Reports, Sell Reports, and My Orders, among others."
              />

              <Accordian
                title="Can orders be cancelled?"
                content="Once orders have been successfully placed, they cannot be cancelled."
              />

              <Accordian
                title="Do I need KYC to be done?"
                content="As per the government regulations, it is a statutory requirement and a part of
regulatory instructions and compliance that KYC needs to be done for buying any
product on the Augmont platform. We want to ensure that we have the correct KYC
of the customers to enable us to identify the owners of the gold/silver lying in the
vaults with accuracy. It is advised that proper KYC is updated to prevent any
misuse of your gold account. In any case, you will be prompted to provide your PAN
details once your buying reaches a certain threshold limit."
              />

              <Accordian
                title="Where is the physical Gold/Silver stored?"
                content="The physical gold/silver is stored safely in the vault of Sequel, the service which is
utilized by various banks in India and also by various Asset Management
Companies (AMCs) for their Gold-ETFs. The physical Gold/Silver stored in the vault
is also covered by insurance."
              />

              <Accordion className={classes.rootAcc}>
                <AccordionSummary
                  expandIcon={
                    <img src="/images/Vector 5.svg" height="20" width="20" />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Why buy DigiGold from Augmont Gold for All?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.content}>
                    Sequel Logistics (
                    <Link href="https://www.sequelglobal.com/" color="primary">
                      https://www.sequelglobal.com/
                    </Link>
                    ) is a global leader in security- related services for
                    banks, financial institutions, mines, retailers, diamond and
                    jewelry industries, pharmaceuticals and a variety of other
                    commercial and governmental customers.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion className={classes.rootAcc}>
                <AccordionSummary
                  expandIcon={
                    <img src="/images/Vector 5.svg" height="20" width="20" />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    What is the role of ‘Independent Trustee’?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.content}>
                    IDBI Trusteeship Services Limited has been appointed as an
                    Independent Trustee, and IDBI has the authority to act on
                    behalf of clients and to protect their interests. On the
                    bullion (i.e. Gold/Silver) you purchase, IDBI will have
                    first and exclusive charge. Customers enable Augmont to send
                    physical bullion to a secured vault on their behalf, which
                    is verified by an Independent Trustee, after accepting the
                    Terms and Conditions.(
                    <Link
                      href="https://www.augmont.com/assets/media/trustee-certificate.pdf"
                      color="primary"
                    >
                      https://www.augmont.com/assets/media/trustee-certificate.pdf.
                    </Link>
                    )
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordian
                title="Who is insuring the physical gold/silver?"
                content="Under a comprehensive insurance contract, a top insurance firm insures the
physical gold and silver housed in vaults. Our insurance also covers the gold,
silver, and jewellery that is in transit at the time of delivery."
              />

              <Accordian
                title="How can I request delivery on Augmont?"
                content="You can request delivery of the Gold and Silver bought on Augmont in the form of
coins, bars and jewellery and they shall be delivered at your doorstep. The
customer can request the delivery by selecting from a wide range of articles and by
paying a nominal making and delivery fee. You can request for the delivery of gold
and silver coins of as low as 0.1 gram gold and 1 gram silver."
              />

              <Accordian
                title="Which cities do Augmont deliver the Gold?"
                content="Augmont delivers to all the cities across India, through partnerships with secure
logistics providers."
              />

              <Accordian
                title="How can I be sure of the quality of the products received?"
                content="The customer can be assured of the quality of the Gold Coins and Jewellery
delivered by Augmont as each and every article delivered is hallmarked by a
government-approved hallmarking agency as per the BIS guidelines."
              />

              <Accordian
                title="Can I track the delivery of my purchase?"
                content="Once the delivery request has been placed by you and accepted by Augmont, you
will receive a link in Email/SMS which will help you keep a tab on the status of your
order. You can also check the status of your order in the My Orders tab on the
dashboard."
              />

              <Accordian
                title="How many days does it take for Doorstep Delivery/after the successful
placing of a shop order?"
                content="The customer will receive the delivery at the shipping address indicated in the order
within 10 working days of placing the order/requesting delivery."
              />

              <Accordion className={classes.rootAcc}>
                <AccordionSummary
                  expandIcon={
                    <img src="/images/Vector 5.svg" height="20" width="20" />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Can I change my registered mobile number?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.content}>
                    The customer can change the registered mobile number by
                    contacting the customer support team on
                    <Link href="mailto:support@augmont.com">
                      support@augmont.com 
                    </Link>
                     or calling them on 9090906867. The customer service shall
                    assist you with the same.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordian
                title="Who shall bear the risk of loss till the product is delivered to the
customer?"
                content="The risk of loss relating to the products shall be on Augmont till the time the
product is delivered to the customer at its shipping address."
              />

              <Accordion className={classes.rootAcc}>
                <AccordionSummary
                  expandIcon={
                    <img src="/images/Vector 5.svg" height="20" width="20" />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    My payment has failed, what should I do?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.content}>
                    Kindly do not panic. If your account was debited, it should
                    be automatically reversed in 72 hours or according to your
                    bank guidelines. We don&#39;t recommend using NEFT as this
                    method takes longer to process. If you still need help,
                    please contact our{" "}
                    <Link href="https://www.augmont.com/contact-us">
                      customer support team.
                    </Link>
                     
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordian
                title="How can I exit/terminate the ongoing ‘Augmont’ account?"
                content="By contacting the customer care desk, you can request that your account be
terminated. In response to your request, we will close your account. However,
before your account is terminated, you must verify that any gold or silver in your
account is sold back to Augmont at market price."
              />

              <Accordion className={classes.rootAcc}>
                <AccordionSummary
                  expandIcon={
                    <img src="/images/Vector 5.svg" height="20" width="20" />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    My payment has failed, what should I do?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.content}>
                    The partner platforms will provide the first level of
                    support. To reach out to us, you can write to 
                    <Link href="mailto:support@augmont.com">
                      support@augmont.com 
                    </Link>
                    . You can also call or WhatsApp us on +91 9090906867. 
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </Box>
      </Grid>
    </div>
  );
};

export default Faq;
