import React,{useState,useEffect} from 'react'
import { Box,makeStyles,Table,TableRow,TableHead,TableBody,TableCell,Divider, Grid } from '@material-ui/core'
import AllocationTable from './AllocationTable'
import LagPercent from './LagPercent'
import Subscription from './Subscription'
import Amcs from './Amcs'


const Index = () => {
    

  return (
    <div>
        <AllocationTable/>
        <LagPercent/>
        <Subscription/>
        <Amcs/>
    </div>
  )
}

export default Index