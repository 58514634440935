import React from 'react'
import PaymentSuccess from '../../../components/MutualFund/PaymentSuccess'
import { useLocation } from 'react-router-dom'

const Redeem_success = () => {
   const {state}=useLocation()
   const{value}=state
  return (
    <div>
        <PaymentSuccess message={`Your redeem request of ${value} is recieved`} message1={"You will recieve the amount in 4-5 working days"}/>
    </div>
  )
}

export default Redeem_success