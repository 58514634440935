import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useParams, useLocation } from "react-router-dom";
import { GET_FUND_DETAILS, GET_FUND_FP_DATA } from "../../utils/endpoints";
import httpInstance from "../../utils/instance";
import FundGraph from "./FundGraph";
import moment from "moment";
import ReturnCal from "./ReturnCal";
import Holdings from "./Holdings";
import HoldingAnalysis from "./HoldingAnalysis";
import AlternativeFunds from "./AlternativeFunds";
import Mf_Invest from "./Mf_Invest";
import Kyc_status from "../Dashboard/Funds/KycStatusCheck/Kyc_status";
import { namespace } from "../../environment";

const RatingImage = ({ rating }) => {
  if (rating > 5) {
    rating = 0;
  }

  return [...Array(rating)].map((img, i) => {
    return (
      <img
        key={i}
        style={{ marginRight: "4px" }}
        src="/images/starRating.svg"
        height="10px"
        width="10px"
      />
    );
  });
};

const FundDetail = () => {
  const useStyles = makeStyles((theme) => ({
    root: {},
    mainGrid: {
      padding: "30px 4rem 0px 8rem",
    },
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      textDecoration: "underline",
      fontFamily: "inherit",
    },
    text: {
      fontSize: "0.8rem",
      fontWeight: 700,
    },
    text1: {
      fontSize: "0.8rem",
    },

    textBold: {
      fontSize: "0.8rem",
      fontWeight: 700,
      marginBottom: "1rem",
    },
    rupees: {
      fontFamily: "Open Sans",
    },
    "@media (max-width: 767px)": {
      mainGrid: {
        padding: "0px 10px 0px 10px",
      },
    },
  }));
  const classes = useStyles();
  const location = useLocation();

  const [fundData, setFundData] = useState(null);
  const [fpData, setFpData] = useState({});
  const [showBanner, setShowBanner] = useState();
  const [loading, setLoading] = useState(false);

  const { code } = useParams();
  // const {state}=useLocation()
  // const {fundsData}=state

  useEffect(() => {
    localStorage.setItem("fund_id", code);
    if (location.state?.data) {
      setFundData(location.state?.data);
    }
  }, [code]);

  const get_fund_data = async () => {
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        scheme_code: code,
        plan_name: "",
        option_name: "growth",
        holding_period: "1M",
      },
    };

    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_FUND_DETAILS}`, httpConfig);
      setFundData(response.data?.data);
      const fpres = await httpInstance(`${GET_FUND_FP_DATA}`, {
        ...httpConfig1,
        params: { isin: response.data?.data?.isin },
      });
      setFpData(fpres.data?.data);
    } catch {}
  };

  const get_fund_fp_data = async () => {
    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { isin: location.state?.data?.isin },
    };

    try {
      const fpres = await httpInstance(`${GET_FUND_FP_DATA}`, httpConfig1);

      setFpData(fpres.data?.data);
    } catch {}
  };

  useEffect(() => {
    if (location.state) {
      get_fund_fp_data();
    }
  }, [code]);

  useEffect(() => {
    if (!location.state) {
      get_fund_data();
    }
  }, []);

  const show_banner = () => {
    setShowBanner(true);
  };

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        px={{ xs: 1, md: 17 }}
        pt={2}
        style={{ borderBottom: "1px solid #E6E3E3" }}
      >
        <Link
          to={`${namespace}/dashboard/mutualfund/diy`}
          style={{ color: "black" }}
        >
          <Typography className={classes.textUnderline}>
            Find Mutual Funds
          </Typography>
        </Link>
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography
            className={classes.subTabtext}
            display="flex"
            alignItems="start"
          >
            {fundData?.scheme_name}
          </Typography>
        </Box>
      </Box>
      {/* kyc banner */}
      {showBanner && <Kyc_status />}
      <Box
        display="flex"
        justifyContent="flex-start"
        px={{ xs: 2, md: 15 }}
        mt={5}
        className={classes.backButton}
      >
        <Link to={`${namespace}/dashboard/mutualfund/diy`}>
          <img src="/images/backIcon.svg" height="20px" width="20px" />
        </Link>
      </Box>

      <Grid container className={classes.mainGrid}>
        <Grid item xs={12} md={8}>
          <Grid container>
            <Grid item xs={2} md={1}>
              <img src={fundData?.img_url} height="50px" width="50px" />
            </Grid>
            <Grid item xs={9}>
              <Typography>{fundData?.scheme_name} </Typography>
              <Typography className={classes.text}>
                {fundData?.asset_sub_category}
              </Typography>
              <RatingImage rating={fundData?.vr_rating.length} />
            </Grid>
            <Grid item xs={6} md={2}>
              <Typography style={{ fontSize: "1rem", color: "#6FDDB8" }}>
                {fundData?.performance?.fiveY}%
              </Typography>
              <Typography className={classes.text}>Returns( 5yrs )</Typography>
            </Grid>
          </Grid>
          {/* grapg */}
          <FundGraph code={code} />
          <Grid container>
            <Grid item xs={3} className={classes.textBold}>
              Nav : {moment(fundData?.nav_date).format("DD MMM YYYY")}{" "}
            </Grid>
            <Grid item xs={3} className={classes.text1}>
              <span className={classes.rupees}>₹</span>
              {fundData?.nav}
            </Grid>
            <Grid item xs={3} className={classes.textBold}>
              Rating{" "}
            </Grid>
            <Grid item xs={3} className={classes.text1}>
              {fundData?.vr_rating == "Not Rated" ||
              fundData?.vr_rating == "" ? (
                "Not Rated"
              ) : (
                <RatingImage rating={fundData?.vr_rating.length} />
              )}
            </Grid>

            <Grid item xs={3} className={classes.textBold}>
              {" "}
              Min. SIP Amount{" "}
            </Grid>
            <Grid item xs={3} className={classes.text1}>
              <span className={classes.rupees}>₹</span>
              {fundData?.min_sip_amount}
            </Grid>
            <Grid item xs={3} className={classes.textBold}>
              Fund size{" "}
            </Grid>
            <Grid item xs={3} className={classes.text1}>
              <span className={classes.rupees}>₹</span>
              {fundData?.fund_size.toFixed(2)}Cr.
            </Grid>
            <Grid item xs={3} className={classes.textBold}>
              {" "}
              Min. Initial SIP Amount{" "}
            </Grid>
            <Grid item xs={3} className={classes.text1}>
              <span className={classes.rupees}>₹</span>
              {fundData?.min_initial_investment}
            </Grid>
          </Grid>

          {/* Return caluculator */}
          <ReturnCal returns={fundData?.performance} />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
          className="padding-2rem"
        >
          {!loading && (
            <Mf_Invest
              fundData={fundData}
              fpData={fpData}
              show_banner={show_banner}
            />
          )}
        </Grid>
      </Grid>
      {/* Holdings */}

      <Holdings schemeCode={code} />

      {/* Holding Analysis */}
      <HoldingAnalysis
        exitLoad={fundData?.exit_load_period_remark}
        fundSize={fundData?.fund_size}
        schemeCode={code}
      />

      {/* Alternative funds */}

      <AlternativeFunds asset_catogory={fundData?.asset_sub_category} />
    </div>
  );
};

export default FundDetail;
