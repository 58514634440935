import React, { useState,useEffect } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CustomSelect = ({ handleSelect, type, placeholder, options,isToggle }) => {
  const useStyles = makeStyles({
    root: {
      background: "#fff",
      borderRadius: "20px",
    },
    header: {
      color: "#686868",
      fontSize: "1rem",
      fontWeight: 600,
      padding: "0px 10px",
    },
    title: {
      color: "#0C97F0",
    },
    select: {
      fontSize: "0.85rem",
      color: "black",
      padding: "3px 0px",
      borderRadius: "10px",

      minWidth: "20rem",
      "& .MuiSelect-select": {
        paddingTop: "10px ",
        paddingRight: "40px",
        paddingLeft: "10px",
        border: "1px solid #DDDDDD",
        borderRadius: "5px",
        "&:focus": {
          backgroundColor: "#fff",
        },
      },
      "& .MuiSvgIcon-root": {
        color: "black",
      },
      "& .MuiMenuItem-root": {
        fontSize: "0.85rem !important",
      },
    },
    menu: {
      boxShadow: "none",
      border: "1px solid #DDDDDD",
    },
    menuItem: {
      fontSize: "0.85rem !important",
      display: "flex",
      justifyContent: "space-between",
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: "#326CF9",
      },
      "& .MuiIconButton-root":{
        padding:"0px"
      }
    },
    
  });
  const classes = useStyles();

  const [selected, setSelected] = useState([]);
  const [disValue,setDisValue]=useState()
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleChange = (event) => {
    const value = event.target.value;  
    if(isToggle){
        setSelected(value)
    }else{
        if (value[value.length - 1] === "all") {
        setSelected(selected.length === options.length ? [] : options.map(ele=>ele.value));
        return;
        }
    setSelected(value)
}
  };

  useEffect(()=>{
    handleSelect(selected)
  },[selected])
  
  return (
    <div>
      <Box>
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <Select
            value={selected}
            multiple={isToggle ? false :true}
            onChange={handleChange}
            className={classes.select}
            disableUnderline
            IconComponent={KeyboardArrowDownIcon}
            displayEmpty
            renderValue={(selected) => selected?.length < 0 ?  selected?.join(", "):placeholder }
            //renderValue={selected?.length > 0 ?(selected) selected.join(", ") : () => placeholder}
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={{
              style: {
                maxHeight: 350,
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              classes: { paper: classes.menu },
            }}
          >
            {!isToggle && 
                <MenuItem
                  
                    value="all"
                    className={classes.menuItem}
                     >
                    All
                    <Checkbox
                    className={classes.checkbox}
                        color="primary"
                        checked={isAllSelected}                     
                    />               
                    </MenuItem>  }
            {options?.map((ele,i)=>{
                return(
                    <MenuItem
                    key={i}
                    value={ele.value}
                    className={classes.menuItem}
                     >
                    { ele.name}
                    <Checkbox
                    className={classes.checkbox}
                        color="primary"
                        checked={selected.indexOf(ele.value) > -1}                     
                    />               
                    </MenuItem>  
                )
              })}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default CustomSelect;
