import React, { useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ADD_TIMESTAMPS, UPDATE_TIMESTAMPS } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import ErrorMessage from "../../../components/Validation/ErrorMessage";

const AddTimestamp = ({
  show,
  handleClose,
  get_all_timestamps,
  timestamps,
  updateId,
  status,
}) => {
  if (status == 0) {
    timestamps = [];
  }
  const timestamp = timestamps?.filter((t) => {
    return t.id === updateId;
  });
  console.log(timestamp);
  const useStyles = makeStyles({
    root: {
      "& .MuiDialogContent-root": {
        fontSize: "0.8rem",
        lineHeight: "25px",
        padding: "20px 25px",
      },
      "& .MuiDialog-paperWidthSm": {
        minWidth: "45%",
        //maxWidth:"300px",
        maxHeight: "100%",
      },
    },
    dialogTitle: {
      //display: "flex",
      justifyContent: "center",
      alignItems: " center",
      //textAlign: "center",
      padding: "0px 10px 0px 25px",
      marginTop: "-10px",
      "& .MuiTypography-body1": {
        fontSize: "1.2rem",
        fontWeight: 500,
      },
    },
    closeIcon: {
      padding: "5px",
      justifyContent: "flex-end",
    },
    text: {
      fontSize: "0.8rem",
    },
    textCancel: {
      fontSize: "0.8rem",
      cursor: "pointer",
    },
    select: {
      fontSize: "0.85rem",
      color: "black",
      padding: "3px 0px",
      borderRadius: "10px",

      minWidth: "20rem",
      "& .MuiSelect-select": {
        paddingTop: "10px ",
        paddingBottom: "10px",
        paddingRight: "40px",
        paddingLeft: "10px",
        border: "1px solid #DDDDDD",
        borderRadius: "5px",
        "&:focus": {
          backgroundColor: "#fff",
        },
      },
      "& .MuiSvgIcon-root": {
        color: "black",
      },
      "& .MuiMenuItem-root": {
        fontSize: "0.85rem !important",
      },
    },
    menu: {
      boxShadow: "none",
      border: "1px solid #DDDDDD",
    },
    menuItem: {
      fontSize: "0.85rem !important",
    },
    inputBox: {
      padding: "9px",
      border: "1px solid #DDDDDD",
      borderRadius: "5px",
      fontSize: "0.85rem",
      fontFamily: "inherit",
      marginTop: "10px",
      "&::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "black",
        opacity: 1 /* Firefox */,
      },

      "&:-ms-input-placeholder": {
        /* Internet Explorer 10-11 */ color: "black",
      },

      "&::-ms-input-placeholder ": {
        /* Microsoft Edge */ color: "black",
      },
    },
    primaryButton: {
      background: "#326CF9",
      color: "#fff",
      padding: "10px 2rem",
      border: "none",
      borderRadius: "20px",
      fontFamily: "inherit",
    },
  });

  const classes = useStyles();

  const [isDeleted, setIsDeleted] = useState("");
  const [loading, setLoading] = useState(false);
  const [version, setVersion] = useState("");
  const [error, setError] = useState(false);

  const handle_close = () => {
    setIsDeleted("");
    setVersion("");
    handleClose();
  };

  const handleChange = (e) => {
    console.log(e.target.value);
    setIsDeleted(e.target.value);
    if (status == 1) {
      setVersion(timestamp?.[0]?.versionName);
    }
  };

  //----------------handle email--------------------------------//
  const handle_version = (e) => {
    setVersion(e.target.value);
    if (status == 1) {
      setIsDeleted(timestamp?.[0]?.isActive);
    }
  };

  const add_timestamp = async () => {
    setLoading(true);
    setError(false);
    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        isDeleted: 0,
        versionName: `${version}`,
        isActive: isDeleted || 0,
      },
    };

    try {
      if (status == 1) {
        await httpInstance(
          `${UPDATE_TIMESTAMPS}/${timestamp?.[0]?.id}`,
          httpConfig
        );
      } else {
        await httpInstance(`${ADD_TIMESTAMPS}`, httpConfig);
      }

      get_all_timestamps();
      handle_close();
    } catch (error) {
      setLoading(false);
      setError(error.response?.data?.message);
    }
  };

  return (
    <div>
      <Dialog className={classes.root} open={show} onClose={handle_close}>
        <Box
          display="flex"
          className={classes.closeIcon}
          onClick={handle_close}
        >
          <CloseIcon />
        </Box>

        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Box display="flex" justifyContent="center">
            <Typography className={classes.title}>
              {status ? "Update" : "Add"} Timestamp
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            mt={5}
          >
            <Box display="flex" flexDirection="column">
              <input
                className={classes.inputBox}
                placeholder="Enter Version Name"
                onChange={handle_version}
                defaultValue={timestamp?.[0]?.versionName}
              />
              <br />
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <Select
                  //   value="1"
                  onChange={handleChange}
                  className={classes.select}
                  disableUnderline
                  IconComponent={KeyboardArrowDownIcon}
                  defaultValue={timestamp?.[0]?.isActive ? 1 : 0}
                  displayEmpty
                  //renderValue={(value) => (value !== '' ? value : 'Placeholder text')}
                  //   renderValue={
                  //     type.length> 0
                  //       ? type
                  //       : () => "Select User Type"
                  //   }
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={{
                    style: {
                      maxHeight: 350,
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.menu },
                  }}
                >
                  <MenuItem value="" className={classes.menuItem} disabled>
                    Select Status
                  </MenuItem>
                  <MenuItem value={1} className={classes.menuItem}>
                    Activate
                  </MenuItem>
                  <MenuItem value={0} className={classes.menuItem}>
                    Deactivate
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box mt={15} mb={5}>
              <button className={classes.primaryButton} onClick={add_timestamp}>
                Send request
              </button>
            </Box>
            {error && <ErrorMessage message={error} />}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddTimestamp;
