import {
  GET_NOTIFICATION,
  READ_NOTIFICATION,
  UNREAD_COUNT,
    VERIFY_LOADING,
    VERIFY_ERROR,
    READ_ALL_NOTIFICATION
  
  } from "./AdminNotifiActionTypes";

  const notifyInitialState = {
    notifications:[],
    count:0,
    loading: false,
    error: null,
    
  };

  const admiNoticationReducers = (state = notifyInitialState, action) => {
    switch (action.type) {
      case GET_NOTIFICATION: {
        
        return { ...state, notifications: action.payload.page==1 ? action.payload.notifi :[...state.notifications,...action.payload.notifi], loading: false, error: null };
      }
      case READ_NOTIFICATION:{
        const arry=state.notifications?.map((ele)=>{
          if(ele.id==action.payload){
            return {...ele,isRead:true}
          }else{
            return {...ele}
          }
        })
        return {...state, notifications: arry,count:state.count? state.count-1 : 0,loading: false, error: null}
      }
      case UNREAD_COUNT:{
        return {...state,count:action.payload,loading: false, error: null}
      }
      case READ_ALL_NOTIFICATION:{
        const arry=state.notifications?.map((ele)=>{
          return {...ele,isRead:true}
        })

        return{...state,notifications:arry, count:0,loading: false, error: null}
      }

      default: {
        return state;
      }
    }
  };
  
  export default admiNoticationReducers;