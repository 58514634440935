import React, { useState, useEffect } from "react";
import { GET_USER } from "../../utils/endpoints";
import httpInstance from "../../utils/instance";
import { Outlet, Navigate } from "react-router-dom";
import { namespace } from "../../environment";
const authMpin = () => {
  const mpinVerified = sessionStorage.getItem("mpin");

  if (mpinVerified) {
    return true;
  } else {
    return false;
  }
};
const create_Mpin = async () => {
  const httpConfig = {
    method: "GET",
    tokenType: "user",
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await httpInstance(`${GET_USER}`, httpConfig);
    if (response.data.data?.mpin) {
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
};

const MpinProtect = () => {
  const [isMpin, setIsMpin] = useState(false);
  const [load, setLoad] = useState(true);

  const auth = authMpin();

  useEffect(async () => {
    setIsMpin(await create_Mpin());
    setLoad(false);
  }, []);

  return load ? null : isMpin ? (
    !auth ? (
      <Navigate to={`${namespace}/signIn?stepcount=mpin`} />
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to={`${namespace}/signIn?stepcount=mpin_generate`} />
  );
};

export default MpinProtect;
