import React,{useEffect} from "react";
import { useStyles } from "../styles";
import { Box, createTheme, ThemeProvider,CircularProgress } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import LinearProgressWithLabel from "@material-ui/core/LinearProgress";
import ErrorMessage from "../../Validation/ErrorMessage";
import {BASE_URL} from '../../../environment'

function FileUpload({handleSubmit,fileUrl,handleNext,validation,type,nextbutton }) {
  const classes = useStyles();
  const theme = createTheme({
    overrides: {
      bar: {
        background: "#48D4A5",
        height: "100px"
      }
    }
  });

  const [progress, setProgress] = React.useState(0);
  const [uploadStep, setUploadStep] = React.useState(1);
  const [uploadButton, setEnableUploadButton] = React.useState(false);
  const [loading,setLoading]=React.useState(false)
  const [fileSizeError,setFileSizeError]=React.useState(false)
  const [isNext,setIsNext]=React.useState(false)
  const [fileTypeError,setFileTypeError]=React.useState(false)

  //uploadCounter(uploadStep);

  useEffect(()=>{
    if(fileUrl){
      setUploadStep(3) 
      setIsNext(true)
    }
    if(nextbutton){
      setUploadStep(3) 
    }
  },[fileUrl,nextbutton])

  const onDrop = (acceptedFiles) => {
    if(!acceptedFiles[0].name.match(validation)){
      setFileTypeError(true)
    }else{
      setFileTypeError(false)
    if(acceptedFiles[0].size<5242880){
      //setEnableUploadButton(true);
      setEnableUploadButton(false);
      setUploadStep(2);
      setFileSizeError(false)
    }else{
      setFileSizeError(true)
    }
  }
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop, multiple: false,
    accept:'image/*'
  });

  const files = acceptedFiles.map((file) => (
    <Box key={file.path}>{file.name}</Box>
  ));

  const handleCloseFile=()=>{
    setProgress(0)
    setUploadStep(1)
    setIsNext(false)
    //setEnableUploadButton(false)
    setEnableUploadButton(true)
  }

  const handleNextButton=()=>{
    setIsNext(true)
  }

  const UploadStepTwo = (props) => {
    setEnableUploadButton(true)
   setLoading(true)
    setIsNext(false)
    React.useEffect(() => {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            setLoading(false)
            setEnableUploadButton(false)
            setUploadStep(3);
            //
            clearInterval(timer);
           
            handleSubmit(acceptedFiles)
            
            //handleNextButton()
            return 100
            
           
          }else{
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
          }
        });
      }, 50);

      return () => {
        clearInterval(timer);
      };
    }, []);

    

    const size = acceptedFiles.map((file, progress,i) => (
      <Box key={i}>{Number(file.size / 1024).toFixed(2)}kb</Box>
    ));
    return (
      <React.Fragment>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          className={classes.file}
          p={2}
          px={2}
        >
          <Box
            width="350px"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              width="100px"
            >
              <img src="/images/file.svg" height={35} width={35} />
              <Box
                fontSize={14}
                display="flex"
                alignItems="center"
                justifyContent="center"
                mt={1}
              >
                {acceptedFiles.length>0 ? files :  <Box >{fileUrl.split('\\').pop().split('/').pop()}</Box>}
              </Box>
            </Box>
            <img src="/images/close.svg" height={20} width={20} />
          </Box>
          <Box width="100%" my={1}>
            <ThemeProvider theme={theme}>
              <LinearProgressWithLabel
                variant="determinate"
               
                value={progress}
                //className={classes.progress}
                classes={{ barColorPrimary: classes.barColorPrimary}}
                {...props}
              />
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                my={1}
              >
                {size}
                {Math.floor(progress)}%
              </Box>

              <Box />
            </ThemeProvider>
          </Box>
        </Box>
        {/* <Box mt={3}>
          {uploadButton ? (
            <button
              className={classes.primaryButton}
              onClick={() => setUploadStep(3)}
            >
              Upload
            </button>
          ) : (
            <button className={classes.disabledButton}>Upload</button>
          )}
        </Box> */}
      </React.Fragment>
    );
  };

  

  return (
    <React.Fragment>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        width="100%"
      >
        {uploadStep === 1 && (
          <React.Fragment>
            <Box className={classes.fileUpload}>
              <Box
                {...getRootProps({ className: "dropzone" })}
                display="flex"
                flexDirection="column"
                alignItems="center"
                px={12}
                py={2}
              >
                <input {...getInputProps()} />
                <img src="/images/upload.svg" height={80} width={80} />
                <Box mt={1} fontSize={14}>
                  Click or Drag Files to Upload
                </Box>
                <Box mt={1} fontSize={12}>
                  {`Format: ${type}, Max Size:5MB`}
                </Box>
              </Box>
              
            </Box>
            {fileSizeError && <ErrorMessage message="Maximun file size is 5MB"/>}
            {fileTypeError && <ErrorMessage message={`File type must be ${type}`}/>}
            </React.Fragment>
            ) }
            
           
            {uploadStep === 3 ? (
          <React.Fragment>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width="350px"
              mb={4}
            >
              <Box
                className={classes.file}
                p={2}
                px={2}
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box mr={2}>
                    <img src="/images/file.svg" height={35} width={35} />
                  </Box>

                  <Box
                    fontSize={14}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {acceptedFiles.length>0 ? files :  <Box ><a href={`${BASE_URL}${fileUrl}`} target="_blank">{fileUrl.split('\\').pop().split('/').pop()}</a></Box>}
                   
                  </Box>
                </Box>
                <img src="/images/close.svg" height={20} width={20} onClick={handleCloseFile} />
              </Box>
            </Box>
           
            
          </React.Fragment>
        ) : (
          ""
        )}
  {uploadStep === 2 ? <UploadStepTwo /> : ""}
        <Box mt={3}>
              {uploadButton ? (
                <button
                  className={classes.primaryButton}
                  //onClick={() => setUploadStep(3)}
                >
                  {loading ? <CircularProgress size="1rem" color="inherit"/> : "Upload" }
                </button>
              ) : (
                
                (nextbutton || fileUrl) ? <button className={classes.primaryButton} onClick={handleNext}> Next</button> : <button className={classes.disabledButton}> Upload</button>
              )}
            </Box>
        
        

        {/* {uploadStep === 3 ? (
          <React.Fragment>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width="250px"
            >
              <Box
                className={classes.file}
                p={2}
                px={2}
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box mr={2}>
                    <img src="/images/file.svg" height={35} width={35} />
                  </Box>

                  <Box
                    fontSize={14}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {files}
                  </Box>
                </Box>
                <img src="/images/close.svg" height={20} width={20} />
              </Box>
            </Box>
            <Box mt={3}>
              
                <button
                  className={classes.primaryButton}
                  onClick={() => setUploadStep(2)}
                >
                  Upload
                </button>
             
            </Box>
          </React.Fragment>
        ) : (
          ""
        )} */}
      </Box>
    </React.Fragment>
  );
}

export default FileUpload;
