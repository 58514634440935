import React,{useState,useEffect} from 'react'
import { Box,makeStyles, Typography } from '@material-ui/core'
import {UPDATE_LAG_PERCENTAGE,GET_LAG_PERCENTAGE} from '../../../utils/endpoints'
import httpInstance from '../../../utils/instance'


const LagPercent = () => {
    const useStyles=makeStyles({
        root:{
            background:"#fff",
            borderRadius:"10px"
        },
        primaryButton:{
            background:"#326CF9",
            color:"#fff",
            padding:"5px 1.5rem",
            border:"none",
            borderRadius:"20px",
            fontFamily:"inherit",
            boxShadow: "0px 2px 26px rgba(0, 0, 0, 0.15)",
            cursor:"pointer",
            marginLeft:"3rem"
        },

        title:{
            fontSize:"0.9rem",
            color:"#686868",
            fontWeight:600
        },
        inputBox:{
            width:"30%",
            border:"1px solid #DDDDDD",
            borderRadius:"5px",
            margin:"0px 10px",
            padding:"5px",
            fontFamily:"inherit",
           
        }
    })

    const classes=useStyles()

    const [lag,setLag]=useState('')
    const [edit,setEdit]=useState(false)

    const get_lag_percentage=async()=>{
        const httpConfig = {
            method: "GET",
            tokenType: "admin",
            headers:{
              "Content-Type": "application/json",
            },     
          };
          try{
            const response=await httpInstance(`${GET_LAG_PERCENTAGE}`,httpConfig)
            setLag(response.data?.data)
          }
          catch{

          }
    }

    useEffect(()=>{
        get_lag_percentage()
    },[])

    const handle_edit=()=>{
        if(edit){
            update_lag ()
        }else{
            setEdit(true)
        }
        
    }
    //---------------------------change lag input----------------------------//
    const change_lag=(e)=>{
        e.target.value = e.target.value.replace(/[^0-9]/g, "").toLowerCase();
        setLag(e.target.value)
    }

    //--------------update lag-------------------------------------------//
    const update_lag=async()=>{
        const httpConfig = {
            method: "POST",
            tokenType: "admin",
            headers:{
              "Content-Type": "application/json",
            },  
            params:{lagPercentage:lag}
          };
          try{
            await httpInstance(`${UPDATE_LAG_PERCENTAGE}`,httpConfig)
            setEdit(false)
          }
          catch{

          }
    }
    
  return (
    <div>
        <Box pr={15} pl={3} mt={5}>
        <Box className={classes.root} >
        <Box display="flex" justifyContent="space-between" alignItems="center" px={5} py={2}>
            <Typography className={classes.title}>Lag Percentage</Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                {edit ? 
                <input 
                     className={classes.inputBox}
                     name="lag"
                     value={lag}
                    onChange={change_lag}
                    required/> :
                <Typography className={classes.title}>{lag}</Typography> }
                <Typography className={classes.title}>%</Typography>
                <button className={classes.primaryButton} onClick={handle_edit} >
                    {edit ? "Save" :"Edit"}
                </button>
                </Box>
            </Box>

        </Box>
        </Box>
    </div>
  )
}

export default LagPercent