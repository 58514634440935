import React from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/Footer'
import Terms_Conditions from '../../containers/About/Terms_Conditions'
import { isMobile } from "react-device-detect";

const Terms_conditions = () => {
  return (
    <div>
        {!isMobile && <Header/>}
        <Terms_Conditions/>
        { !isMobile &&  <Footer/>}
    </div>
  )
}

export default Terms_conditions