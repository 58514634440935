import { Box, Typography, CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useStyles } from "../styles";
import Webcam from "react-webcam";
import { useReactMediaRecorder } from "react-media-recorder";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { UPLOAD_VIDEO, GET_VIDEO_OTP } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams } from "react-router-dom";
import ErrorMessage from "../../Validation/ErrorMessage";
import getBlobDuration from "get-blob-duration";
import { namespace } from "../../../environment";

function TakeVideo({ step }) {
  const classes = useStyles();

  const webcamRef = React.useRef(null);

  const navigate = useNavigate();

  const [isRecording, setIsRecording] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showCamera, setShowCamera] = useState(true);
  const [uploadButton, setUploadButton] = useState(false);
  const [otp, setOpt] = useState("");
  const [videoSizeError, setVideoSizeError] = useState(false);
  const [durationError, setDurationError] = useState(false);

  const handleStartRecording = (startRecording) => {
    setShowCamera(true);
    setIsRecording(true);
    startRecording();
    setShowVideo(false);
  };
  const handleStopRecording = (stopRecording) => {
    setIsRecording(false);
    setShowVideo(true);
    stopRecording();
    setShowCamera(false);
    setUploadButton(true);
  };
  //----------------------video recorder---------------------------------------------//
  const { status, startRecording, stopRecording, mediaBlobUrl, previewStream } =
    useReactMediaRecorder({ video: true, type: "video/mp4" });

  useEffect(async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_VIDEO_OTP}`, httpConfig);
      setOpt(response.data?.data?.otp);
    } catch {}
  }, []);

  const handle_submit = async () => {
    setLoading(true);
    const mediaBlob = await fetch(mediaBlobUrl).then((response) =>
      response.blob()
    );

    //get video duration

    const blobUrl = mediaBlob;
    const duration = await getBlobDuration(blobUrl);

    const myFile = new File([mediaBlob], "otpvideo.webm", {
      type: "video/webm",
    });

    if (duration > 10) {
      setDurationError(false);
      if (myFile.size < 10485760) {
        const formData = new FormData();

        formData.append("video_file", myFile);

        const httpConfig = {
          method: "POST",
          tokenType: "user",
          headers: {
            "Content-Type": "application/json",
          },
          data: formData,
        };

        try {
          await httpInstance(`${UPLOAD_VIDEO}`, httpConfig);
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "23" })}`,
          });
        } catch {
          setLoading(false);
        }
      } else {
        setVideoSizeError(true);
        setLoading(false);
      }
    } else {
      setLoading(false);
      setDurationError(true);
    }
  };

  const handleback = () => {
    navigate({
      pathname: `${namespace}/signIn`,
      search: `?${createSearchParams({ stepcount: "21" })}`,
    });
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={5}
        className={classes.backButton}
        onClick={handleback}
      >
        <img src="/images/backIcon.svg" />
      </Box>
      <Box display={"flex"} justifyContent="center">
        <Box
          display={"flex"}
          alignItems="center"
          flexDirection={"column"}
          justifyContent="flex-start"
        >
          <Box
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="column"
            py={2}
          >
            <Typography className={classes.title}>Take a Video</Typography>
            <Box mt={1} style={{ width: "100%" }} textAlign={""}>
              <Typography
                className={classes.headingDescription}
                style={{ width: "23rem" }}
              >
                Please create a video by reading out the OTP and then upload it
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography className={classes.headingDescription}>
                Your OTP : <span className={classes.optTitle}> {otp}</span>
              </Typography>
            </Box>
          </Box>

          <Box my={2} display="flex" justifyContent="center">
            {!isRecording ? (
              <button
                className={classes.recordingButton}
                onClick={() => handleStartRecording(startRecording)}
              >
                Start Recording
              </button>
            ) : (
              <button
                className={classes.recordingButton}
                onClick={() => handleStopRecording(stopRecording)}
              >
                Stop Recording
              </button>
            )}
          </Box>

          <Box>
            {showCamera && (
              <>
                {isRecording && (
                  <FiberManualRecordIcon
                    className={classes.recordIcon}
                    style={{ color: "red" }}
                  />
                )}
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  style={{ width: "26rem" }}
                />
              </>
            )}
          </Box>

          {/* {isRecording && <VideoPreview stream={previewStream} />} */}
          {showVideo && (
            <video
              src={mediaBlobUrl}
              controls
              autoPlay
              loop
              style={{ width: "26rem" }}
            />
          )}

          {videoSizeError && <ErrorMessage message="Maximum file size 10MB" />}
          {durationError && (
            <ErrorMessage message="Video should be atleast 10 seconds long" />
          )}
          <Box mt={3} display="flex" justifyContent="center" px={5}>
            {uploadButton ? (
              <button className={classes.primaryButton} onClick={handle_submit}>
                {loading ? (
                  <CircularProgress size="1rem" color="inherit" />
                ) : (
                  "Upload"
                )}
              </button>
            ) : (
              <button className={classes.disabledButton}>Upload</button>
            )}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default TakeVideo;
