import React from 'react'
import PaymentFailed from '../../../components/DashBoard/DigitalGold/PaymentFailed'

const BuyFailed = () => {
  return (
    <div>
        <PaymentFailed cancel="/myportfolio?tab=digitalgold" retry="/dashboard/digitalgold"/>
    </div>
  )
}

export default BuyFailed