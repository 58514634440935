import { Box, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import TitleLayoult from "../TitleLayoult";
import { useStyles } from "../styles";
import { useNavigate, createSearchParams } from "react-router-dom";
import {
  CREAT_INVESTER_ACCOUNT,
  CREAT_MF_ACCOUNT,
  KYC_STATUS,
  CREATE_GOLD_USER,
  ACCEPT_TERMS,
  ADD_GOLD_BANK,
  GET_BANK_DETAILS,
  CREAT_GOLD_KYC,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useSelector } from "react-redux";
import Lottie from "react-lottie";
import animationData from "../../../lotties/account_create.json";
import { namespace } from "../../../environment";

function Step12({ step }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { userStore } = useSelector((state) => state);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(async () => {
    const userId = localStorage.getItem("user_id");
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { userId: userId },
    };

    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    // const httpConfig2 = {
    //   method: "POST",
    //   tokenType: "user",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   params:{userId:userId}
    // };

    const httpConfig3 = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${KYC_STATUS}`, httpConfig1);
      if (response.data?.data?.isKycCompliant) {
        await httpInstance(`${CREAT_INVESTER_ACCOUNT}`, httpConfig);
        // await httpInstance(`${CREAT_MF_ACCOUNT}`,httpConfig)
      }

      //------------------create gold user-----------------------------------------------//

      if (userStore.goldUser.length == 0) {
        await httpInstance(`${CREATE_GOLD_USER}`, httpConfig3);
        await httpInstance(`${ACCEPT_TERMS}`, httpConfig3);
        await httpInstance(`${ADD_GOLD_BANK}`, httpConfig3);
        await httpInstance(`${CREAT_GOLD_KYC}`, httpConfig3);
      }

      //--------RETAKE KYC----------------------------------//
      if (userStore.user?.retakeKyc) {
        await httpInstance(`${ADD_GOLD_BANK}`, httpConfig3);
      }

      navigate({
        pathname: `${namespace}/signIn`,
        search: `?${createSearchParams({ stepcount: "17" })}`,
      });
    } catch {}
  }, []);

  const handle_setupLater = () => {
    navigate({
      pathname: `${namespace}/signIn`,
      search: `?${createSearchParams({ stepcount: "17" })}`,
    });
  };

  return (
    <React.Fragment>
      <Box display={"flex"} alignItems="center" flexDirection={"column"}>
        <Lottie options={defaultOptions} height={300} width={300} />
        <Box mt={3}>
          <Typography style={{ fontSize: "1.2rem" }}>
            Please wait, We are creating Your Account
          </Typography>
        </Box>
        {/* <TitleLayoult
          title={"Set up Auto Mandate"}
          description={
            "You can Set up Automandate to automatically debit amount from your bank account every month for SIP payments "
          }
          align="center"
        />

        <Box>
          <img
            src="/images/Automandate.svg"
            style={{ height: "14rem", width: "14rem" }}
          />
        </Box>
        <Box mt={3} display="flex" flexDirection={"column"}>
          <Box mb={2}>
            <button className={classes.primaryButton} >
              Setup Automandate
            </button>
          </Box>
          <Box>
            <button className={classes.borderedButton} onClick={handle_setupLater}>
             {loading ? <CircularProgress size="1rem" color="blue"/> : " Setup Later"} 
            </button>
          </Box>
        </Box> */}
      </Box>
    </React.Fragment>
  );
}

export default Step12;
