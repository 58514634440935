import React,{useState,useEffect} from 'react'
import { Box, Typography,makeStyles } from '@material-ui/core'

const Strategy = () => {
    const useStyles=makeStyles({
        header:{
            fontSize:"1.2rem",
            fontWeight:500,
            marginTop:"2rem"
        }
    })

    const classes=useStyles()

    const images=[
        "/images/assisted_service_loading_0.svg",
        "/images/assisted_service_loading_1.svg",
        "/images/assisted_service_loading_2.svg"
    ]

    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        const intervalId = setInterval(() => {
            if(currentIndex === images.length - 1) {
                setCurrentIndex(0);
            } 
            else {
                 setCurrentIndex(currentIndex + 1);
            }
        }, 1000)
        
        return () => clearInterval(intervalId);
    })
  return (
    <div>
        <Box display="flex" flexDirection="column" alignItems="center">
            <img src={images[currentIndex]}/>
            <Typography className={classes.header}>Almost there!!</Typography>
            <Typography>We are cooking the best strategy for you</Typography>
        </Box>
    </div>
  )
}

export default Strategy