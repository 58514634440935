import React from 'react'
import { Box,makeStyles,Table,TableRow,TableHead,TableBody,TableCell,Divider, Grid } from '@material-ui/core'

const InvestorTable = ({data}) => {
    const useStyles=makeStyles({
        root:{
            background:"#fff",
            borderRadius:"10px",
            "& .MuiTableCell-root":{
                fontSize:"0.8rem"            
                }
        },
        // table:{
        //     borderTop:"1px solid #DDDDDD",
        // },
        cellBorder:{
            "&.MuiTableCell-root":{
            borderRight:"1px solid #DDDDDD",
            minWidth:"10rem" ,
            
            
            }
        },

        gridBorder:{
            borderRight:"1px solid #DDDDDD",
            paddingBottom:"5px"
        },
        cell:{
            "&.MuiTableCell-root":{
                padding:"0px",
                paddingTop:"5px",
                borderRight:"1px solid #DDDDDD",
                maxWidth:"19rem",
                
            }
        },
        subCell:{
            "&.MuiTableCell-root":{
                padding:"0px",   
                borderRight:"1px solid #DDDDDD",   
                      
            }  
        },
        gridItem:{
            padding:"16px",
            borderRight:"1px solid #DDDDDD",
        },
        gridItem1:{
            padding:"16px",
        },

        tableRow:{
            '&:last-child td': {
               borderBottom: 0,
              },
            }

    })
    const classes=useStyles()

    const investorName={
        1:"Balanced",
        2:"Moderate",
        3:"Aggressive",
        4:"Very Aggressive"
    }
  return (
    <div>
        <Box className={classes.root} >
            <Table >
                <TableHead>
                    <TableRow>
                    <TableCell align='center' className={classes.cellBorder}>Investment Type</TableCell>
                    <TableCell align='center' className={classes.cell}>
                        Points Range
                        <Divider  />
                        <Grid container>
                            <Grid item xs={6} className={classes.gridBorder}>
                            Min
                            </Grid>
                            <Grid item xs={6}>
                            Max
                            </Grid>
                            
                        </Grid>
                        
                    </TableCell>
                    <TableCell align='center' >Thematic: Cap Ratio</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((ele,i)=>{
                        return(
                            <TableRow key={i} className={classes.tableRow}>
                    <TableCell align='center' className={classes.cellBorder}>{investorName[ele.riskType]} </TableCell>
                    <TableCell align='center' className={classes.subCell}>
                    <Grid container>
                            <Grid item xs={6} className={classes.gridItem}>
                                {ele.low}  Points
                               
                            </Grid>
                            <Grid item xs={6} className={classes.gridItem1}>
                           {ele.high} Points
                            </Grid>
                            
                        </Grid>
                    </TableCell>
                    <TableCell align='center'>
                        {
                        `${ele.thematicPercentage} : ${ele.capPercentage}`
                        }
                         </TableCell>
                    </TableRow>
                        )
                    })}
                    
                </TableBody>
            </Table>

        </Box>
    </div>
  )
}

export default InvestorTable