import React,{useState,useEffect} from 'react'
import { Box,makeStyles,Typography,Grid} from '@material-ui/core'
import SwitchDialog from './SwitchDialog'

const SwitchCard = ({fundToData,schemeData,fundFromData}) => {
    const useStyles = makeStyles({
        root:{
            border:"1px solid #D0D0D0",
            borderRadius:"5px"
        },
        primaryButton: {
            padding: "0.8rem 3rem",
            background: "#326CF9",
            color: "#fff",
            fontSize: "0.8rem",
            fontFamily:"inherit",
            fontWeight: 600,
            borderRadius: "45px",
            border: "none",
            cursor: "pointer",
          },

          title:{
            fontSize:"1rem",
            fontWeight:500
          },
          textgrey:{
            fontSize:"0.7rem",
            color:"#626262"
          },

        "@media  (max-width:600px)":{
            primaryButton: {
                padding: "0.6rem 1rem",
                borderRadius:"10px"
                
              },
        }
    })
    const classes=useStyles()

    const [showSwitch,setShowSwitch]=useState(false)

    const handle_close=()=>{
      setShowSwitch(false)
    }
  return (
    <div>
        <Box className={classes.root} p={3} px={{xs:1,md:4}} mt={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
                <img src={fundToData?.img_url} height="30px" width="30px" style={{marginRight:"10px"}}/>
                <Box>
                <Typography className={classes.title}>{fundToData?.scheme_name}</Typography>
                <Typography className={classes.textgrey}>Exit load and capital gain tax may be applicable</Typography>
                </Box>
            </Box>
            <button className={classes.primaryButton} onClick={()=>setShowSwitch(true)}>SWITCH</button>
            </Box>      

        </Box>

        <SwitchDialog show={showSwitch} handleClose={handle_close} fundToData={fundToData} fundFromData={fundFromData} />
    </div>
  )
}

export default SwitchCard