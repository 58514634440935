import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
  Grid,
  CircularProgress
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import {
    GET_DETAILS_BY_IFSC,UPDATE_BANK,UPDATE_INVESTOR_ACCOUNT
  } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import CustomToast from '../../../components/UI/CustomToast'

const EditIfsc = ({ show, handleClose, bankDetails,get_all_bank }) => {
  const useStyles = makeStyles((theme) => ({
    closeIcon: {
      justifyContent: "flex-end",
    },

    title: {
      fontSize: "1.1rem",
      fontWeight: 500,
      textAlign: "center",
      fontFamily: "inherit",
    },
    dialogTitle: {
      padding: "10px",
    },
    dialogPaper: {
      maxWidth: "23rem",
      justifyContent: "center",
    },
    dialogContent: {
        padding: "8px 30px",
      },
    inputBox: {
        background: "#E6E6E6",
        padding: "0.6rem 0.6rem",
        borderRadius: "10px",
        border: "none",
        fontFamily:"inherit",
        width:"-webkit-fill-available",
        marginBottom:"10px"
      },
      text: {
        fontSize: "0.8rem",
        textAlign: "center",
        fontFamily:"inherit",
        marginTop:"10px"
      },
      primaryButton: {
        padding: "0.8rem 5rem",
        background: "#326CF9",
        color: "#fff",
        fontSize: "0.8rem",
        fontWeight: 600,
        borderRadius: "45px",
        border: "none",
        cursor: "pointer",
        
      },
  }));

  const classes = useStyles();

  const [loading,setLoading]=useState(false)
  const [ifsc,setIfsc]=useState(bankDetails?.ifscCode)
  const [bankName,setBankName]=useState(bankDetails?.bankName)
  const [branch,setBranch]=useState(bankDetails?.branchName)
  const [error,setError]=useState(false)

  

  const handle_ifsc=(e)=>{
    let ifscCode=e.target.value.toUpperCase()
    setIfsc(ifscCode)
    get_bankData(ifscCode)

  }

  const get_bankData=async(ifscCode)=>{
    const httpConfig = {
        method: "GET",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        
      };
    
      try {
        const response = await httpInstance(
            `${GET_DETAILS_BY_IFSC}${ifscCode}`,
          httpConfig
        )
        setBankName(response.data?.data?.bank_name)
        setBranch(response.data?.data?.branch_name)
        setError(false)
        }
        catch(error){
            setError(error.response.data?.message)
        }
  
    }

    
    const handle_submit= async()=>{
      setLoading(true)
        const formData={
            id:bankDetails?.id,
            accountHolderName:bankDetails?.accountHolderName,
            accountNumber:bankDetails?.accountNumber,
            bankName:bankName,
            branchName:branch,
            ifscCode:ifsc,
            accountType:bankDetails?.accountType.id
        }
        const httpConfig = {
            method: "POST",
            tokenType: "user",
            headers: {
              "Content-Type": "application/json",
            },
            data:formData
            
          };

          const httpConfig1 = {
            method: "POST",
            tokenType: "user",
            headers: {
              "Content-Type": "application/json",
            },
            
          };

          try{
            const response=await httpInstance(`${UPDATE_BANK}`,httpConfig)
            await httpInstance(`${UPDATE_INVESTOR_ACCOUNT}`,httpConfig1)
            get_all_bank()
            handleClose()
            setLoading(false)
            CustomToast("IFSC Code Updated","SUCCESS")
          }
          catch(error){
            setError(error.response.data?.message)
            setLoading()
          }
    }


  return (
    <div>
      <Dialog
        open={show}
        onClose={handleClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle className={classes.dialogTitle}>
          <Grid container>
            <Grid item xs={11}>
              <Typography className={classes.title}>
                {"Update IFSC Code"}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box
                display="flex"
                justifyContent="end"
                className={classes.closeIcon}
              >
                <CloseIcon
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <label className={classes.text}>Enter IFSC Code</label>
          <input className={classes.inputBox} 
            type="text"
            value={ifsc}
            onChange={handle_ifsc}
            maxLength={11}
          />

          <label className={classes.text}>Bank Name</label>
          <input className={classes.inputBox} 
            type="text"
            disabled 
            value={bankName}
            />

          <label className={classes.text}>Branch Name</label>
          <input className={classes.inputBox} 
          type="text"
            disabled 
            value={branch}
            />

            {error && <ErrorMessage message={error}/>}
          <Box display="flex" justifyContent="center" my={2}>
           
          <button
              className={classes.primaryButton}
              onClick={handle_submit}
            >
              {loading ? <CircularProgress size="1rem" color="inherit" /> : " Update"}
               
               
              
            </button>
            </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditIfsc;
