import React, { useState, useEffect } from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import { GET_QUESTIONARES,UPDATE_QUESTIONARES,DELETE_QUESTION } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import AddIcon from '@mui/icons-material/Add';
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import AddQuestion from "./AddQuestion";
import Confirmation from "../../../components/MutualFund/InfoMsg/Confirmation";
import { useSelector } from "react-redux";
import CustomToast from "../../../components/UI/CustomToast";

const Questionaries = () => {
  const useStyles = makeStyles({
    root: {
      background: "#fff",
      borderRadius: "10px",
    },
    title: {
      fontSize: "0.9rem",
      fontWeight: 600,
      color: "#686868",
    },
    primaryButton: {
      background: "#326CF9",
      color: "#fff",
      padding: "5px 1.5rem",
      border: "none",
      borderRadius: "20px",
      fontFamily: "inherit",
      margin: "0px 15px",
      boxShadow: "0px 2px 26px rgba(0, 0, 0, 0.15)",
      display:"flex",
      alignItems:"center",
      cursor:"pointer"
    },
    answer: {
      margin: "3px 0px",
    },
    inputBox: {
      width: "100%",
      border: "1px solid #DDDDDD",
      borderRadius: "5px",
      margin: "0px 10px",
      padding: "5px",
      fontFamily: "inherit",
    },
  });
  const classes = useStyles();

  const [questions, setQuestions] = useState([]);
  const [editId, setEditId] = useState();
  const [editObj, setEditObj] = useState();
  const [error,setError]=useState(false)
  const [add,setAdd]=useState(false)
  const [showDialog,setShowDailog]=useState(false)
  const [deleteData,setDeleteData]=useState({})

  const {adminStore}=useSelector((state)=>state)
  

  //------------get questions-------------------------------------//
  const get_questinres = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_QUESTIONARES}`, httpConfig);
      setQuestions(response.data?.data);
    } catch {}
  };

  useEffect(() => {
    get_questinres();
  }, []);

  //--------------------------handle edit--------------------------------------//
  const handle_edit = (id) => {
    setEditId(id);

  const arry=questions.filter((ele)=>{
    return ele.quizeId==id
  })
    setEditObj(arry[0])
  };

  //----------------------------edit question------------------------------------//

  const change_question=(e)=>{
    setEditObj({...editObj,question:e.target.value})
  }

  const change_answer=(e,id)=>{
    const choiceArry=editObj.choices.map((ele)=>{
      if(ele.id==id){
        return {...ele,[e.target.name]:e.target.value}
      }else{
        return {...ele}
      }
    })

     setEditObj({...editObj,choices:choiceArry})
  }

  //--------------------------------Save Edit----------------------------------------------//
  const save_edit=async()=>{
    setError(false)
    const formData={
      id:editObj.quizeId,
      question:editObj.question,
      choices:editObj.choices
    }

    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      data:formData
    };

    try{
      await httpInstance(`${UPDATE_QUESTIONARES}`,httpConfig)
      if(adminStore?.Adminuser?.role?.name=="Admin"){
        CustomToast(`Approval request sent`,"SUCCESS")
      }else{
        get_questinres()
      }    
      setEditId('')
    }
    catch(error){
      setError(error.response.data?.message)
    }
  }

  //-------------------handle add question-----------------------------------//
  const handle_add=()=>{
    get_questinres()
    setAdd(false)
  }

  //-------------------------confirm delete---------------------------------------//
  const confirm_delete=(data)=>{
    setDeleteData(data)
    setShowDailog(true)
  }

  //-----------------------------handle delete---------------------------------------------//
  const handle_delete=async()=>{
    const formData={
      id:deleteData.quizeId,
      question:deleteData.question,
      choices:deleteData.choices
    }

    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      data:formData
    };

    try{
      await httpInstance(`${DELETE_QUESTION}`,httpConfig)
      if(adminStore?.Adminuser?.role?.name=="Admin"){
        CustomToast(`Approval request sent`,"SUCCESS")
      }else{
        get_questinres()
      } 
      setShowDailog(false)
    }
    catch{

    }

  }


  return (
    <div>
      <Box pr={15} pl={3} mt={4}>
        <Typography className={classes.title}>Questionnaires</Typography>

        {questions?.map((ele, i) => {
          return (
            <Box
              key={ele.quizeId}
              className={classes.root}
              px={5}
              py={2}
              mt={2}
            >
              <Grid container>
                <Grid item xs={10}>
                {editId == ele.quizeId ? (
                  <Box display="flex" alignItems="center" >
                    <Typography className={classes.title}>{`${
                      i + 1
                    }`}</Typography>
                    <input
                      className={classes.inputBox}
                      value={editObj.question}
                      onChange={change_question}
                    />
                  </Box>
                ) : (
                  <Typography className={classes.title}>{`${i + 1}. ${
                    ele.question
                  }`}</Typography>
                )}

                </Grid >
                
                <Grid item xs={2}>
                <Box display="flex" alignItems="center" justifyContent="end">
                {editId == ele.quizeId ?
                 <button
                    className={classes.primaryButton}
                    onClick={save_edit}
                  >
                     Save
                  </button> :

                  <button
                    className={classes.primaryButton}
                    onClick={() => handle_edit(ele.quizeId)}
                  >
                    Edit
                  </button> }
                  <img
                    src="/images/admin/delete.svg"
                    height="20px"
                    width="20px"
                    onClick={()=>confirm_delete(ele)}
                    style={{cursor:"pointer"}}
                  />
                </Box>
                </Grid>
                
               
              </Grid>
              <Box mt={3}>
                <ol>
                  {ele.choices.map((answer, i) => {
                    return (
                      <li>
                        <Box key={answer.id}>
                          {/* <p>{answer.name}</p> 
                  <p>{answer.weight} Points</p>                 */}

                          <Grid container spacing={2}>
                            <Grid item xs={10} className={classes.answer}>
                              {editId == ele.quizeId ? (
                                <input className={classes.inputBox}
                                  value={editObj.choices[i].name}
                                  name="name"
                                  onChange={(e)=>{change_answer(e,answer.id)}}
                                />
                              ) : (
                                ` ${answer.name}`
                              )}
                            </Grid>
                            <Grid item xs={2} className={classes.answer}>
                              <Box display="flex" justifyContent="end" alignItems="center">
                                {editId == ele.quizeId ? (
                                  <input className={classes.inputBox} 
                                  value={editObj.choices[i].weight}
                                  name="weight"
                                  onChange={(e)=>{change_answer(e,answer.id)}}
                                  />
                                ) : (
                                  `${answer.weight} `
                                )}
                                Points
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </li>
                    );
                  })}
                </ol>
              </Box>
              {error && (editId == ele.quizeId && <ErrorMessage message={error}/>)}
            </Box>
          );
        })}

        

        {add && <AddQuestion handle_add={handle_add} handle_delete={()=>setAdd(false)}/>}
      </Box>

      <Box display="flex" justifyContent="center" mt={3}>
      <button className={classes.primaryButton} onClick={()=>setAdd(true)}>
        <AddIcon/>
        Add More</button>
        </Box>

        <Confirmation 
        show={showDialog} 
        handleClose={()=>setShowDailog(false)} 
        handle_yes={handle_delete}
        title="Delete Confirmation"
        text="Are you sure you want to delete this question?"
        />
    </div>
  );
};

export default Questionaries;
