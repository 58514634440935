import React from 'react'
import {Box,Typography,Dialog,DialogTitle,DialogContent,makeStyles,CircularProgress,IconButton,Grid} from '@material-ui/core'

const Confirmation = ({show, handleClose,handle_yes,title,text}) => {
   
    const useStyles = makeStyles({
        root:{
            "& .MuiDialogContent-root":{
                fontSize:"0.8rem",
                lineHeight:"25px",
                padding:"20px 25px"
            },
            "& .MuiDialog-paperWidthSm":{
                minWidth:"25%",
                maxWidth:"300px",
                maxHeight:"100%"
                
            },
        
        },
        dialogTitle: {
            //display: "flex",
            justifyContent: "center",
            alignItems: " center",
            //textAlign: "center",
            padding: "15px 10px 0px 25px",
            marginTop:"-10px",
            "& .MuiTypography-body1":{
                fontSize:"1rem",
                fontWeight:600,
            }
          },
        closeIcon: {
            padding: "5px",
            justifyContent: "flex-end",
  
          },
          text:{
            fontSize:"0.8rem"
          },
          textCancel:{
            fontSize:"0.8rem",
            cursor:"pointer"
          },
          textConform:{
            fontSize:"0.8rem",
            color:"#326CF9",
            cursor:"pointer"
          }
        })

        const classes=useStyles()
  return (
    <div>
         <Dialog className={classes.root} open={show} onClose={handleClose}>
        <Box display="flex" className={classes.closeIcon}>
          {" "}
          
        </Box>
        <DialogTitle
          classes={{ root: classes.dialogTitle }}
        
        >
            <Box  display="flex" justifyContent="space-between" >
            <Typography className={classes.title}>{title}</Typography>
          
              
            </Box>
         
          
        </DialogTitle>
        <DialogContent>

            <Typography className={classes.text}>{text}</Typography>
            
            <Box display="flex" justifyContent="space-between" px={5} py={1} mt={3} style={{borderTop:"1px solid #cccc"}}>
                <Typography className={classes.textCancel} onClick={handleClose}>No</Typography>
                <Typography className={classes.textConform} onClick={handle_yes} >Yes</Typography>
            </Box>
        </DialogContent>
        </Dialog>
    </div>
  )
}

export default Confirmation