import axios from "axios";
import {BASE_URL} from "../environment"

const instance = axios.create({
 // baseURL: "https://bullsurge.mindstack.in/",
 baseURL:BASE_URL
});




const requestHandler = (config) => {
  if (typeof localStorage !== 'undefined') {
 var userToken=localStorage.getItem("user_token");
 var adminToken=localStorage.getItem("admin_token")
 var userTypes={
  user:userToken,
  admin:adminToken

}

if (config.tokenType!==undefined) {
  config.headers["Authorization"] = `Bearer ${userTypes[config.tokenType]}`;
}
  }
 
   
  // config.headers["Access-Control-Allow-Origin"] = "*";
  // config.headers["crossorigin"] = "true";
  // config.headers["Access-Control-Allow-Headers"] =
  //   "Origin, X-Requested-With, Content-Type, Accept, Authorization";
  // config.headers["Content-Type"] = "application/json";
  // config.headers["Access-Control-Allow-Methods"] =
  //   "PUT, POST, PATCH, DELETE, GET";
  ;
  // config.timeout = 30000;
  return config;
};
instance.interceptors.request.use(requestHandler);
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
   
    const code = error && error.response ? error.response.status : 0;
    if (code === 401 || code === 403) {
      // console.warn("error code", code);
    }
    return Promise.reject(error);
  },
);
export default instance;