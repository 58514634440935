import React,{useState,useEffect} from 'react'
import { Box,makeStyles, Typography } from '@material-ui/core'
import {GET_SUBSCRIPTION_FEES,UPDATE_SUBSCRIPTION_FEES} from '../../../utils/endpoints'
import httpInstance from '../../../utils/instance'

const Subscription = () => {
    const useStyles=makeStyles({
        root:{
            background:"#fff",
            borderRadius:"10px"
        },
        primaryButton:{
            background:"#326CF9",
            color:"#fff",
            padding:"5px 1.5rem",
            border:"none",
            borderRadius:"20px",
            fontFamily:"inherit",
            boxShadow: "0px 2px 26px rgba(0, 0, 0, 0.15)",
            cursor:"pointer",
            marginLeft:"3rem"
        },

        title:{
            fontSize:"0.9rem",
            color:"#686868",
            fontWeight:600
        },
        inputBox:{
            width:"30%",
            border:"1px solid #DDDDDD",
            borderRadius:"5px",
            margin:"0px 10px",
            padding:"5px",
            fontFamily:"inherit",
           
        }
    })

    const classes=useStyles()

    const [fees,setFees]=useState('')
    const [edit,setEdit]=useState(false)

    const get_fees=async()=>{
        const httpConfig = {
            method: "GET",
            tokenType: "admin",
            headers:{
              "Content-Type": "application/json",
            },     
          };
          try{
            const response=await httpInstance(`${GET_SUBSCRIPTION_FEES}`,httpConfig)
            setFees(response.data?.data)
          }
          catch{

          }
    }

    useEffect(()=>{
        get_fees()
    },[])

    const handle_edit=()=>{
        if(edit){
            update_subscription ()
        }else{
            setEdit(true)
        }
        
    }
    //---------------------------change lag input----------------------------//
    const change_subscription=(e)=>{
       let value = e.target.value.replace(/[^0-9]/g, "").toLowerCase();   
            setFees({...fees,amount:value})
        
        
    }

    //--------------update lag-------------------------------------------//
    const update_subscription=async()=>{
        let value=fees.amount
        if(value !=0  && value!=""){
        const httpConfig = {
            method: "POST",
            tokenType: "admin",
            headers:{
              "Content-Type": "application/json",
            },  
            data:{id:fees.id,amount:fees.amount}
          };
          try{
            await httpInstance(`${UPDATE_SUBSCRIPTION_FEES}`,httpConfig)
            setEdit(false)
          }
          catch{

          }
        }
    }
    
  return (
    <div>
         <Box pr={15} pl={3} mt={5}>
        <Box className={classes.root} >
        <Box display="flex" justifyContent="space-between" alignItems="center" px={5} py={2}>
            <Typography className={classes.title}>Assisted services Fees</Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center" >
            <Typography className={classes.title}>₹ </Typography>
                {edit ? 
                <input 
                     className={classes.inputBox}
                     name="lag"
                     value={fees.amount}
                    onChange={change_subscription}
                    required/> :
                <Typography className={classes.title}> {fees.amount}</Typography> }
                
                <button className={classes.primaryButton} onClick={handle_edit} >
                    {edit ? "Save" :"Edit"}
                </button>
                </Box>
            </Box>

        </Box>
        </Box>
    </div>
  )
}

export default Subscription