import React, { useState } from "react";
import { useStyles } from "../styles";
import { Box, Typography } from "@material-ui/core";
import TitleLayoult from "../TitleLayoult";
import TextInputField from "../Input/TextInputField";
import httpInstance from "../../../utils/instance";
import { GoogleLogin } from "react-google-login";
import { useForm } from "react-hook-form";
import ErrorMessage from "../../Validation/ErrorMessage";
import { useNavigate, createSearchParams } from "react-router-dom";
import { GOOGLE_LOGIN, GET_USER } from "../../../utils/endpoints";
import { namespace } from "../../../environment";

function Step1({ step, setData }) {
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const navigate = useNavigate();

  const [google, setGoogle] = useState(false);

  const responseGoogle = async (response) => {
    setGoogle(true);
    sessionStorage.removeItem("mpin");
    // reset({
    //   email:response.profileObj. email
    // })

    const formData = {
      emailId: response.profileObj.email,
      loginType: "GOOGLE",
      secretKey: "A*C:-1/Lg-N!KK-gpwr-QY@z",
      accessToken: response.accessToken,
      //secretKey:process.env.REACT_APP_API_KEY
    };

    const httpConfig = {
      method: "POST",
      data: formData,
    };

    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GOOGLE_LOGIN}`, httpConfig);
      localStorage.setItem("user_token", response.data?.data?.accessToken);
      localStorage.setItem("refresh_token", response.data?.data?.refreshToken);
      localStorage.setItem("user_id", response.data?.data?.user?.id);

      // navigate({
      //   pathname: '/signIn',
      //   search: `?${createSearchParams({stepcount:"2"})}`,
      // });
      // step(2)

      const status = await httpInstance(`${GET_USER}`, httpConfig1);
      if (status.data?.data?.mpin) {
        navigate({
          pathname: `${namespace}/signIn`,
          search: `?${createSearchParams({ stepcount: "mpin" })}`,
        });
      } else {
        if (status.data?.data?.isMobileNumberVerified) {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "mpin_generate" })}`,
          });
        } else {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "2" })}`,
          });
        }
      }
    } catch (error) {}
  };

  const handleEmailSubmit = async (data) => {
    sessionStorage.removeItem("mpin");

    //   if(google){
    //   const   formData={emailId: data.email,loginType:"GOOGLE"}

    //   const httpConfig = {
    //     method: "POST",
    //     data: formData,
    //   };

    //     try{
    //       const response = await httpInstance(`${GOOGLE_LOGIN}`,httpConfig)
    //       localStorage.setItem("user_token",response.data?.data?.accessToken)
    //       localStorage.setItem("refresh_token",response.data?.data?.refreshToken)
    //       localStorage.setItem("user_id",response.data?.data?.user?.id)

    //       navigate({
    //         pathname: '/signIn',
    //         search: `?${createSearchParams({stepcount:"2"})}`,
    //       });
    //       step(2)
    //   }
    //   catch(error){
    //     console.log(error)
    //   }
    // }else{
    localStorage.setItem("email", data.email);

    const formData = { emailId: data.email };

    const httpConfig = {
      method: "POST",
      data: formData,
    };

    try {
      const response = await httpInstance(
        "api/v1/usermgmt/user/login",
        httpConfig
      );
      localStorage.setItem("sessionId", response?.data?.data?.sessionId);
      setData({
        email: data.email,
        sessionId: response?.data?.data?.sessionId,
        creationTime: response?.data?.data?.creationTime,
      });
      localStorage.setItem("creationTime", response?.data?.data?.creationTime);
      navigate({
        pathname: `${namespace}/onBoard`,
        search: `?${createSearchParams({ stepcount: "1" })}`,
      });
      step(2);
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <Box display={"flex"} justifyContent="center">
        <Box display={"flex"} alignItems="center" flexDirection={"column"}>
          <TitleLayoult title="Welcome to Neosurge" />
          <Box>
            {/* <button className={classes.socialButton}>
          <img
            className={classes.socialButtonImage}
            src="/images/google-logo.svg"
          />
          <p >Continue with Google</p>
        </button> */}
            <GoogleLogin
              clientId="901855431398-vo2vjitftml7v641unu1puorobh6u32r.apps.googleusercontent.com"
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className={classes.socialButton}
                >
                  <img
                    className={classes.socialButtonImage}
                    src="/images/google-logo.svg"
                  />
                  <p>Continue with Google</p>
                </button>
              )}
              buttonText="Continue with Google"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
            />
          </Box>
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            mt={4}
          >
            <Typography
              style={{
                color: "#c7c7c7",
                fontSize: "0.8rem",
              }}
            >
              or
            </Typography>
          </Box>

          <form onSubmit={handleSubmit(handleEmailSubmit)}>
            <Box my={3}>
              <TextInputField
                label="Enter Email ID"
                name="email"
                placeholder=""
                register={register}
                inputPattern={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
              />
              {errors.email && (
                <ErrorMessage message="Please Enter Valid Email ID" />
              )}
            </Box>
            <Box mt={2}>
              <button className={classes.primaryButton} type="submit">
                Continue
              </button>
            </Box>
          </form>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Step1;
