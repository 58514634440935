import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import IntroSection from "../../components/IntroSection";
import StepsSection from "../../components/StepsSection";
import AssistedServices from "../../components/AssistedServices";
import CuratedProfile from "../../components/CuratedProfile";
import FAQ from "../../components/FAQ";
import ConnectModal from "../../components/ConnectModal";
import ConnectSuccess from "../../components/ConnectSuccess";

function Index() {
  const [showModal, setshowModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);

  const handleCloseModal = () => {
    setshowModal(false);
  };

  const handleShowModal = () => setshowModal(true);
  const handleShowSuccess = () => setSuccessModal(true);
  const handleCloseSuccessModal = () => setSuccessModal(false);

  return (
    <React.Fragment>
      <div className="font-body">
        <Header handleShowModal={handleShowModal} />
        <IntroSection handleShowModal={handleShowModal} />
        <StepsSection />
        <AssistedServices handleShowModal={handleShowModal} />
        <CuratedProfile handleShowModal={handleShowModal} />
        <FAQ type={"mutualFund"} />
        <Footer />
        <ConnectModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          handleShowSuccess={handleShowSuccess}
        />
        {/* connect success */}
        <ConnectSuccess
          handleCloseSuccessModal={handleCloseSuccessModal}
          showSuccessModal={showSuccessModal}
        />
      </div>
    </React.Fragment>
  );
}

export default Index;
