export const super_admin_nav = [
  {
    text: "Dashboard",
    icon: "/images/admin/dashboard.svg",
    path: "/admin/dashboard",
  },

  {
    text: "Allocation  Management",
    icon: "/images/admin/allocation management.svg",
    path: "/admin/allocation-management",
  },

  {
    text: "User Management",
    icon: "/images/admin/usermanagement.svg",
    path: "/admin/user-management",
  },
  {
    text: "Customer Database",
    icon: "/images/admin/customerdatabase.svg",
    path: "/admin/customer-database",
  },
  {
    text: "Investor Insights",
    icon: "/images/admin/investor insight.svg",
    path: "/admin/investor-insights",
  },
  {
    text: "Fund Management",
    icon: "/images/admin/fundmanagement.svg",
    path: "/admin/fund-management",
  },
  {
    text: "Assisted Pins",
    icon: "/images/admin/usermanagement.svg",
    path: "/admin/assisted-pins",
  },
  {
    text: "Timestamps",
    icon: "/images/admin/usermanagement.svg",
    path: "/admin/timestamps",
  },
  // {
  //   text:"Email Template",
  //   icon:"/images/admin/emailtemplate.svg",
  //   path:"/admin/email-template"
  // },
];

export const admin_nav = [
  {
    text: "Dashboard",
    icon: "/images/admin/dashboard.svg",
    path: "/admin/dashboard",
  },

  {
    text: "User Status",
    icon: "/images/admin/usermanagement.svg",
    path: "/admin/user-status",
  },
  {
    text: "Customer Database",
    icon: "/images/admin/customerdatabase.svg",
    path: "/admin/customer-database",
  },
  {
    text: "Investor Insights",
    icon: "/images/admin/investor insight.svg",
    path: "/admin/investor-insights",
  },
  {
    text: "Fund Management",
    icon: "/images/admin/fundmanagement.svg",
    path: "/admin/fund-management",
  },
  {
    text: "Assisted Pins",
    icon: "/images/admin/usermanagement.svg",
    path: "/admin/assisted-pins",
  },
  // {
  //   text:"Email Template",
  //   icon:"/images/admin/emailtemplate.svg",
  //   path:"/admin/email-template"
  // },
];
