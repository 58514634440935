import React from 'react'
import SwitchFund from '../../../containers/Portfolio/MutualFund/SwitchFund'

const Switch = () => {
  return (
    <div>
        <SwitchFund/>
    </div>
  )
}

export default Switch