import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import {
  CREATE_LUMSUM,
  INITIATE_PAYMENT,
  KYC_DATA,
  GET_LUMSUM_DETAIL,
} from "../../../utils/endpoints";
import { useNavigate, useParams } from "react-router-dom";
import httpInstance from "../../../utils/instance";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import { useSelector } from "react-redux";
import {
  GET_CONSENT_LUMPSUM_OTP,
  VERIFY_CONSENT_LUMPSUM_OTP,
} from "../../../utils/endpoints";
import { namespace } from "../../../environment";
const Lumsum = ({ fundData, fpData, kycStatus, show_banner }) => {
  const useStyles = makeStyles((theme) => ({
    inputBox: {
      background: "#E6E6E6",
      padding: "0.7rem 0.95rem",
      borderRadius: "10px",
      border: "none",
      width: "90.5%",
      fontFamily: "inherit",
    },
    label: {
      fontSize: "0.7rem",
      fontWeight: 500,
    },
    primaryButton: {
      padding: "0.8rem 3rem",
      background: "#326CF9",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
    },
  }));

  const classes = useStyles();

  const [error, setError] = useState("");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");

  const { userStore } = useSelector((state) => state);
  const navigate = useNavigate();

  const { code } = useParams();
  const [otp, SetOTP] = useState("");
  const [consentId, SetConsentId] = useState("");
  const [timer, setTimer] = useState(30);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [otpHasBeenSent, setotpHasBeenSent] = useState(true);
  const [handleOTP, setHandleOTP] = useState(0);

  useEffect(() => {
    setAmount("");
  }, [code]);

  const handle_amount = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "").toLowerCase();

    setAmount(e.target.value);
  };

  const handle_otp_value = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "").toLowerCase();
    SetOTP(e.target.value);
  };

  const handle_otp = async () => {
    if (sessionStorage.getItem("mpin")) {
      if (kycStatus == "successful") {
        if (
          amount <
          (fundData?.folio_number
            ? fpData?.minAdditionalInvestment
            : fpData?.minInitialInvestment)
        ) {
          setError(
            `Minimum Investment is ₹${
              fundData?.folio_number
                ? fpData?.minAdditionalInvestment
                : fpData?.minInitialInvestment
            }`
          );
        } else {
          setError("");
          setLoading(true);

          const formDataOTP = {
            consentId: consentId,
            otp: otp,
          };

          const httpConfigOTP = {
            method: "POST",
            tokenType: "user",
            headers: {
              "Content-Type": "application/json",
            },
            data: formDataOTP,
          };

          const formData = {
            userId: userStore.user?.id,
            transactionTypeId: 1,
            fundPlanId: fundData?.scheme_code,
            fundAmount: amount,
            fundFolioNumber: fundData?.folio_number
              ? fundData?.folio_number
              : "",
            isin: fundData?.isin,
            nav: fundData?.nav,
            amcId: fundData?.amc_code,
            planName: fundData?.scheme_name,
            mfLumpsumConsentId: consentId,
          };

          const httpConfig = {
            method: "POST",
            tokenType: "user",
            headers: {
              "Content-Type": "application/json",
            },
            data: formData,
          };

          try {
            const responseOTP = await httpInstance(
              `${VERIFY_CONSENT_LUMPSUM_OTP}`,
              httpConfigOTP
            );

            if (responseOTP["data"]["responseStatus"] == "Success") {
              const createRes = await httpInstance(
                `${CREATE_LUMSUM}`,
                httpConfig
              );

              navigate(`${namespace}/mutualfund/order-summary`, {
                state: {
                  orderData: createRes.data?.data,
                  type: "lumsum",
                  schemeCode: fundData?.scheme_code,
                },
              });
            }

            // const formData2 = {
            //   userId: userStore.user?.id,
            //   paymentType: "NETBANKING",
            //   euin: createRes?.data?.data?.euin,
            //   referenceCode: "",
            //   id: createRes?.data?.data?.id,
            //   isWeb: true,
            // };
            // const httpConfig1 = {
            //   method: "POST",
            //   tokenType: "user",
            //   headers: {
            //     "Content-Type": "application/json",
            //   },
            //   data: formData2,
            // };
            // sessionStorage.removeItem("sip")
            // sessionStorage.setItem("lumsumId", createRes?.data?.data?.id);
            // const response = await httpInstance(
            //   `${INITIATE_PAYMENT}`,
            //   httpConfig1
            // );
            // window.location.replace(response.data?.data?.tokenUrl);
          } catch (error) {
            setLoading(false);
            setApiError(error.response.data?.message);
          }
        }
      } else {
        show_banner();
        setApiError(
          kycStatus == "submitted"
            ? "Your KYC is under review"
            : "Please complete your KYC"
        );
      }
    } else {
      navigate(`${namespace}/onBoard`);
    }
  };

  const handle_lumsum = async () => {
    if (
      userStore?.user?.id == "" ||
      userStore?.user?.id == "undefined" ||
      userStore?.user?.id == null
    ) {
      navigate(`${namespace}/onBoard`);
    }
    console.log("kycStatus", kycStatus);
    if (kycStatus == "successful") {
      // console.log(
      //   "fpData?.minAdditionalInvestment",
      //   fpData?.minAdditionalInvestment
      // );
      console.log("fpData?.minInitialInvestment", fpData?.minInitialInvestment);
      // console.log("fundData?.folio_number", fundData?.folio_number);
      if (
        amount <
        (fundData?.folio_number
          ? fpData?.minAdditionalInvestment
          : fpData?.minInitialInvestment)
      ) {
        setError(
          `Minimum Investment is ₹${
            fundData?.folio_number
              ? fpData?.minAdditionalInvestment
              : fpData?.minInitialInvestment
          }`
        );
      } else {
        setError("");
        setHandleOTP(1);
        const httpConfig = {
          method: "GET",
          tokenType: "user",
          headers: {
            "Content-Type": "application/json",
          },
        };

        const response = await httpInstance(
          `${GET_CONSENT_LUMPSUM_OTP}`,
          httpConfig
        );
        // console.log("response.data?.data.otp", response.data?.data.otp);
        SetOTP("");
        SetConsentId(response.data?.data.id);

        var timeLeft = 30; // set the time limit in seconds
        var timer = setInterval(function () {
          setIsTimerActive(true);
          setotpHasBeenSent(true);
          timeLeft--;
          setTimer(timeLeft);

          if (timeLeft <= 0) {
            clearInterval(timer);
            setotpHasBeenSent(false);
            setIsTimerActive(false);
          }
        }, 1000); // set the interval to 1000 milliseconds (1 second)
      }
    }
  };
  return (
    <div>
      {handleOTP == 1 ? (
        <Box m={3} p={3}>
          <label className={classes.label}>Enter OTP</label>
          <input
            className={classes.inputBox}
            type="text"
            onChange={handle_otp_value}
            value={otp}
          />
          <div className="resendOTP">
            {otpHasBeenSent ? (
              <></>
            ) : (
              <>
                <p className="otpWillRecivie">
                  OTP has been sent you will receive shortly
                </p>
              </>
            )}
            {isTimerActive ? (
              <div>
                <p className="resendOTPTimer">Resend OTP in {timer} seconds</p>
              </div>
            ) : (
              <div>
                <p className="resendOTPRequest">
                  Didn't receive OTP?{" "}
                  <a href="javascript:void" onClick={handle_lumsum}>
                    Resend OTP
                  </a>
                </p>
              </div>
            )}
          </div>
          {apiError && <ErrorMessage message={apiError} />}
          <button
            className={classes.primaryButton}
            onClick={handle_otp}
            style={{ marginTop: "10px" }}
          >
            {loading ? (
              <CircularProgress size="1rem" color="inherit" />
            ) : (
              "Verify OTP"
            )}
          </button>
          <div>{error && <ErrorMessage message={error} />}</div>
        </Box>
      ) : (
        <Box p={3}>
          <label className={classes.label}>Enter Amount</label>
          <input
            className={classes.inputBox}
            type="text"
            onChange={handle_amount}
            value={amount}
          />
          {error && <ErrorMessage message={error} />}

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={15}
          >
            {apiError && <ErrorMessage message={apiError} />}
            <button className={classes.primaryButton} onClick={handle_lumsum}>
              {loading ? (
                <CircularProgress size="1rem" color="inherit" />
              ) : (
                "Invest"
              )}
            </button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default Lumsum;
