import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
} from "@material-ui/core";
import {
  GET_BUYLIST_STATUS,
  GET_SELL_LIST_STATUS,
  GET_DELIVERY_TRANS,
  GET_TRANS_BY_STATUS,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import moment from "moment";
import { Link, useNavigate, createSearchParams } from "react-router-dom";
import { namespace } from "../../../environment";
const InvestmentTable = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "2rem 30%",
      "& .MuiPaper-elevation1": {
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;",
      },
      " & .MuiTableCell-root ": {
        padding: "10px",
      },
    },

    subTitle: {
      fontSize: "0.85rem",
      fontFamily: "Poppins",
      fontWeight: 500,
      //marginBottom:"10px"
    },

    text: {
      fontSize: "0.65rem",
      fontFamily: "Poppins",
      // marginBottom:"10px",
      fontWeight: 500,
    },
    textgrey: {
      fontSize: "0.65rem",
      fontFamily: "Poppins",
      color: "#C0C0C0",
      //marginBottom:"10px"
    },
    buttonBox: {
      background: "#EAEAEA",
      borderRadius: "8px",
      padding: "2px 5px",
    },
    primaryButton: {
      padding: "0.7rem 3rem",
      background: "#0C97F0",
      color: "#fff",
      fontSize: "0.9rem",
      fontFamily: "Poppins",
      fontWeight: 600,
      borderRadius: "5px",
      border: "none",
    },
    button: {
      background: "#EAEAEA",
      padding: "0.7rem 3rem",
      fontSize: "0.9rem",
      fontFamily: "Poppins",
      borderRadius: "5px",
      border: "none",
      cursor: "pointer",
    },

    viewText: {
      textAlign: "center",
      width: "fit-content",
      fontSize: "0.8rem",
      color: "#0C97F0",
      marginTop: "10px",
      fontWeight: 500,
      cursor: "pointer",
    },
    rupees: {
      fontFamily: "Open Sans",
    },

    "@media(max-width:991px)": {
      root: {
        padding: "2rem 2%",
      },
    },
  }));

  const classes = useStyles();

  const [value, setValue] = useState("buysell");
  const [buySellList, setBuySellList] = useState([]);
  const [sellList, setSellList] = useState([]);
  const [deliveryList, setDeliveryList] = useState([]);

  const navigate = useNavigate();

  const get_data = async () => {
    const httpConfig1 = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { type: "buy/sell", status: "SUCCESS", limit: 5, from: 0 },
    };

    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { status: "SUCCESS", limit: 5, from: 0 },
    };

    try {
      const response = await httpInstance(
        `${GET_TRANS_BY_STATUS}`,
        httpConfig1
      );
      // const successbuy= response.data?.data?.filter((ele)=>{
      //   return ele.status!="FAILED"
      // })
      setBuySellList(response.data?.data);

      //---delivery----------------------------//
      const delResponse = await httpInstance(
        `${GET_DELIVERY_TRANS}`,
        httpConfig
      );
      let delArr = delResponse?.data?.data.map((ele) => {
        return {
          id: ele.id,
          orderType: "Delivery",
          metalType: ele.deliveryGoldSilverProduct[0]?.sku?.metalType,
          createdAt: ele.createdAt,
          totalAmount: ele.deliveryGoldSilverProduct[0]?.totalAmount,
          quantity: ele.deliveryGoldSilverProduct[0]?.quantity,
        };
      });
      setDeliveryList(delArr);
    } catch {}
  };

  useEffect(() => {
    get_data();
  }, []);

  const handleValue = (data) => {
    setValue(data);
  };

  const handle_order_status = (type, id) => {
    navigate({
      pathname: `${namespace}/myportfolio/my-order`,
      search: `?${createSearchParams({
        order_type: type.toLowerCase(),
        order_id: id,
        status: "complete",
      })}`,
    });
  };

  return (
    <div>
      <Box className={classes.root}>
        <Box display="flex" justifyContent="center" mb={3}>
          <Box className={classes.buttonBox}>
            <button
              className={
                value === "buysell" ? classes.primaryButton : classes.button
              }
              onClick={() => handleValue("buysell")}
            >
              Buy/Sell
            </button>
            <button
              className={
                value === "delivery" ? classes.primaryButton : classes.button
              }
              onClick={() => handleValue("delivery")}
            >
              Delivery
            </button>
          </Box>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {(value == "buysell" ? buySellList : deliveryList)?.map((ele) => {
                return (
                  <TableRow
                    key={ele.id}
                    onClick={() => handle_order_status(ele.orderType, ele.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>
                      <Grid container>
                        <Grid item xs={4} style={{ display: "flex" }}>
                          <img
                            src={
                              ele.metalType == "gold"
                                ? "/images/image 2.svg"
                                : "/images/image 3.svg"
                            }
                            height="40px"
                            width="40px"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className={classes.subTitle}>
                            {value == "buysell"
                              ? ele.orderType == "BUY"
                                ? "Invested"
                                : "Sold"
                              : "Delivery"}
                          </Typography>
                          <Typography
                            className={classes.textgrey}
                            style={{ marginTop: "5px" }}
                          >
                            {moment(ele.createdAt).format("DD MMMM YYYY")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell align="left">
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography className={classes.textgrey}>
                            Qt.
                          </Typography>
                          {ele.orderType != "Delivery" && (
                            <Typography
                              className={classes.textgrey}
                              style={{ marginTop: "5px" }}
                            >
                              Price
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className={classes.text}>
                            {ele.quantity}g
                          </Typography>
                          {ele.orderType != "Delivery" && (
                            <Typography
                              className={classes.text}
                              style={{ marginTop: "5px" }}
                            >
                              {(ele.totalAmount / ele.quantity).toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                              /g
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell align="right" className={classes.subTitle}>
                      <span className={classes.rupees}>₹</span>
                      {ele.totalAmount.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="center">
          <Link
            to="/myportfolio/all-transactions"
            style={{ textDecoration: "none" }}
          >
            <Typography className={classes.viewText}>
              View All Transactions
            </Typography>
          </Link>
        </Box>
      </Box>
    </div>
  );
};

export default InvestmentTable;
