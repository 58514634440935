import React from 'react'
import { Box,makeStyles, Typography } from '@material-ui/core'


const FundChanges = ({data,type}) => {
    const useStyles=makeStyles({
        root:{
            boxBorder:"1px solid #DDDDDD",
            background:"#ffff",
            borderRadius:"5px"
        }
    })
    const classes=useStyles()
  return (
    <div>
        
            <Typography>{type.justification}</Typography>
            <Box mt={2}>
            {type.justification=="Trigger" ? 
                data?.proposedList?.exitTriggerFunds?.map((ele)=>{
                    return (
                        <Box key={ele.planId} className={classes.root} p={2} mt={2} display="flex" alignItems="center">
                        <img src={ele.amcLogoUrl} height="30px" width="30px" style={{marginRight:"10px"}}/>
                        <Typography>{ele.schemeName}</Typography>
                        </Box> 
                    )
                }) :
            
            <Box className={classes.root} p={2} display="flex" alignItems="center">
                <img src={data?.proposedList?.assisted?.imgUrl} height="30px" width="30px" style={{marginRight:"10px"}}/>
                <Typography>{data?.proposedList?.assisted?.name}</Typography>
            </Box>  
            }
        </Box>
    </div>
  )
}

export default FundChanges