import React,{useState,useEffect} from 'react'
import { Box,makeStyles,Table,TableRow,TableHead,TableBody,TableCell,Typography,FormControlLabel,Checkbox,Switch } from '@material-ui/core'
import {GET_ALL_ADMINS,ACTIVATE_DEACTIVATE,DELETE_ADMIN} from '../../../utils/endpoints'
import httpInstance from '../../../utils/instance'
import moment from 'moment'
import Confirmation from '../../../components/MutualFund/InfoMsg/Confirmation';
import AddUser from './AddUser'

const AdminsTable = () => {
    const useStyles=makeStyles({
        root:{
            "& .MuiCheckbox-colorPrimary.Mui-checked": {
              color: "#0C97F0",
  
            },
            "& .MuiTypography-body1":{
              fontSize:"0.85rem"
            }
          },
          primaryButton:{
            background:"#326CF9",
            color:"#fff",
            padding:"10px 2rem",
            border:"none",
            borderRadius:"20px",
            fontFamily:"inherit"
        },
        table:{
            margin:"1rem 0px",
            "& .MuiTableCell-root":{
                fontSize:"0.8rem"
            },
            "& .MuiTableHead-root":{
                background:"#F9FBFC",
                borderTopLeftRadius:" 4px",
                "& .MuiTableCell-root":{
                    color:"#C0C5D0",
    
                }
            },
            "& .MuiTableBody-root":{
                background:"#fff",
            }
        },

        switch:{
           '& .MuiSwitch-colorSecondary.Mui-checked':{
                color:"black",
                "& .MuiSwitch-track":{
                    background:"#0C97F0",
                    opacity:1
                }
           },
           "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track":{
            background:"#0C97F0",
            opacity:1
           },
           "& .MuiSwitch-switchBase":{
            color:"black",
           }
        }

    })
    const classes=useStyles()

    const [allAdmins,setAllAdmins]=useState([])
    const [showDialog,setShowDailog]=useState(false)
    const [deleteId,setDeleteId]=useState('')
    const [showAddUser,setShowAddUser]=useState(false)
    const [isSuperAdmin,setIsSuperAdmin]=useState(false)

    
   


    const get_all_admins=async()=>{
        const httpConfig = {
            method: "GET",
            tokenType: "admin",
            headers:{
              "Content-Type": "application/json",
            },
            params:{isSuperAdminOnly:isSuperAdmin}
          };

          try{
            const response=await httpInstance(`${GET_ALL_ADMINS}`,httpConfig)
            setAllAdmins(response.data?.data)
          }
          catch{

          }
    }

    useEffect(()=>{
        get_all_admins()
    },[isSuperAdmin])

    //-----------------handle is super admins only----------------------------------//
    const handle_onlySuperAdmin=(e)=>{
      setIsSuperAdmin(e.target.checked)
    }

    //--------------active/deactive admins-------------------------//
    const handleChange= async(e,id)=>{
        const httpConfig = {
            method: "POST",
            tokenType: "admin",
            headers:{
              "Content-Type": "application/json",
            },
            params:{useId:id,isActivate:e.target.checked}
          };

          try{
            await httpInstance(`${ACTIVATE_DEACTIVATE}`,httpConfig)
            get_all_admins()
          }
          catch{

          }
    }

    //--------------------delete admin------------------------------//
    const delete_admin=(id)=>{
        setDeleteId(id)
        setShowDailog(true)
    }
    const handle_delete= async()=>{
        const httpConfig = {
            method: "POST",
            tokenType: "admin",
            headers:{
              "Content-Type": "application/json",
            },
            params:{useId:deleteId}
          };

          try{
            await httpInstance(`${DELETE_ADMIN}`,httpConfig)
            get_all_admins()
            setShowDailog(false)
          }
          catch{

          }
    }


  return (
    <div>
        <Box mt={5}>
            <Box display="flex" justifyContent="space-between">
        <FormControlLabel
            control={
              <Checkbox
                //checked={cancellation}
                onChange={handle_onlySuperAdmin}
                color="primary"
              />
            }
            label="Show Super admins only"
          />
          <button className={classes.primaryButton} onClick={()=>setShowAddUser(true)}>Add Users</button>
          </Box>

          <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Role</TableCell>
                    <TableCell align="left">Phone Number</TableCell>
                    <TableCell align="left">Join Date</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {allAdmins?.map((ele)=>{
                    return(
                        <TableRow key={ele.id}>
                        <TableCell>{ele.name}</TableCell>
                        <TableCell>{ele.email}</TableCell>
                        <TableCell>{ele.role?.name}</TableCell>
                        <TableCell>{ele.phone}</TableCell>
                       <TableCell>{moment(ele.joinDate).format("DD/MM/YYYY")}</TableCell>
                       <TableCell className={classes.switch}>
                        
                        <Switch
                            checked={ele.isAdmin}
                            onChange={(e)=>handleChange(e,ele.id)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        
                        
                        </TableCell>
                        <TableCell><img src="/images/admin/delete.svg" style={{cursor:"pointer"}} onClick={()=>delete_admin(ele.id)}/></TableCell>
                        </TableRow>
                   
                    )
                })}
                        
            
            </TableBody>
         </Table>

         <Confirmation 
        show={showDialog} 
        handleClose={()=>setShowDailog(false)} 
        handle_yes={handle_delete}
        title="Delete Confirmation"
        text="Are you sure you want to delete this admin?"
        />
        </Box>

        <AddUser show={showAddUser} handleClose={()=>setShowAddUser(false)} get_all_admins={get_all_admins}/>
    </div>
  )
}

export default AdminsTable