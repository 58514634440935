import React, { useState, useEffect } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { useLocation, Link } from "react-router-dom";
import { ACTIVATE_MANDATE } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
export default function MandateProgress() {
  const [timer, setTimer] = useState(60);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [mandate, setMandate] = useState("");
  const location = useLocation();
  const useStyles = makeStyles((theme) => ({
    section1: {
      background: "#0C97F0",
      minHeight: "100vh",
      width: "30%",
      boxSizing: "border-box",
      position: "sticky",
      height: "100%",
    },
    sectionTwo: {
      height: "80vh",
      padding: "4rem 0",
      display: "flex",
      //alignItems: "center",
      //justifyContent: "center",
      flexDirection: "column",
      flex: 1,
      width: "70%",
      overflowY: "scroll",
      position: "sticky",
    },
    detailsBox: {
      margin: "0 auto",
    },
    headingOne: {
      fontSize: "1.6rem",
      color: "white",
      fontWeight: 400,
      padding: "0px 2rem",
    },
    headingTwo: {
      fontSize: "1.3rem",
      color: "#fff",
      fontWeight: 400,
      textAlign: "center",
    },
    headingThree: {
      fontSize: "0.9rem",
      color: "#fff",
      fontWeight: 400,
      textAlign: "center",
    },
    paragraph: {
      fontSize: "0.7rem",
      color: "white",
    },
    title: {
      fontSize: "1.5rem",
      fontWeight: 500,
      textAlign: "center",
    },
    "@media (max-width: 480px)": {
      section1: {
        display: "none",
      },
    },
  }));

  useEffect(() => {
    timerFunc();
  }, []);

  const getMandate = async () => {
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { mandateId: location.state.mandateId, isWeb: true },
    };

    const response = await httpInstance(`${ACTIVATE_MANDATE}`, httpConfig);
    // console.log(response.data?.data?.tokenUrl);
    window.location.replace(response.data?.data?.tokenUrl);
    // setMandate(get_mandate.data?.data?.[0]?.tokenUrl);
  };

  const timerFunc = () => {
    var timeLeft = 60; // set the time limit in seconds
    var timer = setInterval(function () {
      timeLeft--;
      setTimer(timeLeft);
      //   if (timeLeft <= 10) {
      //     getMandate();
      //   }
      if (timeLeft <= 0) {
        clearInterval(timer);
        getMandate();
      }
    }, 1000); // set the interval to 1000 milliseconds (1 second)
  };
  const classes = useStyles();
  return (
    <>
      <Box display="flex" flexDirection="row" flex={1} height="100%">
        <Box className={classes.section1} py={4}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="space-between"
            justifyContent="space-around"
            height="100%"
            px={2}
          >
            <Box className={classes.headingOne} mb={6}>
              Investment <br /> made simple
            </Box>
            <img src="/images/Group 22509.svg" height="230" width="100%" />
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="center"
              flexDirection="column"
              className={classes.detailsBox}
            >
              <Box className={classes.headingTwo} mt={3} textAlign="center">
                Invest in <span style={{ color: "#6FDDB8" }}>Mutual Funds</span>
              </Box>
              <Box className={classes.headingThree} mt={2}>
                Bullsurge is secure and private
              </Box>
              <Link
                to="/terms-conditions"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box className={classes.paragraph} mt={1}>
                  Terms & Conditions *
                </Box>
              </Link>
            </Box>
          </Box>
        </Box>
        <Box className={classes.sectionTwo}>
          <Typography className={classes.title}>
            We are working on your E-Mandate
          </Typography>
          {isTimerActive ? (
            <div>
              <p style={{ fontSize: "2rem", textAlign: "center" }}>{timer}</p>
            </div>
          ) : (
            ""
          )}
          <div style={{ textAlign: "center" }}>
            <img src="/images/mandate-working.svg" width="600" />
          </div>
        </Box>
      </Box>
    </>
  );
}
