import React from 'react'
import InvestTable from './InvestTable'
import Questionaries from './Questionaries'

const Index = () => {
  return (
    <div>
        <InvestTable/>
        <Questionaries/>
    </div>
  )
}

export default Index