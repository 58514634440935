export const DigitalData = [
  {
    title: "How do you get started? ",
    content: `Any resident Indian with a valid PAN/Form 60 and a bank account can start purchasing DigiGold on Bullsurge. Simply enter your mobile number and verify it with the OTP issued to you to register.
    `
  },
  {
    title: "Is GST included in the price quoted? ",
    content: `All of our website prices are exclusive of GST and any applicable taxes. At the last stage of checkout, the applicable GST is added to the given price.
    `
  },
  {
    title: "What is an independent trustee's role? ",
    content: `Bullsurge Partner Augmont  has the authority to act on behalf of and safeguard the interests of its clients. It will have first and exclusive charge on the bullion (i.e. Gold/Silver) you acquire. After accepting the Terms and Conditions, customers transmit real bullion to a secure vault on their behalf, which is confirmed by an Independent Trustee.
    `
  },
  {
    title: "How can I be assured of the quality?",
    content: `The consumer can be confident in the quality of the Gold Coins and Jewellery delivered by Bullsurge, because each object is hallmarked by a government-approved hallmarking agency in accordance with BIS criteria.
    `
  },
  {
    title: "Who shall bear the risk?",
    content: `Bullsurge Partner Augmont shall bear the risk of loss connected to the products until the product is delivered to the customer at the shipping address.
    `
  },
  {
    title: "How can I get my holding statement?",
    content: `You can get your gold holding statement by logging in to the Bullsurge’s Digi-Gold platform. `
  },
  {
    title:
      "How can I trust that the delivery will be made to the correct person?",
    content: `Before the delivery is attempted, the customer will be sent an OTP on the registered mobile number by the logistic partner and the same needs to be provided to the delivery person.
    Once the OTP has been verified by the delivery person, then only the package will be handed over to the customer.
    `
  }
];

export const MutualData = [
  {
    title: "What is DIY Investment Mode?",
    content: `Do it yourself is the Mutual fund investment mode where you can search and invest in any mutual fund if you have  expertise in identifying and choosing the right investment option, it is also known as self-directed investing. `
  },
  {
    title:
      "What is assisted services mode and how is it different from DIY mode?",
    content: `Assisted service mode is our auto navigation mode of mutual fund investment where the investor will give their valuable input and based on that our AI ML tool will identify the right investment portfolio and guide you to invest in that. 
    In the assisted mode the investor will get a trigger to exit from our portal to realise the gain or switch the fund from our end which helps in maximising the gain. However, In DIY mode the investor will need to make a decision on his own. 
    `
  },
  {
    title:
      "What is the minimum investment required to start in Assisted mode of investment?",
    content: `The minimum investment required is 5 Lakhs.`
  },
  {
    title: "How does portfolio diversification take place?",
    content: `Based on the input given by the investor, our AI decides the suitable funds for the investor considering the macro economic factors.`
  },
  {
    title: "How will we get the Trigger?",
    content: `In the portfolio section you will get the trigger of ‘exit’ or ‘book the gain’. In addition, you will be notified via email if you hold any funds.`
  },
  {
    title: "Is Assisted service chargeable? ",
    content: `Yes, there are annual subscription fees based on the amount you invest.`
  },
  {
    title: "How much time does it take to invest in Mutual funds on Bullsurge?",
    content: `The account opening process is completely free, paperless and easy at Bullsurge, with all the details in hand it will take 2 minutes to start investing. `
  },
  {
    title:
      "How much commission does Bullsurge charge on Mutual Funds investment?",
    content: `There is 0% commission on mutual funds investment through Bullsurge.`
  }
];
