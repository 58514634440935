import {
  Box,
  MenuItem,
  Select,
  FormControl,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useEffect, useState } from "react";
import TextInputField from "../Input/TextInputField";
import TitleLayoult from "../TitleLayoult";
import { useStyles } from "../styles";
import { useForm, Controller } from "react-hook-form";
import ErrorMessage from "../../Validation/ErrorMessage";
import {
  GET_ALL_WEALTH_SOURCE,
  GET_ALL_COUNTRIES,
  ADD_FATCA_DETAILS,
  GET_FATCA_DETAILS,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams } from "react-router-dom";
import { namespace } from "../../../environment";

function FatcaDetails() {
  const classes = useStyles();

  const defaultValues = {
    income: "",
    taxAnotherCountry: "",
    taxCountry: "",
    country: "",
    typeOfTax: "",
    taxNumber: "",
    SourceIncome: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({ defaultValues });

  const navigate = useNavigate();

  const [sourceIncome, setSourceIncome] = useState([]);
  const [countries, setCountries] = useState([]);
  const [taxAnotherCountry, setTaxAnotherCountry] = useState(false);

  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        input: {
          padding: "8px 10px",
        },
      },
      MuiSelect: {
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  });

  const resetData = (data) => {
    reset({
      income: data.grossAnnualIncome,
      taxAnotherCountry: data.haveAnotherTaxResidence ? "Yes" : "No",
      taxCountry: data.taxResidencyCountry?.id,
      country: data.countryOfBirth.id,
      typeOfTax: data.typeOfTaxIdentification,
      taxNumber: data.taxIdentificationNum,
      SourceIncome: data.fatcaSourceOfWealth?.id,
    });
    setTaxAnotherCountry(data.haveAnotherTaxResidence);
  };

  useEffect(async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const [incomesource, countries] = await Promise.all([
        httpInstance(`${GET_ALL_WEALTH_SOURCE}`, httpConfig),
        httpInstance(`${GET_ALL_COUNTRIES}`, httpConfig),
      ]);

      setSourceIncome(incomesource?.data?.data);
      setCountries(countries?.data?.data);

      const fatca = await httpInstance(`${GET_FATCA_DETAILS}`, httpConfig);
      resetData(fatca?.data?.data);
    } catch (error) {}
  }, []);

  const handletaxAnotherCountry = (e) => {
    if (e.target.value == "Yes") {
      setTaxAnotherCountry(true);
    } else {
      setTaxAnotherCountry(false);
    }
  };

  const submit_fatca_details = async (data) => {
    const formData = {
      grossAnnualIncome: data.income,
      haveAnotherTaxResidence: data.taxAnotherCountry === "Yes" ? true : false,
      taxResidencyCountryId: data.taxCountry ? data.taxCountry : null,
      countryOfBirthId: data.country,
      typeOfTaxIdentification: data.typeOfTax ? data.typeOfTax : null,
      taxIdentificationNum: data.taxNumber ? data.taxNumber : null,
      fatcaSourceOfWealthId: data.SourceIncome,
    };

    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      await httpInstance(`${ADD_FATCA_DETAILS}`, httpConfig);
      navigate({
        pathname: `${namespace}/signIn`,
        search: `?${createSearchParams({ stepcount: "8" })}`,
      });
    } catch {}
  };

  const handleback = () => {
    navigate({
      pathname: `${namespace}/signIn`,
      search: `?${createSearchParams({ stepcount: "6" })}`,
    });
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={5}
        className={classes.backButton}
        onClick={handleback}
      >
        <img src="/images/backIcon.svg" />
      </Box>
      <Box display={"flex"} justifyContent="center">
        <Box alignItems="flex-start" flexDirection={"column"}>
          <Box sx={{ width: "100%" }}>
            <TitleLayoult
              title="Fatca Details"
              description="Please enter your fatca details "
            />
          </Box>
          <Box>
            <form onSubmit={handleSubmit(submit_fatca_details)}>
              <Box my={1}>
                <lable className={classes.rupeesSymbol}>₹</lable>
                <TextInputField
                  name="income"
                  label="Annual Income"
                  placeholder="In terms of 1000s"
                  register={register}
                  inputPattern={/^[0-9]+$/}
                />
                {errors.income && errors.income.type === "required" && (
                  <ErrorMessage message="Enter Annual income" />
                )}
                {errors.income && errors.income.type === "pattern" && (
                  <ErrorMessage message="Enter valid income" />
                )}
              </Box>

              <ThemeProvider theme={theme}>
                <Box
                  my={2}
                  //display="flex"
                  alignItems={"flex-start"}
                  justifyContent="flex-start"
                  flexDirection="column"
                  width={"100%"}
                >
                  <label className={classes.label}>Source of Income</label>
                  <Box width={"100%"} className={classes.Select}>
                    <FormControl fullWidth className={classes.Select}>
                      <Controller
                        control={control}
                        name={`SourceIncome`}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            disableUnderline
                            className={classes.selectItem}
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                              style: {
                                top: 5,
                                minWidth: 200,
                              },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            {sourceIncome &&
                              sourceIncome.map((ele) => {
                                return (
                                  <MenuItem
                                    key={ele.id}
                                    className={classes.menuItem}
                                    value={ele.id}
                                  >
                                    {ele.type}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Box>
                  {errors.SourceIncome && (
                    <ErrorMessage message="Please Select Source of Income" />
                  )}
                </Box>
              </ThemeProvider>

              <ThemeProvider theme={theme}>
                <Box
                  my={1}
                  display="flex"
                  alignItems={"flex-start"}
                  justifyContent="flex-start"
                  flexDirection="column"
                  width={"100%"}
                >
                  {" "}
                  <label className={classes.label}>Country of Birth</label>
                  <Box width={"100%"} className={classes.Select}>
                    <FormControl fullWidth className={classes.Select}>
                      <Controller
                        control={control}
                        name={`country`}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            disableUnderline
                            className={classes.selectItem}
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                              style: {
                                maxHeight: 300,
                                top: 5,
                                minWidth: 200,
                              },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            {countries &&
                              countries?.map((ele) => {
                                return (
                                  <MenuItem
                                    key={ele.id}
                                    className={classes.menuItem}
                                    value={ele.id}
                                  >
                                    {ele.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Box>
                </Box>
                {errors.country && (
                  <ErrorMessage message="Please Select Country of Birth" />
                )}
              </ThemeProvider>
              <Box
                display="flex"
                flexDirection="column"
                alignItems={"flex-start"}
                justifyContent="flex-start"
                width={"100%"}
              >
                <Box my={1}>
                  <Box my={1} className={classes.label}>
                    Tax Residence of another Country?
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <input
                        type="radio"
                        height="16px"
                        width="16px"
                        name="fillType"
                        style={{
                          height: "16px",
                          width: "16px",
                          margin: 0,
                          outline: "none",
                        }}
                        value="Yes"
                        {...register("taxAnotherCountry", {
                          required: true,
                          onChange: (e) => handletaxAnotherCountry(e),
                        })}
                      />
                      <Box fontSize={12} fontWeight={500} ml={1}>
                        Yes
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      ml={6}
                    >
                      <input
                        type="radio"
                        height="16px"
                        width="16px"
                        name="fillType"
                        style={{
                          height: "16px",
                          width: "16px",
                          margin: 0,
                          outline: "none",
                        }}
                        value="No"
                        {...register("taxAnotherCountry", {
                          required: true,
                          onChange: (e) => handletaxAnotherCountry(e),
                        })}
                      />
                      <Box fontSize={12} fontWeight={500} ml={1}>
                        No
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {errors.taxAnotherCountry && (
                  <ErrorMessage message={"Please select"} />
                )}
              </Box>

              {taxAnotherCountry && (
                <div>
                  <ThemeProvider theme={theme}>
                    <Box
                      my={1}
                      display="flex"
                      alignItems={"flex-start"}
                      justifyContent="flex-start"
                      flexDirection="column"
                      width={"100%"}
                    >
                      {" "}
                      <label className={classes.label}>
                        Country of Tax Residence
                      </label>
                      <Box width={"100%"} className={classes.Select}>
                        <FormControl fullWidth className={classes.Select}>
                          <Controller
                            control={control}
                            name={`taxCountry`}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                disableUnderline
                                className={classes.selectItem}
                                classes={{ paper: classes.selectPaper }}
                                IconComponent={KeyboardArrowDownIcon}
                                MenuProps={{
                                  style: {
                                    maxHeight: 300,
                                    top: 5,
                                    minWidth: 200,
                                  },
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                              >
                                {countries &&
                                  countries?.map((ele) => {
                                    return (
                                      <MenuItem
                                        key={ele.id}
                                        className={classes.menuItem}
                                        value={ele.id}
                                      >
                                        {ele.name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                    {errors.taxCountry && (
                      <ErrorMessage message="Please Select Country" />
                    )}
                  </ThemeProvider>
                  <Box my={1}>
                    <TextInputField
                      name="typeOfTax"
                      label="Type of Tax Identification"
                      placeholder=""
                      register={register}
                    />
                    {errors.typeOfTax && (
                      <ErrorMessage message="Enter Type of Tax Identification" />
                    )}
                  </Box>

                  <Box my={1}>
                    <TextInputField
                      name="taxNumber"
                      label="Tax Identification Number"
                      placeholder=""
                      register={register}
                    />
                    {errors.taxNumber &&
                      errors.taxNumber.type === "required" && (
                        <ErrorMessage message="Enter Tax Identification Number" />
                      )}
                    {errors.taxNumber &&
                      errors.taxNumber.type === "pattern" && (
                        <ErrorMessage message="Enter valid Number" />
                      )}
                  </Box>
                </div>
              )}
              <Box mt={2}>
                <button className={classes.primaryButton} type="submit">
                  Next
                </button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default FatcaDetails;
