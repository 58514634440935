import React from 'react'
import Dashboard from '../../containers/Admin/Dashboard'

const Admin_dashboard = () => {
  return (
    <div>
        <Dashboard/>
    </div>
  )
}

export default Admin_dashboard