import React from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/Footer'
import Delivery from '../../containers/DigitalGold/Delivery/index'

const GoldDelivery = () => {
  return (
    <div>
         {/* <Header/> */}
            <Delivery/>
        {/* <Footer/> */}
    </div>
  )
}

export default GoldDelivery