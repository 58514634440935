import React, { useState, useEffect } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { IS_EXPIRED, NEW_TOKEN } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { namespace } from "../../../environment";
const authOtp = () => {
  const otpVerified = sessionStorage.getItem("admin_otp");

  if (otpVerified) {
    return true;
  } else {
    return false;
  }
};
//------------------token expire check------------------------------------------//
const userTokenStatus = async () => {
  let status;
  const token = localStorage.getItem("admin_token");
  const httpConfig = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: { refreshToken: token },
  };
  try {
    const response = await httpInstance(`${IS_EXPIRED}`, httpConfig);
    if (response) {
      return true;
    }
  } catch (error) {
    //status= get_new_token()
    if (error.response) {
      return false;
    }
  }
};

// const get_new_token= async ()=>{
//  let status

//  const token=localStorage.getItem("admin_refresh_token")

//  const httpConfig = {
//    method: "POST",
//    headers:{
//      "Content-Type": "application/json",
//    },
//    data:{refreshToken:token}
//  };
// try{
//  const response= await httpInstance(`${NEW_TOKEN}`,httpConfig)
//  if(response.data){
//    localStorage.setItem("admin_token",response.data?.data?.accessToken)
//    return true

//  }

// }
// catch(error){
//    if(error.response){
//      return false
//    }
// }

// }

const AuthAdmin = async () => {
  const token = localStorage.getItem("admin_token");
  const isExpired = userTokenStatus();
  if (token) {
    if (await isExpired) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const AdminProtect = () => {
  const opt_auth = authOtp();

  const [auth, setAuth] = useState(false);
  const [load, setLoad] = useState(true);

  const location = useLocation();

  useEffect(async () => {
    setAuth(await AuthAdmin());
    setLoad(false);
  }, [location.path]);

  return opt_auth ? (
    load ? null : auth ? (
      <Outlet />
    ) : (
      <Navigate to={`${namespace}/admin/login`} />
    )
  ) : (
    <Navigate to={`${namespace}/admin/login`} />
  );
};

export default AdminProtect;
