import React, { useState } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import OtpInput from "react-otp-input";
import { ADMIN_EMAIL, EMAIL_VERIFY } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import { namespace } from "../../../environment";
const Index = () => {
  const useStyles = makeStyles({
    root: {
      background: "#F5F5F5",
      height: "100%",
    },
    container: {
      background: "#fff",
      borderRadius: "10px",
    },
    rootHeader: {
      background: "rgba(85, 110, 230, 0.25098)",
      color: "#556EE6",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
    },

    header: {
      fontSize: "1.3rem",
      fontWeight: 500,
    },
    circularDiv: {
      position: "absolute",
      borderRadius: "50%",
      background: "#EFF2F7",
      padding: "1rem 1.2rem",
      marginTop: "-20px",
      marginLeft: "4rem",
    },
    emailDiv: {
      borderRadius: "50%",
      background: "#EFF2F7",
      padding: "1.2rem 1.2rem",
      display: "flex",
      marginBottom: "15px",
    },
    inputBox: {
      background: "#E6E6E6",
      padding: "0.6rem 0.6rem",
      borderRadius: "10px",
      border: "none",
      //border: "1px solid #CED4DA",
      fontFamily: "inherit",
      width: "90%",
      marginBottom: "10px",
    },
    label: {
      fontSize: "0.85rem",
      marginBottom: "5px",
    },
    primaryButton: {
      padding: "0.8rem 5rem",
      background: "#326CF9",
      color: "#fff",
      fontSize: "0.85rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      fontFamily: "inherit",
    },
    text: {
      color: "#495057",
      fontSize: "0.85rem",
    },
    resendText: {
      color: "#556EE6",
      cursor: "pointer",
    },
    msgText: {
      fontSize: "0.7rem",
      color: "#48D4A5",
      marginRight: "5px",
    },
  });
  const classes = useStyles();

  const navigate = useNavigate();

  const [comp, setComp] = useState("login");
  const [email, setEmail] = useState("");
  const [emailResp, setEmailRes] = useState();
  const [otp, setOtp] = useState("");
  const [otpResendMsg, setOptResendMsg] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [otpError, setOtpError] = useState(false);

  const handle_email_change = (e) => {
    setEmail(e.target.value);
  };

  const handle_continue = async (e) => {
    e.preventDefault();
    if (
      /^([a-zA-Z0-9._“”\-])+\@(([\[a-zA-Z0-9])+\.)+([a-zA-Z0-9\]]{2,7})+$/.test(
        email
      )
    ) {
      setEmailError(false);
      const httpConfig = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: { emailId: email },
      };

      try {
        const response = await httpInstance(`${ADMIN_EMAIL}`, httpConfig);
        setEmailRes(response.data?.data);
        comp == "otp" && setOptResendMsg(response.data?.message);
        setComp("otp");
      } catch (error) {
        setEmailError(error.response.data?.message);
      }
    } else {
      setEmailError("Please enter valid email");
    }
  };
  //----------------handle otp----------------------
  const handleChange = (code) => setOtp(code);

  const handle_otp_verify = async (e) => {
    e.preventDefault();
    setOtpError(false);
    const httpConfig = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        emailAddress: email,
        userOTP: otp,
        sessionId: emailResp.sessionId,
        creationTime: emailResp.creationTime,
      },
    };

    try {
      const response = await httpInstance(`${EMAIL_VERIFY}`, httpConfig);
      localStorage.setItem("admin_token", response.data?.data?.accessToken);
      // localStorage.setItem(
      //   "admin_refresh_token",
      //   response.data?.data?.refreshToken
      // );
      localStorage.setItem("admin_userId", response.data?.data?.user?.id);
      sessionStorage.setItem("admin_otp", true);
      navigate(`${namespace}/admin/dashboard`);
    } catch (error) {
      setOtpError(error.response.data?.message);
    }
  };
  return (
    <div className={classes.root}>
      <Box px={40} py={10}>
        {comp == "login" ? (
          <Box className={classes.container}>
            <div className={classes.rootHeader}>
              <Box display="flex" justifyContent="space-between" px={2}>
                <Box p={5}>
                  <Typography className={classes.header}>Welcome </Typography>
                  <Typography>to neosurge Admin Panel</Typography>
                </Box>
                <img
                  src="/images/admin/Rectangle 5.svg"
                  height="200px"
                  width="200px"
                />
              </Box>
            </div>
            <div className={classes.circularDiv}>
              <img src="/images/admin/logo.svg" height="40px" width="40px" />
            </div>

            <Box display="flex" justifyContent="center">
              <Box width="50%" mt={10}>
                <form onSubmit={handle_continue}>
                  <label className={classes.label}>Email ID</label>
                  <input
                    className={classes.inputBox}
                    onChange={handle_email_change}
                  />
                  {emailError && <ErrorMessage message={emailError} />}
                  <Box display="flex" justifyContent="center" mt={2} mb={10}>
                    <button className={classes.primaryButton} type="submit">
                      Next
                    </button>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        ) : (
          //--------------------otp screen--------------------------------------------//
          <>
            <Box className={classes.container}>
              <form onSubmit={handle_otp_verify}>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  mt={10}
                  pt={10}
                >
                  <div className={classes.emailDiv}>
                    <img src="/images/admin/email.svg" />
                  </div>

                  <Typography className={classes.header}>
                    Verify your email
                  </Typography>
                  <Typography className={classes.label}>
                    {`Please enter the 6 digit code sent to ${email}`}
                  </Typography>
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    separator={<span style={{ width: "8px" }}></span>}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    inputStyle={{
                      border: "none",
                      borderRadius: "8px",
                      width: "40px",
                      height: "40px",
                      fontSize: "13px",
                      color: "#000",
                      fontWeight: "400",
                      caretColor: "blue",
                      background: "#E6E6E6",
                      marginTop: "15px",
                    }}
                    focusStyle={{
                      border: "1px solid #CFD3DB",
                      outline: "none",
                    }}
                  />
                  {otpError && <ErrorMessage message={otpError} />}
                  <Box display="flex" justifyContent="center" mt={4} mb={6}>
                    <button
                      className={classes.primaryButton}
                      //onClick=
                      type="submit"
                    >
                      Confirm
                    </button>
                  </Box>
                </Box>
              </form>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              mt={2}
              flexDirection="column"
              alignItems="center"
            >
              <Typography className={classes.text}>
                Didn't receive a code ?{" "}
                <span className={classes.resendText} onClick={handle_continue}>
                  Resend
                </span>
              </Typography>
              <Typography className={classes.msgText}>
                {otpResendMsg}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </div>
  );
};

export default Index;
