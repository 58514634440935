import React, { useState,useEffect } from "react";
import { Box, Container, makeStyles, Typography,Select,
  FormControl,
  MenuItem  } from "@material-ui/core";
  import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  
} from "recharts";
import moment from "moment";
import { GET_ALL_GRAPH_VALUE,GET_FILTERED_GRAPH_VALUES,GET_GOLD_SILVER_RATE } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import ReactLoading from 'react-loading';

function Graph({tabData}) {
  const useStyles = makeStyles((theme) => ({
    graphButtonInactive: {
      border: "none",
      background: "none",
      padding:"5px",
      borderRadius:"10px",
      fontSize: "0.7rem",
      cursor:"pointer",
      outline:"none",
      "&:hover":{
        background:"#6FDDB8",
        color:"white",
        padding:"5px",
        },
        "&:active":{
          background:"#6FDDB8",
          color:"white"
          }
    },

    activeButton:{
      background:"#6FDDB8",
      color:"white",
      padding:"5px",
      border: "none",
      borderRadius:"10px",
      fontSize: "0.7rem",
      cursor:"pointer",
      outline:"none",
    },

    tootipBox: {
      boxShadow: "0px 4.45122px 4.45122px rgba(0, 0, 0, 0.12)",
      borderRadius: "13px",
      background: "white",
    },

    tooltipText: {
      fontSize: "0.7rem",
      marginBottom: "3px",
      padding: "0px 5px",
    },

    livetext:{
      fontSize: "1.1rem",
      color:"#0C97F0",
      fontFamily:"inherit"
     
    },

    select: {
      fontSize: "1.1rem",
      color:"#0C97F0",
      padding:"3px 0px",
      borderRadius: "10px",
      boxShadow: "0 0 8px -3px #444444",
      "& .MuiSelect-select": {
        paddingRight:"40px",
        paddingLeft:"10px",
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
      "& .MuiSvgIcon-root": {
        color: "#0C97F0",
        fontSize:"2rem"
      },
      "& .MuiSelect-icon":{
        top:4,
        
      },
      line:{
        border: "1px solid red"
      }
    },

    spinner:{
      width:"600px",
      height:"300px"
    },
    rupees:{
      fontFamily:"Open Sans"
    },
    "@media (max-width: 480px)": {
      spinner:{
        width:"100px",
        height:"300px"
      }, 
    }

  }));

  const classes = useStyles();

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box display="flex" className={classes.tootipBox}>
          <p className={classes.tooltipText}>
            {" "}
            <span className={classes.rupees}>₹</span> {metal=="gold" ? (tabData=="buy" ? payload[0].payload.goldBuyRate : payload[0].payload.goldSellRate ) : (tabData=="buy" ? payload[0].payload.silverBuyRate :payload[0].payload.silverSellRate ) }
          </p>
          <p className={classes.tooltipText}>
            {moment(payload[0].payload.onDate).format("DD MMM YYYY")}
          </p>
        </Box>
      );
    }

    return null;
  };

  const [data, setData] = useState([]);
  const [goldRate,setGoldRate]=useState('')
  const [goldSell,setGoldSell]=useState('')
  const [silverRate,setSilverRate]=useState('')
  const [silverSell,setSilverSell]=useState('')
  const [metal,setMetal]=useState("gold")
  const [button,setButton]=useState(12)
  const [spinner,setSpinner]=useState(true)


  useEffect(()=>{
    getData()
    get_filteredData(12)
  },[])

  const getData = async () => {
    setSpinner(true)

  var requestOptions = {
    method: 'GET',
    
    
  };

  const httpConfig1 = {
    method: "POST",
    headers:{
      "Content-Type": "application/json",
    },
  };
    try{
      // const agm=await fetch(
      //   `https://www.augmont.com/api/digital-gold/rates`,requestOptions
      // )

      
     //const allRates=await agm.json()
     const allRates= await httpInstance(`${GET_GOLD_SILVER_RATE}`,httpConfig1)
    
    setGoldRate(allRates?.data?.data?.goldBuyRate)
    setGoldSell(allRates?.data?.data?.goldSellRate)
    setSilverRate(allRates?.data?.data?.silverBuyRate)
    setSilverSell(allRates?.data?.data?.silverSellRate)
    setSpinner(false)
   
    }
    catch(error){
      
    }
    
  };

  const handleSelectChange=(e)=>{
    setMetal(e.target.value)
  }

  const get_allData = async () => {
    setButton("all")
    const httpConfig = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_ALL_GRAPH_VALUE}`, httpConfig);
      const rateData = response.data?.data?.map((ele, i) => {
        return { ...ele, xid: i, dateNum: new Date(ele.onDate).getTime() };
      });
      setData(rateData);
    } catch {}
  };

  const get_filteredData=async(range)=>{
    setButton(range)
    setSpinner(true)
    const fromDate=moment().subtract(range, 'months').format('YYYY-MM-DD HH:MM:SS')
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params:{
        from:fromDate,
        to:moment().format('YYYY-MM-DD HH:MM:SS')
      }
    };

    try{
      const response= await httpInstance(`${GET_FILTERED_GRAPH_VALUES}`,httpConfig)
      const rateData=response.data?.data?.map((ele,i)=>{
        return { ...ele, xid: i}
      })
      setData(rateData);
      setSpinner(false)
    }
    catch{

    }

  }

  return (
    <div>
      <Box justifyContent="center" px={{xs:1,md:35}}>
        
        <Box py={8}>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.livetext}>Live Rates :<span style={{color:"black",fontSize:"15px"}}>{metal=='gold' ? (tabData=="buy" ?( goldRate*10).toFixed(2): (goldSell*10).toFixed(2))  : (tabData=="buy" ? (silverRate*10).toFixed(2): (silverSell*10).toFixed(2))}/10gms</span></Typography>

         <Box px={3}>
        <Select
          value={metal}
          onChange={handleSelectChange}
         className={classes.select}
         disableUnderline
         IconComponent={KeyboardArrowDownIcon}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          MenuProps={{
            style: {
                maxHeight: 350, 
                top:3                     
              },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
        >
          
          <MenuItem className={classes.livetext} value={"gold"}>Gold</MenuItem>
          <MenuItem className={classes.livetext} value={"silver"}>Silver</MenuItem>
         
        </Select> 
        </Box> 
     
        </Box> 

        <Box py={10} >
          <Box pr={5} >
          {spinner ? 
          <Box  display="flex" justifyContent="center" className={classes.spinner}><ReactLoading type="spinningBubbles" color="	#0C97F0" height={'5%'} width={'5%'} /></Box> :
          <ResponsiveContainer width="100%" height={250}>
          <LineChart
            //width={600}
            height={300}
            connectNulls={true}
            data={data}
            
            
          >
            <Tooltip
              content={<CustomTooltip />}
              position={{ x: "auto", y: 0 }}
            />
            
             
            
        
              <Line
                data={data}
                type="monotone"
                connectNulls={false}
                dataKey={metal==="gold" ?( tabData=="buy" ? 'goldBuyRate' : "goldSellRate"): (tabData=="buy" ? "silverBuyRate" : "silverSellRate")}
                stroke={"#0C97F0"}
                activeDot={{ r: 8, color: "#ef731f" }}
                dot={false}
                //className={props?.data?.amc?.amcShortName}
                //type="number"
                strokeWidth={2}
               
              />
             
              
            <XAxis
              interval={1000}
              dataKey="xid"
              //interval={0}
              type="number"
              tick={false}
              tickLine={false}
              axisLine={false}
              domain={["dataMin", "dataMax + 1"]}
              allowDuplicatedCategory={false}
            />
            
            
            <YAxis
              allowDuplicatedCategory={false}
              //yAxisId="left"
              // dataKey="baseValue"
              tick={false}
              axisLine={false}
              domain={["dataMin", "dataMax + 1"]}
            />
        
      
          </LineChart> 
          </ResponsiveContainer>
          } 
          
          </Box>
          {data && 
          <Box style={{borderBottom:"1px solid #0D87F4",height:"30px"}} display="flex" justifyContent="space-between">
          
           <p className={classes.tooltipText}>{moment(data[0]?.onDate).format("DD MMM YYYY")}</p>
           <p className={classes.tooltipText}>{moment(data[data.length-1]?.onDate).format("DD MMM YYYY")}</p>
           
          </Box>
          }
          <Box display="flex" justifyContent="space-between" px={15} mt={2}>
            <button
              className={button==6 ? classes.activeButton : classes.graphButtonInactive}
              onClick={()=>{get_filteredData(6)}}
            >
              6M
            </button>
            <button
              className={button==12 ? classes.activeButton :classes.graphButtonInactive}
              onClick={()=>{get_filteredData(12)}}
            >
              1Y
            </button>
            <button
              className={button==36 ? classes.activeButton :classes.graphButtonInactive}
              onClick={()=>{get_filteredData(36)}}
            >
              3Y
            </button>
            <button
              className={button==60 ? classes.activeButton :classes.graphButtonInactive}
              onClick={()=>{get_filteredData(60)}}
            >
              5Y
            </button>
            <button
              className={button=="all" ? classes.activeButton :classes.graphButtonInactive}
              onClick={get_allData}
            >
              All
            </button>
          </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default Graph;
