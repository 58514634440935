import React from 'react'
import { Box,makeStyles,Grid,Typography} from '@material-ui/core'

const Questionare = ({data,type}) => {
    const useStyles = makeStyles({
        root: {
          background: "#fff",
          borderRadius: "10px",
        },
        title: {
          fontSize: "0.9rem",
          fontWeight: 600,
          color: "#686868",
        },
        answer: {
            margin: "3px 0px",
          },
    })

    const classes = useStyles();


  return (
    <div>
        <Box
              className={classes.root}
              px={5}
              py={2}
              mt={2}
            >
              <Grid container>
                <Grid item xs={10}>
                
                  <Typography className={classes.title}>
                    {data?.question}
                 </Typography>
                

                </Grid >
                
                <Grid item xs={2}>
                <Box display="flex" alignItems="center" justifyContent="end">
                
                  
                </Box>
                </Grid>
                
               
              </Grid>
              <Box mt={3}>
                <ol>
                  {data?.choices?.map((answer, i) => {
                    return (
                      <li>
                        <Box key={answer.id}>
                        

                          <Grid container spacing={2}>
                            <Grid item xs={10} className={classes.answer}>
                            
                               {answer.name}
                              
                            </Grid>
                            <Grid item xs={2} className={classes.answer}>
                              <Box display="flex" justifyContent="end" alignItems="center">
                               
                                 {answer.weight} Points
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </li>
                    );
                  })}
                </ol>
              </Box>
             
            </Box>
        
        

       
      
    </div>
  )
}

export default Questionare