import React, { useState, useEffect } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { IS_EXPIRED, NEW_TOKEN } from "../../utils/endpoints";
import httpInstance from "../../utils/instance";
import { namespace } from "../../environment";

const userTokenStatus = async () => {
  let status;
  const token = localStorage.getItem("user_token");
  const httpConfig = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: { refreshToken: token },
  };
  try {
    const response = await httpInstance(`${IS_EXPIRED}`, httpConfig);
    if (response) {
      return true;
    }
  } catch (error) {
    //status= get_new_token()
    if (error.response) {
      return get_new_token();
    }
  }
};

const get_new_token = async () => {
  let status;

  const token = localStorage.getItem("refresh_token");

  const httpConfig = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: { refreshToken: token },
  };
  try {
    const response = await httpInstance(`${NEW_TOKEN}`, httpConfig);
    if (response.data) {
      localStorage.setItem("user_token", response.data?.data?.accessToken);
      return true;
    }
  } catch (error) {
    if (error.response) {
      return false;
    }
  }
};

const auseAuth = async () => {
  const token = localStorage.getItem("user_token");
  const isExpired = userTokenStatus();
  if (token) {
    if (await isExpired) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const ProtectedRoutes = () => {
  const location = useLocation();

  const [auth, setAuth] = useState(false);
  const [load, setLoad] = useState(true);

  useEffect(async () => {
    setAuth(await auseAuth());
    setLoad(false);
  }, [location.path]);

  return load ? null : auth ? (
    <Outlet />
  ) : (
    <Navigate to={`${namespace}/onBoard`} />
  );
};

export default ProtectedRoutes;
