import React from 'react'
import {Box,Typography,makeStyles,Grid,IconButton,CircularProgress} from '@material-ui/core'
import Avatar from 'react-avatar'
import { useSelector,useDispatch } from "react-redux";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import {UPLOAD_PROFILE_PHOTO} from '../../../utils/endpoints'
import httpInstance from '../../../utils/instance'
import { uploadProfilePic} from "../../../redux/User/userActions";
import {BASE_URL} from '../../../environment'
import { useState } from 'react';


const Index = () => {
    const useStyles = makeStyles((theme) => ({
        root:{
        },
        header:{
            fontWeight:500,
            fontSize:"1.3rem"
        },
        inputBox: {
            background: "#E6E6E6",
            padding: "0.6rem 0.6rem",
            borderRadius: "10px",
            border: "none",
            fontFamily:"inherit",
            width:"90%",
            marginBottom:"10px"
          },
          text: {
            fontSize: "0.8rem",
            textAlign: "center",
            fontFamily:"inherit",
            marginTop:"10px",
            fontWeight:500
          },

          box1:{
            width:"50%"
          },
          "@media (max-width: 767px)":{
            box1:{
                width:"90%"
              }
          },
          cameraIcon:{
            height:"35px",
            width:"35px",
            background:"#0C97F0",
            borderRadius:"50%",
            position:"relative",
            top:-35,
            left:65,
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            cursor:"pointer"
          },

          loader:{
            position:"relative",
            display:"flex",
            left:38,
            top:70
          },
          loaderActive:{
            position:"relative",
            visibility:"hidden",
            display:"flex",
            left:38,
            top:70
          }
    }))
    const classes=useStyles()

    const [loading,setLoading]=useState(false)

    const {adminStore}=useSelector((state)=>state)
    const dispatch=useDispatch()

    const handle_photo= async(e)=>{
      setLoading(true)
      const file=e.target.files[0]

      const formData=new FormData()
      formData.append('profile_photo',file)
  
      const httpConfig = {
        method: "POST",
        tokenType: "admin",
        headers: {
          "Content-Type": "application/json",
        },
        data:formData
      };
      try{
        const response=await httpInstance(`${UPLOAD_PROFILE_PHOTO}`,httpConfig)
        dispatch(uploadProfilePic(response.data?.data?.profile_image))
        setLoading(false)
        
      }
      catch{
  
      }
    
      
    }

   

  return (
    <div>
        <Box px={{xs:2,md:20}} mt={3} display="flex" alignItems="center" flexDirection="column">
        <Typography className={classes.header}>Account Details</Typography>
      
        <Box mt={2} >
        {<CircularProgress className={loading ? classes.loader : classes.loaderActive} size="2rem" color="primary"/>}
       {adminStore.Adminuser?.profile_image ? 
    
       <Avatar src={`${BASE_URL}${adminStore.Adminuser?.profile_image}`} size="110" round={true} textSizeRatio={2} /> 
      
       :
       <Avatar name={adminStore.Adminuser?.userName?.charAt(0)} size="110" round={true} textSizeRatio={2} />
        
      } 

    
        <Box className={classes.cameraIcon}>
       
        <IconButton color="primary" aria-label="upload picture" component="label">
       
            <input hidden accept="image/*" type="file" onChange={handle_photo} />
        <PhotoCameraIcon sx={{ color:"white",height:"20px"}}/>
        </IconButton>
            

        </Box>
        </Box>
        <Box className={classes.box1} mt={2}>
           {adminStore.Adminuser?.userName && 
           <>
            <label className={classes.text}>Name</label><br/>
            <input 
                className={classes.inputBox}
                value={adminStore.Adminuser?.userName}
                disabled
            /><br/>
            </>
           }
            <label className={classes.text}>Email</label><br/>
            <input 
                className={classes.inputBox}
                value={adminStore.Adminuser?.emailId}
                disabled
            /><br/>
            {adminStore.Adminuser?.mobileNumber && 
            <>
            <label className={classes.text}>Mobile Number</label><br/>
            <input 
                className={classes.inputBox}
                value={adminStore.Adminuser?.mobileNumber}
                disabled
            />
            </>
          }
        </Box>
        </Box>
    </div>
  )
}

export default Index