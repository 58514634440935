import React, { useState, useEffect } from "react";
import Investment from "../../../components/DashBoard/DigitalGold/Investment";
import { Box, Typography, makeStyles } from "@material-ui/core";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Transactions from "./Transactions";
import { useNavigate, createSearchParams, Link } from "react-router-dom";
import { GET_GOLD_SILVER_RATE } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useSelector, useDispatch } from "react-redux";
import { getGoldUser } from "../../../redux/User/userActions";
import OrderprogressCard from "../../../components/Portfolio/DigitalGold/OrderprogressCard";
import OrderStatus from "./OrderStatus";
import EmptyPortPolio from "./EmptyPortPolio";
import { namespace } from "../../../environment";

const Index = () => {
  const useStyles = makeStyles((theme) => ({
    primaryButton: {
      padding: "0.8rem 7rem",
      background: "#326CF9",
      color: "#fff",
      fontSize: "0.8rem",
      fontFamily: "Poppins",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      // marginRight:"2rem"
    },

    secondaryButton: {
      padding: "0.2rem 0.6rem",
      background: "#fff",
      color: "black",
      fontSize: "0.8rem",
      fontFamily: "Poppins",
      fontWeight: 400,
      borderRadius: "45px",
      border: "1px solid black",
      cursor: "pointer",
    },
    calender: {
      marginRight: "5px",
    },
    header: {
      fontFamily: "Poppins",
    },

    "@media (max-width:480px)": {
      primaryButton: {
        padding: "0.5rem 0.5rem",
        marginRight: "0px",
      },
      secondaryButton: {
        padding: "0.3rem 0.5rem",
        marginTop: "10px",
        width: "100%",
      },
    },
  }));

  const classes = useStyles();

  const navigate = useNavigate();
  //---redux------------------------------------------//
  const { userStore } = useSelector((state) => state);

  const [currentValue, setCurrentValue] = useState();

  const get_currentValue = async () => {
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const rateData = await httpInstance(
        `${GET_GOLD_SILVER_RATE}`,
        httpConfig
      );
      setCurrentValue({
        goldRate: rateData.data?.data?.goldSellRate,
        silverRate: rateData.data?.data?.silverSellRate,
      });
    } catch {}
  };

  useEffect(() => {
    get_currentValue();
  }, []);

  const handleDelivery = () => {
    navigate({
      pathname: `${namespace}/myportfolio/delivery`,
      search: `?${createSearchParams({ tab: "getDelivery" })}`,
    });
  };

  const handleInvestment = () => {
    navigate(`${namespace}/myportfolio/digitalgold/detail-investment`);
  };
  return (
    <div>
      {!userStore.goldUser?.transactionalGoldBalance &&
      !userStore.goldUser?.transactionalSilverBalance ? (
        <EmptyPortPolio />
      ) : (
        <>
          <Box display="flex" justifyContent="center">
            <Typography className={classes.header}>
              Your current Investments
            </Typography>
          </Box>
          <Box>
            <Investment currentValue={currentValue} detail={true} />
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            p={4}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <button className={classes.primaryButton} onClick={handleDelivery}>
              Get Delivery
            </button>
            {/* not required now */}
            {/* <button className={classes.secondaryButton} >
              <Box display="flex" alignItems="center" justifyContent="space-between"> 
              <Box display="flex" alignItems="center" mr={{sm:17}}>
                <img src="/images/Calender.svg" height="15px" width="15px" className={classes.calender}/>
                <span >3 SIPs(₹2000)</span>
              </Box>
                <KeyboardArrowRightIcon/>
              </Box>
            
                </button> */}
          </Box>
          <OrderprogressCard />
          {/* <Transactions/> */}
        </>
      )}
    </div>
  );
};

export default Index;
