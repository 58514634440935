import React from 'react'
import UserManagement from '../../containers/Admin/UserManagement'

const User_managemnet = () => {
  return (
    <div>
        <UserManagement/>
    </div>
  )
}

export default User_managemnet