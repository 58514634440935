import React from 'react'
import {
  Cell,
  PieChart,
  Pie,
  ResponsiveContainer,
  Label,
} from "recharts";
import {  makeStyles, Grid } from "@material-ui/core";



const SuggestedPieChart = ({ data, colors, title, fundSize,suggested }) => {
    const useStyles = makeStyles((theme) => ({
        LegendList: {
          //display: "grid",
          //gridTemplateColumns: "1fr 1fr",
          listStyle: "none" /* Remove default bullets */,
          "& li": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            margin: "5px",
          },
        },
        cirecleBullet: {
          borderRadius: "50%",
          minWidth: "15px",
        },
        bulletLabel: {
          display: "flex",
          alignItems: "center",
          //wordBreak: "break-all",
        },
        subTitle: {
          fontWeight: 600,
          padding: "0px 3rem",
        },
    
        desktop: {
          display: "block",
        },
        mobileView: {
          display: "none",
        },
    
        "@media (max-width: 991px)": {
          desktop: {
            display: "none",
          },
          mobileView: {
            display: "block",
          },
        },
      }));
    
      const classes = useStyles();
    
      const Bullet = ({ backgroundColor, size }) => {
        return (
          <div
            className={classes.cirecleBullet}
            style={{
              backgroundColor,
              width: size,
              height: size,
            }}
          ></div>
        );
      };
    
    
      const CustomizedLegend2 = (props) => {
        const { payload } = props;
    
        return (
          <div>
            <p className={classes.subTitle}>{title}</p>
            <ul className={classes.LegendList}>
              {payload.map((entry, index) => (
                <li key={index}>
                  <Grid container>
                    <Grid item xs={10}>
                      <div className={classes.bulletLabel}>
                        <Bullet backgroundColor={colors[index]} size="15px" />
    
                        <div style={{ marginLeft: "10px", marginRight: "20px" }}>
                          {entry.name}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      {entry.value.toFixed(2)}%
                    </Grid>
                  </Grid>
                </li>
              ))}
            </ul>
          </div>
        );
      };
    
      const CustomLabel = ({ viewBox, labelText, value }) => {
        const { cx, cy } = viewBox;
        return (
          <g>
            <text
              x={cx}
              y={cy}
              // className="recharts-text recharts-label"
              textAnchor="middle"
              dominantBaseline="central"
              alignmentBaseline="middle"
              fill="#272B41"
              fontSize="13"
              fontWeight={600}
              
            >
              {suggested ? 
              `₹${value}`: `₹${value}Cr.`}
            </text>
          </g>
        );
      };
    
  return (
    <div>  
        <div >
            <Grid container>
                <Grid item xs={12} md={8} >
                    <CustomizedLegend2 payload={data}/>
                </Grid>
                <Grid item xs={12} md={4}>
                <ResponsiveContainer width={"100%"} height={250} >
            <PieChart>
              {/* <Legend
                content={<CustomizedLegend />}
                layout="vetical"
                verticalAlign="middle"
                align="left"
                width="70%"
                wrapperStyle={{
                  left: 0,
                  top: 0,
                  paddingBottom: 10,
                  position:"absolute"
                }}
              /> */}
              

              <Pie
                data={data}
                dataKey="value"
                outerRadius={100}
                innerRadius={75}
                cx="60%"
                cy="50%"
              >
                {data.map((entry, index) => (
                  <Cell key={index} fill={suggested ? colors[index%12] :colors[index]} />
                ))}
                <Label
                  content={<CustomLabel value={fundSize} />}
                  position="center"
                />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
                </Grid>
            </Grid>
          
        </div>
      
      
    </div>
  )
}

export default SuggestedPieChart