import React, { useEffect, useState } from "react";
import {
  Typography,
  makeStyles,
  Box,
  Grid,
  Container,
} from "@material-ui/core";

import { GET_GOLD_USER } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import Investment from "./Investment";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getNotificationCount } from "../../../redux/Notification/NotifiActions";
import { namespace } from "../../../environment";

const BuySellSuccess = ({ line1, line2 }) => {
  const useStyles = makeStyles((theme) => ({
    textgrey: {
      fontSize: "0.85rem",
      color: " #797979",
      margin: "3px",
      fontFamily: "inherit",
    },

    text: {
      fontSize: "0.85rem",
      color: " #797979",
      margin: "3px",
      fontWeight: 600,
      fontFamily: "inherit",
    },
    primaryButton: {
      padding: "0.8rem 5rem",
      background: "#326CF9",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      fontFamily: "inherit",
    },
  }));

  const classes = useStyles();

  const [balance, setBalance] = useState();

  const dispatch = useDispatch();

  useEffect(async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_GOLD_USER}`, httpConfig);
      setBalance({
        gold: response.data?.data?.goldBalance,
        silver: response.data?.data?.silverBalance,
      });
    } catch {}
    //------------------get notification-----------------------//
    dispatch(getNotificationCount());
  }, []);
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="center"
        p={3}
        flexDirection="column"
        alignItems="center"
      >
        <img src="/images/Group 22601.svg" height="220px" width="220px" />
        <Typography style={{ fontWeight: 600, fontFamily: "inherit" }}>
          Congratulations!
        </Typography>
        <Typography className={classes.textgrey}>{line1}</Typography>
        <Typography className={classes.textgrey}>{line2 && line2}</Typography>
        <Typography className={classes.text}>
          Order will take 48 hours to process
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography className={classes.textgrey}>
          Your Current Digital Gold Investment
        </Typography>
      </Box>
      <Investment balance={balance} />
      <Box display="flex" justifyContent="center" mt={5}>
        <Link to={`${namespace}/myportfolio?tab=digitalgold`}>
          <button className={classes.primaryButton}>Go to My Portfolio</button>
        </Link>{" "}
      </Box>
    </Box>
  );
};

export default BuySellSuccess;
