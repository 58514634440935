import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Switch,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
  GET_MAJOR_MINOR_AMC,
  ACTIVATE_DEACTIVATE_AMC,
  DELETE_AMC,MOVE_AMC
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from '@mui/icons-material/Add';
import AddAmcs from "./AddAmcs";



const Amcs = () => {
  const useStyles = makeStyles({
    root: {
      background: "#fff",
      borderRadius: "10px",
    },
    primaryButton: {
      background: "#326CF9",
      color: "#fff",
      padding: "5px 1.5rem",
      border: "none",
      borderRadius: "20px",
      fontFamily: "inherit",
      boxShadow: "0px 2px 26px rgba(0, 0, 0, 0.15)",
      cursor: "pointer",
      marginLeft: "3rem",
      display:"flex",
      alignItems:"center"
    },

    title: {
      fontSize: "0.9rem",
      color: "#686868",
      fontWeight: 600,
    },
    borderBox: {
      border: "1px solid #DDDDDD",
      borderRadius: "10px",
    },
    title: {
      fontSize: "0.9rem",
      color: "#686868",
      fontWeight: 600,
    },
    accordion: {
      borderRadius: "10px",
      border: 0,
      "& .MuiAccordionDetails-root": {
        display: "block",
      },
    },

    switch: {
      "& .MuiSwitch-colorSecondary.Mui-checked": {
        color: "black",
        "& .MuiSwitch-track": {
          background: "#0C97F0",
          opacity: 1,
        },
      },
      "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
        background: "#0C97F0",
        opacity: 1,
      },
      "& .MuiSwitch-switchBase": {
        color: "black",
      },
    },
    amcname: {
      fontSize: "0.9rem",
      color: "#686868",
    },
  });

  const classes = useStyles();

  //---------------------menu comp----------------------------------//
  const MenuComp=({id,type,delete_amc,move_amc,moveType})=>{
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
  return(
    <>
    <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={handleClick}
                              style={{ padding: "2px" }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              PaperProps={{
                                style: {
                                  boxShadow:
                                    "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                                },
                              }}
                            >
                              <MenuItem style={{ fontSize: "0.8rem" }} onClick={()=>move_amc(id,moveType)}>
                                {`Move to ${type==1 ? "Minor" : "Major"} Categories`}
                              </MenuItem>
                              <MenuItem style={{ fontSize: "0.8rem" }} onClick={()=>delete_amc(id,type)}>
                                Delete
                               
                              </MenuItem>
                            </Menu></>
  )
  }
  

  const [majorAmc, setMajorAmc] = useState([]);
  const [minorAmc, setMinorAmc] = useState([]);
  
  const [showAdd,setShowAdd]=useState(false)
  const [type,setType]=useState("")

  

  const get_major_amc = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      params: { type: 1 },
    };
    try {
      const response = await httpInstance(`${GET_MAJOR_MINOR_AMC}`, httpConfig);
      setMajorAmc(response.data?.data);
    } catch {}
  };

  const get_minor_amc = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      params: { type: 2 },
    };
    try {
      const response = await httpInstance(`${GET_MAJOR_MINOR_AMC}`, httpConfig);
      setMinorAmc(response.data?.data);
    } catch {}
  };

  useEffect(()=>{
    get_major_amc()
    get_minor_amc()
  },[])

  //---------------------active or deactive------------------------------------//

  const active_deactive = async (e, id, type) => {
    let arry = (type == "major" ? majorAmc : minorAmc).map((ele) => {
      if (id == ele.id) {
        return { ...ele, isActive: e.target.checked };
      } else {
        return { ...ele };
      }
    });
    type == "major" ? setMajorAmc(arry) : setMinorAmc(arry);
    //--------------------------------------//
    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      params: { id: id, isActivate: e.target.checked },
    };

    try {
      await httpInstance(`${ACTIVATE_DEACTIVATE_AMC}`, httpConfig);
    } catch {}
  };

  //---------------------------add Amcs-----------------------------------//
  const add_amc=(type)=>{
    setType(type)
    setShowAdd(true)
  }

  const handle_showAdd_close=()=>{
    if(type==1){
      get_major_amc()
    }else{
      get_minor_amc()
    }
    setShowAdd(false)
  }

  //----------------------------delete AMC---------------------------------------------------//
  const delete_amc=async(id,type)=>{
    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      params: { id: id}
    };

    try{
      await httpInstance(`${DELETE_AMC}`,httpConfig)
      type==1 ? get_major_amc(): get_minor_amc()
    }
    catch{

    }
  }

  //----------------------MOVE Amcs----------------------------------//
  const move_amc=async(id,type)=>{
    const httpConfig = {
      method: "POST",
      tokenType: "admin",
      headers: {
        "Content-Type": "application/json",
      },
      params: { id: id,type:type}
    };

    try{
      await httpInstance(`${MOVE_AMC}`,httpConfig)
      get_major_amc()
       get_minor_amc()
    }
    catch{

    }
  }

  return (
    <div>
      <Box pr={15} pl={3} mt={5}>
        <Box className={classes.root} p={4}>
          <Box display="flex" alignItems="center">
            <img
              src="/images/admin/amcs.svg"
              height="20px"
              width="20px"
              style={{ marginRight: "10px" }}
            />
            <Typography className={classes.title}>Active AMCs : {majorAmc?.length+minorAmc?.length}</Typography>
          </Box>
          <Box className={classes.borderBox} p={1} mt={4} px={2}>
            <Accordion variant="outlined" className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                //onClick={get_major_amc}
              >
                <Typography className={classes.title}>
                  Major Categories
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {majorAmc?.map((ele) => {
                  return (
                    <Box
                      key={ele.id}
                      p={1}
                      className={classes.borderBox}
                      mt={2}
                    >
                      <Grid container alignItems="center">
                        <Grid item xs={10}>
                          <Box display="flex">
                            <img
                              src={ele.amcLogo?.imgUrl}
                              height="25px"
                              width="25px"
                              style={{ marginRight: "15px" }}
                            />
                            <Typography className={classes.amcname}>
                              {ele.amcLogo?.amcName}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2} className={classes.switch}>
                          <Box display="flex" justifyContent="end">
                            <Switch
                              checked={ele.isActive}
                              onChange={(e) =>
                                active_deactive(e, ele.id, "major")
                              }
                              inputProps={{ "aria-label": "controlled" }}
                            />
                            <MenuComp id={ele.id} type={1} delete_amc={delete_amc} moveType={2} move_amc={move_amc}/>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
                <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                <button className={classes.primaryButton} onClick={()=>add_amc(1)}>
                  <AddIcon style={{fontSize:"1.2rem"}}/>
                  Add More</button>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          {/* minor cat */}
          <Box className={classes.borderBox} p={1} px={2}>
            <Accordion variant="outlined" className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                //onClick={get_minor_amc}
              >
                <Typography className={classes.title}>
                  Minor Categories
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {minorAmc?.map((ele) => {
                  return (
                    <Box
                      key={ele.id}
                      p={1}
                      className={classes.borderBox}
                      mt={2}
                    >
                      <Grid container alignItems="center">
                        <Grid item xs={10}>
                          <Box display="flex">
                            <img
                              src={ele.amcLogo?.imgUrl}
                              height="25px"
                              width="25px"
                              style={{ marginRight: "15px" }}
                            />
                            <Typography className={classes.amcname}>
                              {ele.amcLogo?.amcName}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2} className={classes.switch}>
                          <Box display="flex" justifyContent="end">
                            <Switch
                              checked={ele.isActive}
                              onChange={(e) =>
                                active_deactive(e, ele.id, "minor")
                              }
                              inputProps={{ "aria-label": "controlled" }}
                            />

                            <MenuComp id={ele.id} type={2} delete_amc={delete_amc} moveType={1} move_amc={move_amc}/>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
                <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                <button className={classes.primaryButton} onClick={()=>add_amc(2)}>
                  <AddIcon style={{fontSize:"1.2rem"}}/>
                  Add More</button>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>

      {/* ADD AMC */}
      <AddAmcs show={showAdd} handleClose={handle_showAdd_close} type={type}/>
    </div>
  );
};

export default Amcs;
