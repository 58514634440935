import { Box, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import CuratedCard from "../components/CuratedCard";
import SliderBar from "../components/SliderBar";

function CuratedContainer({handleShowModal}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      alignItems: "start",
      justifyContent: "space-around"
    },
    mobileRoot: { display: "none" },
    "@media (max-width: 900px)": {
      root: {
        display: "none",
        alignItems: "start",
        justifyContent: "space-around",
        flexDirection: "column",
       
      },
      mobileRoot: { display: "block" }
    }
  }));
  const classes = useStyles();

  const [step, setStep] = React.useState(1);
  const [time, setTime] = React.useState(Date.now());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setStep((prevCount) => prevCount + 1);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  

  if (step === 3) {
    setStep(1);
  }

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <CuratedCard
          title="Well Diversified"
          paragraph="Don't like going to deep research? We diversify your holdings for you."
          image={"/images/Expertly Managed.mp4"}
          handleShowModal={handleShowModal}
        />

        <CuratedCard
          title="Expertly Managed"
          paragraph="Formed by investment professionals based 
        on extensive research & financial analysis"
          image={"/images/Expertly Managed_1.mp4"}
          handleShowModal={handleShowModal}
        />
      </Box>
      <Box className={classes.mobileRoot}>
        {step == 1 ? (
          <CuratedCard
            title="Well Diversified"
            paragraph="Don't like going to deep research? We diversify your holdings for you."
            image={"/images/Expertly Managed.mp4"}
            handleShowModal={handleShowModal}
          />
        ) : (
          ""
        )}
        {step == 2 ? (
          <CuratedCard
            title="Expertly Managed"
            paragraph="Formed by investment professionals based 
        on extensive research & financial analysis"
            image={"/images/Expertly Managed_1.mp4"}
            handleShowModal={handleShowModal}
          />
        ) : (
          ""
        )}
      </Box>
      <Box mt={6}>
        <SliderBar step={step} bar={2} />
      </Box>
    </React.Fragment>
  );
}

export default CuratedContainer;
