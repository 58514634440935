import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox,FormGroup,FormControlLabel, Typography } from '@material-ui/core';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import {asset_Sub_Category} from '../../../components/Data/assetSubCatogory'

const riskType=[
  {value:"Aggressive", name:"Aggressive"},
  {value:"Moderately Aggressive", name:"Moderately Aggressive"},
  {value:"Conservative", name:"Conservative"},
  {value:"Moderately Conservative", name:"Moderately Conservative"},
  {value:"Balanced", name:"Balanced"},
]



const useStyles = makeStyles((theme) => ({
  root: {
    "& p":{
      margin:"4px"
    },
    width: '100%',
    "& .MuiAccordion-root:before":{
      height:"0px",
      top:-4
     },
     "& .MuiAccordion-rounded:first-child ":{
      borderTopLeftRadius:"10px",
      borderTopRightRadius:"10px"
     },
     "& .MuiAccordion-rounded:last-child ":{
      borderBottomLeftRadius:"10px",
      borderBottomRightRadius:"10px"
     },
     "& .MuiFormControlLabel-root":{
      padding:"5px 0px"
     }
  },
  heading: {
    fontSize:"1rem",
    fontWeight:500,
  },
  filterStyle:{
    justifyContent: "flex-start",
      alignItems:"top"
  },

  checkBox:{
    fontSize:"1rem",
    "& .Muip-body1":{
      fontSize:"0.85rem"
    },
    "& .MuiCheckbox-colorPrimary.Mui-checked": {
      color: "#0C97F0",
    },
  },
   accordion:{
     borderRadius:"10px"
   },

   reset:{
    fontSize:"0.9rem",
    color:"#0C97F0",
    textAlign:"end",
    cursor:"pointer"
   }
   
}));

const RatingImage=({rating})=>{
  return(
    [...Array(rating)].map((img,i)=>{
      return <img key={i} src="/images/starRating.svg"/>
    })
  )
}

export default function SimpleAccordion({allAmc,amc_filter,risk_filter,subAsset_filter,rating_filter,reset_filter}) {
  const classes = useStyles();
  
  const [checkedAmc, setCheckedAmc]=useState([])
  const [checkedRisk,setCheckedRisk]=useState([])
  const [checkedAsset, setCheckedAsset]=useState([])
  const [checkRate,setCheckRate]=useState([])

  const handle_amc=(e)=>{
    const { value, checked } = e.target
    let amcArry
    if(checked){
      amcArry=[...checkedAmc,value]
    }else{
      amcArry=checkedAmc.filter((e)=>e!=value)
    }
    setCheckedAmc(amcArry)
    amc_filter(amcArry.toString())
  }

  //---------------------------risk profile-----------------------------------------------//
  const handle_riskProfile=(e)=>{
    const {value,checked}=e.target
    let riskArry
    if(checked){
      riskArry=[...checkedRisk,value]
    }else{
      riskArry=checkedRisk.filter((e)=>e!=value)
    }
    setCheckedRisk(riskArry)
    risk_filter(riskArry.toString())
  }

  const handle_sub_asset=(e)=>{
    const {value,checked}=e.target
    let assetkArry
    if(checked){
      assetkArry=[...checkedAsset,value]
    }else{
      assetkArry=checkedAsset.filter((e)=>e!=value)
    }
    setCheckedAsset(assetkArry)
    subAsset_filter(assetkArry.toString())
  }

  const handle_rating=(e)=>{
    const {value,checked}=e.target
    let ratingArry
    if(checked){
      ratingArry=[...checkRate,value]
    }else{
      ratingArry=checkRate?.filter((e)=>e!=value)

    }
    setCheckRate(ratingArry)
    rating_filter(ratingArry)
    
  }



  //------------------handle reset--------------------------------------------//
  const handle_reset=()=>{
    setCheckedAmc([])
    setCheckedRisk([])
    setCheckedAsset([])
    setCheckRate([])
    reset_filter()
  }

  

  return (
    <div className={classes.root}>
      <Typography className={classes.reset} onClick={handle_reset}>Reset</Typography>
        <div className={classes.filterStyle}>
      <Accordion variant='outlined' className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.heading}
        >
       Pick AMC
        </AccordionSummary>
        <SimpleBar style={{ maxHeight: 300 }}>
        <AccordionDetails className={classes.checkBox}>
       
          <FormGroup>
            {allAmc?.map((amc,i)=>{
              return(
                <FormControlLabel 
                key={i}
                control={<Checkbox color="primary" value={amc.amcCode} checked={checkedAmc.includes(amc.amcCode)} onChange={handle_amc}/>}  
                label={amc.amcName} />
              )
            })}
            
          </FormGroup>
        
        
        </AccordionDetails>
        </SimpleBar>
      </Accordion>
      <Accordion variant='outlined' className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.heading}
        >
         Pick Rating
        </AccordionSummary>
        <AccordionDetails>
        <FormGroup>
            {[...Array(5)].map((rating,i)=>{
               let ratingCount=5-i
              return (
                <FormControlLabel 
                key={i}
                control={<Checkbox color="primary" value={5-i} checked={checkRate?.includes((5-i).toString())} onChange={handle_rating}/>}  
                label={<RatingImage rating={ratingCount}/> } />
              )
            })}
                         
          </FormGroup>
        </AccordionDetails>
      </Accordion>
      <Accordion variant='outlined' className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          className={classes.heading}
          
        >
         Pick Risk
        </AccordionSummary>
        <SimpleBar style={{ maxHeight: 300 }}>
        <AccordionDetails className={classes.checkBox}>
       
          <FormGroup>
            {riskType?.map((risk,i)=>{
              return(
                <FormControlLabel
                key={i} 
                control={<Checkbox color="primary" value={risk.value} checked={checkedRisk.includes(risk.value)} onChange={handle_riskProfile}/>}  
                label={risk.name} />
              )
            })}
            
          </FormGroup>
        
        
        </AccordionDetails>
        </SimpleBar>
      </Accordion>
      <Accordion variant='outlined' className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          className={classes.heading}
        >
         Pick Asset Sub Categories
        </AccordionSummary>
        <SimpleBar style={{ maxHeight: 200 }}>
        <AccordionDetails className={classes.checkBox}>
          <FormGroup>
            {asset_Sub_Category?.map((ele,i)=>{
              return(
                <FormControlLabel 
                key={i}
                control={<Checkbox color="primary" value={ele.value} checked={checkedAsset.includes(ele.value)} onChange={handle_sub_asset}/>}  
                label={ele.name} />
              )
            })}
            
          </FormGroup>
        
        
        </AccordionDetails>
        </SimpleBar>
      </Accordion>
      </div>
    </div>
  );
}
