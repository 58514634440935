import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";

function Intro({handleShowModal}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundImage: "url('./images/introBG.svg')",
      padding: "8rem 0rem 7rem 0rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "column"
    },
    headingOne: {
      fontSize: "3rem",
      fontWeight: 600,
      color: "#2C2C2C",
      marginBottom: "1.2rem"
    },
    headingTwo: {
      fontSize: "3rem",
      fontWeight: 600,
      color: "#F3C04F"
    },
    description: {
      fontSize: "1rem",
      fontWeight: 400,
      color: "#2C2C2C"
    },
    button: {
      padding: "1rem 4rem",
      background: "#326CF9",
      color: "#fff",
      fontSize: "1rem",
      borderRadius: "40px",
      border: "none",
      margin: "1.8rem 0",
      cursor:"pointer"
    },
    "@media (max-width: 900px)": {
      headingOne: {
        fontSize: "2rem"
      },
      headingTwo: {
        fontSize: "2rem"
      }
    }
  }));
  const classes = useStyles();
  return (
    <div className={classes.root} height="100%">
      <Box className={classes.headingOne}>
        India&apos;s No.1 <span className={classes.headingTwo}>Gold</span>
      </Box>
      <Box className={classes.headingOne}> Ecosystem</Box>
      <Box className={classes.description} my={2}>
        Buy Gold & Silver at best Rates
      </Box>
      <button className={classes.button} onClick={handleShowModal}>Invest Now</button>
    </div>
  );
}

export default Intro;
