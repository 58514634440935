import React from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/Footer'
import Faq from '../../containers/About/Faq'
import { isMobile } from "react-device-detect";

const Faqs = () => {
  return (
    <div>
     {!isMobile && <Header/>}
        <Faq/>
        { !isMobile &&  <Footer/>}
    </div>
  )
}

export default Faqs